export const workbenchProjectColumn = [
  {
    field: 'job_number',
    headerName: 'Project ID',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    cellRendererFramework: ({ value }) => value,
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'job_title',
    headerName: 'Title',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'stage',
    headerName: 'Status',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'created_by',
    headerName: 'Owner',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'days_open',
    headerName: 'Days Open',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'company_name',
    headerName: 'Company',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'industries',
    headerName: 'Industries',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'job_function',
    headerName: 'Job Function',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'location',
    headerName: 'Location',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'consultants',
    headerName: 'Consultants',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'capital_structure',
    headerName: 'Capital Structure',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'vertical_practice',
    headerName: 'Vertical Practice',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'horizontal_practice',
    headerName: 'Horizontal Practice',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'service_offering',
    headerName: 'Service Offering',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    headerName: 'Actions',
    width: 120,
    pinned: 'right',
    field: 'actions',
    cellRendererFramework: ({ value }) => value,
    suppressFilter: true
  }
];

export const workbenchContactColumn = [
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    },
    cellRendererFramework: ({ value }) => value
  },
  {
    field: 'company_name',
    headerName: 'Company',
    minWidth: 200,
    flex: 1,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'job_title',
    headerName: 'Job Title',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'job_function',
    headerName: 'Job Function',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'location',
    headerName: 'Location',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'contact_country_p1',
    headerName: 'Country',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'owner',
    headerName: 'Owner',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'last_activity_type',
    headerName: 'Last Activity Type',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'last_activity_date',
    headerName: 'Last Activity Date',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'updated_on',
    headerName: 'Updated On',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'phone_number',
    headerName: 'Phone Number',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'contact_industry_p1',
    headerName: 'Industries',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    field: 'source',
    headerName: 'Source',
    minWidth: 200,
    flex: 2,
    filter: 'agTextColumnFilter',
    sortable: true,
    menuTabs: ['filterMenuTab'],
    headerComponentParams: {
      menuIcon: 'fa-bars'
    }
  },
  {
    headerName: 'Actions',
    width: 120,
    pinned: 'right',
    field: 'actions',
    cellRendererFramework: ({ value }) => value,
    suppressFilter: true
  }
];
