//-----------------------------------------------------------// In-built Imports // ------------------------------

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBrandConfigsData } from '../../actions';

//-----------------------------------------------------------// External Imports // ------------------------------

import { defaultTheme } from '../../theme';
const convertThemeConfig = (config = {}, theme) => {
  const newTheme = { ...theme };
  const configList = [
    { srcKey: 'primary', dbKey: 'primary_color' },
    { srcKey: 'secondary', dbKey: 'secondary_color' },
    { srcKey: 'text', dbKey: 'text_color' },
    { srcKey: 'heading', dbKey: 'heading_color' },
    { srcKey: 'error', dbKey: 'error_color' },
    { srcKey: 'background', dbKey: 'background_color' }
  ];
  for (const element of configList) {
    newTheme[element.srcKey] = config[element.dbKey] || newTheme[element.srcKey];
  }
  return newTheme;
};
export const useThemeHook = () => {
  const [theme, setTheme] = useState(defaultTheme());
  const [colorData, setColorData] = useState({});
  const dispatch = useDispatch();
  const brandConfigs = useSelector(state => state.commonReducer.brandConfigs);
  useEffect(() => {
    if (!brandConfigs) {
      /* Pass the Brand Config Version dynamically once the UI Setup integration done */
      dispatch(fetchBrandConfigsData('brandConfigs', 'is_default=true'));
    } else {
      let themeData = {
        primary: '#009d81',
        secondary: '#23cea7',
        text: '#333',
        heading: '#1b4965',
        error: '#c3423f',
        background: '#f0f1f6'
      };
      const newThemeData = convertThemeConfig(brandConfigs, themeData);
      setColorData(newThemeData);
      // themeData = null;
      // const newTheme = defaultTheme(newThemeData);
      // setTheme(newTheme);
    }
  }, [brandConfigs]);

  useEffect(() => {
    const newTheme = defaultTheme(colorData);
    setTheme(newTheme);
  }, [colorData]);

  return { theme, colorData };
};
