//-----------------------------------------------------------// In-built Imports // ------------------------------
import React, { useEffect, useRef, useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------
import { AppBar, Avatar, Box, Button, Checkbox, Grid, Link, Stack, Toolbar, Tooltip } from '@mui/material';
import 'ag-grid-community/dist/styles/ag-grid.css';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import withRouter from '../../utils/withRouter';
// import IosShareIcon from "@mui/icons-material/IosShare";
import AddIcon from '@mui/icons-material/Add';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PlaceIcon from '@mui/icons-material/Place';
import { useSnackbar } from 'notistack';

//-----------------------------------------------------------// Internal Imports // ------------------------------
import contact_image from '../../../src/assets/images/default_contact.png';
import { fetchUserList } from '../../actions';
import { cleanupCompanyDetails } from '../../components/AddContact/utils';
import Loader from '../../components/common/Loader';
import {
  acquireLockApi,
  ignAthenaDataApi,
  // contactDataApi,
  releaseLockApi /*, workbench_data*/ /* contactDataRefreshApi */
} from '../../services/ApiService';
import { VALIDATION_MESSAGE, notFoundMessage } from '../../services/MessageService';
import { EMAIL_TYPE, IGNYTE_GLOBALS, IGN_API, LINKEDIN_SCRAPER, PHONE_TYPE, POST, WARNING } from '../../services/constantService';
import { CompensationRenderer, SCROLL_TIMEOUT, sortByChronologicalOrder } from '../../utils/common';
import AddContactToWorkbench from '../Commons/AddContactToWorkbench';
import GenericCellEditor from '../Commons/CellEditors/GenericCellEditor';
import PhoneEditor from '../Commons/CellEditors/PhoneEditor';
import ColumnFilter from '../Commons/ColumnFilter';
import CustomFilter from '../Commons/CustomFilter';
import { DateRenderer, DateTimeRenderer } from '../Commons/Utils';
import '../Commons/index.scss';
import CopyCandidatesToSearch from '../Searches/AllCandidates/CopyCandidatesToSearch';
import ActionsPopover from './ActionsPopover';
import ExportSideNav from './ExportSideNav';
import Search from './Search';
import './index.scss';
import {
  ContactNameRenderer,
  CustomLoadingOverlayComponent,
  PAGE_LIMIT,
  ResumeRenderer,
  SUCCESS_STATUS_CODE,
  acquire as acquireUtil,
  addAsMyBDTarget,
  checkContactFetchingStatus,
  columnDefs,
  defaultColumns,
  getFilterParamString,
  getQueryParamsString
} from './utils';
// import QueryContact from "./components/QueryContact";
import ViewContactDrawer from '../../components/ViewContactDrawer';
import AddContactToProject from '../../components/common/AddContactToProject';
import CustomButton from '../../components/common/CustomButton';
import GridActionSelection from '../../components/common/FunctionalComponents/GridActionSelection';
import TotalCount from '../../components/common/TotalCount/index';
import { getAccessToken } from '../../services/cognitoService';
import { useLanguageHooks } from '../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../utils/languageNamespaces';
import { addHttps } from '../../utils/string';
import AssignTag from './AssignTag';
// import ContactGridSelection from "../../components/common/FunctionalComponents/ContactGridSelection";
import moment from 'moment-timezone';
import CustomPhoneInput, { formatPhoneNumber } from '../../components/common/CustomPhoneInput';
import CustomPopover from '../../components/common/CustomPopover';
import { getUserSelector } from '../../selectors';
import { AthenaEmailInviteModal } from '../../utils/AthenaInvite';
import { dataSourceUtils } from '../../utils/dataSource';
import { CompanyNameRenderer } from '../Companies/Utils';
let gridApi, columnApi;

const App = props => {
  const [isRemove, setIsRemove] = useState(false);
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [contactIds, setContactIds] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  const [isAddToWorkbenchOpen, setIsAddToWorkbenchOpen] = useState(false);
  const [masterQuery, setMasterQuery] = useState('');
  const [isClassicQuery, setIsClassicQuery] = useState(false);
  const [quickQuery, setQuickQuery] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [filterModel, setFilterModel] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [isTagPopupOpen, setIsTagPopupOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState(false);
  const [employmentHistoryMessageEndYearDate, setEmploymentHistoryMessageEndYearDate] = useState(null);
  const [isUpdateEmploymentHistory, setIsUpdateEmploymentHistory] = useState(false);
  const [skipCheckJobTitle, setSkipCheckJobTitle] = useState(false);
  const [currentContact, setCurrentContact] = useState(null);
  const [newContactData, setNewContactData] = useState(null);
  const [showFilterCount, setShowFilterCount] = useState(0);
  const [contactId, setContactId] = useState('');
  const [isAddToSearchOpen, setIsAddToSearchOpen] = useState(false);
  const [isGridReady, setIsGridReady] = useState(false);
  const [sortModel] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [tagPopUp, setTagPopUp] = useState([]);
  const [tagDataId, setTagDataId] = useState([]);
  const [isBulkTag, setIsBulkTag] = useState(false);
  const [tagChange, setTagChange] = useState(false);
  const [isAddToProjectOpen, setIsAddToProjectOpen] = useState(false);
  const [tagList, setTagsList] = useState();
  const [anchorElTagsHoverPopup, setAnchorElTagsHoverPopup] = useState(null);
  const [isEdited, setIsEdited] = useState(false);
  const [isSelectedFromList, setIsSelectedFromList] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const userData = useSelector(getUserSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tagListRef = useRef();
  tagListRef.current = tagList;
  const isSelectedListRef = useRef();
  const quickQueryRef = useRef();
  const masterQueryRef = useRef();
  const isClassicQueryRef = useRef();
  const url = useRef();
  isSelectedListRef.current = isSelectedFromList;
  masterQueryRef.current = masterQuery;
  quickQueryRef.current = quickQuery;
  isClassicQueryRef.current = isClassicQuery;
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [loadedContactCount, setLoadedContactCount] = useState(0);
  const { id } = useParams();
  const paramsValueID = useRef();

  //invite to athena
  const [athenaInviteModalOpen, setAthenaInviteModalOpen] = useState(false);
  const [athenaInviteData, setAthenaInviteData] = useState();

  const [selectedContactId, setSelectedContactId] = useState(null);

  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_ADD_CONTACT,
    TRANS_KEYS.CONTACTS_ACTION,
    TRANS_KEYS.CONTACTS_SEARCH,
    TRANS_KEYS.CONTACTS_EXPORT_LIST,
    TRANS_KEYS.CONTACTS_SAVE_LIST,
    TRANS_KEYS.CONTACTS_ABOUT,
    TRANS_KEYS.CONTACTS_CONTACT_DETAILS,
    TRANS_KEYS.CONTACTS_RECENT_ACTIVITY,
    TRANS_KEYS.CONTACTS_NAME,
    TRANS_KEYS.CONTACTS_SELECT,
    TRANS_KEYS.CONTACTS_TAGS,
    TRANS_KEYS.CONTACTS_INVITE_TO_ATHENA,
    TRANS_KEYS.CONTACTS_ASSIGN_TAG,
    TRANS_KEYS.CONTACTS_ADD_TO_LIST,
    TRANS_KEYS.CONTACTS_ADD_TO_PROJECT,
    TRANS_KEYS.CONTACTS_LOG_AN_ACTIVITY,
    TRANS_KEYS.CONTACTS_SELECT_TAG,
    TRANS_KEYS.CONTACTS_NOTES,
    TRANS_KEYS.CONTACTS_TRY_ATHENA
  ]);
  const openTagsViewMore = Boolean(anchorElTagsHoverPopup);
  const openDrawer = params => {
    navigate(`/contacts/list/all-contacts/${params.data.id}`);
  };

  useEffect(() => {
    const isContactView = location.pathname.includes(id);
    if (isContactView && !isDrawerOpen && isComponentLoaded) {
      setIsDrawerOpen(true);
    }
  }, [location?.pathname, isComponentLoaded]);

  useEffect(() => {
    if (tagChange && !isTagPopupOpen) {
      gridApi && gridApi.onFilterChanged();
    }
  }, [tagChange, isTagPopupOpen]);

  useEffect(() => {
    if (location && location.state) {
      setMasterQuery(location.state);
    }
  }, [location]);

  useEffect(() => {
    const accessToken = async () => {
      url.current = await getAccessToken();
    };
    accessToken();
  }, [contacts]);

  useEffect(() => {
    if (!isDrawerOpen && isEdited) {
      gridApi && gridApi.onFilterChanged();
      setIsEdited(false);
    }
  }, [isDrawerOpen, isEdited]);

  const onGridReady = params => {
    columnApi = params.columnApi;
    gridApi = params.api;
    params.columnApi.autoSizeColumns();
    // loadColumnStateFromLocalStorage("contactColumns", { columnApi, gridApi });
    setIsGridReady(true);
  };

  const emailRenderer = value => {
    if (value) {
      return (
        <Link className='link' href={`mailto:${value}`} target={'noopener'}>
          {value}
        </Link>
      );
    }
    return null;
  };

  const linkedInRenderer = (linkedinUrl, linkedin_username) => {
    return (
      linkedinUrl && (
        <Link href={addHttps(linkedinUrl)} target='_blank' rel='noopener noreferrer' className='link'>
          {linkedin_username}
        </Link>
      )
    );
  };
  const getLocation = address => {
    const newAddress = address && address.filter(item => item?.address_type === 'work');
    if (newAddress.length === 1) {
      const addressArray = [newAddress[0]?.address_lines, newAddress[0]?.city, newAddress[0]?.contact_country?.name];
      const newAddressArray = addressArray.filter(item => item !== null && item !== undefined && item !== '');
      return newAddressArray.length !== 0 ? newAddressArray.join(', ') : '-';
    } else {
      const addressDetails = address && address.filter(item => item?.address_type === 'residential');
      const addressArray = [addressDetails[0]?.address_lines, addressDetails[0]?.city, addressDetails[0]?.contact_country?.name];
      const newAddressArray = addressArray.filter(item => item !== null && item !== undefined && item !== '');
      return newAddressArray.length !== 0 ? newAddressArray.join(', ') : '-';
    }
  };

  const navItems = [
    { label: t(`${TRANS_KEYS.CONTACTS_SAVE_LIST}:key`), key: 'saveList' },
    { label: t(`${TRANS_KEYS.CONTACTS_EXPORT_LIST}:key`), key: 'exportList' }
  ];

  const contactDetailsRenderer = params => {
    if (params?.data) {
      const contactPhone = params.data.contact_phones && params.data.contact_phones.find(ele => ele.is_primary || PHONE_TYPE.PERSONAL.field_value);
      const contactEmail = params.data.contact_emails && params.data.contact_emails.find(ele => ele.is_primary || EMAIL_TYPE.PERSONAL.field_value);
      const contactAddress = params.data.contact_address ? params.data.contact_address : '';
      return (
        <Box id='contact-details-renderer'>
          <Box className='d-flex'>
            <Box className='d-flex p-2'>
              <CallIcon className='contact_details_icon mr-1' />
              <Tooltip title={contactPhone?.phone_number}>
                <Box component='div' className='details ml-2 fs-14 content-wrap'>
                  {contactPhone?.phone_number ? (
                    <div className='flex'>
                      <CustomPhoneInput className='phone-input-read-only' id='outlined-basic' variant='outlined' size='small' value={contactPhone.phone_number} onChange={() => {}} disabled={true} />
                      <span className='text-truncate fs-14'>{formatPhoneNumber(contactPhone?.phone_number)}</span>
                    </div>
                  ) : (
                    '--'
                  )}
                </Box>
              </Tooltip>
            </Box>
            <Box className='d-flex p-2'>
              <EmailIcon className='contact_details_icon mr-1' />
              <Tooltip title={contactEmail?.email}>
                <Box component='div' className='details-width ml-2 fs-14 content-wrap'>
                  {contactEmail?.email ? emailRenderer(contactEmail?.email) : '--'}
                </Box>
              </Tooltip>
            </Box>
          </Box>
          <Box className='d-flex'>
            <Box className='d-flex py-0 px-2'>
              <PlaceIcon className='contact_details_icon mr-1' />
              <Tooltip title={getLocation(contactAddress)}>
                <Box component='div' className='details ml-2 fs-14 content-wrap'>
                  {getLocation(contactAddress)}
                </Box>
              </Tooltip>
            </Box>
            <Box className='d-flex py-0 px-2'>
              <LinkedInIcon className='contact_details_icon mr-1' />
              <Tooltip title={params?.data?.linkedin_username}>
                <Box component='div' className='details-width ml-2 fs-14 content-wrap'>
                  {params?.data?.linkedin_username && params.data.linkedin_url ? linkedInRenderer(params.data.linkedin_url, params?.data?.linkedin_username) : '--'}
                </Box>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      );
    }
  };

  const getLastActivity = params => {
    const activityData = params?.data?.contact_notes_all?.[0] || false;
    if (activityData) {
      return (
        <Box className='d-flex p-2' id='contact-details-renderer'>
          <Tooltip
            title={
              <Box spacing={2}>
                <Grid container spacing={2} key={activityData?.id} m={1}>
                  <Grid activityData xs={10} md={10.5} lg={8}>
                    <Box>
                      {activityData?.notes_type !== 'Call' && (
                        <Box className='mb-2'>
                          <Box className='fs-12'>
                            <Box component='span'> {userData?.name} Logged a</Box> <Box component='span'>Note</Box> {activityData?.notes_type ? 'as a ' : ''}
                            <Box component='span'>{activityData?.ign_translate_value?.short_desc ? activityData?.ign_translate_value?.short_desc : activityData?.notes_type}</Box>
                          </Box>
                        </Box>
                      )}
                      {(activityData?.project?.job_number || activityData?.project?.job_title) && (
                        <Box className='mb-2'>
                          <Box component='span' className='fs-12 mr-2' variant='body1'>
                            {`${t(`${TRANS_KEYS.CONTACTS_WORD_FOR}:key`)}:`}
                          </Box>
                          <Box component='span' className='fs-12'>
                            {activityData?.project?.job_number || activityData?.project?.job_title ? `${activityData?.project?.job_number} - ${activityData?.project?.job_title}` : '-'}
                          </Box>
                        </Box>
                      )}
                      <Box mb={0.5}>
                        <Box component='span' className='fs-12 mr-2' variant='body1'>
                          {`${t(`${TRANS_KEYS.CONTACTS_NOTES}:key`)}:`}
                        </Box>
                        <Box component='span' className='fs-12'>
                          {activityData?.notes ? <Box component='span' dangerouslySetInnerHTML={{ __html: activityData.notes || '-' }} /> : '-'}
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid activityData xs={12} md={12} lg={3} className='d-flex justify-content-end'>
                    <Box mr={1}>
                      <Box className='mb-2'>
                        <Box component='span' className='d-flex fs-12'>
                          {/* {item?.time} */}
                          {activityData?.activity_added_at ? moment(activityData?.activity_added_at).utc().local().format('HH:mm, DD/MM/YYYY') : ''}
                        </Box>
                      </Box>
                      <Box className='fs-12 mb-2'>
                        {activityData?.created_user?.name && (
                          <Box component='span' className='fs-12'>
                            Last Edited By
                            <Box component='span' className='ml-1 fs-12 '>
                              {activityData?.updated_user?.name ? activityData?.updated_user?.name : activityData?.created_user?.name}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            }
          >
            {userData?.created_user === null ? (
              <Box component='div' className='details-width align-items-center fs-14 content-wrap d-flex justify-content-center'>
                --
              </Box>
            ) : (
              <Box component='div' className='details-width ml-2 fs-14 content-wrap'>
                <Box component='span'>{userData?.name} Logged a</Box>{' '}
                <Box component='span' className='value-text-activity fs-12 activity-type mx-1'>
                  Note
                </Box>{' '}
                {activityData?.notes_type ? 'as a' : ''}
                <Box component='span' className='value-text-activity fs-12 activity-type mx-1'>
                  {activityData?.ign_translate_value?.short_desc ? activityData?.ign_translate_value?.short_desc : activityData?.notes_type}
                </Box>
              </Box>
            )}
          </Tooltip>
        </Box>
      );
    } else {
      return (
        <Box component='div' className='details-width align-items-center fs-14 content-wrap d-flex' sx={{ marginLeft: '77px' }}>
          {'--'}
        </Box>
      );
    }
  };

  const getRecordsType = data => {
    if (data) {
      return (
        <Tooltip
          title={data?.contact_record_types?.map(e => {
            if (e.type_desc) return `${e.type_desc} `;
          })}
        >
          <Box className='px-2'>
            {data?.contact_record_types &&
              data?.contact_record_types.map(
                (item, index) =>
                  item && (
                    <Box component='span' className={`fs-12 content-wrap color-type-${item.type_desc}`} key={index}>
                      {item.type_desc}&nbsp;
                    </Box>
                  )
              )}
          </Box>
        </Tooltip>
      );
    }
  };

  const aboutRenderer = params => {
    // const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const openDrawer = params => {
      navigate(`/contacts/list/all-contacts/${params.data.id}`);
      // const { pathname } = window.location;
      // const isContactView = pathname.includes(`profile`)
      // if (isContactView && !isDrawerOpen) {
      //   setIsDrawerOpen(true);
      // }
      // setIsDrawerOpen(true);
    };

    // const closeDrawer = () => {
    //   setIsDrawerOpen(false);
    // };
    if (params?.data) {
      return (
        // <Link to={`/contacts/list/all-contacts/${params.data.id}`}>
        <Box
          id='about-renderer'
          onClick={() => {
            openDrawer(params);
          }}
        >
          <>
            {params?.data?.current_job_title ? (
              <Tooltip title={params?.data?.current_job_title}>
                <Box component='div' className='header-content p-2 fs-14 header-font content-wrap'>
                  {' '}
                  {params.data.current_job_title}
                </Box>
              </Tooltip>
            ) : (
              <Box className='p-2 fs-14 content-wrap'> {'--'}</Box>
            )}
          </>
          <Tooltip title={params?.data?.contact_company?.name}>
            <Box component='div' className='fs-12 px-2'>
              {params?.data?.contact_company ? params.data.contact_company.name : '--'}
            </Box>
          </Tooltip>
        </Box>
        //  </Link>
      );
    }
  };

  const nameColumn = params => {
    if (params?.data) {
      let name = params.data && params.data.first_name + ' ' + params.data.last_name;
      return (
        // <Link to={`/contacts/list/all-contacts/${params.data.id}`}>
        <Box
          id='name-column'
          onClick={() => {
            openDrawer(params);
          }}
        >
          <Avatar
            variant='square'
            className='contact-icon'
            src={params?.data?.image_id ? `${IGN_API.contact}/${params?.data?.id}/image?token=${url.current}&time=${Date.now()}` : contact_image}
            alt='contact image'
          />
          <Box component='div' className='ml-1 column-width'>
            <Tooltip title={name}>
              <Box component='div' className='header-content header-font p-2 fs-14 content-wrap'>
                {name}
              </Box>
            </Tooltip>
            {/* <Box component="div" className="fs-12 px-2 content-wrap color-type">
              {params?.data?.contact_record_types ? params?.data?.contact_record_types.map((elem) => elem.type) : "--"}
            </Box> */}
            {getRecordsType(params?.data)}
          </Box>
        </Box>
        //  </Link>
      );
    }
  };

  useEffect(() => {
    dispatch({ type: 'resetLinkedinScraperEvents' });
    if (props && props.location && props.location.state && typeof props.location.state === 'string') {
      setMasterQuery(props.location.state);
    }
    if (!props.users) {
      fetchUserList('users');
    }
    dispatch({ type: 'resetLinkedinScraperEvents' });
    if (skipCheckJobTitle) {
      handleUpdateJobHistory();
    }
    if (props[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED]) {
      const event = props[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED];
      console.log('socket event found:: ', LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED, event.msg, event.obj);
      let contactFound;
      gridApi.forEachNode(node => {
        console.log('node', node.data?.id, contactId);
        console.log('socket obj::', event.obj?.contact?.id);
        if (node.data?.id === event.obj?.contact?.id) {
          contactFound = node;
        }
      });
      console.log('socket event contact found:: ', contactFound);
      if (contactFound) {
        contactFound.setData(event.obj.contact);
        gridApi.refreshCells({ force: true });
      }
      dispatch({
        type: LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED,
        payload: undefined
      });
    }
    if (props.refreshContact) {
      gridApi.onFilterChanged();
    }
  }, []);

  const getURLs = {
    listURl: `${IGN_API.contact}/`,
    quickQuery: IGN_API.contact
  };

  const dataSource = {
    getRows: async params => {
      try {
        setIsLoading(true);
        //gridApi.showLoadingOverlay();
        params.filterModel && setFilterModel(params.filterModel);
        setShowFilterCount(Object.keys(params.filterModel)?.length);
        //  this.gridApi.deselectAll();
        if (params.filterModel && Object.keys(params.filterModel)?.length > 0) gridApi.deselectAll();
        // setIsLoading(true)
        const thisValue = {
          quickQuery: quickQueryRef.current,
          isSelectedFromList: isSelectedListRef.current
        };
        if (masterQueryRef.current) {
          handleSearchChange('', masterQueryRef.current);
        } else {
          const obj = {
            params: params,
            context: thisValue,
            pageLimit: PAGE_LIMIT,
            url: getURLs,
            contactsScreen: true
          };
          const getData = await dataSourceUtils(obj, getFilterParamString, getQueryParamsString);
          const { status, data } = getData;
          if (status === SUCCESS_STATUS_CODE) {
            if (Array.isArray(data?.contacts)) {
              const message = notFoundMessage('records');
              if (data?.length === 0) {
                enqueueSnackbar(message, { variant: WARNING });
              }
              params.successCallback(data?.contacts, data?.total);
              setRowCount(data?.total);
              setLoadedContactCount(data?.totalRowCount);
              setContacts(data?.contacts);
            } else if (data) {
              if (data?.length === 0) {
                const message = notFoundMessage('records');
                enqueueSnackbar(message, { variant: WARNING });
              }
              params.successCallback(data, data?.length);
              setLoadedContactCount(0);
              setRowCount(data?.length);
              setContacts(data);
            }
            isAllRowsSelected && setSelectAllRows(isAllRowsSelected);
          } else {
            params.failCallback();
          }
          setIsComponentLoaded(true);
          // setIsLoading(false);
          //gridApi.hideOverlay();
        }
        setIsLoading(false);
        //gridApi.hideOverlay();
      } catch (e) {
        console.log('Error found in getRows::', e);
      }
      // localStorage.removeItem('contactColumns')
    },
    rowCount: null
  };

  const handleSearchChange = (event, value) => {
    setIsClassicQuery(false);
    setIsSelectedFromList(false);
    if (masterQuery) {
      setMasterQuery('');
    }
    if (typeof value === 'string') {
      setQuickQuery(value);
      setSearchValue(value);
    } else if (value && value.id) {
      setIsSelectedFromList(true);
      setQuickQuery(value.id);
      setSearchValue(value.name);
    }
    setQuickQuery(value?.id ? value?.id : value ? value : '', () => {
      gridApi.onFilterChanged();
    });
    resetFilter();
    resetSort();
  };

  const resetFilter = async () => {
    gridApi.setFilterModel(null);
  };

  const resetSort = async () => {
    gridApi.setSortModel(null);
  };

  // const toggleMenu = () => {
  //   setIsMenuOpen(true);
  // };
  const navigateToAddContact = () => {
    navigate('/contacts/add/details');
  };

  const getRowClass = params => {
    if (params?.node?.id === paramsValueID.current) {
      return 'ag-row-hover';
    } else {
      return '';
    }
  };
  const handleAssignTag = (data, bulkTag = '') => {
    if (bulkTag === 'bulkTag') {
      const ids = [];
      const contactTags = [];
      data.map(dataItem => {
        ids.push(dataItem.id);
        contactTags.push(dataItem.contact_tags);
      });
      setTagDataId('');
      setIsBulkTag(true);
      setTagPopUp(contactTags);
    } else {
      setTagPopUp(data.contact_tags);
      setTagDataId(data.id);
      setIsBulkTag(false);
    }

    setIsTagPopupOpen(true);
  };

  const handleTryAthena = async data => {
    try {
      const payload = {
        project_id: null,
        contact_id: data?.id
      };
      const res = await ignAthenaDataApi(POST, '', payload, 'create-assessment');
      console.log({ athenaRes: res });

      const assessmentId = res?.data ?? '';
      if (assessmentId.length > 0) {
        navigate(`/athena/${assessmentId}/${data?.name}`);
      }
    } catch (err) {
      enqueueSnackbar(err);
    }
  };

  const setSelectAllRows = isAllRowsSelected => {
    setIsAllRowsSelected(isAllRowsSelected);
    gridApi.forEachNode(node => {
      node.setSelected(isAllRowsSelected);
    });
  };

  const handleChange = event => {
    const checked = event.currentTarget.checked;
    setSelectAllRows(checked);
  };

  const headerCheckbox = () => {
    return (
      <Checkbox
        // className="checkbox-style"
        className='header-checkbox'
        classes={{ root: 'custom-checkbox-root' }}
        size='small'
        color='primary'
        onChange={e => handleChange(e)}
        // style={{
        //   borderColor: "#ffffff",
        //   width: 24,
        //   height: 24
        // }}
      />
    );
  };

  const nameRenderer = params => {
    return <ContactNameRenderer params={params} handleAddToSearch={handleAddToSearch} />;
  };

  const companyNameRenderer = params => {
    const status = checkContactFetchingStatus(params);
    if (status) return status;
    return <CompanyNameRenderer company={params?.data?.company} />;
  };

  const addContactToRenderer = params => {
    const status = checkContactFetchingStatus(params);
    if (status) return status;
    const list = [
      {
        label: 'To Search',
        onClick: () => handleAddToSearch(params?.data?.id)
      },
      {
        label: 'To Workbench',
        onClick: () => handleAddToWorkbench(params.data?.id)
      },
      {
        label: 'As BD Target',
        onClick: () => addAsMyBDTarget(params?.data, props.userData, gridApi, enqueueSnackbar)
      },
      {
        label: 'As Client',
        onClick: () => handleAddAsClient(params?.data?.id)
      }
    ];
    return params.data ? <ActionsPopover list={list} /> : null;
  };

  const handleAddToSearch = contactId => {
    setIsAddToSearchOpen(true);
    setContactIds([contactId]);
  };

  const handleAddToProject = contactId => {
    setIsAddToProjectOpen(true);
    setSelectedContactId(contactId);
  };

  const handleAddAsClient = contactId => {
    //setIsAddAsClientOpen(true);
    setContactIds([contactId]);
  };

  const handleAddToSearchClose = () => {
    setIsAddToSearchOpen(false);
    setContactIds([]);
  };
  const handleAddToProjectClose = () => {
    setIsAddToProjectOpen(false);
  };

  // const showContactActivityHistory = (contact) => {
  //   setActiveContact(contact);
  //   console.log(activeContact);
  // };

  const acquire = async contactId => {
    let { status, data } = await acquireLockApi(contactId);
    if (status && status === 200 && data.message === undefined) {
      if (isRemove) {
        //setIsDeleteOpen(true);
        setContactId(contactId);
        setIsRemove(false);
      } else {
        await releaseLockApi(contactId);
        navigate(`/contacts/${contactId}/log-an-activity/general-info`, {
          sortModel: sortModel,
          fromContact: true,
          previousPath: props.location.pathname
        });
      }
    } else {
      //if (data.isAdmin) {
      enqueueSnackbar(data.message, {
        variant: WARNING,
        // eslint-disable-next-line react/display-name
        action: key => (
          <>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={async () => {
                closeSnackbar(key);
                releaseLock(contactId);
              }}
            >
              Force Unlock
            </Button>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                closeSnackbar(key);
                navigate(`/contacts/${contactId}/log-an-activity/read-only/general-info`, {
                  sortModel: sortModel,
                  fromContact: true,
                  previousPath: props.location.pathname
                });
              }}
            >
              Open Read Only View
            </Button>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={async () => {
                closeSnackbar(key);
              }}
            >
              CLOSE
            </Button>
          </>
        )
      });
    }
  };
  const releaseLock = async contactId => {
    await releaseLockApi(contactId);
    acquire(contactId);
  };

  const handleAddToWorkbench = contactId => {
    setIsAddToWorkbenchOpen(true);
    setContactIds([contactId]);
  };

  const handleAddToWorkbenchClose = () => {
    setIsAddToWorkbenchOpen(false);
  };

  const loaderChange = async value => {
    setIsLoading(value);
  };
  const GetActionRenderer = params => {
    return (
      <GridActionSelection
        handleAssignTag={handleAssignTag}
        handleAddToProject={handleAddToProject}
        handleTryAthena={handleTryAthena}
        params={params}
        id={params?.data?.id}
        data={params?.data}
        setIsAddToProjectOpen={setIsAddToProjectOpen}
        handleInviteAthena={handleInviteAthena}
      />
    );
  };
  const openTagsPopup = (event, tags) => {
    setTagsList(tags);
    setAnchorElTagsHoverPopup(event.currentTarget);
  };
  const closeTagsPopup = () => {
    setAnchorElTagsHoverPopup(null);
  };
  const getTags = params => {
    if (params?.data) {
      return (
        <Box className='tag-container tag-pointer'>
          {params?.data?.contact_tags &&
            params?.data?.contact_tags.slice(0, 2).map(
              (item, index) =>
                item && (
                  <Box
                    component='div'
                    onMouseOver={e => {
                      openTagsPopup(e, params?.data?.contact_tags);
                    }}
                    onMouseLeave={closeTagsPopup}
                    className={'p-1 fs-14 tag-border'}
                    key={index}
                    style={{ color: item.ign_tags?.color }}
                  >
                    {item.ign_tags?.name}
                  </Box>
                )
            )}
        </Box>
      );
    }
  };

  const handleUpdateJobHistory = async () => {
    setSkipCheckJobTitle(false);
    const params = newContactData;
    if (isUpdateEmploymentHistory) {
      setIsLoading(true);
      const contact = currentContact;
      let newJobHistory = [
        {
          company: contact.company,
          title: contact.current_job_title,
          start_year: contact.current_job_start_year,
          end_year: employmentHistoryMessageEndYearDate || new Date().getFullYear()
        }
      ];
      newJobHistory = [...newJobHistory, ...contact.job_history]?.filter(item => item?.company);
      params.job_history = sortByChronologicalOrder(cleanupCompanyDetails(newJobHistory, contact.id));
    }
    await acquireUtil(params, 'current_job_title', enqueueSnackbar, closeSnackbar, true, loaderChange, undefined, undefined, true);
    setEmploymentHistoryMessageEndYearDate(null);
    setIsUpdateEmploymentHistory(false);
    setCurrentContact(null);
    setNewContactData(null);
  };

  const handleInviteAthena = async data => {
    setAthenaInviteData(data);
    setAthenaInviteModalOpen(true);
  };

  return (
    <Box id='contact-tab-container' className='list-contacts d-flex flex-column'>
      <Loader show={isLoading} />
      <Helmet>
        <title>Contacts - {IGNYTE_GLOBALS.BRAND}</title>
      </Helmet>

      <Box className='tool-bar mb-3'>
        <AppBar position='static'>
          <Toolbar>
            <Search value={searchValue} onChange={handleSearchChange} type={'contacts'} className='search-bar' placeholder={t(`${TRANS_KEYS.CONTACTS_SEARCH}:key`)} />

            <Box className='tool-bar' />
            <Stack direction='row' spacing={3}>
              {/* 20th March 2024: Temporary commanted this code as per requirement */}
              {/* {isRowSelected ? (
                <ContactGridSelection
                  handleAssignTag={handleAssignTag}
                  toggleActivity={toggleActivity}
                  handleAddToProject={handleAddToProject}
                  // params={params}
                  // id={params?.data?.id}
                  data={selectedRows}
                  setIsAddToProjectOpen={setIsAddToProjectOpen}
                />
              ) : null}

              <CustomButton
                onClick={toggleMenu}
                type={"secondary"}
                size={"medium"}
                variant="outlined"
                width={42}
                customWidth={42}
                iconLeft={<IosShareIcon sx={{ width: 20, height: 20 }} />}
              /> */}
              <CustomButton
                variant='contained'
                onClick={navigateToAddContact}
                type={'primary'}
                size={'medium'}
                buttonText={t(`${TRANS_KEYS.CONTACTS_ADD_CONTACT}:key`)}
                customWidth={110}
                iconLeft={<AddIcon sx={{ width: 20, height: 20 }} />}
              />
            </Stack>
          </Toolbar>
        </AppBar>
      </Box>
      <Box className='d-flex justify-content-end mb-3'>
        {/* <QueryContact /> */}
        <TotalCount rowCount={rowCount} selectedRowsCount={selectedRowsCount} isRowSelected={isRowSelected} loadedContactCount={loadedContactCount} showTotalCount={false} />
      </Box>

      {isAddToWorkbenchOpen && (
        <AddContactToWorkbench
          open={isAddToWorkbenchOpen}
          contactIds={contactIds}
          onClose={handleAddToWorkbenchClose}
          //contactsQuery={queryDataRef}
          type='Contacts'
        />
      )}
      {isAddToSearchOpen && (
        <CopyCandidatesToSearch
          contacts={contacts}
          header={VALIDATION_MESSAGE.add_contacts_to_search}
          buttonText={VALIDATION_MESSAGE.add_contacts_to_search}
          uiLabel='contact(s)'
          open={isAddToSearchOpen}
          contactIds={contactIds}
          // isAllRowsSelected={showQuery && isAllRowsSelected}
          //queryData={queryDataRef}
          handleClose={handleAddToSearchClose}
        />
      )}
      {isAddToProjectOpen && <AddContactToProject data={{ id: selectedContactId }} isPopupOpen={isAddToProjectOpen} handleClose={handleAddToProjectClose} />}
      <ExportSideNav setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} location={location} navItems={navItems}></ExportSideNav>

      <Box id='view-contact-drawer'>
        <>
          <ViewContactDrawer isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} id={id} setIsEdited={setIsEdited} baseRoute={`/contacts/list/all-contacts/${id}`} />
        </>
      </Box>

      <AssignTag
        tagPopUp={tagPopUp}
        tagId={tagDataId}
        isBulkTag={isBulkTag}
        setIsPopupOpen={setIsTagPopupOpen}
        isPopupOpen={isTagPopupOpen}
        setTagChange={setTagChange}
        label={t(`${TRANS_KEYS.CONTACTS_SELECT_TAG}:key`)}
        selectedRows={selectedRows}
        isAllRowsSelected={isAllRowsSelected}
      />
      <CustomPopover
        id='mouse-over-popover-tags'
        open={openTagsViewMore}
        anchorEl={anchorElTagsHoverPopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={closeTagsPopup}
        pointerEvents='none'
      >
        <Box onMouseEnter={event => event.stopPropagation()} sx={{ pointerEvents: 'auto' }}>
          {tagListRef.current && (
            <Box>
              {tagListRef?.current.map((tag, index) => (
                <Box key={index} className={`tag-border m-1 p-1 tag${tag?.ign_tags?.name}`}>
                  {tag?.ign_tags?.name}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </CustomPopover>
      <Box className='list-view'>
        {isGridReady && <ColumnFilter columnApi={columnApi} defaultColumns={defaultColumns} showFilterCount={showFilterCount} filterModel={filterModel} />}
        <Box id='myGrid' className='ag-theme-alpine'>
          <AgGridReact
            onGridReady={onGridReady}
            enableBrowserTooltips={true}
            defaultColDef={{
              width: 1501,
              resizable: true,
              sortable: false,
              sortingOrder: ['asc', 'desc', null]
            }}
            rowHeight={70}
            isRowSelectable={params => {
              const status = checkContactFetchingStatus(params);
              if (status) return false;
              return true;
            }}
            cacheBlockSize={PAGE_LIMIT}
            loadingOverlayComponent={'CustomLoadingOverlayComponent'}
            frameworkComponents={{
              CustomLoadingOverlayComponent,
              GetActionRenderer: GetActionRenderer,
              contactDetailsRenderer: contactDetailsRenderer,
              AddContactToRenderer: addContactToRenderer,
              NameRenderer: nameRenderer,
              ResumeRenderer,
              HeaderCheckbox: headerCheckbox,
              DateTimeRenderer,
              DateRenderer,
              CompanyNameRenderer: companyNameRenderer,
              GenericCellEditor,
              PhoneEditor,
              CompensationRenderer,
              CustomFilter,
              //AthenaRenderer: athenaRenderer,
              getLastActivity: getLastActivity,
              aboutRenderer: aboutRenderer,
              getTags: getTags,
              nameColumn
            }}
            blockLoadDebounceMillis={SCROLL_TIMEOUT}
            scrollbarWidth={12}
            suppressHorizontalScroll={false}
            suppressDragLeaveHidesColumns={true}
            rowModelType={'infinite'}
            datasource={dataSource}
            columnDefs={columnDefs(t)}
            suppressMenuHide={true}
            paginationPageSize={PAGE_LIMIT}
            rowSelection={'multiple'}
            getRowNodeId={data => data.id}
            onRowSelected={() => {
              // if (!e.node.selected) {
              //   this.setState({ isAllRowsSelected: false })
              // }
              setSelectedRowsCount(gridApi?.getSelectedRows()?.length);
              setIsRowSelected(gridApi?.getSelectedRows()?.length > 0);
              setSelectedRows(gridApi?.getSelectedRows());
            }}
            suppressRowClickSelection={true}
            getRowClass={getRowClass}
          ></AgGridReact>
          <AthenaEmailInviteModal isOpen={athenaInviteModalOpen} onClose={() => setAthenaInviteModalOpen(false)} contact={athenaInviteData} />
        </Box>
      </Box>
    </Box>
  );
};

App.propTypes = {
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func,
  location: PropTypes.object,
  navigate: PropTypes.func,
  userData: PropTypes.object,
  users: PropTypes.array,
  fetchUserList: PropTypes.func,
  socket: PropTypes.object,
  refreshContact: PropTypes.bool,
  [LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED]: PropTypes.object,
  dispatch: PropTypes.func
};

// const mapStateToProps = (state) => {
//   return {
//     userData: state.commonReducer.userData,
//     users: state.rootReducer.users,
//     refreshContact: state.rootReducer.refreshContact,
//     [LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED]:
//       state.rootReducer[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED],
//   };
// };
// const mapDispatchToProps = (dispatch) => ({
//   fetchUserList,
//   dispatch,
// });

// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App))
export default withRouter(App);
