import { Box, ListItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { navItems } from '../../../components/SideNav';
import ViewContactDrawer from '../../../components/ViewContactDrawer';
import { createEnglishStatements } from '../../../Containers/Home/utils';
import { ROUTES } from '../../../services/constantService';
import { highlightText, renderRichText } from '../../../utils/common.js';

const useStyles = makeStyles({
  link: {
    fontStyle: 'italic',
    fontSize: '12px',
    fontWeight: 500
  },
  title: {
    fontSize: '14px',
    fontWeight: 900
  },
  logItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    cursor: 'pointer',
    paddingRight: '.2rem',
    fontSize: '12px'
  },
  rightText: {
    fontWeight: 'lighter',
    fontStyle: 'italic',
    fontSize: '12px',
    marginTop: '10px'
  },
  dialogChildSlider: {
    zIndex: 9999 // Set a higher value for z-index
  }
});

export function LogItem({ item }) {
  const log = item?._source;
  const styles = useStyles();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const logOwner = log?.updated_user?.first_name ? log?.updated_user : log?.created_user;
  const logOwnerName = ((logOwner?.first_name || '') + ' ' + (logOwner?.last_name || '')).trim();

  const name = ((log?.contact?.first_name || '') + ' ' + (log?.contact?.last_name || '')).trim();
  let iconNames = ['searches', 'contacts', 'companies'];
  const iconItems = navItems.filter(item => iconNames.includes(item.key));

  const getIcon = log => {
    if (log?.activity_type === 'candidate_stage_change') {
      return iconItems.find(item => item.key === iconNames[1])?.logo;
    } else if (log?.activity_type === 'project_stage_update') {
      return iconItems.find(item => item.key === iconNames[2])?.logo;
    } else {
      return iconItems.find(item => item.key === iconNames[0])?.logo;
    }
  };
  const Icon = getIcon(log);

  return (
    <ListItem sx={{ padding: 0, marginBottom: 2 }}>
      <Box display={'flex'} alignItems={'center'} width={'100%'} height={'10vh'} paddingBottom={1} borderBottom={'1px solid rgba(0, 0, 0, 0.3)'}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon sx={{ width: '53px', height: '53px', borderRadius: '50%' }} color='disabled' />
        </Box>

        <Box width={'100%'} display={'grid'} gridTemplateColumns={'1fr 2fr'} alignItems={'center'} marginLeft={3}>
          <Box className={styles.logItem} sx={{ fontWeight: 'inherit' }} onClick={() => {}}>
            {log?.activity_type?.includes('candidate_stage_change') ? (
              <Box>
                <span className={styles.link} onClick={() => setIsDrawerOpen(true)}>{`${name ?? 'Unknown user'}`}</span>
                <span>{' moved to'}</span>
                <b>
                  <span>{` ${log?.to_stage} `}</span>
                </b>
                <span>on</span>
                <span
                  className={styles.link}
                  onClick={() => {
                    navigate(`${ROUTES.projectDetails}/${log?.project_id}`);
                  }}
                >{` #${log?.project?.job_number || '0000'}-${log?.project?.job_title || '.'}`}</span>
              </Box>
            ) : log?.activity_type?.includes('project_stage_update') ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  fontWeight: 600
                }}
              >
                {createEnglishStatements([log])?.map((activity, index) => {
                  return (
                    <Box key={index}>
                      <span className={styles.link} onClick={() => navigate(`${ROUTES.projectDetails}/${activity.project_id}`)}>
                        #{activity.reference}{' '}
                      </span>
                      <span>{activity.action}</span>
                      <span
                        style={{
                          fontWeight: 'bold'
                        }}
                      >
                        {' '}
                        {activity.stage_to}
                      </span>
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <Box fontWeight={600} display={'flex'} flexDirection={'column'} justifyContent={'center'} gap={0.2}>
                <span className={styles.title}>{` ${logOwnerName || 'Unknown user'} logged a ${log?.activity_type} for `}</span>
                <span
                  className={styles.link}
                  onClick={() => {
                    navigate(`${ROUTES.projectDetails}/${log?.project_id}`);
                  }}
                >{`#${log?.project?.job_number || '0000'}-${log?.project?.job_title || '.'}`}</span>
              </Box>
            )}

            <ViewContactDrawer navigateToAllContacts={false} isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} id={log?.contact_id} />
          </Box>
          {log?.notes?.trim().length > 0 && (
            <Box width={'100%'} borderRadius={2} padding={1} bgcolor={'rgba(149, 159, 183, 0.07)'}>
              <Typography fontSize={'12px'} color={'grey'} fontWeight={600}>
                {renderRichText(highlightText(log?.notes?.trim() || '', item?.highlight?.notes?.[0] || ''), {
                  maxWidth: '100%',
                  maxCount: 250,
                  textStyles: {
                    color: '#979FB5',
                    fontWeight: 600
                  },
                  readMoreStyles: {
                    color: '#1b4965',
                    fontSize: '10px'
                  }
                })}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </ListItem>
  );
}

LogItem.propTypes = {
  item: PropTypes.object
};
