//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';
import { useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Typography, FormControl, RadioGroup, FormControlLabel, Radio, InputLabel } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { KeyboardDatePicker } from '@material-ui/pickers';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from '../../../../common/InputField';
import ActivitySelection from '../../ActivitySelection';
import ActivityStageSelection from '../../ActivityStageSelection';
import SearchSelection from '../../../../../components/SearchSelection';
import ArenaStageSelection from '../../ArenaStageSelection';
import RichTextPopup from '../../../../RichTextPopup';
export default function LogAnActivity(props) {
  const { register, watch, setValue, isContactActivityLog, fromHistoryPopup = false, contact, hideNextSteps, arenaSearch, readOnly, selectedHistory } = props;
  useEffect(() => {
    register('stage');
    register('search');
    register('activity');
    register('type');
    register('reason');
    register('arena_stage');
    register('activity_created_at');
    register('comments');
  }, [register]);
  const { control } = useForm();
  return (
    <div className={`w-100 section-content ${readOnly && 'read-only'}`}>
      {fromHistoryPopup || hideNextSteps ? null : <Typography className='section-label'>Log An Activity</Typography>}
      {!isContactActivityLog && (
        <div className='d-flex flex-grow-1'>
          <ActivityStageSelection watch={watch} setValue={setValue} />
        </div>
      )}
      <div className='d-flex flex-grow-1'>
        <ActivitySelection watch={watch} setValue={setValue} selectedHistory={selectedHistory} />
      </div>
      <div className='d-flex flex-grow-1'>
        <FormControl className='w-100' style={{ paddingRight: 20 }}>
          <Typography className='input-label'>
            Direction{' '}
            <span style={{ fontSize: 12, display: 'inline-flex' }} className='MuiInputLabel-asterisk'>
              *
            </span>
          </Typography>
          <RadioGroup
            row
            aria-label='gender'
            name='direction'
            onChange={e => {
              setValue('direction', e.target.value);
            }}
            value={watch('direction') || null}
            className='radio-group'
          >
            <FormControlLabel control={<Radio size='small' value='Outgoing' inputRef={register('direction')} />} label='Outgoing' />
            <FormControlLabel control={<Radio size='small' value='Incoming' inputRef={register('direction')} />} label='Incoming' />
          </RadioGroup>
        </FormControl>
        {isContactActivityLog && (
          <>
            <Controller
              name='search'
              render={({ field }) => (
                <SearchSelection
                  {...field}
                  name='search'
                  {...register('search')}
                  watch={watch}
                  className='field-value'
                  defaultValue={watch('search') || null}
                  label=''
                  form={true}
                  placeholder={'Search #'}
                  contactId={contact?.id}
                  contactName={contact?.first_name + ' ' + contact?.last_name}
                  stage='Open'
                  onChange={(e, data) => {
                    setValue('search', data ? data : null);
                  }}
                />
              )}
              control={control}
              onChange={([data]) => data}
            />
          </>
        )}
        {!isContactActivityLog && !fromHistoryPopup && arenaSearch ? (
          <ArenaStageSelection className='input-form-field input-field-old' InputLabelProps={{ focused: true }} value={watch('arena_stage')} onChange={(e, data) => setValue('arena_stage', data)} />
        ) : null}
      </div>
      <KeyboardDatePicker
        className='input-form-field input-field-old'
        //disableToolbar
        autoOk
        variant='inline'
        format='dd/MM/yyyy'
        label='Activity Date'
        placeholder='Activity Date'
        InputLabelProps={{ focused: true }}
        value={watch('activity_created_at') || null}
        onChange={date => {
          setValue('activity_created_at', date);
        }}
      />
      <InputField {...register('subject')} className='input-form-field input-field-old' label='Subject' required={true} InputLabelProps={{ focused: true }} name='subject' placeholder='Subject' />
      <div className='contact-details mt-2 mb-2'>
        <InputLabel className='contact-view-label'>Comments</InputLabel>
        <div className='outlined-border transform p-0'>
          <RichTextPopup
            name={'comments'}
            watch={watch}
            form={true}
            onChange={data => {
              setValue('comments', data);
            }}
            value={watch('comments')}
            className='input-form-field input-field-old'
            title='Comments'
          />
        </div>
      </div>
    </div>
  );
}

LogAnActivity.propTypes = {
  register: PropTypes.func,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  isContactActivityLog: PropTypes.string,
  fromHistoryPopup: PropTypes.bool,
  contact: PropTypes.object,
  arenaSearch: PropTypes.bool,
  autoSaveSubmit: PropTypes.func,
  saveStatus: PropTypes.bool,
  hideNextSteps: PropTypes.bool,
  readOnly: PropTypes.bool,
  selectedHistory: PropTypes.object
};
