import { useState } from 'react';
import {
  createCompanyOffLimit,
  createDirectOfflimit,
  deleteCompanyOffLimit,
  deleteDirectOfflimit,
  getCompanyOffLimit,
  getOffLimitConfig,
  getOffLimitFromSummary,
  updateCompanyOffLimit,
  updateDirectOfflimit,
  updateOffLimitConfig
} from './useOfflimitHelpers';

export const useOfflimit = () => {
  const [contactOffLimit, setContactOffLimit] = useState([]);
  const [companyOffLimit, setCompanyOffLimit] = useState([]);
  const [offLimitConfig, setOffLimitConfig] = useState([]);
  const [hasLimitApplied, setIsLimitApplied] = useState(false);
  const [isConfigFetched, setIsConfigFetched] = useState(false);

  return {
    contactOffLimit,
    offLimitConfig,
    hasLimitApplied,
    companyOffLimit,
    isConfigFetched,
    createCompanyOffLimit: async data => {
      const result = await createCompanyOffLimit(data);
      const offLimit = result.find(entry => entry !== null);
      if (offLimit) {
        setCompanyOffLimit([offLimit]);
        setIsLimitApplied(true);
      }
    },
    updateCompanyOffLimit: async data => {
      const result = await updateCompanyOffLimit(data);
      if (result) {
        setCompanyOffLimit([result]);
      }
    },
    deleteCompanyOffLimit: async companyId => {
      const success = await deleteCompanyOffLimit(companyId);
      if (success) {
        setCompanyOffLimit([]);
      }
    },
    createDirectOfflimit: async data => {
      const result = await createDirectOfflimit(data);
      if (result) {
        setContactOffLimit(prevData => [...prevData, result]);
        setIsLimitApplied(true);
      }
    },
    updateDirectOfflimit: async data => {
      const result = await updateDirectOfflimit(data);
      if (result) {
        setContactOffLimit(prevData => {
          const inherited = prevData.filter(item => item.type !== 'Direct');
          const direct = prevData.find(item => item.type === 'Direct');
          const newDirectState = { ...direct, ...result };
          return [...inherited, newDirectState];
        });
      }
    },
    deleteDirectOfflimit: async contactId => {
      const success = await deleteDirectOfflimit(contactId);
      if (success) {
        setContactOffLimit(prevData => prevData.filter(item => item.type !== 'Direct'));
      }
    },
    getOffLimitFromSummary: async contactId => {
      const result = await getOffLimitFromSummary(contactId);
      if (result) {
        setContactOffLimit(result);
        setIsLimitApplied(result.length > 0);
      }
    },
    getCompanyOffLimit: async companyId => {
      const result = await getCompanyOffLimit(companyId);
      if (result) {
        setCompanyOffLimit(result);
        setIsLimitApplied(result.length > 0);
      }
    },
    getOffLimitConfig: async () => {
      const config = await getOffLimitConfig();
      if (config) {
        setIsConfigFetched(true);
        setOffLimitConfig(config);
        return config;
      }
    },
    updateOffLimitConfig: async data => {
      const config = await updateOffLimitConfig(data);
      if (config) {
        setOffLimitConfig(config);
      }
    }
  };
};
