import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, CircularProgress, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Select, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Loader from '../../../../components/common/Loader';
import { BulkCandidateCreateApi, getMethodWithCancelTokenApi } from '../../../../services/ApiService';
import { IGN_API } from '../../../../services/constantService';
const ChooseFromContact = props => {
  const { open, setOpen, callback, stages, openCandidateDrawer, initStage, projectId } = props;
  const [searchValue, setSearchValue] = useState('');
  const [selectedStage, setSelectedStage] = useState('');
  const [rowData, setRowData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const gridRef = React.useRef(null);
  // const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
    setSelectedIds([]);
    setSelectedStage('');
    setRowData([]);
    setSearchValue('');
  };
  const onSelectionChanged = event => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => {
      return {
        contact_id: node.data.id,
        id: undefined,
        ...node.data
      };
    });
    setSelectedIds(selectedData);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3
  };
  const columnDefs = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
      sortable: false,
      suppressMenu: true,
      resizable: false,
      headerName: '',
      field: 'id'
    },
    {
      headerName: 'Name',
      field: 'first_name',
      sortable: true,
      filter: true,
      cellRendererFramework: params => (
        <div onClick={() => openCandidateDrawer(params.data.id)}>
          {params.data.first_name} {params.data.last_name}
        </div>
      )
    },
    {
      headerName: 'Job Title',
      field: 'current_job_title',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Company',
      field: 'company_name',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Updated At',
      field: 'updated_at',
      cellRendererFramework: params => {
        return params.value ? new Date(params.value).toLocaleDateString() : '';
      },
      sortable: true,
      filter: true
    },
    {
      headerName: 'Source',
      field: 'source',
      sortable: true,
      filter: true
    }
  ];
  const handleSearchChange = e => {
    setSearchValue(e.target.value);
  };
  const getSearchData = async () => {
    const url = `${IGN_API.picklists}/contacts?name=${searchValue}`;
    setLoading(true);
    if (searchValue && searchValue.length > 3) {
      await getMethodWithCancelTokenApi(url, {}, {}).then(response => {
        const { status, data } = response;
        if (status === 200) {
          const records = data?.data?.map(record => ({ ...record, company_name: record?.contact_company?.name }))
          setRowData(records);
          if (data?.data?.length === 0) {
            enqueueSnackbar('No data found', { variant: 'error' });
            setRowData([]);
          }
        }
      });
      setLoading(false);
    }
  };

  const handleSave = async () => {
    setPageLoading(true);
    if (selectedIds.length > 0) {
      if (!selectedStage) {
        enqueueSnackbar('Please select stage', { variant: 'error' });
        return;
      }
      const formattedData = selectedIds.map(data => ({
        contact_id: data?.id,
        stage: selectedStage,
        project_id: projectId
        // ...data
      }));
      await BulkCandidateCreateApi({
        candidates: formattedData
      })
        .then(response => {
          const { status } = response;
          if (status === 200) {
            enqueueSnackbar('Candidates added successfully', { variant: 'success' });
          }
        })
        .catch(error => {
          enqueueSnackbar(error?.message || 'Something went wrong', { variant: 'error' });
        });
      await callback(selectedIds, selectedStage);
      setPageLoading(false);
      setOpen(false);
      setSelectedIds([]);
      setSelectedStage('');
      setRowData([]);
      setSearchValue('');
    }
  };
  useEffect(() => {
    if (initStage === 'All') setSelectedStage('');
    else setSelectedStage(initStage);
  }, [initStage]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{
        zIndex: '7!important'
      }}
    >
      <Box sx={{ ...style, width: '80%', border: 'none' }}>
        {pageLoading && <Loader show={pageLoading} />}
        <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #ebedf1', paddingBottom: '.6rem', marginBottom: '1rem', alignItems: 'center' }}>
          <Typography variant='h3' className='fs-16'>
            Add existing Candidate
          </Typography>

          <IconButton onClick={handleClose} style={{ cursor: 'pointer' }}>
            <CloseIcon />
          </IconButton>
        </div>
        <Stack>
          <Grid container gap={4}>
            <Grid item xs={2.5}>
              {/* <Search value={searchValue} onChange={handleSearchChange} type={'contacts'} className='search-bar w-100' placeholder={'Search Candidate, Job, Company'} /> */}
              <FormControl fullWidth>
                {/* <InputLabel id='demo-simple-search-label'>Search Candidate, Job, Company</InputLabel> */}
                <TextField
                  id='demo-simple-search-label'
                  label='Search Candidate, Job, Company'
                  size='small'
                  variant='outlined'
                  value={searchValue}
                  onChange={handleSearchChange}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      getSearchData();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={getSearchData}
                          onMouseDown={e => {
                            e.preventDefault();
                          }}
                        >
                          {loading ? <CircularProgress size={20} /> : <SearchIcon />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3.5}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Select Stage</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={selectedStage}
                  size='small'
                  label='Select Stage'
                  onChange={e => {
                    setSelectedStage(e.target.value);
                  }}
                >
                  {stages &&
                    stages?.map(stage => {
                      if (stage?.isLogicalStage) return;
                      return (
                        <MenuItem key={stage?.stage_name} value={stage?.stage_name}>
                          {stage?.stage_name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
          <div id='myGrid' className='ag-theme-alpine mx-2 ag-grid-container' style={{ height: '300px' }}>
            <Loader loading={loading} className={loading ? 'ag-overlay-loading-center' : ''} />
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              rowData={rowData}
              suppressRowClickSelection={true}
              paginationPageSize={10}
              rowSelection='multiple'
              enableRangeSelection={true}
              onSelectionChanged={onSelectionChanged}
              defaultColDef={{
                resizable: true,
                filter: true,
                sortable: true
              }}
              // headerComponentFramework={CustomHeader}
              // {...sx}
              columnMenu={true}
              // onGridReady={onGridReady}
            />
          </div>
          {/* {isGridReady && (
        <ColumnFilter
          columnApi={columnApi}
          defaultColumns={defaultColumns}
          //showFilterCount={showFilterCount}
          //filterModel={filterModel}
        />
      )} */}
        </Stack>
        <Stack direction='row' spacing={2} justifyContent='flex-end' sx={{ padding: '1rem' }}>
          <Button variant='text' onClick={handleClose}>
            Cancel
          </Button>
          <Tooltip title={selectedIds.length === 0 ? 'Please select candidate' : selectedStage === '' ? 'Please select stage' : ''}>
            <div>
              <Button variant='contained' onClick={handleSave} disabled={selectedIds.length === 0 || selectedStage === ''}>
                Add Candidate
              </Button>
            </div>
          </Tooltip>
        </Stack>
      </Box>
    </Modal>
  );
};

ChooseFromContact.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleGet: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  stages: PropTypes.array,
  openCandidateDrawer: PropTypes.func.isRequired,
  initStage: PropTypes.string,
  projectId: PropTypes.string
};

export default ChooseFromContact;
