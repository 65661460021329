//----------------------------------------------// In-built Imports // -------------------------------------------------
import React /* useState */ from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Drawer, Slide } from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';

const DrawerComponent = props => {
  const {
    children,
    id,
    anchor,
    open,
    onClose,
    width = '50vw',
    BackdropProps,
    isViewDrawer = false,
    variant,
    drawerWidth,
    setDrawerWidth = () => {},
    isWidthApply,
    setIsWidthApply = () => {},
    transitionType = Slide,
    top = '',
    drawerHeight,
    minWidthDrawer,
    drawerPosition,
    drawerCloseIconTop = '45px',
    overFlowY,
    isViewCloseIcon = true
  } = props;

  const toggleDrawerWidth = () => {
    if (isWidthApply) {
      setIsWidthApply(!isWidthApply);
      setDrawerWidth('normal');
    } else {
      setIsWidthApply(!isWidthApply);
      setDrawerWidth('normal');
    }
  };

  return (
    <>
      <Drawer
        id={id}
        anchor={anchor}
        variant={variant}
        sx={{
          '& .MuiDrawer-paper': {
            overflowY: 'visible'
          }
        }}
        open={open}
        PaperProps={{
          sx: {
            width: drawerWidth === 'full' && isViewDrawer === true ? '93vw' : width,
            height: drawerHeight,
            top: top,
            minWidth: minWidthDrawer,
            position: drawerPosition,
            overflowY: overFlowY
          }
        }}
        BackdropProps={BackdropProps}
        TransitionComponent={transitionType}
        transitionDuration={500}
        top='60px'
      >
        {isViewCloseIcon && <CloseOutlined className='close-rectangle p-1' onClick={onClose} sx={{ top: drawerCloseIconTop }} />}
        {isViewDrawer === true &&
          (isWidthApply === false ? (
            <OpenInFullIcon className='normalwidth-square p-1' onClick={toggleDrawerWidth} />
          ) : (
            <CloseFullscreenIcon className='normalwidth-square p-1' onClick={toggleDrawerWidth} />
          ))}
        {children}
      </Drawer>
    </>
  );
};

DrawerComponent.propTypes = {
  open: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  anchor: PropTypes.string,
  isMenuOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  width: PropTypes.string,
  BackdropProps: PropTypes.bool,
  isViewDrawer: PropTypes.bool,
  variant: PropTypes.string,
  drawerWidth: PropTypes.string,
  setDrawerWidth: PropTypes.func,
  isWidthApply: PropTypes.bool,
  setIsWidthApply: PropTypes.func,
  marginTop: PropTypes.string,
  transitionType: PropTypes.func,
  isViewCloseIcon: PropTypes.bool,
  drawerHeight: PropTypes.string,
  top: PropTypes.func,
  minWidthDrawer: PropTypes.string,
  drawerPosition: PropTypes.string,
  drawerCloseIconTop: PropTypes.string,
  overFlowY: PropTypes.string
};

export default DrawerComponent;
