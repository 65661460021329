//-----------------------------------------------------------// In-built Imports // ------------------------------
import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

//-----------------------------------------------------------// Internal Imports // ------------------------------
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Avatar, CircularProgress, Link, MenuItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { t } from 'i18next';
import toUpper from 'lodash/toUpper';
import '../../../Containers/Commons/AutoCompleteStyle/index.scss';
import '../../../Containers/Commons/CheckboxStyle/index.scss';
import { ROUTES } from '../../../services/constantService';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import { addHttps } from '../../../utils/string';
import CustomButton from '../CustomButton';
import './index.scss';
// import { useLanguageHooks } from "../../../utils/Hooks/UseLanguageHook";
// import { TRANS_KEYS } from "../../../utils/languageNamespaces";

const CustomDropdown = props => {
  // const { t } = useLanguageHooks([
  //   TRANS_KEYS.CONTACTS_SELECT]);
  const {
    label = '',
    className,
    required = false,
    color = 'heading',
    // placeholder = t(`${TRANS_KEYS.CONTACTS_SELECT}:key`),
    multiple = false,
    isCheckBox = false,
    options,
    disableCloseOnSelect = false,
    name = '',
    disabled = false,
    isDrawer = false,
    isIcon = false,
    isAvatar = false,
    avatarKey = 'logo',
    size = 'small',
    onInputChange = () => {},
    viewAddress,
    selectedValue = [],
    addAnotherButton = false,
    filterOptions,
    addAnotherButtonText = 'Add Another',
    coloredDropdown = false,
    isLoading = false,
    isToAddCompany = false,
    setAddCompanyPopup,
    InputLabelProps = {},
    ...rest
  } = props;

  return (
    <Autocomplete
      {...rest}
      popupIcon={<ExpandMoreIcon />}
      multiple={multiple}
      disabled={disabled}
      className={viewAddress === true ? 'animate-icon' : `AutoCompleteStyle animate-icon ${className}`}
      options={options}
      size={size}
      disableCloseOnSelect={disableCloseOnSelect}
      onInputChange={onInputChange}
      filterOptions={filterOptions}
      // !Added to avoid "The value provided to Autocomplete is invalid." warning
      // Ref: https://stackoverflow.com/questions/61947941/material-ui-autocomplete-warning-the-value-provided-to-autocomplete-is-invalid
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      getOptionLabel={option => {
        const labelValue = option?.short_desc
          ? option.short_desc
          : option?.label
            ? option.label
            : option?.name
              ? option?.name
              : option?.description
                ? option?.description
                : option?.market
                  ? option?.market
                  : option?.stage_name
                    ? option?.stage_name
                    : option?.email;
        if (typeof option === 'string') {
          return option;
        }
        return labelValue || '';
      }}
      getOptionSelected={(option, value) => {
        const labelValue = option?.short_desc
          ? option.short_desc
          : option?.label
            ? option.label
            : option?.name
              ? option?.name
              : option?.description
                ? option?.description
                : option?.market
                  ? option?.market
                  : option?.stage_name
                    ? option?.stage_name
                    : option?.email;
        const values = value?.short_desc
          ? value.short_desc
          : value.label
            ? value.label
            : value.name
              ? value?.name
              : value?.description
                ? value?.description
                : value?.market
                  ? value?.market
                  : option?.stage_name
                    ? option?.stage_name
                    : option?.email;
        if (!value) {
          return false;
        }
        if (typeof value === 'string') {
          return labelValue === value;
        }
        return labelValue === values;
      }}
      renderOption={(props, option, { selected }) => {
        if (!selected) {
          if (selectedValue.length) {
            selected = selectedValue.some(item => {
              if (typeof item === 'string') {
                return item.includes(option?.short_desc || option?.label || option?.name || option?.description || option?.email || option?.type);
              } else if (typeof item === 'object' && item.name) {
                const optionValue = option?.short_desc || option?.label || option?.name || option?.description || option?.email || option?.type;
                return item.name === optionValue;
              }
              return false;
            });
          }
        }
        return (
          <li {...props} className='custom-dropdown-list'>
            <div className='d-flex justify-content-between m-2 align-items-center'>
              {isCheckBox ? (
                <>
                  <div>
                    {option.logo || option?.image_id ? (
                      <Box component='span' className={`${isDrawer ? 'drawer-open-content' : ''}`}>
                        {isIcon ? (
                          <Box>
                            {' '}
                            <option.logo /> {option?.short_desc ? option.short_desc : option?.label ? option.label : option?.name ? option?.name : option?.email}
                          </Box>
                        ) : (
                          <Box className='d-flex align-items-center'>
                            <Avatar sx={{ width: 24, height: 24 }} src={option?.logo || option?.image_id} alt={option?.name ? option?.name : option?.email} />{' '}
                            <Box className='pl-2'>{option?.short_desc ? option.short_desc : option?.label ? option.label : option?.name ? option?.name : option?.email}</Box>
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Box component='span' className={`${isDrawer ? 'drawer-open-content' : ''}`}>
                        {option?.short_desc ? option.short_desc : option?.label ? option.label : option?.name ? option?.name : option?.email}
                      </Box>
                    )}
                  </div>

                  <Checkbox className='checkboxStyle' checked={selected} />
                </>
              ) : option.logo || isAvatar ? (
                <div>
                  <Box component='span' className={`${isDrawer ? 'drawer-open-content' : ''}`}>
                    {isIcon ? (
                      <Box>
                        {' '}
                        <option.logo /> {option?.short_desc ? option.short_desc : option?.label ? option.label : option?.name ? option?.name : option?.email}
                      </Box>
                    ) : (
                      <Box className='d-flex align-items-center'>
                        <Avatar sx={{ width: 24, height: 24 }} src={option[avatarKey]} alt={option?.name ? option?.name : option?.email} />{' '}
                        <Box className='pl-2'>{option?.short_desc ? option.short_desc : option?.label ? option.label : option?.name ? option?.name : option?.email}</Box>
                      </Box>
                    )}
                    {/* <option.logo /> {option.label} */}
                  </Box>
                </div>
              ) : (
                <div style={{ width: '100%' }}>
                  <Box component='span' className={`${isDrawer ? 'drawer-open-content' : ''}`}>
                    {coloredDropdown && (
                      <MenuItem value={option}>
                        <Box className='drawer-content' style={{ backgroundColor: option }} />
                        <Typography variant='caption' ml={2}>
                          {toUpper(option)}
                        </Typography>
                      </MenuItem>
                    )}
                    {option.addNewCompany && (
                      <>
                        <CustomButton type={'w-100 primary'} variant={'outlined'} onClick={() => setAddCompanyPopup(true)} buttonText={'+ ' + t(`${TRANS_KEYS.ADD_COMPANY}:key`)} />
                      </>
                    )}
                    {option.inputValue && addAnotherButton ? (
                      <>
                        {isToAddCompany ? (
                          <Link href={addHttps(`${window.location.host}${ROUTES.addCompany}`)} target='_blank' rel='noopener noreferrer'>
                            <CustomButton
                              variant='text'
                              onClick={() => {}}
                              type={'tertiary'}
                              size={'small'}
                              buttonText={addAnotherButtonText}
                              iconLeft={
                                <AddCircleIcon
                                  sx={{
                                    width: '2vw',
                                    height: '2vh',
                                    paddingRight: 1
                                  }}
                                />
                              }
                            />
                          </Link>
                        ) : (
                          <CustomButton
                            variant='text'
                            onClick={() => {}}
                            type={'tertiary'}
                            size={'small'}
                            buttonText={addAnotherButtonText}
                            iconLeft={
                              <AddCircleIcon
                                sx={{
                                  width: '2vw',
                                  height: '2vh',
                                  paddingRight: 1
                                }}
                              />
                            }
                          />
                        )}
                      </>
                    ) : option?.short_desc ? (
                      option.short_desc
                    ) : option?.label ? (
                      option.label
                    ) : option?.name ? (
                      option?.name
                    ) : option?.description ? (
                      option?.description
                    ) : option?.market ? (
                      option?.market
                    ) : option?.stage_name ? (
                      option?.stage_name
                    ) : (
                      option?.email
                    )}
                  </Box>
                </div>
              )}
            </div>
          </li>
        );
      }}
      renderInput={params => (
        <TextField
          {...params}
          required={required}
          size={size}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color='primary' size={size} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
          InputLabelProps={InputLabelProps}
          label={label}
          name={name}
          // placeholder={placeholder}
          className={className}
          color={color}
        />
      )}
    />
  );
};

CustomDropdown.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  viewAddress: PropTypes.bool,
  isCheckBox: PropTypes.bool,
  options: PropTypes.array,
  isAvatar: PropTypes.bool,
  avatarKey: PropTypes.string,
  disableCloseOnSelect: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  isDrawer: PropTypes.bool,
  isIcon: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  onInputChange: PropTypes.func,
  required: PropTypes.bool,
  selectedValue: PropTypes.array,
  inputValue: PropTypes.object,
  addAnotherButton: PropTypes.bool,
  filterOptions: PropTypes.func,
  addAnotherButtonText: PropTypes.string,
  coloredDropdown: PropTypes.bool,
  isLoading: PropTypes.bool,
  isToAddCompany: PropTypes.bool,
  setAddCompanyPopup: PropTypes.func,
  InputLabelProps: PropTypes.object
};

export default CustomDropdown;
