import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { Box, Button, Checkbox, Divider, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import FlipCard from '../Commons/FlipCard';
// internal imports
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../components/common/CustomButton';
import { PROJECT_SCREEN, ROUTES } from '../../services/constantService';
import convert from '../../utils/currencyConvertor';
import { calculateNumberOfDays } from '../../utils/date';
import fbPicture from './../../assets/images/fb.png';
import CompanyImageAvatar from './CompanyImageAvatar';
import { PROJECT_CONSTANTS } from './ViewProject/Constant';

const CardViewContainer = props => {
  const { allProjects = [], viewButton } = props;
  const navigate = useNavigate();

  // const cardTags = [
  //   {
  //     name: "Open",
  //     color: "secondary.main",
  //     type: "success",
  //   },
  //   // {
  //   //   name: "High Priority",
  //   //   color: "Red",
  //   //   type: "error",
  //   // },
  // ];
  const handleLinkView = project => {
    const { id, stage } = project;
    let pathWithId = ROUTES.viewProject.replace(':id', id);
    if (viewButton == PROJECT_SCREEN.PROJECT) {
      if (stage == PROJECT_CONSTANTS.DRAFT) {
        let pathWithTab = ROUTES.viewProjectWithDeepLink;
        pathWithTab = pathWithTab.replace(':id', id);
        pathWithTab = pathWithTab.replace(':parentTab', 'Roadmap');
        pathWithTab = pathWithTab.replace(':subTab', 'Position Profile');
        navigate({ pathname: pathWithTab }, { state: { draft: true } });
      } else {
        let pathWithTab = ROUTES.viewProjectWithDeepLink;
        pathWithTab = pathWithTab.replace(':id', id);
        pathWithTab = pathWithTab.replace(':parentTab', 'Candidate Pipeline');
        pathWithTab = pathWithTab.replace(':subTab', 'null');
        navigate(pathWithTab);
      }
    } else {
      navigate(pathWithId);
    }
  };

  return (
    <Stack direction='row' justifyContent='space-evenly' alignItems='flex-start' flexWrap='wrap' mt={0}>
      {allProjects.map((project, index) => (
        <FlipCard
          key={index}
          style={{
            width: '32%',
            maxWidth: '32%',
            minWidth: '400px',
            // minHeight: "420px",
            height: '100%',
            padding: '0'
          }}
          className='border shadow rounded mt-3'
        >
          {/* Front Card */}
          <Stack direction='column' alignItems='stretch'>
            {/* top */}

            <Stack direction='row' className='p-1'>
              <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} className='w-100'>
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                  <CompanyImageAvatar
                    alt={project?.ign_companies?.name}
                    sx={{
                      width: '60px',
                      height: '60px',
                      borderRadius: '50%'
                    }}
                    id={project?.ign_companies?.id}
                  />

                  <Stack sx={{ marginLeft: '4px !important' }} spacing={0} rowGap={0}>
                    <Typography variant='caption' display='block' lineHeight='1' sx={{ cursor: 'pointer' }} onClick={() => handleLinkView(project)}>
                      {project?.ign_companies?.name}
                    </Typography>
                    <Typography variant='subtitle1' display='block' lineHeight='1.25' sx={{ cursor: 'pointer' }} onClick={() => handleLinkView(project)}>
                      {project?.job_title}
                    </Typography>
                    <Typography lineHeight='1' variant='caption' display='block'>
                      {project?.location}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction='column' alignItems={'flex-end'} justifyContent={'center'}>
                {/* <Checkbox /> */}
                {viewButton == PROJECT_SCREEN.PROJECT && <div className='fs-12 bold'>Project</div>}
                {viewButton == PROJECT_SCREEN.BUSINESS_DEVELOPMENT && <div className='fs-12 bold'>BD</div>}
                <Link sx={{ cursor: 'pointer' }} onClick={() => handleLinkView(project)} className=''>
                  {project?.job_number}
                </Link>
              </Stack>
            </Stack>

            {/* Middle  */}
            <Stack direction='column' rowGap={1} fullWidth>
              <Divider />
              <Stack direction='row' justifyContent='space-between' alignItems='center' className='p-1'>
                <Box fullWidth>
                  {/* {cardTags.map((data, index) => (
                    <Button
                      key={index}
                      variant="text"
                      size="small"
                      sx={{
                        borderRadius: "8px",
                        fontSize: "12px",
                        padding: "4px 6px",
                        minWidth: "0",
                        color: data.color,
                      }}
                      className="mr-1"
                    >
                      {data.name}
                    </Button>
                  ))} */}
                  <Button
                    key={index}
                    variant='text'
                    size='small'
                    sx={{
                      borderRadius: '8px',
                      fontSize: '12px',
                      padding: '4px 6px',
                      minWidth: '0',
                      color: 'primary.main'
                    }}
                    className='mr-1'
                  >
                    {project?.stage}
                  </Button>
                  <Button
                    variant='contained'
                    display='block'
                    sx={{
                      fontSize: '10px',
                      backgroundColor: 'primary.main',
                      padding: '4px',
                      borderLeft: '6px solid black ',
                      borderRadius: '4px',
                      color: 'titleBackground.main'
                    }}
                    className='pr-3'
                  >
                    <TimerOutlinedIcon sx={{ fontSize: '16px', padding: '2px' }} />
                    Days Open
                    {' ' + calculateNumberOfDays(project?.created_at)}
                  </Button>
                </Box>
                {/* <Stack direction="row" alignItems={"center"}>
                  <Typography variant="caption">Search Health: </Typography>
                  <Typography variant="caption" color="primary.main">
                    Good
                  </Typography>
                </Stack> */}
                {/* </Stack> */}
                {project.record_type === 'Project' ? (
                  <Typography variant='caption'>
                    In Pipeline:
                    <Typography component='span' color='primary.main' variant='caption'>
                      {' '}
                      {project?.candidate_count || 0}
                    </Typography>
                  </Typography>
                ) : (
                  <Typography variant='caption'>
                    Estimated Revenue:
                    <Typography component='span' color='primary.main' variant='caption'>
                      {' '}
                      {`${project?.bd_currency_values?.currency_icon || ''}${project?.estimated_revenue || 0}`}
                    </Typography>
                  </Typography>
                )}
              </Stack>
            </Stack>
            <Divider />

            {/* Bottom */}
            <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={1} sx={{ margin: '4px' }}>
              <Typography variant='caption' sx={{ fontWeight: 'bold' }}>
                <AccessTimeIcon sx={{ fontSize: '20px' }} />
                Updated {calculateNumberOfDays(project?.updated_at)} Days Ago
                {project?.updater?.name && (
                  <>
                    <Typography variant='caption' component='span'>
                      {' '}
                      by
                    </Typography>
                    <Typography variant='subtitle2' color='primary.main' component={'span'} className='ml-1'>
                      {project?.updater?.name}
                    </Typography>
                  </>
                )}
              </Typography>
              {/* <Button
                variant="contained"
                size="small"
                sx={{
                  fontSize: "12px",
                  padding: "4px 6px",
                  minWidth: "0",
                  color: "white !important",
                  background: "secondary.main",
                  borderRadius: "4px",
                }}
              >
                <VerifiedIcon sx={{ fontSize: "20px" }}></VerifiedIcon>
                todo updated by
                Anirudh Gupta
              </Button> */}
            </Stack>
          </Stack>

          {/* Back Card */}
          <Stack direction='column' justifyContent='space-between' alignItems='stretch'>
            <Stack direction='row' className='p-1'>
              <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} className='w-100'>
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
                  <img
                    src={fbPicture}
                    style={{
                      width: '60px',
                      height: '60px',
                      borderRadius: '50%'
                    }}
                  />

                  <Stack sx={{ marginLeft: '4px !important' }} spacing={0} rowGap={0}>
                    <Typography variant='caption' display='block' lineHeight='1'>
                      {project?.ign_companies?.name}
                    </Typography>
                    <Typography
                      variant='subtitle1'
                      display='block'
                      lineHeight='1.25'
                      // sx={{ fontWeight: "900" }}
                    >
                      {project?.job_title}
                    </Typography>
                    <Typography lineHeight='1' variant='caption' display='block'>
                      {project?.location}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction='column' alignItems={'center'}>
                <Checkbox />
                <Link sx={{ cursor: 'pointer' }} onClick={() => handleLinkView(project)} className=''>
                  {project?.job_number}
                </Link>
              </Stack>
            </Stack>

            {/* Middle  */}
            <Stack direction='column' justifyContent='space-between' alignItems='stretch' sx={{ width: '100%' }}>
              <Divider />
              <Box className='p-1'>
                <Typography variant='caption'>Pending Billables</Typography>
                <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
                  <Stack direction='column' justifyContent='center' alignItems='center' spacing={0.5}>
                    <CustomButton variant='contained' size='small' disabled buttonText={'Outstanding'} />
                    <Typography variant='caption' color='error.main'>
                      {/* 
                      <LocalAtmOutlinedIcon fontSize="small" />
                      <b>USD {""}</b> */}
                      ${convert(30000)}{' '}
                    </Typography>
                  </Stack>
                  <Stack direction='column' justifyContent='center' alignItems='center' spacing={0.5}>
                    <CustomButton variant='contained' size='small' disabled buttonText={'Next Billable'} />
                    <Typography variant='caption' color='error.main'>
                      ${convert(14000)}{' '}
                    </Typography>
                  </Stack>
                  <Stack direction='column' justifyContent='center' alignItems='center' spacing={0.5}>
                    <CustomButton disabled variant='contained' size='small' buttonText={'Overdue'} />
                    <Typography variant='caption' color='error.main'>
                      ${convert(3400)}{' '}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
              {/* <Box>
                <Box>Milestone</Box>
                <Stepper activeStep={1} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box> */}
              <Divider />
            </Stack>

            {/* Bottom */}
            <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={1} sx={{ margin: '4px' }}>
              <Typography variant='caption' sx={{ fontWeight: 'bold' }}>
                <AccessTimeIcon sx={{ fontSize: '20px' }} />
                Posted {calculateNumberOfDays(project?.created_at)} Days
                <Typography variant='caption' component='span' className='ml-1'>
                  Ago by
                </Typography>
              </Typography>
              <Typography variant='subtitle2' color='primary.main'>
                Anirudh Gupta
              </Typography>
            </Stack>
          </Stack>
        </FlipCard>
      ))}
    </Stack>
  );
};

CardViewContainer.propTypes = {
  allProjects: PropTypes.array,
  viewButton: PropTypes.string
};
export default CardViewContainer;
