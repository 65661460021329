import { CloseOutlined, Mic, MicOff } from '@mui/icons-material';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Divider } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import Popup from 'reactjs-popup';
import { AWS_LANGUAGE_OPTIONS } from '../../../services/constantService';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import CustomButton from '../CustomButton';
import * as TranscribeClient from './awsTranscribe';
import './index.scss';
import { generateText } from './summarize';

const startRecording = async (language, isRecording, onTranscriptionDataReceived, isLoading) => {
  if (!language) {
    alert('Please select a language');
    return;
  }

  try {
    isRecording(true);
    await TranscribeClient.startRecording(language, onTranscriptionDataReceived, isRecording, isLoading);
  } catch (error) {
    isRecording(false);
    alert('An error occurred while recording: ' + error.message);
    stopRecording();
  }
};

const stopRecording = function () {
  TranscribeClient.stopRecording(console.log('Recording stopped'));
};

/**
 * Custom hook for handling transcription and recording.
 *
 * This hook provides functionality to start and stop audio recording, transcribe the recorded audio,
 * and manage the transcription state. It also provides components for controlling the transcription
 * process and displaying the transcribed text.
 *
 * @param {{
 *  defaultTranscribedText: string
 * }} props The default transcribed text to display.
 *
 * @returns {{
 *   stopRecording: Function,
 *   TranscribeButton: React.FunctionComponent,
 *   SummariseButton: React.FunctionComponent,
 *   ViewTranscribedText: React.FunctionComponent,
 *   TranscribePopup: React.FunctionComponent,
 *   transcribedText: string
 * }} An object containing properties for managing transcription and recording.
 */
const useTranscribe = props => {
  const { defaultTranscribedText } = props;

  const [language] = React.useState(AWS_LANGUAGE_OPTIONS[0]);
  const [loading, setLoading] = React.useState(false);
  const [isRecording, setIsRecording] = React.useState(false);
  const [isTranscribePopupOpen, setIsTranscribePopupOpen] = React.useState(false);
  const [prevTranscribedText, setPrevTranscribedText] = React.useState(defaultTranscribedText);

  const { t } = useLanguageHooks([TRANS_KEYS.START_RECORDING, TRANS_KEYS.STOP_RECORDING, TRANS_KEYS.PLEASE_WAIT, TRANS_KEYS.MAX_RECORDING_TIME, TRANS_KEYS.SUMMARIZE]);

  const { setValue, getValues, watch } = useForm({
    defaultValues: {
      body: ''
    }
  });

  const onTranscriptionDataReceived = data => {
    const currentBody = getValues('body') ? getValues('body') : '<p></p>';
    const updatedBody = currentBody.replace(/<\/p>$/, `${data}</p>`);
    setValue('body', updatedBody);
  };

  useEffect(() => {
    return () => {
      stopRecording();
    };
  }, []);

  const getButtonText = (isRecording, isLoading) => {
    if (isRecording && isLoading) {
      return t(`${TRANS_KEYS.PLEASE_WAIT}:key`);
    }
    if (isRecording) {
      return t(`${TRANS_KEYS.STOP_RECORDING}:key`);
    }
    if (isLoading) {
      return t(`${TRANS_KEYS.PLEASE_WAIT}:key`);
    }
    return t(`${TRANS_KEYS.START_RECORDING}:key`);
  };

  const TranscribeButton = () => {
    return (
      <CustomButton
        type={isRecording ? 'tertiary-error' : 'outline-primary'}
        variant='text'
        buttonText={getButtonText(isRecording, loading)}
        onClick={e => {
          e.preventDefault();
          if (isRecording) {
            stopRecording();
            setIsRecording(false);
            return;
          }
          setLoading(true);
          startRecording(language, setIsRecording, onTranscriptionDataReceived, setLoading);
        }}
        iconLeft={
          isRecording ? (
            <MicOff
              sx={{
                width: 18,
                height: 18,
                marginRight: 1
              }}
            />
          ) : (
            <Mic
              sx={{
                width: 18,
                height: 18,
                marginRight: 1
              }}
            />
          )
        }
        iconRight={
          <Spinner
            size='sm'
            className='ml-2'
            style={{
              display: loading ? 'inline-block' : 'none'
            }}
          />
        }
        disabled={loading}
        bgColor={'none'}
      />
    );
  };

  const SummariseButton = () => {
    return (
      <CustomButton
        buttonText={t(`${TRANS_KEYS.SUMMARIZE}:key`)}
        variant={'text'}
        bgColor={'none'}
        iconLeft={
          <TextSnippetIcon
            sx={{
              width: 18,
              height: 18,
              marginRight: 1
            }}
          />
        }
        boxClassName='outline-primary'
        onClick={() => {
          generateText(
            getValues('body'),
            () => {
              setPrevTranscribedText(getValues('body'));
              setValue('original_text', getValues('body'));
              setValue('body', '');
            },
            summary => {
              if (!summary) {
                return;
              }
              const currentBody = getValues('body') ? getValues('body') : '<p></p>';
              const updatedBody = currentBody.replace(/<\/p>$/, `${summary}</p>`);
              setValue('body', updatedBody);
            }
          );
        }}
      />
    );
  };

  const ViewTranscribedText = () => {
    return (
      <div>
        <p
          className='body-font fs-11 cursor-pointer'
          onClick={() => {
            setIsTranscribePopupOpen(true);
          }}
        >
          <u>View Transcribed Text</u>
        </p>
      </div>
    );
  };

  const TranscribePopup = () => {
    return (
      <Popup
        open={isTranscribePopupOpen}
        modal={true}
        onClose={() => {
          setIsTranscribePopupOpen(false);
        }}
        className='transcribe-popup'
      >
        <div className='d-flex justify-content-between align-items-center'>
          <h6 className='header-font font-weight-bold'>Transcribed Text</h6>
          <div
            onClick={() => {
              setIsTranscribePopupOpen(false);
            }}
            className='cursor-pointer'
          >
            <CloseOutlined />
          </div>
        </div>
        <Divider className='my-2' />
        <ReactQuill readOnly={true} value={prevTranscribedText} modules={{ toolbar: false }} className='w-100 flex' theme='bubble' id='task-notes' />
      </Popup>
    );
  };

  const transcribedText = watch('body');

  return {
    stopRecording,
    TranscribeButton,
    SummariseButton,
    ViewTranscribedText,
    TranscribePopup,
    setTranscribedText: setValue,
    transcribedText
  };
};

export default useTranscribe;

PropTypes.useTranscribe = {
  defaultTranscribedText: PropTypes.string
};
