import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import CustomButton from '../CustomButton';
import './index.scss';

const Footer = props => {
  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_SAVE, TRANS_KEYS.CONTACTS_CANCEL]);
  const {
    onClose = () => {},
    data = {},
    closeText = t(`${TRANS_KEYS.CONTACTS_CANCEL}:key`),
    onSubmit = () => {},
    submitText = t(`${TRANS_KEYS.CONTACTS_SAVE}:key`),
    disabled = false,
    submitLoading
  } = props;
  return (
    <Box id='common-footer'>
      <Box component='footer' className='container-footer py-2'>
        <CustomButton type={'tertiary-error'} size={'small'} buttonText={closeText} customWidth={90} onClick={onClose} variant={'text'} />
        <CustomButton type={'primary'} size={'small'} buttonText={submitText} customWidth={90} onClick={() => onSubmit(data)} variant={'contained'} disabled={disabled} isLoading={submitLoading} />
      </Box>
    </Box>
  );
};
Footer.propTypes = {
  onClose: PropTypes.func,
  closeText: PropTypes.string,
  submitText: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.object,
  disabled: PropTypes.bool,
  submitLoading: PropTypes.bool
};

export default Footer;
