import { Box, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { globalSearchSelector } from '../../../selectors';
import { resetGlobalSearchPagination, updateGlobalSearchData } from '../../../store/globalSearchSlice';

const Tabs = () => {
  const { data, tabs, selectedTabId, loading } = useSelector(globalSearchSelector);

  const dispatch = useDispatch();

  const changeTab = tabId => {
    if (loading) return;
    dispatch(updateGlobalSearchData({ key: 'selectedTabId', data: tabId }));
    dispatch(resetGlobalSearchPagination());
  };

  return (
    <Box width={'fit-content'} paddingY={2.5} paddingX={4} display={'flex'} gap={6} boxShadow={1} borderRadius={1} bgcolor={'Background'}>
      {tabs.map(tab => {
        const list = data?.find(item => item?.model === tab?.model);

        return (
          <Box key={tab?.model} sx={{ cursor: 'pointer' }} onClick={() => changeTab(tab?.model)}>
            <Typography color={tab?.model === selectedTabId ? '#009d81' : ''} fontSize={12} fontWeight={900}>
              {tab?.label} {list?.data?.hits?.total?.value ? `(${list?.data?.hits?.total?.value})` : ''}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default Tabs;
