import React, { useEffect, useRef, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
// import Typography from "@mui/material/Typography";
import '../../index.scss';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import RichText from './RichText';
import CustomTable from './CustomTable';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// import delete_icon from '../../../assets/icons/delete_icon.svg';
// import edit_icon from '../../../assets/icons/edit_icon.svg';
import CustomPopup from './CustomPopup';
import { ExpandableSection } from '../ActivityLog/Components/ExpandableSection';
import DoneIcon from '@mui/icons-material/Done';
import CustomButton from './CustomButton';
import AddIcon from '@mui/icons-material/Add';
import { useLanguageHooks } from '../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../utils/languageNamespaces';
import ProjectSelection from './FunctionalComponents/ProjectSelection';
import { DELETE, SUCCESS } from '../../services/constantService';
import { ignContactDataApi } from '../../services/ApiService';
import { successMessage } from '../../services/MessageService';
import { enqueueSnackbar } from 'notistack';
import Loader from './Loader';
import CustomConfirmationPopup from './CustomConfirmationPopup';

const CustomCommonPersonality = props => {
  const {
    setIsPopupOpen = () => {},
    onSubmit = () => {},
    isPopupOpen,
    isEditing = false,
    itemValue,
    noteType1,
    noteType2,
    isMorePopupOpen = false,
    setIsMorePopupOpen = () => {},
    approved1 = '',
    approved2 = '',
    inlineEditing = () => {},
    isInlineEditing,
    setIsInLineEditing = () => {},
    selectedType = '',
    notesLabel,
    setValue = () => {},
    register = () => {},
    contact_id,
    getContact = () => {},
    popupClose
  } = props;
  const defaultValue = {
    projects: {},
    [noteType1]: [],
    [noteType2]: [],
    [approved1]: false,
    [approved2]: false
  };
  const [notes1, setNotes1] = useState([{ [noteType1]: { notes: '', id: '' } }]);
  const [notes2, setNotes2] = useState([{ [noteType2]: { notes: '', id: '' } }]);
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState(isEditing || isMorePopupOpen ? itemValue?.projects : '');
  const [isCheckApproved1, setIsCheckApproved1] = useState(false);
  const [isCheckApproved2, setIsCheckApproved2] = useState(false);
  const [data, setData] = useState(defaultValue);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [noteId, setNoteId] = useState();
  const noteIdRef = useRef();
  noteIdRef.current = noteId;
  const isCheckApproved1Ref = useRef();
  isCheckApproved1Ref.current = isCheckApproved1;
  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_APPROVED_TO_PUBLISH,
    TRANS_KEYS.CONTACTS_ADD_ANOTHER,
    TRANS_KEYS.CONTACTS_SELECT_PROJECT,
    TRANS_KEYS.CONTACTS_WORD_AND,
    TRANS_KEYS.CONTACTS_SAVE,
    TRANS_KEYS.CONTACTS_UPDATE
  ]);
  // useEffect(() => {
  //   register("project_id");
  //   register("is_partner_approved");
  //   register("notes_type");
  //   register("notes");
  //   register("seq");
  //   return () => {
  //     unregister("project_id")
  //     unregister("is_partner_approved");
  //     unregister("notes_type");
  //     unregister("notes");
  //     unregister("seq");
  //   };
  // }, [register, unregister]);
  useEffect(() => {
    register('contact_notes_all');
  }, [register]);
  useEffect(() => {
    if ((isEditing || isMorePopupOpen) && itemValue) {
      if (itemValue[noteType1].length > 0) {
        setNotes1(itemValue[noteType1]?.map(value => ({ [noteType1]: value })));
      }
      if (itemValue[noteType2].length > 0) {
        setNotes2(itemValue[noteType2]?.map(value => ({ [noteType2]: value })));
      }
      setIsCheckApproved1(itemValue[approved1]);
      setIsCheckApproved2(itemValue[approved2]);
    }
  }, [isEditing, isMorePopupOpen, isInlineEditing, itemValue]);
  const handleChange = data => {
    setProject(data);
  };
  const handleAddAnotherNote1 = () => {
    setNotes1([...notes1, { [noteType1]: { notes: '', id: null } }]);
  };
  const handleRemoveNote1 = async (index, id) => {
    if (id && (isEditing || isMorePopupOpen)) {
      setNoteId(id);
      setOpenConfirmationPopup(true);
    } else {
      const updatedNotes1 = [...notes1];
      updatedNotes1.splice(index, 1);
      setNotes1(updatedNotes1);
    }
  };
  const handleAddAnotherNote2 = () => {
    setNotes2([...notes2, { [noteType2]: { notes: '', id: null } }]);
  };
  const handleRemoveNote2 = async (index, id) => {
    if (id && (isEditing || isMorePopupOpen)) {
      setNoteId(id);
      setOpenConfirmationPopup(true);
    } else {
      const updatedNotes2 = [...notes2];
      updatedNotes2.splice(index, 1);
      setNotes2(updatedNotes2);
    }
  };
  const onConfirm = async () => {
    try {
      if (noteIdRef.current) {
        setLoading(true);
        const sub_route = `contact-notes?id=${noteIdRef.current}`;
        const { status } = await ignContactDataApi(DELETE, null, null, sub_route);
        if (status === 200) {
          setLoading(false);
          const message = successMessage('Notes', 'Deleted');
          enqueueSnackbar(message, { variant: SUCCESS });
          getContact(contact_id, 'PERSONALITY_TAB');
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit(data);
    // setIsPopupOpen(false);
    if (popupClose?.current) {
      setIsPopupOpen(false);
    }
    setLoading(false);
  };

  const handleClose = () => {
    setIsPopupOpen(false);
    if (isMorePopupOpen) {
      setIsMorePopupOpen(false);
      setIsInLineEditing(false);
    }
  };
  // const handleBack = () => {
  //   setIsPopupOpen(false)
  //   if (isMorePopupOpen) {
  //     setIsMorePopupOpen(false)
  //   }
  // }
  const handleChangeNotes1 = (index, name, data) => {
    let newList = [...notes1];
    newList[index] = {
      ...newList[index],
      [name]: {
        ...newList[index][name],
        notes: data
      }
    };
    const updatedList = [...newList];
    setNotes1(updatedList);
  };
  const handleChangeNotes2 = (index, name, data) => {
    let newList = [...notes2];
    newList[index] = {
      ...newList[index],
      [name]: {
        ...newList[index][name],
        notes: data
      }
    };
    const updatedList = [...newList];
    setNotes2(updatedList);
  };
  const handleCheckNote1 = e => {
    if (isMorePopupOpen) {
      setIsInLineEditing(true);
      setIsCheckApproved1(e.target.checked);
      setLoading(true);
      setTimeout(() => {
        onSubmit();
      }, 100);
      setLoading(false);
    } else {
      setIsCheckApproved1(e.target.checked);
    }
  };
  const handleCheckNote2 = e => {
    if (isMorePopupOpen) {
      setIsInLineEditing(true);
      setIsCheckApproved2(e.target.checked);
      setLoading(true);
      setTimeout(() => {
        onSubmit();
      }, 100);
      setLoading(false);
    } else {
      setIsCheckApproved2(e.target.checked);
    }
  };

  useEffect(() => {
    setData({
      projects: {
        project_id: isEditing || isMorePopupOpen ? project?.project_id : project?.id ? project?.id : null,
        project_name: isEditing || isMorePopupOpen ? project?.project_name : project?.name ? project?.name : null
      },
      [noteType1]: notes1.map(elem => elem[noteType1]),
      [noteType2]: notes2.map(elem => elem[noteType2]),
      [approved1]: isCheckApproved1Ref.current,
      [approved2]: isCheckApproved2
    });
  }, [notes1, notes2, project, isCheckApproved1Ref.current, isCheckApproved2]);
  // const editItem = () => {
  //   editItems(indexValue)
  //   // setIsMorePopupOpen(false)
  // }
  useEffect(() => {
    const notesType = [noteType1, noteType2];
    const result = [];
    notesType.forEach(key => {
      const notesArray = data[key];
      if (notesArray && Array.isArray(notesArray)) {
        notesArray.forEach(note => {
          if (note.id) {
            result.push({
              id: note.id,
              notes_type: key,
              notes: note.notes,
              is_partner_approved: data[`is_${key}_approved_by_partner`] ? data[`is_${key}_approved_by_partner`] : false,
              seq: note.seq,
              project_id: data.projects.project_id
            });
          } else {
            result.push({
              notes_type: key,
              notes: note.notes,
              is_partner_approved: data[`is_${key}_approved_by_partner`] ? data[`is_${key}_approved_by_partner`] : false,
              project_id: data.projects.project_id
            });
          }
        });
      }
    });
    setValue('contact_notes_all', result, { shouldDirty: true });
  }, [data]);
  // useEffect(() => {
  //   setValue(`contact_personality.project_id`, project)
  //   setValue(`contact_personality.${noteType1}`, notes1.map((elem) => elem[noteType1]))
  //   setValue(`contact_personality.${noteType2}`, notes2.map((elem) => elem[noteType2]))
  //   setValue(`contact_personality.${approved1}`, isCheckApproved1Ref.current)
  //   setValue(`contact_personality.${approved2}`, isCheckApproved2)
  // }, [notes1, notes2, project, isCheckApproved1Ref.current, isCheckApproved2])
  const handleEditClick = (index, type) => {
    const selectedIndexValue = `${type}${index}`;
    inlineEditing(selectedIndexValue);
  };
  const onCloseClick = () => {
    setIsInLineEditing(false);
  };
  const updateData = async () => {
    setLoading(true);
    await onSubmit(data);
    setLoading(false);
  };
  const closeConfirmationPopup = () => {
    setOpenConfirmationPopup(false);
  };
  // const editIcon = (
  //   <Box className='edit-icons mr-1' onClick={editItem}>
  //     <img src={edit_icon} alt='' />
  //   </Box>
  // );
  // const deleteIcon = (
  //   <Box className='delete-icon'>
  //     <img src={delete_icon} alt='' />
  //   </Box>
  // )
  // const icons = [editIcon, deleteIcon];
  return (
    <Box>
      <CustomPopup
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={isPopupOpen}
        title={`${notesLabel?.label1} ${t(`${TRANS_KEYS.CONTACTS_WORD_AND}:key`)} ${notesLabel?.label2}`}
        showAction={!isMorePopupOpen ? true : false}
        handleSubmit={handleSubmit}
        confirmText={isEditing ? t(`${TRANS_KEYS.CONTACTS_UPDATE}:key`) : t(`${TRANS_KEYS.CONTACTS_SAVE}:key`)}
        // icons={isMorePopupOpen && icons}
      >
        <DialogContent className='add-new-body px-0' id='custom-personality-popup'>
          <Loader show={loading} />
          <Box className='custom-personality-input' mb={2} px={1}>
            {/* <Typography variant="body2" mb={1}>
              {t(`${TRANS_KEYS.CONTACTS_SELECT_PROJECT}:key`)}
            </Typography> */}
            <ProjectSelection
              defaultValue={project?.project_name || ''}
              id='custom-input'
              name='project'
              onChange={(e, data) => {
                handleChange(data);
              }}
              selectedList={itemValue}
              disabled={isEditing || isMorePopupOpen}
              label={t(`${TRANS_KEYS.CONTACTS_SELECT_PROJECT}:key`)}
            />
          </Box>
          <Box className='d-flex' px={1} gap={4}>
            <Box className='type-field-custom'>
              {!isMorePopupOpen && (
                <Box className='d-flex justify-content-end align-item-top pb-2'>
                  <CustomButton
                    type={'tertiary'}
                    variant='text'
                    size={'medium'}
                    buttonText={t(`${TRANS_KEYS.CONTACTS_ADD_ANOTHER}:key`)}
                    customWidth={120}
                    iconSide={'left'}
                    onClick={handleAddAnotherNote1}
                    iconLeft={<AddIcon sx={{ width: 14 }} />}
                  />
                </Box>
              )}
              <CustomTable
                headerData={['']}
                title={notesLabel?.label1}
                // handleAnotherRow={handleAddPhoneNumber}
                rows={notes1}
                showAddAnotherButton={false}
                isShowApprovalCheckbox={true}
                checked={isCheckApproved1Ref.current}
                handleChangeCheckBox={handleCheckNote1}
                checkboxLabel={t(`${TRANS_KEYS.CONTACTS_APPROVED_TO_PUBLISH}:key`)}
                isShowHeader={false}
              >
                {notes1 &&
                  notes1.map((elem, index) => {
                    return (
                      <TableRow key={index} disabled={true}>
                        <TableCell className='input-field-data flex justify-center align-center'>
                          <ExpandableSection
                            title={`${notesLabel?.label1} ${index + 1} `}
                            isSectionNotLabel={true}
                            defaultOpen={isMorePopupOpen ? index === 0 : notes1.length - 1 === index ? true : false}
                            showActivityLabel={isMorePopupOpen}
                          >
                            <Box>
                              {isMorePopupOpen ? (
                                isInlineEditing && selectedType === `${noteType1}${index}` ? (
                                  <RichText
                                    defaultValue={elem[noteType1].notes || ''}
                                    name={noteType1}
                                    onChange={data => {
                                      handleChangeNotes1(index, noteType1, data);
                                    }}
                                  />
                                ) : (
                                  <Box onDoubleClick={() => handleEditClick(index, noteType1)} className='view-custom-data pl-3'>
                                    <RichText defaultValue={elem[noteType1].notes || ''} isView={true} displayToolbar={false} />
                                  </Box>
                                )
                              ) : (
                                <RichText
                                  defaultValue={elem[noteType1].notes || ''}
                                  name={noteType1}
                                  onChange={data => {
                                    handleChangeNotes1(index, noteType1, data);
                                  }}
                                />
                              )}
                            </Box>
                          </ExpandableSection>
                        </TableCell>
                        <TableCell>
                          {(isInlineEditing && selectedType === `${noteType1}${index}` ? false : true) && notes1.length > 1 && (
                            <CloseIcon
                              onClick={() => {
                                handleRemoveNote1(index, elem[noteType1].id);
                              }}
                              fontSize='inherit'
                              className='remove-button table-close-icon'
                            />
                          )}
                          {isInlineEditing && selectedType === `${noteType1}${index}` && (
                            <Box className='d-flex custom-action-button'>
                              <Box component='span' className='action-icon'>
                                <DoneIcon cursor='pointer' color='primary' onClick={updateData} />
                              </Box>
                              <Box component='span' className='action-icon'>
                                <CloseIcon cursor='pointer' className='table-close-icon' onClick={onCloseClick} />
                              </Box>
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </CustomTable>
            </Box>
            <Box className='type-field-custom'>
              {!isMorePopupOpen && (
                <Box className='d-flex justify-content-end align-item-top pb-2'>
                  <CustomButton
                    type={'tertiary'}
                    variant='text'
                    size={'medium'}
                    buttonText={t(`${TRANS_KEYS.CONTACTS_ADD_ANOTHER}:key`)}
                    customWidth={120}
                    onClick={handleAddAnotherNote2}
                    iconLeft={<AddIcon sx={{ width: 14 }} />}
                  />
                </Box>
              )}
              <CustomTable
                headerData={['']}
                title={notesLabel?.label2}
                // handleAnotherRow={handleAddPhoneNumber}
                rows={notes2}
                showAddAnotherButton={false}
                isShowApprovalCheckbox={true}
                checked={isCheckApproved2}
                handleChangeCheckBox={handleCheckNote2}
                checkboxLabel={t(`${TRANS_KEYS.CONTACTS_APPROVED_TO_PUBLISH}:key`)}
                isShowHeader={false}
              >
                {notes2 &&
                  notes2.map((elem, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell className='input-field-data flex justify-center align-center'>
                          <ExpandableSection
                            title={`${notesLabel?.label2} ${index + 1} `}
                            isSectionNotLabel={true}
                            defaultOpen={isMorePopupOpen ? index === 0 : notes2.length - 1 === index ? true : false}
                            showActivityLabel={isMorePopupOpen}
                          >
                            <Box>
                              {isMorePopupOpen ? (
                                isInlineEditing && selectedType === `${noteType2}${index}` ? (
                                  notes2.length > 0 && (
                                    <RichText
                                      defaultValue={elem[noteType2].notes || ''}
                                      name={noteType2}
                                      onChange={data => {
                                        handleChangeNotes2(index, noteType2, data);
                                      }}
                                    />
                                  )
                                ) : (
                                  notes2.length > 0 && (
                                    <Box onDoubleClick={() => handleEditClick(index, noteType2)} className='view-custom-data pl-3'>
                                      <RichText defaultValue={elem[noteType2].notes || ''} isView={true} displayToolbar={false} />
                                    </Box>
                                  )
                                )
                              ) : (
                                <RichText
                                  defaultValue={elem[noteType2].notes || null}
                                  name={noteType2}
                                  onChange={data => {
                                    handleChangeNotes2(index, noteType2, data);
                                  }}
                                />
                              )}
                            </Box>
                          </ExpandableSection>
                        </TableCell>
                        <TableCell>
                          {(isInlineEditing && selectedType === `${noteType2}${index}` ? false : true) && notes2.length > 1 && (
                            <CloseIcon
                              onClick={() => {
                                handleRemoveNote2(index, elem[noteType2].id);
                              }}
                              fontSize='inherit'
                              color='error'
                              className='remove-button  table-close-icon'
                            />
                          )}
                          {isInlineEditing && selectedType === `${noteType2}${index}` && (
                            <Box className='d-flex custom-action-button'>
                              <Box component='span' className='action-icon'>
                                <DoneIcon cursor='pointer' color='primary' onClick={updateData} />
                              </Box>
                              <Box component='span' className='action-icon'>
                                <CloseIcon cursor='pointer' className='table-close-icon' onClick={onCloseClick} />
                              </Box>
                            </Box>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </CustomTable>
            </Box>
          </Box>
          {/* </Box> */}
        </DialogContent>
      </CustomPopup>

      <CustomConfirmationPopup open={openConfirmationPopup} onClose={closeConfirmationPopup} type='delete' cancelText='No' confirmText='Yes' onConfirm={onConfirm} setOpen={setOpenConfirmationPopup} />
    </Box>
  );
};

CustomCommonPersonality.propTypes = {
  setIsPopupOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  checkboxData: PropTypes.bool,
  isPopupOpen: PropTypes.bool,
  // types: PropTypes.string,
  isEditing: PropTypes.bool,
  // isAddAnother: PropTypes.bool,
  // addAnotherData: PropTypes.object,
  itemValue: PropTypes.array,
  noteType1: PropTypes.string,
  noteType2: PropTypes.string,
  isMorePopupOpen: PropTypes.bool,
  // editItems: PropTypes.func,
  // indexValue: PropTypes.string,
  setIsMorePopupOpen: PropTypes.func,
  approved1: PropTypes.string,
  approved2: PropTypes.string,
  inlineEditing: PropTypes.func,
  isInlineEditing: PropTypes.bool,
  setIsInLineEditing: PropTypes.func,
  selectedType: PropTypes.string,
  notesLabel: PropTypes.object,
  register: PropTypes.func,
  setValue: PropTypes.func,
  getContact: PropTypes.func,
  contact_id: PropTypes.string,
  popupClose: PropTypes.bool
};

export default CustomCommonPersonality;
