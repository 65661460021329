import { createSlice } from '@reduxjs/toolkit';
import { CompanyCard } from '../components/GlobalSearch/Cards/CompanyCard';
import { ContactCard } from '../components/GlobalSearch/Cards/ContactCard';
import { LogItem } from '../components/GlobalSearch/Cards/LogItem';
import { ProjectCard } from '../components/GlobalSearch/Cards/ProjectCard';
import CustomSkeletonCard from '../components/GlobalSearch/Components/CustomSkeletonCard';
import RecentActivitySkeleton from '../components/GlobalSearch/Components/RecentActivitySkeleton';

export const globalSearchTabs = [
  {
    id: '5',
    index: 'all',
    model: 'all',
    label: 'All',
    subTabs: [],
    skeletonComponent: CustomSkeletonCard
  },
  {
    id: '1',
    index: 'idx-sgcorp-sandbox-ign-contacts',
    model: 'ign-contacts',
    component: ContactCard,
    label: 'Contacts',
    subTabs: [
      {
        id: '1',
        label: 'Education',
        values: [
          {
            id: '1',
            name: 'education_1'
          },
          {
            id: '2',
            name: 'education_2'
          },
          {
            id: '3',
            name: 'education_3'
          }
        ]
      },
      {
        id: '2',
        label: 'Industry',
        values: [
          {
            id: '4',
            name: 'industry_1'
          },
          {
            id: '5',
            name: 'industry_2'
          },
          {
            id: '6',
            name: 'industry_3'
          }
        ]
      },
      {
        id: '3',
        label: 'Source',
        values: []
      },
      {
        id: '4',
        label: 'Record Status',
        values: []
      },
      {
        id: '5',
        label: 'Off-limit Status',
        values: []
      }
    ],
    skeletonComponent: CustomSkeletonCard
  },
  {
    id: '2',
    index: 'idx-sgcorp-sandbox-ign-projects',
    model: 'ign-projects',
    component: ProjectCard,
    label: 'Projects',
    subTabs: [
      {
        id: '1',
        label: 'Industry',
        subTabs: []
      },
      {
        id: '2',
        label: 'Stage',
        subTabs: []
      }
    ],
    skeletonComponent: CustomSkeletonCard
  },
  {
    id: '3',
    index: 'idx-sgcorp-sandbox-ign-company',
    model: 'ign-companies',
    component: CompanyCard,
    label: 'Companies',
    subTabs: [
      {
        id: '1',
        label: 'Industry',
        subTabs: []
      },
      {
        id: '2',
        label: 'Stage',
        subTabs: []
      }
    ],
    skeletonComponent: CustomSkeletonCard
  },
  {
    id: '4',
    index: 'idx-sgcorp-sandbox-ign-contact-notes-all',
    model: 'ign-contact-notes-all',
    label: 'Recent Activities',
    component: LogItem,
    subTabs: [],
    skeletonComponent: RecentActivitySkeleton
  }
];

const initialState = {
  tabs: globalSearchTabs,
  selectedTabId: 'all',
  page: 1,
  pages: ['Prev', 1, 2, 3, 'Next'],
  totalCount: 0,
  limit: 5,
  search: '',
  data: [],
  loading: false,
  refetch: 0
};

const globalSearchSlice = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {
    updateGlobalSearchData(state, action) {
      state[action.payload.key] = action.payload.data;
    },
    updateGlobalSearchPagination(state, action) {
      const totalPages = Math.ceil(state.totalCount / state.limit);
      if (action.payload.data === 'Next') {
        if (state.pages.includes(totalPages)) return;
        state.pages = state.pages.map(p => (p === 'Next' || p === 'Prev' ? p : p + 1));
        state.page = state.pages[3];
        state.refetch++;
        return;
      }

      if (action.payload.data === 'Prev') {
        if (state.pages.includes(1)) return;
        state.pages = state.pages.map(p => (p === 'Next' || p === 'Prev' ? p : p - 1));
        state.page = state.pages[1];
        state.refetch++;
        return;
      }

      state.page = action.payload.data;
      state.refetch++;
    },
    resetGlobalSearchPagination(state) {
      state.totalCount = 0;
      state.pages = ['Prev', 1, 2, 3, 'Next'];
      state.page = 1;
    }
  }
});

export const { updateGlobalSearchData, updateGlobalSearchPagination, resetGlobalSearchPagination } = globalSearchSlice.actions;

export default globalSearchSlice.reducer;
