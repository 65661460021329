import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DynamicTabLayout from '../../../components/DynamicTabs/TabLayout';
import './index.scss';
import StageSetup from './StageSetup';
import Workflow from './Workflow';

const TabData = [
  { key: 'setup', label: 'Stage Setup', content: 'Content for Stage setup', subTabs: [] },
  {
    key: 'workflow',
    label: 'Stage Workflow',
    content: 'Workflow for stage',
    subTabs: []
  }
];
const Stage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(searchParams.get('tab') === 'workflow' ? 1 : 0);

  const handleTabChange = index => {
    setActiveTab(index);
    const activeTab = TabData[index];
    setSearchParams({ tab: activeTab.key });
  };

  return (
    <Box className='mr-3'>
      <DynamicTabLayout navItems={TabData} isDrawer={true} activeTab={activeTab} setActiveTab={handleTabChange}>
        <StageSetup index='Stage Setup' />
        <Workflow index='Stage Workflow' />
      </DynamicTabLayout>
    </Box>
  );
};

export default Stage;
