/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import '../index.scss';
import Loader from '../../../components/common/Loader';
import { capitalizeFirstLetter, columnDefs, createRequiredField, createSchema, getPayload, getUpdatedPayload, updateObject } from './utils';
import AddTag from './AddTag';
import Search from '../../Contacts/Search';
import CustomButton from '../../../components/common/CustomButton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import TotalCount from '../../../components/common/TotalCount';
import { tagSetupApi } from '../../../services/ApiService';
import TagGridActionSelection from '../../../components/common/FunctionalComponents/TagGridActionSelection';
import ViewTagDrawer from './ViewTagDrawer';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../../selectors';
import { customFormValidator, requireValidMessage, splitLastIfMultiple } from '../../../utils/common';
import { enqueueSnackbar } from 'notistack';
import { DELETE, ERROR, GET, POST, PUT, SUCCESS, TAG_SETUP_LOGS_MESSAGE, WARNING } from '../../../services/constantService';
import { useCustomMessageHook } from '../../../utils/Hooks/useCustomMessageHook';
import TagDeleteConfirmation from './TagDeleteConfirmation';
import { useNavigate } from 'react-router-dom';
import { SUCCESS_STATUS_CODE } from '../../Commons/Utils';
import { notFoundMessage } from '../../../services/MessageService';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
let gridApi, columnApi;

const TagSetupList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [tagData, setTagData] = useState();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false);
  const [statusData, setStatusData] = useState(null);
  const [isEdited, setIsEdited] = useState(false);
  const [tagStatus, setTagStatus] = useState(false);
  const [tagCount, setTagCount] = useState({});
  const { getValues, setValue, register, unregister, watch } = useForm({});
  const [isDeleteTag, setIsDeleteTag] = useState(false);
  const navigate = useNavigate();
  const userData = useSelector(getUserSelector);
  const { translateMessage } = useCustomMessageHook();
  const { t } = useLanguageHooks([
    TRANS_KEYS.TAGS_SETUP_TAG_NAME,
    TRANS_KEYS.TAGS_SETUP_COLOUR_LABEL,
    TRANS_KEYS.TAGS_SETUP_MODULE,
    TRANS_KEYS.TAGS_SETUP_STATUS,
    TRANS_KEYS.BACK,
    TRANS_KEYS.TAGS_SETUP_ADD_TAGS
  ]);

  const isValidated = async requestBody => {
    let isValid = await createSchema.isValid(requestBody);
    if (!isValid) {
      let dirtyField = customFormValidator(requestBody, createRequiredField(t));
      if (dirtyField) {
        const message = translateMessage('Required', ...splitLastIfMultiple(dirtyField));
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      }
    } else {
      return true;
    }
  };
  const deleteTag = async id => {
    setLoading(true);
    const sub_route = `delete_tag/${id}`;
    const response = await tagSetupApi(DELETE, null, sub_route);
    if (response.status === 200) {
      const message = translateMessage('Successfully', false, 'Tag', 'Deleted');
      enqueueSnackbar(message, { variant: SUCCESS });
      gridApi && gridApi.onFilterChanged();
      setLoading(false);
      setIsConfirmationPopupOpen(false);
      setIsDrawerOpen(false);
      setLoading(false);
    } else if (status === 500) {
      const message = translateMessage('UnableMessage', false, 'Delete', 'Tag');
      enqueueSnackbar(message, { variant: ERROR });
    }
  };
  const updateData = async (data, tagStatus) => {
    let formValues = {};
    if (typeof tagStatus === 'string') {
      formValues['status'] = tagStatus === 'Activate' ? true : false;
    } else {
      formValues = { ...getValues() };
    }
    const updatedObject = updateObject(data, formValues);
    const requestBody = getUpdatedPayload(updatedObject, userData);
    const IsValidRequestBody = await isValidated(requestBody);

    if (IsValidRequestBody) {
      setLoading(true);
      const { status, data } = await tagSetupApi(PUT, requestBody, requestBody.id);
      if (status === 200) {
        const message = translateMessage('Successfully', false, 'Tag', 'updated');
        enqueueSnackbar(message, { variant: SUCCESS });
        setIsConfirmationPopupOpen(false);
        setLoading(false);
        setIsEdited(false);
        gridApi && gridApi.onFilterChanged();
      } else if (status === 409) {
        const message = translateMessage('AlreadyExist', false, 'Tag');

        enqueueSnackbar(message, {
          variant: ERROR
        });
      } else {
        const message = translateMessage('UnableMessage', false, 'Create', 'Tag');
        enqueueSnackbar(message, { variant: ERROR });
      }
      setLoading(false);
    }
  };
  const saveData = async () => {
    let formValues = { ...getValues() };
    const requestBody = getPayload(formValues, userData);

    const IsValidRequestBody = await isValidated(requestBody);
    if (IsValidRequestBody) {
      setLoading(true);
      const { status, data } = await tagSetupApi(POST, requestBody);
      if (status === 201) {
        const message = translateMessage('Successfully', false, 'Tag', 'created');
        enqueueSnackbar(message, { variant: SUCCESS });
        setIsPopupOpen(false);
        setLoading(false);
        gridApi && gridApi.onFilterChanged();
      } else if (status === 409) {
        const message = translateMessage('AlreadyExist', false, 'Tag');

        enqueueSnackbar(message, {
          variant: ERROR
        });
      } else {
        const message = translateMessage('UnableMessage', false, 'Create', 'Tag');
        enqueueSnackbar(message, { variant: ERROR });
      }
      setLoading(false);
    }
  };
  const getTagCountByModule = async id => {
    setLoading(true);
    const sub_route = `tag_count/${id}`;
    const tagCount = await tagSetupApi(GET, null, sub_route);
    setLoading(false);
    setTagCount(tagCount?.data);
  };
  const getModules = params => {
    if (params?.data) {
      const moduleNames = params?.data?.tag_module.map(module => capitalizeFirstLetter(module?.module_name));
      const allModules = moduleNames.join(',');

      return (
        <>
          <Box
            onClick={() => {
              setIsDrawerOpen(true);
              setIsEdited(false);
              setTagData(params.data);
            }}
            className='cursor-pointer'
          >
            {allModules}
          </Box>
        </>
      );
    }
  };
  const colorRenderer = params => {
    return (
      <Box
        onClick={() => {
          setIsDrawerOpen(true);
          setIsEdited(false);
          setTagData(params.data);
        }}
        className='cursor-pointer'
      >
        <Box className='color-label' sx={{ backgroundColor: params?.data?.color }} />
      </Box>
    );
  };
  const statusRender = params => {
    const tagGridStatus = params?.data?.status ? 'Active' : 'Inactive';
    return (
      <Box
        onClick={() => {
          setIsDrawerOpen(true);
          setIsEdited(false);
          setTagData(params.data);
        }}
        className='cursor-pointer'
      >
        {tagGridStatus}
      </Box>
    );
  };
  const handledeleteTag = async (data, id, status) => {
    setStatusData(data);
    setTagStatus(status);
    setIsDeleteTag(true);
    await getTagCountByModule(id);
    setIsConfirmationPopupOpen(true);
  };
  const handleChangeStatus = async (data, status) => {
    setStatusData(data);
    setTagStatus(status);
    setIsDeleteTag(false);
    await getTagCountByModule(data?.id);
    setIsConfirmationPopupOpen(true);
  };

  const changeStatus = async tagAction => {
    tagAction === 'Delete' ? await deleteTag(statusData?.id) : await updateData(statusData, tagStatus);
  };
  const GetActionRenderer = params => {
    return <TagGridActionSelection params={params} handledeleteTag={handledeleteTag} handleChangeStatus={handleChangeStatus} />;
  };
  const nameRenderer = params => {
    return (
      <Box
        className='cursor-pointer'
        onClick={() => {
          setIsDrawerOpen(true);
          setIsEdited(false);
          setTagData(params.data);
        }}
      >
        {params?.data?.name}
      </Box>
    );
  };
  const backMenu = () => {
    navigate('/productSetup');
  };

  const onGridReady = params => {
    columnApi = params.columnApi;
    gridApi = params.api;
    params.columnApi.autoSizeColumns();
  };

  const dataSource = {
    getRows: async params => {
      try {
        setIsLoading(true);
        const { status, data } = await tagSetupApi(GET);
        const rowData = data?.data?.rows;
        if (status === SUCCESS_STATUS_CODE) {
          if (Array.isArray(rowData)) {
            const message = notFoundMessage('records');
            if (data?.length === 0) {
              enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(rowData, rowData?.length);
            setRowCount(rowData?.length);
          }
        } else {
          params.failCallback();
        }
        setIsLoading(false);
      } catch (e) {
        console.log(TAG_SETUP_LOGS_MESSAGE.GET_ROWS_FOUND_ERROR, e);
      }
    },
    rowCount: null
  };

  return (
    <div
      style={{
        height: '80vh'
      }}
    >
      <Loader show={isLoading || loading || isLoading} />

      <Box height='75%' paddingX='20px'>
        <Box className='mb-3 pt-3'>
          <CustomButton
            onClick={() => backMenu()}
            buttonText={t(`${TRANS_KEYS.BACK}:key`)}
            type={'tertiary'}
            size={'small'}
            variant=''
            width={42}
            customWidth={42}
            iconLeft={<KeyboardBackspaceIcon sx={{ width: 20, height: 20 }} />}
          />
        </Box>

        <div className='d-flex justify-content-between mb-3'>
          <div>
            <Search
              // value={searchValue}
              //onChange={handleSearchChange}
              className='search-bar'
              // placeholder={t(`${TRANS_KEYS.CONTACTS_SEARCH}:key`)}
            />
          </div>

          <Button variant='contained' id='add-tag-button' className='text-capitalize' startIcon={<AddIcon className='icon-size' />} onClick={() => setIsPopupOpen(true)}>
            {t(`${TRANS_KEYS.TAGS_SETUP_ADD_TAGS}:key`)}
          </Button>
        </div>
        <Box className='d-flex justify-content-end mb-3'>
          <TotalCount rowCount={rowCount} />
        </Box>
        <div className='ag-theme-alpine text-center' id='myGrid'>
          <AgGridReact
            onGridReady={onGridReady}
            enableBrowserTooltips={true}
            rowModelType={'infinite'}
            datasource={dataSource}
            columnDefs={columnDefs(t)}
            defaultColDef={{
              width: 1501,
              resizable: true,
              sortable: false,
              sortingOrder: ['asc', 'desc', null]
            }}
            frameworkComponents={{
              getModules: getModules,
              colorRenderer: colorRenderer,
              statusRender: statusRender,
              GetActionRenderer: GetActionRenderer,
              nameRenderer: nameRenderer
            }}
          />
        </div>
        <AddTag setIsPopupOpen={setIsPopupOpen} isPopupOpen={isPopupOpen} saveData={saveData} setValue={setValue} register={register} unregister={unregister} watch={watch} />
        <ViewTagDrawer
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          data={tagData}
          setValue={setValue}
          register={register}
          unregister={unregister}
          onSubmit={updateData}
          watch={watch}
          handledeleteTag={handledeleteTag}
          handleChangeStatus={handleChangeStatus}
          isEdited={isEdited}
          setIsEdited={setIsEdited}
        />
        <TagDeleteConfirmation
          open={isConfirmationPopupOpen}
          onClose={() => {
            setIsConfirmationPopupOpen(false);
          }}
          onDelete={changeStatus}
          tagCount={tagCount}
          tagStatus={tagStatus}
          isDeleteTag={isDeleteTag}
        ></TagDeleteConfirmation>
      </Box>
    </div>
  );
};

export default TagSetupList;
