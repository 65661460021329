//----------------------------------------------// In-built Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import BusinessIcon from '@mui/icons-material/Business';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { globalSearchSelector } from '../../selectors';
import { globalSearchApi } from '../../services/ApiService';
import { ROUTES } from '../../services/constantService';
import { resetGlobalSearchPagination, updateGlobalSearchData } from '../../store/globalSearchSlice';
import { SEARCH_TIMEOUT } from '../../utils/common';
import InputField from '../common/InputField';

const useStyles = makeStyles(() => ({
  root: {
    color: 'white',
    width: '100%'
  },
  inputRoot: {
    color: 'gray',
    fontSize: 14
  },
  clearIndicator: {
    color: 'white'
  },
  popper: {
    width: 'auto !important',
    maxWidth: '500px'
  }
}));

export default function MasterSearchSelection(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { label = '', placeholder = 'Search', InputLabelProps, ...rest } = props;
  const [searchValue, setSearchValue] = useState('');
  const searchRef = useRef('');
  const { limit, page, selectedTabId, loading: isLoading, refetch } = useSelector(globalSearchSelector);

  const models = selectedTabId !== 'all' ? [selectedTabId] : [];

  const modelsParam = models.map(model => `models[]=${model}`).join('&');

  const getTotalCount = data => {
    if (selectedTabId === 'all') return data.reduce((acc, curr) => acc + curr?.data?.hits?.total?.value, 0);
    const list = data?.find(item => item?.model === selectedTabId);
    return list?.data?.hits?.total?.value;
  };

  const fetchGlobalSearchResults = () => {
    if (!searchValue || searchValue.length < 3) return;
    dispatch(updateGlobalSearchData({ key: 'loading', data: true }));
    const timer = setTimeout(() => {
      let updatedPage = page;
      if (searchRef.current !== searchValue) {
        dispatch(resetGlobalSearchPagination());
        updatedPage = 1;
        searchRef.current = searchValue;
      }
      const sub_route = `?keyword=${searchValue || ''}&from=${(updatedPage - 1) * limit}&size=${limit}&${modelsParam}`;
      globalSearchApi(sub_route).then(response => {
        const { status, data } = response;
        if (status === 200) {
          navigate(`${ROUTES.globalSearch}`);
          dispatch(updateGlobalSearchData({ key: 'data', data }));
          dispatch(updateGlobalSearchData({ key: 'totalCount', data: getTotalCount(data) }));
        }
        dispatch(updateGlobalSearchData({ key: 'loading', data: false }));
      });
    }, SEARCH_TIMEOUT);
    return timer;
  };

  useEffect(() => {
    const timer = fetchGlobalSearchResults();
    return () => clearTimeout(timer);
  }, [searchValue, selectedTabId]);

  useEffect(() => {
    fetchGlobalSearchResults();
  }, [refetch]);

  const renderOptionElement = (props, option) => {
    switch (option.master_search_type || option.backup_search_type) {
      case 'company':
        return (
          <Link {...props} to={`/companies/${option.id}`}>
            <Typography className='d-flex master-search-item cursor-pointer' color='primary'>
              <BusinessIcon className='mr-2' />
              <div className='d-flex flex-column'>
                <Typography>
                  {option.name} {option.country ? <span className='master-search-info'> - {option.country}</span> : ''}
                </Typography>
              </div>
            </Typography>
          </Link>
        );
      case 'contact':
        return (
          <Link {...props} to={`/contacts/${option.id}`}>
            <Typography className='d-flex master-search-item cursor-pointer' color='primary'>
              <PersonIcon className='mr-2' />
              <div className='d-flex flex-column'>
                <Typography>
                  {option.name}
                  {option.company_name ? <span className='master-search-info'> - {option.company_name}</span> : ''}
                </Typography>
                {option.job_title ? <span className='master-search-info master-search-info-sec'>{option.job_title}</span> : ''}
              </div>
            </Typography>
          </Link>
        );
      case 'search':
        return (
          <Link {...props} to={`/searches/${option.id}`}>
            <Typography className='d-flex master-search-item cursor-pointer' color='primary'>
              <SearchIcon className='mr-2' />
              <div className='d-flex flex-column'>
                <Typography>
                  {option.job_title}
                  {option.job_number ? <span className='master-search-info'> - {option.job_number}</span> : ''}
                  {option.stage ? <span className='master-search-info'> - {option.stage}</span> : ''}
                </Typography>
                {option.search_type ? <span className='master-search-info master-search-info-sec'>{option.search_type}</span> : ''}
              </div>
            </Typography>
          </Link>
        );
      case 'work_bench':
        return (
          <Link {...props} to={`/workbenches/${option.id}`}>
            <Typography className='d-flex master-search-item cursor-pointer' color='primary'>
              <ListAltIcon className='mr-2' />
              <div className='d-flex flex-column'>
                <Typography>{option.name}</Typography>
                {option.type ? <span className='master-search-info master-search-info-sec'>{`Workbench ${option.type}`}</span> : ''}
              </div>
            </Typography>
          </Link>
        );
    }
  };

  const onInputChange = (event, val) => {
    setSearchValue(val);
    dispatch(updateGlobalSearchData({ key: 'search', data: val }));
  };

  return (
    <div className='position-relative header-search'>
      <Autocomplete
        {...rest}
        freeSolo
        loading={false}
        options={[]}
        autoSelect={false}
        classes={classes}
        // onFocus={() => {
        //   navigate(`${ROUTES.globalSearch}`);
        // }}
        getOptionLabel={option => option.name || option.job_title || ''}
        onInputChange={(e, val) => onInputChange(e, val)}
        getOptionSelected={(option, value) => option.id === value.id}
        renderOption={(props, option) => {
          return renderOptionElement(props, option);
        }}
        renderInput={params => (
          <InputField
            {...params}
            InputLabelProps={InputLabelProps}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              startAdornment: (
                <span className='search-icon'>
                  <SearchIcon fontSize='inherit' />
                </span>
              ),
              endAdornment: (
                <React.Fragment>
                  {isLoading ? (
                    <span color='white'>
                      <CircularProgress color='inherit' size={20} />
                    </span>
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
            variant='standard'
            label={label}
            placeholder={placeholder}
          />
        )}
      />
    </div>
  );
}

MasterSearchSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.object,
  required: PropTypes.bool
};
