//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import reduxStore from './store/index';
// import configureAmplify, {withAuthenticator} from './amplify';
// const reduxStore = configureStore({});
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import './i18n';
Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN_STRING });

// configureAmplify().then(() => {
//   ReactDOM.render(withAuthenticator(<ReduxProvider store={reduxStore}><App /></ReduxProvider>), document.getElementById('root'));
// })
const app = document.getElementById('root');

const root = createRoot(app);

root.render(
  <ReduxProvider store={reduxStore}>
    <StyledEngineProvider injectFirst>
      <App />
    </StyledEngineProvider>
  </ReduxProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
