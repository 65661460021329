import { Box, Button } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ProjectDataApi } from '../../../../services/ApiService';
import { POST, TAG_SETUP_LOGS_MESSAGE } from '../../../../services/constantService';
import ViewBD from '../../../ViewBD';
import TotalCount from '../../../common/TotalCount';
import { columnBdDef, columnDefs } from '../../utils';
import './index.scss';

const ViewEngagement = props => {
  const { setLoading = () => {} } = props;
  const { id } = useParams();
  const [rowCount, setRowCount] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [selectEngagementTab, setEngagementTab] = useState('projects');
  const [viewBd, setViewBd] = useState(false);
  const [selectedBdData, setSelectedBdData] = useState();
  const selectedTabRef = useRef();
  selectedTabRef.current = selectEngagementTab;
  const handleEngagementTabState = value => {
    setEngagementTab(value);
  };
  const fetchProjects = async type => {
    try {
      setLoading(true);

      const projectData = await ProjectDataApi(
        POST,
        '',
        {
          query: {
            order: [['job_number', 'DESC']],
            company_id: id
          },
          countForStages: [],
          type
        },
        'all'
      );
      if (projectData?.data) {
        setRowData(projectData?.data?.data?.data ? projectData?.data?.data?.data : projectData?.data?.data ? projectData?.data?.data : [projectData?.data]);
        setRowCount(projectData?.data?.data?.data?.length || 0);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectEngagementTab == 'projects') {
          await fetchProjects('all-projects');
        } else {
          await fetchProjects('all-bd');
        }
      } catch (error) {
        console.error(TAG_SETUP_LOGS_MESSAGE.GET_ROWS_FOUND_ERROR, error);
        // Handle error
      }
    };

    fetchData();
  }, [id, selectEngagementTab]);

  const handleOpenBdOrProject = params => {
    if (selectedTabRef.current !== 'projects') {
      setViewBd(true);
      setSelectedBdData(params.data);
    }
  };
  const onCloseBd = () => {
    setViewBd(false);
  };
  const getProjects = params => {
    return (
      <Box>
        {selectedTabRef.current === 'projects' ? (
          <Link to={`/projects/view-project/${params?.data?.id}`} target='_blank'>
            <Box
              className='fs-14'
              sx={{
                color: '#000!important'
              }}
            >
              {params?.data?.job_title}
            </Box>
          </Link>
        ) : (
          <p
            className='fs-14'
            onClick={() => {
              handleOpenBdOrProject(params);
            }}
          >
            {params?.data?.job_title}
          </p>
        )}
      </Box>
    );
  };

  const getBdName = params => {
    return (
      <Link to={`/projects/view-project/${params?.data?.bd_project_id}`} target='_blank'>
        <Box
          className='fs-14'
          sx={{
            color: '#000!important'
          }}
        >
          {params?.data?.bd_project?.job_title}
        </Box>
      </Link>
    );
  };

  const getStage = params => {
    return <Box className='fs-14 details-width'>{params?.data?.stage}</Box>;
  };
  useEffect(() => {
    console.log('rowData', rowData, selectEngagementTab);
  }, [rowData, selectEngagementTab]);

  return (
    <div id='view-company' className={'custom-scrollbar pl-3 pt-2 pb-2'}>
      <Box className='details-container'>
        <Box className='details-container-subtab scroll-content'>
          <Box className='d-flex align-items-center justify-content-between py-3'>
            <Box className='d-flex align-items-center'>
              <Button
                className={`toggle-button px-1 py-1 ${selectEngagementTab === 'projects' ? 'active' : ''} px-5 rounded-left text-capitalize`}
                onClick={() => handleEngagementTabState('projects')}
              >
                Projects
              </Button>
              <Button
                className={`toggle-button px-1 py-1  ${selectEngagementTab === 'businessDevelopment' ? 'active' : ''} rounded-right text-capitalize`}
                onClick={() => handleEngagementTabState('businessDevelopment')}
              >
                Business Development
              </Button>
            </Box>
            <Box className='mb-1 mr-3'>
              <TotalCount rowCount={rowCount} />
            </Box>
          </Box>
          <div className={`ag-theme-alpine text-center mr-3 engagement-wrapper ${selectEngagementTab === 'businessDevelopment' ? 'business-development' : null}`} id='myGrid'>
            {selectEngagementTab === 'projects' ? (
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs()}
                frameworkComponents={{
                  getProjects: getProjects,
                  getStage: getStage,
                  getBdName
                }}
              />
            ) : (
              <AgGridReact
                rowData={rowData}
                columnDefs={columnBdDef()}
                frameworkComponents={{
                  getProjects: getProjects,
                  getStage: getStage,
                  getBdName
                }}
              />
            )}
          </div>
        </Box>
      </Box>
      {viewBd && <ViewBD open={viewBd} onClose={onCloseBd} bd={selectedBdData} companyId={id} />}
    </div>
  );
};

ViewEngagement.propTypes = {
  setLoading: PropTypes.func
};

export default ViewEngagement;
