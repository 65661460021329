import { Box } from '@mui/material';
import React from 'react';
import { CardList } from './CardList';
import { SubTabs } from './SubTabs';
import Tabs from './Tabs';

export const GlobalSearch = () => {
  return (
    <Box paddingTop={2} paddingLeft={4}>
      <Tabs />
      <SubTabs />
      <CardList />
    </Box>
  );
};
