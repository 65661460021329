import React, { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import PropTypes from 'prop-types';
import './index.scss';
import { Box } from '@mui/material';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';

/* file drag and drop */
const FileDragUpload = props => {
  const { /* setValue, */ label, width = 300, handleChangeDocument = () => {}, className, children, isShowOutLineIcon = true, showFileName = true, fileUpoaderClass = '', horizontal = false } = props;
  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_CREATE_FROM_RESUME, TRANS_KEYS.CONTACTS_FILE_NAME]);
  const [file, setFiles] = useState(null);

  const handleChange = file => {
    setFiles(file);
    // setValue("file", file)
    handleChangeDocument(file);
  };

  const deleteFile = () => {
    setFiles(null);
  };

  return (
    <Box className={`fileUploader ${horizontal ? 'flex align-items-baseline' : ''}`}>
      <FileUploader handleChange={handleChange} name='file' classes={fileUpoaderClass}>
        {isShowOutLineIcon && (
          <Box sx={{ width: width }} className={`formParent ${className}`}>
            <Box className='font-size fs-13 d-flex align-items-center'>
              {label}
              <UploadFileOutlinedIcon className='pl-1 fs-28' />
            </Box>
          </Box>
        )}
        {children}
      </FileUploader>
      {showFileName && (
        <Box className={`font-size name-width ${horizontal ? 'flex ml-2' : ''}`}>
          <Box>{file ? `${t(`${TRANS_KEYS.CONTACTS_FILE_NAME}:key`)} : ${file?.name}` : ''}</Box>
          {file ? <CloseIcon onClick={deleteFile} className='close-icon' /> : ''}
        </Box>
      )}
    </Box>
  );
};
FileDragUpload.propTypes = {
  setValue: PropTypes.func,
  label: PropTypes.string,
  width: PropTypes.number,
  handleChangeDocument: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  isShowOutLineIcon: PropTypes.bool,
  showFileName: PropTypes.bool,
  fileUpoaderClass: PropTypes.string,
  horizontal: PropTypes.bool
};
export default FileDragUpload;
