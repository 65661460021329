import { Box, Button, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const ButtonDropDown = props => {
  const { show = true, options = [], fontWeight, variant = 'outlined', buttonText = '', iconLeft, iconRight, className = '', renderPropertyName } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = option => {
    if (props.onClick && typeof props.onClick === 'function') {
      props.onClick(option);
    }
    handleClose();
  };

  return show ? (
    <Box className={className}>
      <Button color='primary' variant={variant} onClick={handleClick} size='small' disabled={props.disabled ? props.disabled : false}>
        <Box fontWeight={fontWeight} className={'d-flex align-items-center justify-content-center '}>
          {iconLeft} {buttonText}
          {iconRight}
        </Box>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((option, index) => (
          <MenuItem onClick={() => handleMenuItemClick(option)} key={index}>
            {renderPropertyName ? option[renderPropertyName] : option}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  ) : (
    <></>
  );
};

ButtonDropDown.propTypes = {
  buttonText: PropTypes.string,
  options: PropTypes.array,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  fontWeight: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  show: PropTypes.bool,
  className: PropTypes.string,
  renderPropertyName: PropTypes.string
};

export { ButtonDropDown };
