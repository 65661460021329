import React, { useEffect, useRef } from 'react';

import { CopyAllOutlined } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import CustomButton from '../../../../components/common/CustomButton';
import DrawerComponent from '../../../../components/common/Drawer';
import Loader from '../../../../components/common/Loader';
import { emailTemplatesApi } from '../../../../services/ApiService';
import { CLIPBOARD_COPY_MESSAGE, MESSATE_TEMPLATE_VALIDATION } from '../../../../services/constantService';
import useMessageTemplateData from '../MessageTemplateData';
import { updateText } from './utils';

import MessageTemplatesRT from '../../../../components/MessageTemplatePopup/MessageTemplatesRT';
import { colorTextInBraces } from '../../../../components/MessageTemplatePopup/utils';
import '../index.scss';

const InitialFormState = {
  subject: '',
  body: '',
  to: '',
  from: '',
  cc: '',
  bcc: '',
  template_name: '',
  template_description: '',
  is_public: false
};

const AddEmailTemplate = props => {
  const { isDrawerOpen, setIsDrawerOpen, templateData, currentTemplate, setCurrentTemplate, refetch } = props;

  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, setValue, register, unregister, watch, reset } = useForm({
    InitialFormState
  });

  const { messageTemplateData } = useMessageTemplateData();

  const [lastActiveElement, setLastActiveElement] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const subjectRef = useRef(null);
  const toRef = useRef(null);
  const fromRef = useRef(null);
  const ccRef = useRef(null);
  const bccRef = useRef(null);
  const bodyRef = useRef(null);

  useEffect(() => {
    register('subject');
    register('body');
    register('to');
    register('from');
    register('cc');
    register('bcc');
    register('template_name');
    register('template_description');
    register('is_public');

    return () => {
      unregister('subject');
      unregister('body');
      unregister('to');
      unregister('from');
      unregister('cc');
      unregister('bcc');
      unregister('template_name');
      unregister('template_description');
      unregister('is_public');

      // Reset the form
      reset(InitialFormState);
    };
  }, [register]);

  useEffect(() => {
    if (templateData?.data?.data && currentTemplate === templateData?.data?.data?.id) {
      const autofillFormData = templateData?.data?.data;
      setValue('subject', autofillFormData.subject);
      setValue('body', autofillFormData.htmlPart);
      setValue('to', autofillFormData.to);
      setValue('from', autofillFormData.from);
      setValue('cc', autofillFormData.cc);
      setValue('bcc', autofillFormData.bcc);
      setValue('template_name', autofillFormData.title);
      setValue('template_description', autofillFormData.description);
      setValue('is_public', autofillFormData.is_public);
    }
  }, [templateData]);

  const handleInsertText = async (textToInsert, color) => {
    switch (lastActiveElement) {
      case 'subject':
        updateText(textToInsert, subjectRef, 'subject', setValue);
        break;
      case 'to':
        updateText(textToInsert, toRef, 'to', setValue);
        break;
      case 'from':
        updateText(textToInsert, fromRef, 'from', setValue);
        break;
      case 'cc':
        updateText(textToInsert, ccRef, 'cc', setValue);
        break;
      case 'bcc':
        updateText(textToInsert, bccRef, 'bcc', setValue);
        break;
      case 'body':
        bodyRef.current.selection.setContent(colorTextInBraces(textToInsert, color));
        bodyRef.current.focus();
        break;
      default:
        try {
          await navigator.clipboard.writeText(textToInsert);
          enqueueSnackbar(CLIPBOARD_COPY_MESSAGE.SUCCESS, {
            variant: 'success'
          });
        } catch (error) {
          console.error(CLIPBOARD_COPY_MESSAGE.FAILED, error);
          enqueueSnackbar(CLIPBOARD_COPY_MESSAGE.FAILED, { variant: 'error' });
        }
        break;
    }
  };

  const handleSubmitForm = async formData => {
    setLoading(true);

    const requestBody = {
      subject: formData.subject,
      html_part: formData.body,
      to: formData.to,
      from: formData.from,
      cc: formData.cc,
      bcc: formData.bcc,
      template_name: formData.template_name,
      template_description: formData.template_description,
      language_code: 'en',
      is_public: formData.is_public
    };

    if (currentTemplate) {
      await emailTemplatesApi('put', `email/${currentTemplate}`, requestBody);
    } else {
      await emailTemplatesApi('post', '', requestBody);
    }

    setIsDrawerOpen(false);
    setLoading(false);

    // Reset the form
    reset(InitialFormState);
    refetch();
    enqueueSnackbar(MESSATE_TEMPLATE_VALIDATION.SUCCESS, {
      variant: 'success'
    });
  };

  return (
    <Box id='add-email-template-drawer'>
      <DrawerComponent
        id={'email-templates'}
        anchor={'right'}
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
          setCurrentTemplate(null);

          // Reset the form
          reset(InitialFormState);
        }}
        width='80vw'
      >
        <Loader show={loading || templateData?.loading} />

        <Box
          sx={{
            display: 'flex',
            p: 2,
            overflowY: 'scroll'
          }}
        >
          <Box
            sx={{
              rowGap: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
              width: '75%'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                justifyContent: 'space-between'
              }}
            >
              <TextField
                label='Template Name'
                id='inputTextStyle'
                fullWidth
                hiddenLabel
                variant='outlined'
                disabled={currentTemplate !== null}
                onFocus={() => setLastActiveElement('template_name')}
                value={watch('template_name') || ''}
                onChange={e => {
                  setValue('template_name', e.target.value);
                }}
              />

              <TextField
                label='Template Description'
                id='inputTextStyle'
                fullWidth
                hiddenLabel
                variant='outlined'
                onFocus={() => setLastActiveElement('template_description')}
                value={watch('template_description') || ''}
                onChange={e => {
                  setValue('template_description', e.target.value);
                }}
              />
            </Box>

            <TextField
              label='To'
              id='inputTextStyle'
              fullWidth
              hiddenLabel
              variant='outlined'
              onFocus={() => setLastActiveElement('to')}
              inputRef={toRef}
              value={watch('to') || ''}
              onChange={e => {
                setValue('to', e.target.value);
              }}
            />

            <TextField
              label='From'
              id='inputTextStyle'
              fullWidth
              hiddenLabel
              variant='outlined'
              onFocus={() => setLastActiveElement('from')}
              inputRef={fromRef}
              value={watch('from') || ''}
              onChange={e => {
                setValue('from', e.target.value);
              }}
            />

            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                justifyContent: 'space-between'
              }}
            >
              <TextField
                label='Cc'
                id='inputTextStyle'
                fullWidth
                hiddenLabel
                variant='outlined'
                onFocus={() => setLastActiveElement('cc')}
                inputRef={ccRef}
                value={watch('cc') || ''}
                onChange={e => {
                  setValue('cc', e.target.value);
                }}
              />

              <TextField
                label='Bcc'
                id='inputTextStyle'
                fullWidth
                hiddenLabel
                variant='outlined'
                onFocus={() => setLastActiveElement('bcc')}
                inputRef={bccRef}
                value={watch('bcc') || ''}
                onChange={e => {
                  setValue('bcc', e.target.value);
                }}
              />
            </Box>

            <TextField
              label='Subject'
              id='inputTextStyle'
              fullWidth
              hiddenLabel
              variant='outlined'
              onFocus={() => setLastActiveElement('subject')}
              inputRef={subjectRef}
              value={watch('subject') || ''}
              onChange={e => {
                setValue('subject', e.target.value);
              }}
            />

            <Box id='message-template-rtf'>
              <Typography>Body</Typography>
              <MessageTemplatesRT
                onFocus={() => {
                  setLastActiveElement('body');
                }}
                inputRef={bodyRef}
                value={watch('body') || ''}
                onChange={value => {
                  setValue('body', value);
                }}
              />
            </Box>
            <Box
              className='message-template-footer-submit'
              sx={{
                paddingBottom: 2
              }}
            >
              <Box
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: '0.5rem'
                }}
              >
                <Checkbox
                  sx={{
                    padding: '0'
                  }}
                  checked={watch('is_public') || false}
                  onChange={e => {
                    setValue('is_public', e.target.checked);
                  }}
                />
                <Typography className='fs-14'>Make Public</Typography>
              </Box>
              <Box
                sx={{
                  display: 'inline-flex',
                  gap: '0.5rem'
                }}
              >
                <CustomButton
                  type='tertiary-error'
                  buttonText={'Cancel'}
                  onClick={() => {
                    setIsDrawerOpen(false);
                    setCurrentTemplate(null);

                    // Reset the form
                    reset(InitialFormState);
                  }}
                />

                <CustomButton buttonText={'Save'} onClick={handleSubmit(handleSubmitForm)} />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: '25%',
              textAlign: 'left',
              padding: '0 1rem'
            }}
          >
            <Typography className='pb-3'>Available Options</Typography>

            {messageTemplateData.map(option => {
              return (
                <Accordion key={option.header}>
                  <AccordionSummary>
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      {option.header /* This is the header for each accordion */}
                      <Box
                        className='p-2'
                        sx={{
                          backgroundColor: option.color,
                          borderRadius: '100%',
                          marginLeft: '8px'
                        }}
                      />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'left'
                    }}
                  >
                    {option.values.map((option, index) => (
                      <Typography
                        onClick={() => {
                          handleInsertText(option.value, option.color);
                        }}
                        className='mb-2 cursor-pointer fs-14'
                        width='100%'
                        key={index}
                      >
                        {option.name} <CopyAllOutlined />
                      </Typography>
                    ))}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        </Box>
      </DrawerComponent>
    </Box>
  );
};

AddEmailTemplate.propTypes = {
  onClose: PropTypes.func,
  id: PropTypes.string,
  isDrawerOpen: PropTypes.bool,
  setIsDrawerOpen: PropTypes.func,
  templateData: PropTypes.object,
  currentTemplate: PropTypes.string,
  setCurrentTemplate: PropTypes.func,
  refetch: PropTypes.func
};

export default AddEmailTemplate;
