import React, { useState } from 'react';
import CustomDropdown from '../../../components/common/CustomDropdown';
// import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

function StageGroupDropdown({ createStageGroups, deleteCandidateStageGroup, item, options, groupDataMapping, projectData }) {
  const [selectedOption, setSelectedOption] = useState({ label: groupDataMapping[item.id]?.short_desc, value: groupDataMapping[item.id]?.field_value });

  // const handleDeleteCandidateStageGroup = async () => {
  //   if (selectedOption?.value) {
  //     await deleteCandidateStageGroup(projectData.id, selectedOption.value, item.id);
  //     setSelectedOption(null);
  //   }
  // };

  return (
    <div className='flex-center-w-full'>
      <CustomDropdown
        label='Stage Group'
        options={options}
        defaultValue={{ label: groupDataMapping[item.id]?.short_desc, value: groupDataMapping[item.id]?.field_value }}
        value={selectedOption}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        className={'w-100'}
        onChange={(e, newValue) => {
          if (newValue && newValue.value) {
            createStageGroups(item.id, newValue.value);
          } else {
            const option = selectedOption.value;
            deleteCandidateStageGroup(projectData.id, option, item.id)
          }
          setSelectedOption(newValue);
        }}
      />
      {/* <CloseIcon color='error' style={{ marginLeft: '8px', cursor: 'pointer' }} onClick={handleDeleteCandidateStageGroup} /> */}
    </div>
  );
}

StageGroupDropdown.propTypes = {
  createStageGroups: PropTypes.func.isRequired,
  deleteCandidateStageGroup: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  options: PropTypes.array,
  groupDataMapping: PropTypes.object.isRequired,
  projectData: PropTypes.object.isRequired
};

export default StageGroupDropdown;
