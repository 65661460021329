import React from 'react';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import CandidateProjectStatusSetupTable from './CandidateProjectStatusSetupTable';

const CandidateProjectStatusSetup = () => {
  const { t } = useLanguageHooks([TRANS_KEYS.TRANSLATE_VALUE_SETUP, TRANS_KEYS.TRANSLATE_VALUE_CONTENT]);

  return (
    <React.Fragment>
      <div className='position-relative m-3'>
        <CandidateProjectStatusSetupTable />
      </div>
    </React.Fragment>
  );
};

export default CandidateProjectStatusSetup;
