import { Box, Divider, TableCell, TableRow } from '@mui/material';
import React, { useEffect } from 'react';
import CustomTable from '../../../common/CustomTable';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import CompanySelection from '../../../common/FunctionalComponents/CompanySelection';
import CompanyRelationChildTypeSelection from '../../../common/FunctionalComponents/CompanyRelationChildTypeSelection';
import CompanyRelationParentTypeSelection from '../../../common/FunctionalComponents/CompanyRelationParentTypeSelection';
import PropTypes from 'prop-types';

const ViewAssociatedCompanies = props => {
  const { updateField, register, unregister, setValue, detailsData } = props;

  const [relationshipParent, setRelationshipParent] = useState([{ relationship: '', parent_company: '' }]);
  const [relationshipChild, setRelationshipChild] = useState([{ relationship: '', child_company: '' }]);
  const [isEditingRelationParent, setIsEditingRelationParent] = useState(true);
  const [isEditingRelationChild, setIsEditingRelationChild] = useState(true);
  useEffect(() => {
    register('company_associated_child');
    register('company_associated_parent');

    return () => {
      unregister('company_associated_child');
      unregister('company_associated_parent');
    };
  }, [register, unregister]);
  useEffect(() => {
    setValue('company_associated_child', relationshipChild);
    setValue('company_associated_parent', relationshipParent);
  }, [relationshipParent, relationshipChild]);
  useEffect(() => {
    if (detailsData && (detailsData?.company_associated_parent || detailsData?.company_associated_child)) {
      if (detailsData?.company_associated_parent.length) {
        setRelationshipParent(detailsData?.company_associated_parent);
      }
      if (detailsData?.company_associated_child.length) {
        setRelationshipChild(detailsData?.company_associated_child);
      }
    }
  }, [detailsData]);

  const handleRelationParent = () => {
    setRelationshipParent([...relationshipParent, { relationship: '', parent_company: '' }]);
  };
  const handleRelationChild = () => {
    setRelationshipChild([...relationshipChild, { relationship: '', child_company: '' }]);
  };
  const handleEditPhoneChange = value => {
    setIsEditingRelationParent(value);
  };
  const handleEditRelationChildChange = value => {
    setIsEditingRelationChild(value);
  };
  const handleRemoveRelationParent = index => {
    const updatedRelationParent = [...relationshipParent];
    updatedRelationParent.splice(index, 1);
    setRelationshipParent(updatedRelationParent);
  };
  const handleRemoveRelationChild = index => {
    const updatedRelationChild = [...relationshipChild];
    updatedRelationChild.splice(index, 1);
    setRelationshipChild(updatedRelationChild);
  };
  const handleChangeRelationshipChild = (index, field, value) => {
    let updatedParentRelation = relationshipChild.map((relation, i) => {
      if (i === index) {
        return { ...relation, [field]: value, ign_translate_value: value };
      }

      return relation;
    });
    setRelationshipChild(updatedParentRelation);
  };
  const handleChangeRelationshipParent = (index, field, value) => {
    let updatedParentRelation = relationshipParent.map((relation, i) => {
      if (i === index) {
        return { ...relation, [field]: value, ign_translate_value: value };
      }

      return relation;
    });
    setRelationshipParent(updatedParentRelation);
  };
  return (
    <div id='view-company' className={'custom-scrollbar pl-3 pt-2 pb-2'}>
      <Box className='details-container'>
        <Box className='details-container-subtab scroll-content'>
          <Box className='section-details m-2'>
            <Box className='communication-section'>
              <Box className='head-container'>
                <Box className='d-flex sub-head-content'>
                  <Box className='field-label-input'>
                    <CustomTable
                      headerData={['Relationship', 'Company']}
                      title={'Parent Company'}
                      handleAnotherRow={handleRelationParent}
                      rows={relationshipParent}
                      disable={3}
                      isEditing={isEditingRelationParent}
                      showAddAnotherButton={false}
                      onEditChange={handleEditPhoneChange}
                      customTableId={'view-contact-content'}
                      headerSize={'fs-12'}
                      titleSize={'fs-13'}
                      isDrawer={true}
                      updateField={updateField}
                      //dataList={phoneList}
                      // handleCloseClick={handleCloseClick}
                      saveDataKey={'company_associated_parent'}
                    >
                      {isEditingRelationParent ? (
                        relationshipParent?.length === 0 ? (
                          <TableRow>
                            <TableCell align='center' className='p-2'>
                              <Box className='fs-11'>-</Box>
                            </TableCell>
                            <TableCell align='center' className='p-2'>
                              <Box className='fs-11'>-</Box>
                            </TableCell>
                          </TableRow>
                        ) : (
                          relationshipParent?.map((relation, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell align='center' className='p-2'>
                                  <Box className='fs-11'> {relation?.ign_translate_value?.short_desc || '-'}</Box>
                                </TableCell>
                                <TableCell align='center' className='p-2'>
                                  <Box className='fs-11'>{relation?.parent_company_name?.name || '-'}</Box>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        )
                      ) : (
                        relationshipParent?.map((relation, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell className='viewcontact-icon-style p-2' align='center'>
                                <CompanyRelationParentTypeSelection
                                  isDrawer={true}
                                  label={'Relationship'}
                                  value={relation?.ign_translate_value?.short_desc ? relation?.ign_translate_value?.short_desc : relation?.relationship}
                                  onChange={(e, value) => handleChangeRelationshipParent(index, 'relationship', value ? value : '')}
                                  // setPhoneList={setPhoneList}
                                />
                              </TableCell>
                              <TableCell className=' p-2' align='center'>
                                <CompanySelection
                                  isDrawer={true}
                                  value={relation?.parent_company?.name || relation?.parent_company_name?.name}
                                  onChange={(e, data) => {
                                    handleChangeRelationshipParent(index, 'parent_company', data ? data : '');
                                  }}
                                  label={'Company'}
                                  isForAddCompany={true}
                                  // selectedList={phoneNumbers}
                                  // setPhoneList={setPhoneList}
                                />
                              </TableCell>

                              {relationshipParent?.length > 1 && (
                                <TableCell align='center' className='remove-another-button p-2' mt={2}>
                                  <CloseIcon onClick={() => handleRemoveRelationParent(index)} className='table-close-icon' />
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })
                      )}
                    </CustomTable>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider variant='middle' />
          <Box className='section-details m-2'>
            <Box className='communication-section'>
              <Box className='head-container'>
                <Box className='d-flex sub-head-content'>
                  <Box className='field-label-input'>
                    <CustomTable
                      headerData={['Relationship', 'Company']}
                      title={'Child Company'}
                      handleAnotherRow={handleRelationChild}
                      rows={relationshipChild}
                      disable={3}
                      isEditing={isEditingRelationChild}
                      showAddAnotherButton={false}
                      onEditChange={handleEditRelationChildChange}
                      customTableId={'view-contact-content'}
                      headerSize={'fs-12'}
                      titleSize={'fs-13'}
                      isDrawer={true}
                      updateField={updateField}
                      //dataList={phoneList}
                      // handleCloseClick={handleCloseClick}
                      saveDataKey={'company_associated_child'}
                    >
                      {isEditingRelationChild ? (
                        relationshipChild?.length === 0 ? (
                          <TableRow>
                            <TableCell align='center' className='p-2'>
                              <Box className='fs-11'>-</Box>
                            </TableCell>
                            <TableCell align='center' className='p-2'>
                              <Box className='fs-11'>-</Box>
                            </TableCell>
                          </TableRow>
                        ) : (
                          relationshipChild?.map((relation, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell align='center' className='p-2'>
                                  <Box className='fs-11'> {relation?.ign_translate_value?.short_desc || '-'}</Box>
                                </TableCell>
                                <TableCell align='center' className='p-2'>
                                  <Box className='fs-11'>{relation?.child_company_name?.name || '-'}</Box>
                                </TableCell>
                              </TableRow>
                            );
                          })
                        )
                      ) : (
                        relationshipChild?.map((relation, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell className='viewcontact-icon-style p-2' align='center'>
                                <CompanyRelationChildTypeSelection
                                  isDrawer={true}
                                  label={'Relationship'}
                                  value={relation?.ign_translate_value?.short_desc ? relation?.ign_translate_value?.short_desc : relation?.relationship.short_desc}
                                  onChange={(e, value) => {
                                    handleChangeRelationshipChild(index, 'relationship', value ? value : '');
                                  }}
                                  // selectedList={phoneNumbers}
                                  // setPhoneList={setPhoneList}
                                />
                              </TableCell>
                              <TableCell className=' p-2' align='center'>
                                <CompanySelection
                                  isDrawer={true}
                                  value={relation?.child_company_name ? relation?.child_company_name : relation?.child_company}
                                  onChange={(e, data) => handleChangeRelationshipChild(index, 'child_company', data ? data : '')}
                                  label={'Company'}
                                  isForAddCompany={true}
                                  // selectedList={phoneNumbers}
                                  // setPhoneList={setPhoneList}
                                />
                              </TableCell>

                              {relationshipChild?.length > 1 && (
                                <TableCell align='center' className='remove-another-button p-2' mt={2}>
                                  <CloseIcon onClick={() => handleRemoveRelationChild(index)} className='table-close-icon' />
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })
                      )}
                    </CustomTable>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
ViewAssociatedCompanies.propTypes = {
  data: PropTypes.func,
  updateField: PropTypes.func,
  register: PropTypes.func,
  unregister: PropTypes.func,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  detailsData: PropTypes.func,
  companyData: PropTypes.object
};

export default ViewAssociatedCompanies;
