import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
// import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
// import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import PartnerInformation from './Components/PartnerInformation';
import RecruiterInformation from './Components/RecruiterInformation';
import ResearcherInformation from './Components/ResearcherInformation';
import AdminInformation from './Components/AdminInformation';
import './index.scss';
const TeamInfo = props => {
  const { register, control, setValue, watch, currentValues, isEditing, option, bd = '' } = props;
  const recruiterMessage =
    currentValues?.brand === (currentValues?.brand === 'Advisory' ? 'Advisory' : 'Advisory')
      ? '<span><b>Note:</b>  Recruiter Information section is not available for ‘Advisory’ Service Offering.<br/> You may consider selecting another ‘Service Offering’ in the Basic Information page ,if Recruiter Information is required.</span>'
      : null;
  const researcherMessage =
    currentValues?.brand === (currentValues?.brand === 'Advisory' ? 'Advisory' : 'Advisory')
      ? '<span><b>Note:</b> Researcher Information section is not available for ‘Advisory’ Service Offering.<br/> You may consider selecting another ‘Service Offering’ in the Basic Information page if Researcher Information is required.</span>'
      : null;
  // const { t } = useLanguageHooks([
  //   TRANS_KEYS.BD_TEAM_INFO,
  // ]);

  return (
    <Box id='team-info' className='p-3'>
      <Box>
        <Box className='m3'>
          {/* {
            bd && <Box className="text-label text-center fs-20 pl-1 pb-1 header-font my-4">
              {t(`${TRANS_KEYS.BD_TEAM_INFO}:key`)}
            </Box>
          } */}

          <Box className='team-info-table d-flex flex-column p-3 text-aligns-center'>
            <PartnerInformation option={option} register={register} control={control} setValue={setValue} watch={watch} isEditing={isEditing} currentValues={currentValues} bd={bd} />
            <RecruiterInformation
              option={option}
              register={register}
              control={control}
              setValue={setValue}
              watch={watch}
              isEditing={isEditing}
              currentValues={currentValues}
              message={recruiterMessage}
            />
            <ResearcherInformation
              option={option}
              register={register}
              control={control}
              setValue={setValue}
              watch={watch}
              isEditing={isEditing}
              currentValues={currentValues}
              message={researcherMessage}
            />
            <AdminInformation option={option} register={register} control={control} setValue={setValue} watch={watch} isEditing={isEditing} currentValues={currentValues} message={recruiterMessage} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

TeamInfo.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  control: PropTypes.object,
  currentValues: PropTypes.object,
  isEditing: PropTypes.bool,
  option: PropTypes.array,
  bd: PropTypes.string,
  types: PropTypes.string,
  currentTabIndex: PropTypes.number
};
export default TeamInfo;
