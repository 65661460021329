import React from 'react';

import { Box, List, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { globalSearchSelector } from '../../../selectors';
import { Pagination } from '../Pagination/index';

export const CardList = () => {
  const { data, tabs, selectedTabId, loading } = useSelector(globalSearchSelector);

  const NoResultsFound = (
    <Box display={'flex'} minHeight={'10vh'} alignItems={'center'} justifyContent={'center'}>
      <Typography align='center' color={'#959fb7'} fontWeight={600} fontSize={12}>
        No results found!!
      </Typography>
    </Box>
  );

  const Loader = Skeleton => (
    <Stack spacing={2}>
      {Array.from({ length: selectedTabId !== 'all' ? 10 : 2 }).map((_, index) => (
        <Skeleton key={index} />
      ))}
    </Stack>
  );

  return (
    <Box width={'85vw'}>
      <Box display={'flex'} borderRadius={1} minHeight={'70vh'} flexDirection={'column'} padding={2} bgcolor={'Background'}>
        {data?.map(tab => {
          const results = tab?.data?.hits?.hits;
          const selectedTab = tabs?.find(t => t?.model === tab?.model);
          const label = selectedTab?.label;
          const CardComponent = selectedTab?.component;

          return selectedTabId === tab?.model || (selectedTabId === 'all' && loading) || (selectedTabId === 'all' && !loading && results?.length !== 0) ? (
            <>
              <Typography fontWeight={'bolder'} fontSize={selectedTabId === 'all' ? 11 : 14} color={'black'}>
                {label}
              </Typography>

              <List sx={{ marginTop: 1 }}>
                {!loading && results && CardComponent && results?.map(item => <CardComponent key={item?._source?._id} item={item} />)}
                {!loading && results && results.length === 0 && NoResultsFound}
                {loading && Loader(selectedTab?.skeletonComponent)}
              </List>
            </>
          ) : (
            <></>
          );
        })}
      </Box>
      <Pagination />
    </Box>
  );
};
