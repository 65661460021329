import { Box, Grid, Stack, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ViewContactDrawer from '../../components/ViewContactDrawer';
import Loader from '../../components/common/Loader';
import { getUserSelector } from '../../selectors';
import { ProjectCandidateApi, ProjectDataApi, getProjectAndBdCount, getRecentActivities } from '../../services/ApiService';
import { GET, POST, ROUTES } from '../../services/constantService';
import LeftSection from './Components/LeftSection';
import Popup from './Components/Popup/Popup';
import TableView from './Components/ProjectTable';
import RightSection from './Components/RightSection';
import { getAllProjectApiQuery, projectGridColumn } from './Constants';
import style from './Home.module.scss';
import { createEnglishStatements } from './utils';

function Home() {
  const [loading, setLoading] = React.useState(false);
  const [allProjects, setAllProjects] = React.useState([]);
  const [contactId, setContactId] = React.useState();
  const [allInterview, setAllInterviews] = React.useState([]);
  const [recentActivities, setRecentActivities] = React.useState([]);
  const [count, setCount] = React.useState();
  const userData = useSelector(getUserSelector);
  const theme = useTheme();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(!!contactId);
  const [interviewPopup, setInterviewPopup] = React.useState(false);
  const [interviewCount, setInterviewCount] = React.useState(0);
  const hagndleCloseAll = () => {
    setInterviewPopup(false);
  };
  const handleInterviewPopup = () => {
    setInterviewPopup(true);
  };

  const fetchAllProjects = async () => {
    try {
      const projectData = await ProjectDataApi(POST, '', getAllProjectApiQuery, 'all');

      if (projectData?.data) {
        const projectGridData = projectData?.data?.data?.data?.map(item => ({
          ...item,
          _metadata: { ...item }
        }));
        setAllProjects(projectGridData);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getAllRecentActivity = async () => {
    try {
      let lastDay = new Date();
      lastDay.setDate(lastDay.getDate() - 1);
      const recentActivities = await getRecentActivities({
        all: true,
        userId: userData?.id,
        // startTime: new Date(lastDay).toISOString(),
        // endTime: new Date().toISOString(),
        sortBy: ['activity_added_at', 'DESC']
      });
      if (recentActivities?.data) {
        const data = createEnglishStatements(recentActivities?.data);
        setRecentActivities(data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getInterviewDetails = async () => {
    try {
      const recentActivities = await getRecentActivities({
        all: true,
        userId: userData?.id,
        sortBy: ['activity_added_at', 'DESC'],
        onlyFutureInterviews: true,
        activityAddedAt: true
      });
      if (recentActivities?.data) {
        const data = recentActivities?.data?.filter(item => item?.data?.project !== null);
        setAllInterviews(data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getCount = async () => {
    try {
      const count = await getProjectAndBdCount();

      if (count?.data) {
        const data = count?.data;
        setCount(data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getInterviewCount = async () => {
    try {
      const count = await ProjectCandidateApi(
        GET,
        '/all',
        {},
        {
          stage: 'KG Interview'
        }
      );
      setInterviewCount(count?.data?.count || 0);
    } catch (err) {
      console.log(err);
    }
  };
  const getAll = async () => {
    try {
      setLoading(true);
      await fetchAllProjects();
      await getAllRecentActivity();
      await getCount();
      await getInterviewCount();
      await getInterviewDetails();
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    getAll();
  }, []);
  return (
    <Box className={style.home_body} backgroundColor={theme.palette.background.default} handleInterviewPopup={hagndleCloseAll}>
      <Stack className={style.home_first_section}>
        <Grid container spacing={2} className={style.top_section}>
          <Grid item md={8} sm={8.5} container spacing={2} className={style.left_top_section}>
            <LeftSection handleInterviewPopup={handleInterviewPopup} name={userData?.name || 'User'} count={count} interviewCount={interviewCount} />
          </Grid>
          <Grid item sm={3.5} md={4} className={style.right_top_section}>
            <RightSection
              activities={recentActivities}
              loading={loading}
              setContactId={id => {
                if (id) {
                  setIsDrawerOpen(true);
                  setContactId(id);
                }
              }}
            />
          </Grid>
        </Grid>
      </Stack>
      <Stack className={style.home_second_section}>
        <TableView projectGridColumn={projectGridColumn} projectGridData={allProjects} loading={loading} />
      </Stack>
      <Popup openInterview={interviewPopup} handleClose={hagndleCloseAll} allInterview={allInterview} />
      {isDrawerOpen && <ViewContactDrawer navigateToAllContacts={false} isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} id={contactId} baseRoute={ROUTES.home} />}
      <Loader show={loading} />
    </Box>
  );
}
export default Home;
