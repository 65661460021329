//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState } from 'react';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { formatDateUtcLocal } from '../../../utils/date';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import { ExpandableSection } from '../../ActivityLog/Components/ExpandableSection';
import DatePickerCommon from '../DatePicker/DatePicker';
import CountrySelection from '../FunctionalComponents/CountrySelection';
import IndustrySelection from '../FunctionalComponents/IndustrySelection';
import JobFunctionSelection from '../FunctionalComponents/JobFunctionSelection';
import StateSelection from '../FunctionalComponents/StateSelection';
import CustomInputField from '../StyledComponents/CustomInputField';

//----------------------------------------------// External Imports // -------------------------------------------------
import { Box, Checkbox, Grid, Link } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import ResumeCompanySelection from './ResumeCompanySelection';

const ProfessionalExperience = props => {
  const { setValue = () => {}, data, setSpecificValue, companyList } = props;

  const [country, setCountry] = useState({});
  const [dateRange, setDateRange] = useState({ start: '', end: '', memberStart: '', memberEnd: '' });
  // const [workingEndDate, setWorkingEndDate] = useState(null);
  const [selectedCheckboxIndex, setSelectedCheckboxIndex] = useState(null);
  const [industries, setIndustries] = useState([]);
  const [jobFunction, setJobFunction] = useState([]);
  const snackbar = useSnackbar();
  const experienceDetails = () => {
    return data['contact_work_experience'];
  };
  const setExperienceDetails = value => {
    setValue('contact_work_experience', value);
  };
  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_INDUSTRY_AND_JOB_FUNCTION,
    TRANS_KEYS.CONTACTS_INDUSTRY,
    TRANS_KEYS.CONTACTS_JOB_FUNCTION,
    TRANS_KEYS.CONTACTS_WORK_EXPERIENCE,
    TRANS_KEYS.EXPAND_ALL,
    TRANS_KEYS.COLLAPSE_ALL,
    TRANS_KEYS.CONTACTS_PROFESSIONAL_EXPERIENCE,
    TRANS_KEYS.CONTACTS_EXPERIENCE,
    TRANS_KEYS.CONTACTS_JOB_TITLE_AND_COMPANY,
    TRANS_KEYS.CONTACTS_JOB_TITLE,
    TRANS_KEYS.CONTACTS_COMPANY,
    TRANS_KEYS.CONTACTS_BASIC_INFO,
    TRANS_KEYS.CONTACTS_COUNTRY,
    TRANS_KEYS.CONTACTS_STATE,
    TRANS_KEYS.CONTACTS_CITY,
    TRANS_KEYS.CONTACTS_WORD_FROM,
    TRANS_KEYS.CONTACTS_WORD_TO,
    TRANS_KEYS.CONTACTS_TIME_PERIOD,
    TRANS_KEYS.CONTACTS_BOARD_MEMBER,
    TRANS_KEYS.CONTACTS_THEY_ARE_A_BOARD_MEMBERS,
    TRANS_KEYS.CONTACTS_THEY_CURRENTLY_WORK_HERE,
    TRANS_KEYS.CONTACTS_FROM_YEAR,
    TRANS_KEYS.CONTACTS_TO_YEAR,
    TRANS_KEYS.CONTACTS_ADD_ANOTHER,
    TRANS_KEYS.CONTACTS_COMMITTEE_DETAILS
  ]);

  const [expanded, setExpanded] = useState('panel1');
  const handleExpand = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleExpandAll = () => {
    // setExpanded(allPanels)
    setExpanded(true);
  };

  const handleCollapseAll = () => {
    setExpanded(false);
  };
  const handleExperienceVisibility = index => {
    snackbar.enqueueSnackbar('Hidden experiences will not be included when saving the contact', { variant: 'error', preventDuplicate: true });
    setExperienceDetails(experienceDetails()?.map((item, i) => (i === index ? { ...item, hide: !item.hide } : item)));
  };

  const handleChangeExperienceData = (index, name, data, recordData) => {
    let newList = [...experienceDetails()];
    newList[index] = { ...newList[index], [name]: data };
    if (name === 'country_id') {
      newList[index] = { ...newList[index], ['country']: { name: recordData?.name } };
    }
    if (name === 'state_id') {
      newList[index] = { ...newList[index], ['state']: { name: recordData?.value } };
    }
    if (name === 'company_id') {
      newList[index] = { ...newList[index], ['company']: { name: recordData?.name } };
    }
    const updatedList = [...newList];
    setExperienceDetails(updatedList);
  };

  const handleWorkingCheckChange = index => {
    if (selectedCheckboxIndex === index) {
      setSelectedCheckboxIndex(null);
    } else {
      setSelectedCheckboxIndex(index);
    }

    setExperienceDetails(experienceDetails().map((item, i) => ({ ...item, is_present: i == index })));
  };

  const handleCheckboxChange = (event, element) => {
    setSpecificValue('contact_work_experience', element.id, { ...element, board_is_present: event.target.checked });
  };

  const handleAddAnotherExperience = () => {
    setExperienceDetails([...experienceDetails(), { title: ' ', company: ' ' }]);
  };

  const handleIndustryChange = value => {
    const industry = value?.map(item => {
      const obj = { ...item, industry_id: item?.id };
      delete obj.id;
      return obj;
    });
    setValue('contact_industries', industry || null, { shouldDirty: true });
    setIndustries(industry);
  };

  const handleJobFunctionChange = value => {
    const jobFunction = value?.map(item => {
      const obj = { job_functions: item, job_function_id: item.id };
      delete obj.id;
      return obj;
    });
    setValue('contact_job_functions', jobFunction || null, { shouldDirty: true });
    setJobFunction(jobFunction);
  };

  return (
    <Box id='education-experiences' className='w-100'>
      <Box className='my-cards flex flex-wrap w-100'>
        <Box className='form-section flex flex-column w-100'>
          <Box className='section-content w-100'>
            <Box className='text-label fs-16 pl-1 pb-1 header-font my-4'>{t(`${TRANS_KEYS.CONTACTS_INDUSTRY_AND_JOB_FUNCTION}:key`)}</Box>
            <Grid container className='w-100' spacing={2}>
              <Grid item xs={6}>
                <IndustrySelection
                  multiple={true}
                  defaultValue={data['contact_industries'] || []}
                  onChange={value => {
                    handleIndustryChange(value);
                  }}
                  className={'w-100'}
                  isCheckBox={true}
                  selectedList={industries}
                  disableCloseOnSelect={true}
                  label={t(`${TRANS_KEYS.CONTACTS_INDUSTRY}:key`)}
                />
              </Grid>

              <Grid item xs={6}>
                <JobFunctionSelection
                  multiple={true}
                  className={'w-100'}
                  defaultValue={data['contact_job_functions'] || []}
                  onChange={value => {
                    handleJobFunctionChange(value);
                  }}
                  label={t(`${TRANS_KEYS.CONTACTS_JOB_FUNCTION}:key`)}
                  isCheckBox={true}
                  disableCloseOnSelect={true}
                  selectedList={jobFunction}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box className='my-cards flex flex-wrap w-100'>
        <Box className='form-section flex flex-column w-100'>
          <Box className='section-content w-100'>
            <Box className='d-flex justify-content-between align-items-center mt-4 mb-2 w-100'>
              <Box className='text-label fs-16 pl-1 header-font'>{t(`${TRANS_KEYS.CONTACTS_WORK_EXPERIENCE}:key`)}</Box>
              <Box className='d-flex'>
                <Link
                  component='button'
                  className='expand-collapse-button mr-2'
                  onClick={() => {
                    handleExpandAll();
                  }}
                >
                  {t(`${TRANS_KEYS.EXPAND_ALL}:key`)}
                </Link>
                <Link
                  component='button'
                  className='expand-collapse-button'
                  onClick={() => {
                    handleCollapseAll();
                  }}
                >
                  {t(`${TRANS_KEYS.COLLAPSE_ALL}:key`)}
                </Link>
              </Box>
            </Box>

            {experienceDetails()?.map((element, index) => {
              return (
                <ExpandableSection
                  key={index}
                  title={`${t(`${TRANS_KEYS.CONTACTS_EXPERIENCE}:key`)} ${index + 1}`}
                  showCheckbox={false}
                  defaultOpen={expanded === true ? expanded : expanded === `panel${index + 1}`}
                  panelName={`panel${index + 1}`}
                  ariaControlls={`panel${index + 1}d-content`}
                  accID={`panel${index + 1}d-header`}
                  handleExpanded={handleExpand(`panel${index + 1}`)}
                  handleExperienceVisibility={() => handleExperienceVisibility(index)}
                  isVisible={!element.hide}
                  className='mb-3 max-w-100'
                  titleClassName='text-label fs-16 header-font'
                  showHideIcon={true}
                >
                  <Box className='my-cards flex flex-wrap w-100'>
                    <Box className='form-section flex flex-column w-100'>
                      <Box className='text-label fs-16 pb-1 header-font'>{t(`${TRANS_KEYS.CONTACTS_JOB_TITLE_AND_COMPANY}:key`)}</Box>
                      <Grid container className='w-100' spacing={2} rowSpacing={2}>
                        <Grid item xs={6}>
                          <CustomInputField
                            className='w-100'
                            required={true}
                            value={element?.title || ''}
                            onChange={e => {
                              handleChangeExperienceData(index, 'title', e.target.value);
                            }}
                            label={t(`${TRANS_KEYS.CONTACTS_JOB_TITLE}:key`)}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <ResumeCompanySelection
                            viewAddress={true}
                            label={t(`${TRANS_KEYS.CONTACTS_COMPANY_NAME}:key`)}
                            defaultValue={element.company || ''}
                            onChange={(e, data) => {
                              handleChangeExperienceData(index, 'company_id', data?.id, data);
                            }}
                            companyList={companyList}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>

                  <Box className='text-label fs-16 pb-1 header-font mt-2'>{t(`${TRANS_KEYS.CONTACTS_BASIC_INFO}:key`)}</Box>
                  <Grid container className='w-100' spacing={2} rowSpacing={2}>
                    <Grid item xs={6}>
                      <CountrySelection
                        className='w-100'
                        value={element?.country && element?.country.name ? element?.country.name : ''}
                        onChange={(e, data) => {
                          setCountry(data?.name);
                          handleChangeExperienceData(index, 'country_id', data?.id, data);
                        }}
                        label={t(`${TRANS_KEYS.CONTACTS_COUNTRY}:key`)}
                      ></CountrySelection>
                    </Grid>

                    <Grid item xs={6}>
                      <StateSelection
                        className='w-100'
                        value={element?.state && element?.state.name ? element?.state.name : ''}
                        onChange={(e, data) => {
                          handleChangeExperienceData(index, 'state_id', data?.id, data);
                        }}
                        label={t(`${TRANS_KEYS.CONTACTS_STATE}:key`)}
                        country={country}
                      ></StateSelection>
                    </Grid>
                    <Grid item xs={6}>
                      <CustomInputField
                        className='w-100'
                        value={element?.city || ''}
                        onChange={e => {
                          handleChangeExperienceData(index, 'city', e.target.value);
                        }}
                        label={t(`${TRANS_KEYS.CONTACTS_CITY}:key`)}
                      />
                    </Grid>
                    <Grid item xs={6}></Grid>

                    <Grid item xs={6}>
                      <DatePickerCommon
                        width='100%'
                        className={'w-100'}
                        value={formatDateUtcLocal(element?.start_date, 'MM-DD-YYYY') || null}
                        format='MM/DD/YYYY'
                        placeholder='mm/dd/yyyy'
                        onChange={e => {
                          const selectedDate = e?.$d;
                          setDateRange({ ...dateRange, start: e });
                          handleChangeExperienceData(index, 'start_date', selectedDate ? formatDateUtcLocal(selectedDate, 'YYYY-MM-DD') : null);
                        }}
                        isDefaultDatePickerStyle={false}
                        maxDate={dateRange.end}
                        label={t(`${TRANS_KEYS.CONTACTS_WORD_FROM}:key`)}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <DatePickerCommon
                        width='100%'
                        className={'w-100'}
                        value={formatDateUtcLocal(element?.end_date, 'MM-DD-YYYY') || null}
                        format='MM/DD/YYYY'
                        placeholder='mm/dd/yyyy'
                        onChange={e => {
                          const selectedDate = e?.$d;
                          // setWorkingEndDate(selectedDate);
                          setDateRange({ ...dateRange, end: e });
                          handleChangeExperienceData(index, 'end_date', selectedDate ? formatDateUtcLocal(selectedDate, 'YYYY-MM-DD') : null);
                        }}
                        isDefaultDatePickerStyle={false}
                        disabled={element?.is_present}
                        label={t(`${TRANS_KEYS.CONTACTS_WORD_TO}:key`)}
                        minDate={dateRange.start}
                      />
                    </Grid>
                  </Grid>

                  <Box className='flex flex-row flex-wrap d-flex pt-2 pb-2'>
                    <Box className=' row d-flex mt-3'>
                      <Box className='col-md-6'>
                        <Box sx={{ width: '18vw' }}>
                          <Box className='text-label fs-16 pl-1 pb-1 header-font' sx={{ width: '18vw' }}>
                            {t(`${TRANS_KEYS.CONTACTS_TIME_PERIOD}:key`)}
                          </Box>
                          <Box className='input-field d-flex justify-content-start align-items-center mt-2' sx={{ width: '18vw' }}>
                            <Checkbox onChange={() => handleWorkingCheckChange(index)} checked={element?.is_present} className='checkboxStyle'></Checkbox>
                            <Box className='ml-2 mt-1 text-color fs-12'>{t(`${TRANS_KEYS.CONTACTS_THEY_CURRENTLY_WORK_HERE}:key`)}</Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box className='col-md-6'>
                        <Box sx={{ width: '18vw' }}>
                          <Box className='text-label fs-16 pl-1 pb-1 header-font' sx={{ width: '18vw' }}>
                            {t(`${TRANS_KEYS.CONTACTS_BOARD_MEMBER}:key`)}
                          </Box>
                          <Box className='input-field d-flex justify-content-start align-items-center mt-2' sx={{ width: '18vw' }}>
                            <Box>
                              <Checkbox className='checkboxStyle' checked={element?.board_is_present} onChange={e => handleCheckboxChange(e, element)} />
                            </Box>

                            <Box className='ml-2 mt-1 text-color fs-12'>{t(`${TRANS_KEYS.CONTACTS_THEY_ARE_A_BOARD_MEMBERS}:key`)}</Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  {element?.board_is_present && (
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <DatePickerCommon
                          value={formatDateUtcLocal(element?.board_start_date, 'MM-DD-YYYY') || null}
                          format='MM/DD/YYYY'
                          placeholder='mm/dd/yyyy'
                          onChange={e => {
                            const selectedDate = e?.$d;
                            setDateRange({ ...dateRange, memberStart: e });
                            handleChangeExperienceData(index, 'board_start_date', selectedDate ? formatDateUtcLocal(selectedDate, 'YYYY-MM-DD') : null);
                          }}
                          label={t(`${TRANS_KEYS.CONTACTS_FROM_YEAR}:key`)}
                          maxDate={dateRange.memberEnd}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <DatePickerCommon
                          value={formatDateUtcLocal(element?.board_end_date, 'MM-DD-YYYY') || null}
                          format='MM/DD/YYYY'
                          placeholder='mm/dd/yyyy'
                          onChange={e => {
                            const selectedDate = e?.$d;
                            setDateRange({ ...dateRange, memberEnd: e });
                            handleChangeExperienceData(index, 'board_end_date', selectedDate ? formatDateUtcLocal(selectedDate, 'YYYY-MM-DD') : null);
                          }}
                          label={t(`${TRANS_KEYS.CONTACTS_TO_YEAR}:key`)}
                          minDate={dateRange.memberStart}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CustomInputField
                          value={element?.board_committee}
                          onChange={e => {
                            handleChangeExperienceData(index, 'board_committee', e.target.value);
                          }}
                          label={t(`${TRANS_KEYS.CONTACTS_COMMITTEE_DETAILS}:key`)}
                        />
                      </Grid>
                    </Grid>
                  )}
                </ExpandableSection>
              );
            })}

            <Box className='d-flex'>
              <Link
                component='button'
                onClick={() => {
                  handleAddAnotherExperience();
                }}
              >
                {`+ ${t(`${TRANS_KEYS.CONTACTS_ADD_ANOTHER}:key`)}`}
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ProfessionalExperience.propTypes = {
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  data: PropTypes.object,
  setSpecificValue: PropTypes.func,
  companyList: PropTypes.array
};

export default ProfessionalExperience;
