import React from 'react';

import StyleIcon from '@mui/icons-material/Style';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { Box, Skeleton, Tooltip, Typography } from '@mui/material';

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
  ['& .MuiTooltip-tooltip']: {
    backgroundColor: '#fffffF',
    color: '#007e6b',
    fontSize: '1rem',
    border: '0.2px solid black'
  }
}));

export const Tags = ({ tags, loading = false }) => {
  return loading ? (
    <Box display={'flex'} alignItems={'center'}>
      <StyleIcon sx={{ width: '16px', height: '16px', marginRight: 0.5 }} color={'#1b4965'} /> Tags:
      <Typography sx={{ textDecoration: tags?.length > 0 ? 'underline' : null, marginLeft: 0.5 }} color={'#007e6b'} fontSize={10} fontWeight={500}>
        <Skeleton variant='text' sx={{ fontSize: 10 }} width={'100px'} />
      </Typography>
    </Box>
  ) : (
    <Box display={'flex'} alignItems={'center'}>
      <StyleIcon sx={{ width: '16px', height: '16px', marginRight: 0.5 }} color={'#1b4965'} /> Tags:
      <Typography sx={{ textDecoration: tags?.length > 0 ? 'underline' : null, marginLeft: 0.5 }} color={'#007e6b'} fontSize={10} fontWeight={500}>
        {tags?.length > 0 ? tags?.[0] : '--'}
      </Typography>
      <CustomTooltip
        placement='top'
        title={
          <Box display={'flex'} gap={1}>
            {tags?.slice(1).map(tag => (
              <Typography key={tag?.id} sx={{ textDecoration: 'underline', marginLeft: 0.5 }} fontSize={10} fontWeight={500}>
                {tag || '--'}
              </Typography>
            ))}
          </Box>
        }
        sx={{ margin: 0, padding: 0, minWidth: 'fit-content' }}
      >
        <Typography sx={{ cursor: 'pointer', marginLeft: 0.5 }} color={'#959fb7'} fontSize={10} fontWeight={600}>
          {tags?.length > 1 ? `+${tags?.length - 1}${tags?.length - 1 > 1 ? 'Tags' : 'Tag'}` : ''}
        </Typography>
      </CustomTooltip>
    </Box>
  );
};

Tags.propTypes = {
  tags: PropTypes.array,
  loading: PropTypes.bool
};
