import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import ProjectTypeSelection from '../../../components/common/FunctionalComponents/ProjectTypeSelection/index.js';
import { ProjectCandidateDataApi } from '../../../services/ApiService';
import { ERROR, POST, SUCCESS } from '../../../services/constantService';
import { successMessage } from '../../../services/MessageService';
import { customFormValidator, requireValidMessage } from '../../../utils/common';
import '../../../utils/common.scss';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import CustomButton from '../CustomButton';
import CustomPopup from '../CustomPopup';
import StageSelection from '../FunctionalComponents/StageSelection';
import Loader from '../Loader';
import './index.scss';

const AddContactToProject = props => {
  const { data, isPopupOpen, handleClose = () => {} } = props;
  const { register, setValue, getValues, watch } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_WORD_ADD,
    TRANS_KEYS.CONTACTS_SEARCH,
    TRANS_KEYS.CONTACTS_ADD_CONTACT_TO_PROJECT,
    TRANS_KEYS.CONTACTS_CHOOSE_PROJECT,
    TRANS_KEYS.CONTACTS_CHOOSE_STAGE,
    TRANS_KEYS.CONTACTS_CHOOSE_PROJECT_NUMBER
  ]);
  useEffect(() => {
    register('stage');
    register('project');
  }, [register]);

  const CreateSchema = yup.object().shape({
    project: yup.object().required(),
    stage: yup.string().required()
  });
  const cleanupPayload = formValue => {
    let payload = { ...formValue };
    payload['contact_id'] = data?.id;

    if (formValue.project) {
      payload['project_id'] = formValue?.project?.id;
    }
    return payload;
  };
  const handleCopyContactToProject = async () => {
    try {
      const formValue = getValues();
      let isValid = await CreateSchema.isValid(formValue);
      if (!isValid) {
        let requiredField = [
          { fieldName: 'project', label: 'Project', type: {} },
          { fieldName: 'stage', label: 'Stage', type: String }
        ];
        let dirtyField = customFormValidator(formValue, requiredField);
        if (dirtyField) {
          const message = requireValidMessage(dirtyField);
          enqueueSnackbar(message, { variant: ERROR });
          return;
        }
      }
      setIsLoading(true);
      const payload = cleanupPayload(formValue);
      const { status, data } = await ProjectCandidateDataApi(POST, null, payload);
      if (status && status === 200) {
        const message = successMessage('Contacts added to project', `(${payload?.project?.name})`);
        enqueueSnackbar(message, { variant: SUCCESS });
        setIsLoading(false);
        handleClose();
      } else {
        enqueueSnackbar(data.message || 'Failed to add to project', { variant: ERROR });
        setIsLoading(false);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <CustomPopup
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={isPopupOpen}
      title={t(`${TRANS_KEYS.CONTACTS_ADD_CONTACT_TO_PROJECT}:key`)}
      // handleSubmit={handleSubmit}
    >
      <Loader show={isLoading} />
      <Box className='d-flex flex flex-column' gap={4} id='add-contact-project'>
        <Box className='head-content flex flex-column py-4'>
          <Box>
            {/* <Box className="label-text fs-14 pb-2">
              {t(`${TRANS_KEYS.CONTACTS_CHOOSE_PROJECT}:key`)}
            </Box> */}
            <ProjectTypeSelection
              className='w-100'
              defaultValue={watch('project') || ''}
              label={t(`${TRANS_KEYS.CONTACTS_CHOOSE_PROJECT}:key`)}
              onChange={(e, data) => {
                setValue('project', data ? data : null);
              }}
              required={true}
            />
          </Box>
          <Box>
            <Box className='input-data d-flex flex align-center'>
              <StageSelection
                defaultValue={watch('stage') || ''}
                onChange={(e, data) => {
                  setValue('stage', data?.stage_name ? data.stage_name : null);
                }}
                label={t(`${TRANS_KEYS.CONTACTS_CHOOSE_STAGE}:key`)}
                required={true}
              />
            </Box>
          </Box>
        </Box>
        <Box className='d-flex justify-content-end'>
          <CustomButton type={'primary'} size={'small'} buttonText={t(`${TRANS_KEYS.CONTACTS_WORD_ADD}:key`)} customWidth={96} variant='contained' onClick={handleCopyContactToProject} />
        </Box>
      </Box>
    </CustomPopup>
  );
};

AddContactToProject.propTypes = {
  isPopupOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.object
};
export default AddContactToProject;
