import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import CustomPopup from '../../../../../components/common/CustomPopup';
import Footer from '../../../../../components/common/Footer';

const DeleteTransitionPopup = props => {
  const { isPopupOpen, deleteActionId, handlePopupClose, onDelete, isLoading, onToggleConfirm, toggleActionData } = props;

  if (toggleActionData) {
    return (
      <CustomPopup dropdown={true} title={`${toggleActionData.action_disabled ? 'Disable' : 'Enable'} this Action`} open={isPopupOpen} onClose={handlePopupClose}>
        <Box className='p-11 px-11 w-100 d-flex flex-column edit-section-gap'>
          <Typography>Are you sure you want to {toggleActionData.action_disabled ? 'Disable' : 'Enable'} this Action?</Typography>
        </Box>
        <Box className='pr-4 pb-4'>
          <Footer onClose={handlePopupClose} onSubmit={() => onToggleConfirm(toggleActionData)} submitText='Confirm' closeText='Cancel' submitLoading={isLoading} disabled={isLoading} />
        </Box>
      </CustomPopup>
    );
  }
  return (
    <CustomPopup dropdown={true} title={`Delete ${deleteActionId ? 'Action' : 'Transition'}`} open={isPopupOpen} onClose={handlePopupClose}>
      <Box className='p-11 px-11 w-100 d-flex flex-column edit-section-gap'>
        <Typography>Are you sure you want to delete this {deleteActionId ? 'Action' : 'Transition'}?</Typography>
      </Box>
      <Box className='pr-4 pb-4'>
        <Footer onClose={handlePopupClose} onSubmit={() => onDelete(deleteActionId)} submitText='Delete' closeText='Cancel' submitLoading={isLoading} disabled={isLoading} />
      </Box>
    </CustomPopup>
  );
};

DeleteTransitionPopup.propTypes = {
  onDelete: PropTypes.func,
  isPopupOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  handlePopupClose: PropTypes.func,
  onToggleConfirm: PropTypes.func,
  deleteActionId: PropTypes.string,
  toggleActionData: PropTypes.object
};

export default DeleteTransitionPopup;
