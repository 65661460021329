import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import AddCompany from '../../../../../components/AddCompany';
import CustomButton from '../../../../../components/common/CustomButton';
import Loader from '../../../../../components/common/Loader';
import { SFPADataApi, TargetCompanyDataApi, ignCompanyDataApi, picklistDropDownApis } from '../../../../../services/ApiService';
import { DELETE, DROP_DOWN_PICKLIST, ERROR, GET, POST, SUCCESS } from '../../../../../services/constantService';
import { customFormValidator, splitLastIfMultiple } from '../../../../../utils/common';
import useApi from '../../../../../utils/Hooks/useApiHook';
import { useCustomMessageHook } from '../../../../../utils/Hooks/useCustomMessageHook';
import { useLanguageHooks } from '../../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../../utils/languageNamespaces';
import { createRequiredField, createSchema } from '../../../../Companies/Utils';
import { ProjectDataContext } from '../../Context';
import { AccordionHeader } from '../Scope';

function TargetCompanies() {
  const { t } = useLanguageHooks([TRANS_KEYS.ADD_TARGET_COMPANIES]);
  const { translateMessage } = useCustomMessageHook();

  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [addCompanyPopup, setAddCompanyPopup] = useState(false);
  const { universalState, projectData, handleUniversalState } = useContext(ProjectDataContext);
  const [recommendedTargetCompanies, setRecommendedTargetCompanies] = useState([]);
  const { getValues, setValue, register, unregister, watch, reset } = useForm({});
  //API Integration
  const { data: companyOptionResponse } = useApi({
    queryFn: () => {
      return picklistDropDownApis(GET, DROP_DOWN_PICKLIST.COMPANIES_PICKLIST);
    }
  });
  const handleUniversalStateLocally = updatedVal => {
    const event = {
      target: {
        name: 'targetCompanies',
        value: updatedVal
      }
    };
    handleUniversalState(event);
  };

  const deleteTargetCompany = async option => {
    try {
      setLoader(true);
      if (!option.id.includes('new') && !option.id.includes('bulk')) {
        const res = await TargetCompanyDataApi(DELETE, '', { id: option.id }, '');
        console.log('target-company-delete', res);
      }

      const updatedTargetCompanies = recommendedTargetCompanies.filter(curr => curr.id != option.id);
      setRecommendedTargetCompanies(updatedTargetCompanies);
      handleUniversalStateLocally(updatedTargetCompanies);
      setLoader(false);
    } catch (err) {
      enqueueSnackbar(err, 'error');
      setLoader(false);
    }
  };

  const addTargetCompany = companyData => {
    console.log({ companyData });
    const newTargetCompanies = [...recommendedTargetCompanies, { id: 'new-' + companyData.id, target_company: companyData }];
    setRecommendedTargetCompanies(newTargetCompanies);
    handleUniversalStateLocally(newTargetCompanies);
  };

  const onClickShowMore = async () => {
    try {
      setLoader(true);
      const companyName = projectData?.ign_companies?.name;
      const ign_industry = {
        name: projectData?.ign_industries?.name
      };

      const companiesToAvoid = universalState['targetCompaniesToAvoid'] ?? [];
      console.log({ companiesToAvoid });
      const selectedCompaniesToAvoid = companiesToAvoid?.map(company => {
        return {
          isSelected: true,
          isNew: false,
          name: company?.name
        };
      });
      const fetchedCompany = recommendedTargetCompanies?.map(company => {
        return {
          isSelected: true,
          isNew: false,
          name: company?.target_company?.name
        };
      });
      const selectedTargetIndustries = universalState['targetIndustries'] ?? [];
      const targetIndustries = selectedTargetIndustries.map(industry => {
        return {
          isSelected: true,
          isNew: false,
          name: industry?.industry?.name
        };
      });
      const payload = {
        template_name: 'SFPA-Company-Search',
        mode: 'more',
        variables: {
          searchCompany: companyName,
          searchIndustry: [ign_industry],
          fetchedCompanyList: [...fetchedCompany, ...selectedCompaniesToAvoid],
          selectedTargetIndustryList: targetIndustries,
          key: 'fetchedCompanyList'
        }
      };
      console.log('target-company-more-payload', payload);

      const res = await SFPADataApi(POST, projectData?.id, payload);

      if (res && res?.status == 200) {
        console.log('target-company-res', res);
      } else {
        enqueueSnackbar(res?.data?.message, 'error');
      }

      if (res && res?.data && res?.data?.target_companies) {
        const allData = res?.data?.target_companies ?? [];
        const newData = allData?.filter(data => data?.isNew == true);

        const recommendedCount = recommendedTargetCompanies.length + 1;
        const targetCompanies = newData.map((data, index) => {
          return {
            id: 'bulk-' + recommendedCount + index,
            target_company: { name: data?.name, id: 'bulk-' + index }
          };
        });
        const newTargetIndustries = [...targetCompanies, ...recommendedTargetCompanies];
        setRecommendedTargetCompanies(newTargetIndustries);
        handleUniversalStateLocally(newTargetIndustries);

        console.log('target-industry-new-data', newData);
      } else {
        enqueueSnackbar(res?.data?.message, 'error');
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      enqueueSnackbar(err, 'error');
    }
  };

  useEffect(() => {
    if (recommendedTargetCompanies.length == 0 && !isDataLoaded) {
      setRecommendedTargetCompanies(projectData?.targetCompanies || []);
      if (projectData?.targetCompanies) {
        setIsDataLoaded(true);
      }
    }
  }, [projectData]);

  const getPayload = data => {
    const addCompanyPayload = {
      ...data,
      company_tags: data?.company_tags ? data?.company_tags.map(tag => ({ tag_id: tag.id })) : [],
      company_industries: data?.company_industries
        ? data?.company_industries.map(industry => ({
            industry_id: industry.id
          }))
        : [],
      capital_structure: data?.capital_structure?.field_value,
      currency_unit: data?.currency_unit?.field_value
    };
    return addCompanyPayload;
  };

  const isValidated = async requestBody => {
    let isValid = await createSchema.isValid(requestBody);
    if (!isValid) {
      let dirtyField = customFormValidator(requestBody, createRequiredField);
      if (dirtyField) {
        const message = translateMessage('Required', ...splitLastIfMultiple(dirtyField));
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      }
    } else {
      return true;
    }
  };

  const saveData = async () => {
    let formValues = { ...getValues() };
    console.log({ formValues });
    const requestBody = getPayload(formValues);
    const IsValidRequestBody = await isValidated(requestBody);
    if (IsValidRequestBody) {
      setLoader(true);
      const { status, data } = await ignCompanyDataApi(POST, requestBody);
      console.log(data);

      if (status === 201) {
        const message = translateMessage('Successfully', false, 'Company', 'created');
        enqueueSnackbar(message, { variant: SUCCESS });
        // setCompanyId(data.id);
        setAddCompanyPopup(false);
        reset();

        setLoader(false);
        addTargetCompany({ ...formValues, ...data });
        // return data.id;
      } else if (status === 409) {
        const message = translateMessage('AlreadyExist', false, 'Company');
        enqueueSnackbar(message, {
          variant: ERROR
        });
      } else {
        const message = translateMessage('UnableMessage', false, 'Create', 'Company');
        enqueueSnackbar(message, { variant: ERROR });
      }
      setLoader(false);
    }
  };
  return (
    <Box>
      <Loader show={loader} />
      <AccordionHeader
        onSearch={addTargetCompany}
        searchOptions={companyOptionResponse?.data || []}
        searchLabel={t(`${TRANS_KEYS.ADD_TARGET_COMPANIES}:key`)}
        isExactSearch={true}
        onChangeExactSearch={() => {}}
        onClickGetMore={() => onClickShowMore()}
        recommendedOptions={recommendedTargetCompanies}
        onClickRecommendOption={deleteTargetCompany}
        noOptionsText={<CustomButton buttonText={'+ Add Company'} type='primary w-100' onClick={() => setAddCompanyPopup(true)} />}
        freeSolo={false}
      />

      <AddCompany
        isPopupOpen={addCompanyPopup}
        handleClose={() => setAddCompanyPopup(false)}
        handleSubmit={saveData}
        setIsLoading={loader}
        setValue={setValue}
        register={register}
        reset={reset}
        setIsPopupOpen={setAddCompanyPopup}
        unregister={unregister}
        watch={watch}
      ></AddCompany>
    </Box>
  );
}
export default TargetCompanies;
