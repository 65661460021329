import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { appSecurityApi, ignUsersApi } from '../../../services/ApiService';
import { GET, POST } from '../../../services/constantService';
import useApi from '../../../utils/Hooks/useApiHook';
import CustomButton from '../../common/CustomButton';
import CustomMuiDropdown from '../../common/CustomMuiDropdown';
import CustomPopup from '../../common/CustomPopup';

const SwitchContext = ({ open, onClose }) => {
  const [options, setOptions] = React.useState([]);
  const [selectedOption, setSelectedOption] = React.useState({});
  const { data } = useApi({
    queryFn: () => {
      return ignUsersApi(GET, 'getCognito');
    }
  });

  useEffect(() => {
    if (data?.data && data.status !== 404) {
      setOptions(data.data);
    }
  }, [data]);

  const getAppSecurity = async () => {
    if (!selectedOption) {
      return;
    }

    const data = await appSecurityApi(POST, 'switch-user-context', {
      userGroup: selectedOption.roleName,
      roleName: selectedOption.name
    });

    if (data.status === 401 && data.data.url) {
      window.location.href = data.data.url;
    } else if (data.status === 200) {
      window.location.href = window.location.origin;
    }
  };

  return (
    <CustomPopup open={open} onClose={onClose} title='Switch Context' width='30vw'>
      <div className='p-0'>
        <p className='mb-3 font-weight-bold fs-12'>Current Context: Kingsley Gate Recruiter</p>
        <CustomMuiDropdown
          label='Switch Context'
          value={selectedOption}
          size='small'
          options={options}
          getOptionLabel={option => option.name}
          className='fs-12'
          onChange={(e, data) => {
            setSelectedOption(data);
          }}
          autoCompleteProps={{
            isOptionEqualToValue: (option, value) => option.name === value.name
          }}
        />
        <div className='d-flex justify-content-end mt-3 '>
          <CustomButton
            buttonText='Switch'
            onClick={async () => {
              await getAppSecurity(selectedOption);
            }}
          />
        </div>
      </div>
    </CustomPopup>
  );
};

export default SwitchContext;

SwitchContext.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};
