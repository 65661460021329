import { Box } from '@mui/material';
import React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PsychologyIcon from '@mui/icons-material/Psychology';
import NoteIcon from '@mui/icons-material/Note';
// import CustomCard from '../../AddContact/Components/CustomCard';
import ProfessionalAndPersonal from '../../AddContact/Components/Personality/ProfessionalAndPersonal';
import BioAndRecommendation from '../../AddContact/Components/Personality/BioAndRecommendation';
import StrengthAndConcern from '../../AddContact/Components/Personality/StrengthAndConcern';
import ScreeningNotes from '../../AddContact/Components/Personality/ScreeningNotes';
import '../../../../src/utils/common.scss';
import PropTypes from 'prop-types';
const ViewPersonality = props => {
  const {
    currentValues,
    updateField = () => {},
    isContactView = false,
    register = () => {},
    unregister = () => {},
    setValue = () => {},
    getContactDetails = () => {},
    popupClose,
    isHeaderNav
  } = props;

  return (
    <div id='view-contact' className={'custom-scrollbar pl-3 pt-2 pb-2'}>
      <Box className={`${isHeaderNav === 'closed' ? 'details-container-close' : 'details-container'} scroll-content`}>
        <Box className='section-details m-2'>
          <Box className='d-flex flex-column row-gap'>
            <Box className='d-flex flex-row column-gap'>
              <Box className='d-flex flex-column'>
                {/* <Box className="title-color fs-12">Published Bio</Box> */}
                <Box id='bio_card'>
                  <Box className='custom-card d-flex content-space viewcontact-icon-style'>
                    <AccountCircleIcon color='disabled'></AccountCircleIcon>
                    <BioAndRecommendation
                      bioCardHeight={155}
                      bioCardWidth={230}
                      bgPaperWidth={200}
                      currentValues={currentValues.contact_notes_all}
                      isContactView={isContactView}
                      handleSave={updateField}
                      setValue={setValue}
                      register={register}
                      unregister={unregister}
                      getContactDetails={getContactDetails}
                      contact_id={currentValues?.id}
                    />
                    {/* <CustomCard data={item} /> */}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className='section-details m-2'>
          <Box className='d-flex flex-column row-gap'>
            <Box className='d-flex flex-row column-gap'>
              <Box className='d-flex flex-column'>
                {/* <Box variant='body1'>Published Bio</Box> */}
                <Box id='bio_card'>
                  <Box className='custom-card d-flex content-space viewcontact-icon-style'>
                    <NoteIcon color='disabled'></NoteIcon>
                    <ProfessionalAndPersonal
                      professionalCardHeight={250}
                      professionalCardWidth={230}
                      currentValues={currentValues.contact_notes_all}
                      isContactView={isContactView}
                      handleSave={updateField}
                      setValue={setValue}
                      register={register}
                      unregister={unregister}
                      getContactDetails={getContactDetails}
                      contact_id={currentValues?.id}
                      popupClose={popupClose}
                    />
                    {/* <CustomCard data={item} /> */}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className='section-details m-2'>
          <Box className='d-flex flex-column row-gap'>
            <Box className='d-flex flex-row column-gap'>
              <Box className='d-flex flex-column'>
                {/* <Box variant='body1'>Published Bio</Box> */}
                <Box id='bio_card'>
                  <Box className='custom-card d-flex content-space viewcontact-icon-style'>
                    <PsychologyIcon color='disabled'></PsychologyIcon>
                    <StrengthAndConcern
                      strengthCardHeight={250}
                      strengthCardWidth={230}
                      currentValues={currentValues.contact_notes_all}
                      isContactView={isContactView}
                      handleSave={updateField}
                      setValue={setValue}
                      register={register}
                      unregister={unregister}
                      getContactDetails={getContactDetails}
                      contact_id={currentValues?.id}
                      popupClose={popupClose}
                    />
                    {/* <CustomCard data={item} /> */}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className='section-details m-2'>
          <Box className='d-flex flex-column row-gap'>
            <Box className='d-flex flex-row column-gap'>
              <Box className='d-flex flex-column'>
                {/* <Box variant='body1'>Published Bio</Box> */}
                <Box id='bio_card'>
                  <Box className='custom-card d-flex content-space viewcontact-icon-style'>
                    <NoteIcon color='disabled'></NoteIcon>
                    <ScreeningNotes
                      screeningCardHeight={250}
                      screeningCardWidth={230}
                      currentValues={currentValues.contact_notes_all}
                      isContactView={isContactView}
                      handleSave={updateField}
                      setValue={setValue}
                      register={register}
                      unregister={unregister}
                      getContactDetails={getContactDetails}
                      contact_id={currentValues?.id}
                      popupClose={popupClose}
                    />
                    {/* <CustomCard data={item} /> */}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

ViewPersonality.propTypes = {
  register: PropTypes.func,
  unregister: PropTypes.func,
  setValue: PropTypes.func,
  updateField: PropTypes.func,
  currentValues: PropTypes.object,
  reset: PropTypes.func,
  isHeaderNav: PropTypes.string,
  isContactView: PropTypes.bool,
  getContactDetails: PropTypes.func,
  popupClose: PropTypes.bool
};

export default ViewPersonality;
