import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import WorkIcon from '@mui/icons-material/Work';
import { Box, Grid, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import React, { useContext, useState } from 'react';
import CustomButton from '../../../components/common/CustomButton';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../services/constantService';
import { calculateNumberOfDays } from '../../../utils/date';
import CompanyImageAvatar from '../CompanyImageAvatar';

import { ButtonDropDown } from '../../../components/common/ButtonDropdown';
import AssignTag from './AssignTag';
import ViewReportDrawer from './CandidatePipeline/ViewReportDrawer';
import { ProjectDataContext } from './Context';
import './index.scss';
import LinkExistingProject from './LinkExistingProject';

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
  ['& .MuiTooltip-tooltip']: {
    backgroundColor: '#fffffF',
    color: '#007e6b',
    fontSize: '1rem',
    border: '0.2px solid black'
  }
}));

function HeaderOpened(props) {
  const { t } = useLanguageHooks([
    TRANS_KEYS.UNPUBLISHED_BUTTON,
    TRANS_KEYS.PUBLISHED_BUTTON,
    TRANS_KEYS.CONTACTS_ADDED_BY,
    TRANS_KEYS.ADD_PROJECT_SELECT_HIRING_MANAGER,
    TRANS_KEYS.SEARCH_ROAD_MAP,
    TRANS_KEYS.OPEN_BUTTON,
    TRANS_KEYS.NO_OF_DAYS,
    TRANS_KEYS.VIEW_REPORTS,
    TRANS_KEYS.ADD_CANDIDATE,
    TRANS_KEYS.CONTACTS_INDUSTRY,
    TRANS_KEYS.CANDIDATES_IN_PIPELINE,
    TRANS_KEYS.BD_CONSULTANT,
    TRANS_KEYS.BD_PROBABILITY,
    TRANS_KEYS.BD_ESTIMATED_REVENUE,
    TRANS_KEYS.BD_ADD_PROJECT,
    TRANS_KEYS.BD_CREATE_NEW_PROJECT,
    TRANS_KEYS.BD_LINK_EXISTING_PROJECT,
    TRANS_KEYS.BILLING_STATUS,
    TRANS_KEYS.PROJECTS_SELECT_TAG
  ]);
  const { fetchProjectData, handleExpand, handleAddCandidate, isPublished, handlePublish, stages, handleStageChange = () => {}, stage } = props;
  const { projectData, bgData, isBD } = useContext(ProjectDataContext);
  const [viewReport, setViewReport] = useState(false);

  const [showExistingProject, setShowExistingProject] = useState(false);
  const [isTagPopupOpen, setIsTagPopupOpen] = useState(false);

  const handleAddProject = key => {
    if (key === t(`${TRANS_KEYS.BD_LINK_EXISTING_PROJECT}:key`)) {
      handleLinkExistingProject();
    } else if (key === t(`${TRANS_KEYS.BD_CREATE_NEW_PROJECT}:key`)) {
      handleCreateNewProject();
    }
  };

  const handleLinkExistingProject = () => {
    setShowExistingProject(true);
  };

  const navigate = useNavigate();

  const currencyCode = bgData?.bd_currency_values?.code || 'USD';
  const estimatedAmt = bgData?.estimated_percentage_bases?.[0]?.calculated_estimated_amt || '0';

  const handleCreateNewProject = () => {
    navigate({ pathname: ROUTES.addProject }, { state: projectData });
  };

  const leadConsultant = bgData?.partners?.find(partner => partner?.is_lead === true);
  const leadConsultantContactId = leadConsultant?.user?.user_contacts?.[0]?.id;

  const handleAssignTag = () => {
    setIsTagPopupOpen(true);
  };
  const maxVisibleTags = 1;

  return (
    <Grid
      container
      className='mt-6 p-2'
      sx={{
        backgroundColor: 'titleBarBackground.main',
        width: '100%',
        borderRadius: '12px',
        position: 'relative'
      }}
    >
      {!isBD && <ViewReportDrawer fromHeaderBar projectName={projectData?.job_title} isDrawerOpen={viewReport} setIsDrawerOpen={setViewReport} projectId={projectData?.id} />}

      <Grid container Item xs={1} direction='column' justifyContent='space-between' alignItems='center' sx={{ maxWidth: '90%' }}>
        <CompanyImageAvatar
          id={projectData?.ign_companies?.id}
          sx={{
            width: '80px',
            height: '80px',
            margin: 'auto',
            borderRadius: '50%'
          }}
        />

        <Select components={'div'} className='stage-drop-down' size='small' sx={{ maxWidth: '90%' }} value={stage ?? ''} onChange={e => handleStageChange(e.target.value)}>
          {stages.map(stage => (
            <MenuItem key={stage.id} value={stage.name}>
              {stage.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={9} className='p-2'>
        <Stack direction={'row'} spacing={2} alignItems={'flex-end'}>
          <Stack alignItems={'center'} direction={'row'}>
            <Typography variant='h5' className='ml-1' sx={{ fontWeight: 600 }}>
              {projectData?.job_title}
            </Typography>
            <div className='ml-2'>
              {projectData?.record_type} : {projectData?.job_number}
            </div>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1}>
            {/* <EditIcon className="p-1" />
            <DeleteIcon className="p-1" /> */}
            {/* <Typography variant="caption">
              <CheckCircleOutlineIcon className="p-1" />
              Added to client Suite
            </Typography> */}
          </Stack>
        </Stack>
        <Grid container paddingTop={1}>
          <Grid container Item xs={4} direction='column' justifyContent='flex-start' alignItems='flex-start'>
            <Link
              style={{
                color: 'black'
              }}
              target='_blank'
              to={`${ROUTES.allCompanies}/${projectData?.ign_companies?.id}`}
            >
              <WorkIcon className='p-1' />
              <Typography variant='caption' className='pe-1' sx={{ cursor: 'pointer' }}>
                {projectData?.ign_companies?.name}
              </Typography>
            </Link>
            <Box>
              <LocationOnIcon className='p-1' />
              {projectData?.location?.length > 20 ? (
                <Tooltip placement='top' title={projectData?.location}>
                  <Typography variant='caption'>{projectData?.location.slice(0, 20) + '...'}</Typography>
                </Tooltip>
              ) : (
                <Typography variant='caption'>{projectData?.location}</Typography>
              )}
            </Box>
            <Box>
              <TimerOutlinedIcon className='p-1' />
              <Typography variant='caption'>
                {t(`${TRANS_KEYS.NO_OF_DAYS}:key`)}{' '}
                <Typography variant='caption' sx={{ fontWeight: 'bold' }}>
                  {calculateNumberOfDays(projectData?.created_at)}
                </Typography>
              </Typography>
            </Box>
          </Grid>
          <Grid container Item xs={4} direction={'column'} justifyContent={'flex-start'}>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography variant='caption'>{t(`${TRANS_KEYS.CONTACTS_ADDED_BY}:key`)}: </Typography>
              <Typography variant='caption' width={'bold'} className='ml-1' sx={{ textDecoration: 'underline' }}>
                {' ' + projectData?.creator?.name}
              </Typography>
            </Stack>
            {!isBD && (
              <Box>
                <Typography variant='caption'>
                  {t(`${TRANS_KEYS.CONTACTS_INDUSTRY}:key`)} :
                  <Typography component={'span'} variant='caption' sx={{ color: 'primary.main' }}>
                    {projectData?.ign_industries?.name}
                  </Typography>
                </Typography>
              </Box>
            )}
            <Box className='mb-2'>
              <Stack direction='row' flexWrap={'wrap'} spacing={1}>
                <Typography variant='caption'>{t(`${TRANS_KEYS.CONTACTS_TAGS}:key`)}:</Typography>
                <Box className='d-flex align-items-center justify-content-center'>
                  {projectData?.project_tags.length !== 0 ? (
                    projectData?.project_tags.slice(0, maxVisibleTags).map((tag, index) => (
                      <Box key={index} className={`tag-border tag${tag?.tag?.name}`} style={{ color: tag?.tag?.color }}>
                        <p className='fs-12 text-black mr-1'>{tag?.tag?.name}</p>
                      </Box>
                    ))
                  ) : (
                    <p className='fs-12 font-normal mr-1'>N/A</p>
                  )}

                  <CustomTooltip
                    title={
                      projectData?.project_tags?.slice(1)?.length !== 0 ? (
                        <Box display={'flex'} flexWrap={'wrap'} width={'200px'}>
                          {projectData?.project_tags?.slice(1)?.map((tag, index) => (
                            <Box key={index} className={`tag-border mr-2 tag${tag?.tag?.name}`} style={{ color: tag?.tag?.color }}>
                              <p style={{ fontSize: 12, fontWeight: 600 }}>{tag?.tag?.name}</p>
                            </Box>
                          ))}
                        </Box>
                      ) : (
                        ''
                      )
                    }
                    placement='top'
                  >
                    <p className='mr-2 cursor-pointer' style={{ fontSize: 12, fontWeight: 600, color: 'black' }}>
                      {projectData?.project_tags?.slice(1)?.length ? `+${projectData.project_tags?.length - 1}` : ''}
                    </p>
                  </CustomTooltip>

                  <Link component='button' onClick={handleAssignTag}>
                    <p style={{ fontSize: 12, fontWeight: 600, color: 'black' }}>+Tag</p>
                  </Link>
                </Box>
              </Stack>
              <AssignTag
                tagPopUp={projectData?.project_tags}
                projectId={projectData?.id}
                setIsPopupOpen={setIsTagPopupOpen}
                isPopupOpen={isTagPopupOpen}
                label={t(`${TRANS_KEYS.PROJECTS_SELECT_TAG}:key`)}
                getHeaderDetails={() => {}}
                setIsEdited={() => {}}
                fetchProjectData={fetchProjectData}
              />
            </Box>
          </Grid>
          <Grid container Item xs={4} direction='column' justifyContent='flex-start' alignItems='flex-start'>
            {!isBD && (
              <Box>
                <Typography variant='caption'>
                  {t(`${TRANS_KEYS.ADD_PROJECT_SELECT_HIRING_MANAGER}:key`)}:
                  <Link
                    style={{
                      color: 'black'
                    }}
                    target='_blank'
                    to={`${ROUTES.allContactGrid}/${projectData?.hiring_manager?.contact?.id}`}
                  >
                    <Typography component={'span'} variant='caption' className='ml-1 cursor-pointer' sx={{ textDecoration: 'underline' }}>
                      {projectData?.hiring_manager?.contact?.name}
                    </Typography>
                  </Link>
                </Typography>
              </Box>
            )}
            {isBD && leadConsultant && (
              <Box className='mb-2'>
                <Stack direction='row' flexWrap={'wrap'} spacing={1}>
                  <Typography variant='caption'>{t(`${TRANS_KEYS.BD_CONSULTANT}:key`)}:</Typography>
                  <Link
                    style={{
                      color: 'black'
                    }}
                    target='_blank'
                    to={`${ROUTES.allContactGrid}/${leadConsultantContactId}`}
                  >
                    {leadConsultant?.user?.name}
                  </Link>
                </Stack>
              </Box>
            )}
            {!isBD && (
              <Box>
                <Typography variant='caption'>
                  {t(`${TRANS_KEYS.CANDIDATES_IN_PIPELINE}:key`)} :
                  <Typography component={'span'} variant='caption' sx={{ color: 'primary.main' }}>
                    {projectData?.candidate_count ?? 0}
                  </Typography>
                </Typography>
              </Box>
            )}
            {
              <Box>
                <Typography variant='caption'>
                  {t(`${TRANS_KEYS.BILLING_STATUS}:key`)} :
                  <Typography component={'span'} variant='caption' sx={{ color: 'primary.main' }}>
                    {` ${bgData?.billing_status_desc?.short_desc ?? ' -'}`}
                  </Typography>
                </Typography>
              </Box>
            }
            {isBD && (
              <Box>
                <Typography variant='caption'>
                  {t(`${TRANS_KEYS.BD_PROBABILITY}:key`)} :
                  <Typography component={'span'} variant='caption' sx={{ color: 'primary.main' }}>
                    {' '}
                    {projectData?.probability || '0%'}
                  </Typography>
                </Typography>
              </Box>
            )}
            {isBD && (
              <Box>
                <Typography variant='caption'>
                  {t(`${TRANS_KEYS.BD_ESTIMATED_REVENUE}:key`)} :
                  <Typography component={'span'} variant='caption' sx={{ color: 'primary.main' }}>
                    {' '}
                    {currencyCode} {estimatedAmt}
                  </Typography>
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={2} direction={'column'} justifyContent={'center'} alignItems={'flex-end'}>
        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'flex-end'}>
          <CustomButton
            show={!isBD}
            variant='contained'
            buttonText={t(`${TRANS_KEYS.VIEW_REPORTS}:key`)}
            type={'primary'}
            onClick={() => {
              setViewReport(true);
            }}
          />

          <CustomButton
            show={isPublished && !isBD}
            buttonText={t(`${TRANS_KEYS.PUBLISHED_BUTTON}:key`)}
            variant={'contained'}
            type={'primary my-2'}
            iconRight={<ArrowDropDownIcon />}
            onClick={handlePublish}
          />
          <CustomButton
            show={!isPublished && !isBD}
            buttonText={t(`${TRANS_KEYS.UNPUBLISHED_BUTTON}:key`)}
            variant='text'
            type={'tertiary border my-2'}
            iconRight={<ArrowDropDownIcon />}
            onClick={handlePublish}
          />
          <ButtonDropDown
            show={!isBD}
            iconRight={<ArrowDropDownIcon />}
            variant='contained'
            options={['Quick add', 'Add from resume', 'Select existing contact']}
            buttonText={t(`${TRANS_KEYS.ADD_CANDIDATE}:key`)}
            onClick={handleAddCandidate}
          />

          <ButtonDropDown
            show={isBD}
            iconRight={<ArrowDropDownIcon />}
            variant='contained'
            options={[t(`${TRANS_KEYS.BD_CREATE_NEW_PROJECT}:key`), t(`${TRANS_KEYS.BD_LINK_EXISTING_PROJECT}:key`)]}
            buttonText={t(`${TRANS_KEYS.BD_ADD_PROJECT}:key`)}
            onClick={handleAddProject}
          />

          <LinkExistingProject companyId={projectData?.company_id} bgId={bgData?.id} open={showExistingProject} onClose={() => setShowExistingProject(false)} />
        </Box>
      </Grid>

      <KeyboardArrowUpIcon
        sx={{
          position: 'absolute',
          bottom: '-10px',
          left: '48%',
          backgroundColor: 'primary.main',
          color: 'titleBarBackground.main',
          borderRadius: '50%',
          cursor: 'pointer'
        }}
        onClick={handleExpand}
      />
    </Grid>
  );
}

HeaderOpened.propTypes = {
  handleExpand: PropTypes.func,
  isPublished: PropTypes.bool,
  handlePublish: PropTypes.func,
  stages: PropTypes.array,
  handleStageChange: PropTypes.func,
  stage: PropTypes.string,
  handleAddCandidate: PropTypes.func,
  fetchProjectData: PropTypes.func
};

export default React.memo(HeaderOpened);
