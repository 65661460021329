import PropTypes from 'prop-types';
import React from 'react';
import CustomDropdown from '../../CustomDropdown';
const FeildOfStudySelection = props => {
  const { onChange = () => {}, label, defaultValue, required = false, ...res } = props;
  /*   const [options, setOptions] = useState([]);
  const fieldOfStudy = useSelector((state) => state.commonReducer.fieldOfStudy);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!documentType) {
      dispatch(fetchPickList("fieldOfStudy"));
    } else {
      setOptions(fieldOfStudy);
    }
  }, [fieldOfStudy, dispatch]); */

  const fieldOfStudyOptions = [
    { label: 'Computer Science', value: 'Computer Science' },
    { label: 'Data Science', value: 'Data Science' },
    { label: 'Economics', value: 'Economics' },
    { label: ' Computer Engineering', value: 'Computer Engineering' },
    { label: 'Information Science', value: 'Information Science' },
    { label: 'Management', value: 'Management' }
  ];

  return <CustomDropdown options={fieldOfStudyOptions} onChange={onChange} label={label} defaultValue={defaultValue} required={required} {...res} />;
};
FeildOfStudySelection.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool
};
export default FeildOfStudySelection;
