import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import CustomPopup from '../../../components/common/CustomPopup';
import CustomInputField from '../../../components/common/StyledComponents/CustomInputField';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import FileDragUpload from '../../../components/common/FileUpload';
import Loader from '../../../components/common/Loader';
import '../../../../src/utils/common.scss';
import ShowInSelection from '../../../components/common/FunctionalComponents/ShowIn';
import ProjectDocumentTypeSelection from '../../../components/common/FunctionalComponents/ProjectDocumentSelection';

export default function AddProjectDocuments(props) {
  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_UPLOAD_FILE, TRANS_KEYS.CONTACTS_DOCUMENT_TYPE, TRANS_KEYS.CONTACTS_ADD_NAME, TRANS_KEYS.CONTACTS_UPLOAD_FILE, TRANS_KEYS.CONTACTS_SHOW_IN]);
  const { title, setIsPopupOpen, isPopupOpen, isEdit, setIsEdit, unregister = () => {}, handleSave = () => {}, data, className } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, setValue } = useForm({});
  const [showIn, setShowIn] = useState([]);
  const handleClose = () => {
    setIsPopupOpen(false);
    setIsEdit(false);
    setIsEdit(false);
  };
  useEffect(() => {
    register('doctype_code');
    register('file_name');
    register('file');
    register('document_visibilities');
    return () => {
      unregister('doctype_code');
      unregister('file_name');
      unregister('file');
    };
  }, [register, unregister]);

  useEffect(() => {
    setValue('document_visibilities', showIn);
  }, [showIn]);

  const handleShowInChange = value => {
    const result = value?.map(elem => {
      if (elem?.attachm) {
        return {
          name: elem.name ? elem.name : elem.field_value,
          show: elem.show,
          document_id: elem.document_id,
          id: elem.id
        };
      } else {
        return {
          name: elem.name ? elem.name : elem.field_value,
          show: true
        };
      }
    });

    setShowIn(result);
  };
  const handleChangeDocument = data => {
    setValue('file', data);
  };

  const saveData = async itemData => {
    setIsLoading(true);
    await handleSave(itemData, data);
    setIsLoading(false);
  };

  useEffect(() => {
    setValue('file_name', data?.file_name);
    setValue('doctype_code', data?.doctype_code);
  }, [data]);

  return (
    <CustomPopup title={title} open={isPopupOpen} onClose={handleClose} showAction={true} handleSubmit={handleSubmit(saveData)}>
      <Box>
        <Box id='add-document'>
          <Loader show={isLoading} />
          <Box className='paper-container pr-3 pl-3'>
            <Box className='d-flex pt-2 flex-wrap flex-row'>
              <Box className='custom-personality-input mr-3'>
                <ProjectDocumentTypeSelection
                  required={true}
                  onChange={(e, data) => {
                    setValue('doctype_code', data?.field_value);
                  }}
                  label={t(`${TRANS_KEYS.CONTACTS_DOCUMENT_TYPE}:key`)}
                  value={data?.ign_translate_value.short_desc || null}
                />
              </Box>
              <Box className='custom-personality-input ml-3'>
                <CustomInputField
                  required={true}
                  className='name-input-box'
                  label={t(`${TRANS_KEYS.CONTACTS_ADD_NAME}:key`)}
                  onChange={e => {
                    setValue('file_name', e.target.value);
                  }}
                  defaultValue={data?.file_name}
                />
              </Box>
            </Box>

            <Box className='d-flex custom-personality-popup mt-3 pb-2'>
              <Box className='custom-personality-input mr-3'>
                <Box className='formParent flex justify-center align-center resume'>
                  <ShowInSelection
                    multiple={true}
                    isCheckBox={true}
                    label={t(`${TRANS_KEYS.CONTACTS_SHOW_IN}:key`)}
                    defaultValue={data?.document_visibilities?.filter(item => item?.show === true) || []}
                    onChange={value => {
                      handleShowInChange(value);
                    }}
                  />
                </Box>
              </Box>
              {!isEdit && (
                <Box className='custom-personality-input ml-3'>
                  <Stack className='formParent flex justify-center align-center resume '>
                    <FileDragUpload className={className} label={t(`${TRANS_KEYS.CONTACTS_UPLOAD_FILE}:key`)} handleChangeDocument={handleChangeDocument} width={'18vw'} />
                    <Typography variant='caption'>only .pdf/.docx format supported</Typography>
                  </Stack>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomPopup>
  );
}

AddProjectDocuments.propTypes = {
  title: PropTypes.string,
  setIsPopupOpen: PropTypes.bool,
  isPopupOpen: PropTypes.bool,
  isEdit: PropTypes.bool,
  setIsEdit: PropTypes.bool,
  register: PropTypes.func,
  unregister: PropTypes.func,
  setValue: PropTypes.func,
  handleSave: PropTypes.func,
  data: PropTypes.object,
  className: PropTypes.string
};
