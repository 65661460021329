//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------

//----------------------------------------------// Internal Imports // -------------------------------------------------

import { AppBar, Stack, Toolbar, Typography } from '@mui/material';
import Approvals from '../Approvals';
import MasterSearchSelection from '../MasterSearch/MasterSearchSelection';
import TasksNotification from '../TasksNotification';
import UserNotification from '../UserNotification';
import BrandLogo from './BrandLogo';
import HeaderProfile from './HeaderProfile';
import HelpDesk from './HelpDesk';
import './index.scss';

const Header = () => {
  return (
    <AppBar color='titleBarBackground' className='mb-2'>
      <Toolbar variant='dense' className='pr-3'>
        <BrandLogo />
        <MasterSearchSelection />
        <Typography className='header-nav-items'></Typography>

        <Stack spacing={0.4} direction={'row'}>
          <Approvals />
          <TasksNotification />
          {/* <Tooltip title="Add to Favorites">
            <IconButton>
              <StarBorderIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Calendar">
            <IconButton>
              <CalendarMonthRoundedIcon />
            </IconButton>
          </Tooltip> */}
          <UserNotification />
          <HelpDesk />
          <HeaderProfile className='profile-container' />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
