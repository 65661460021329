/* eslint-disable no-unused-vars */
import { Box, Checkbox, Divider, Grid, Stack, Switch, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CustomButton from '../../../../components/common/CustomButton';
import CustomDropdown from '../../../../components/common/CustomDropdown';
import CustomMuiDropdown from '../../../../components/common/CustomMuiDropdown';
import CustomPopup from '../../../../components/common/CustomPopup';
import CustomInputField from '../../../../components/common/StyledComponents/CustomInputField';
import { activityInfoApi } from '../../../../services/ApiService';
import { GET, POST, PUT } from '../../../../services/constantService';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';
import useApi from '../../../../utils/Hooks/useApiHook';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
import './index.scss';

const additionalFields = [
  {
    label: 'project-id',
    lookupData: ['all-projects']
  },
  {
    label: 'dictation',
    lookupData: []
  },
  {
    label: 'description',
    lookupData: []
  },
  {
    label: 'date-time',
    lookupData: []
  },
  {
    label: 'timezone',
    lookupData: ['all-timezones']
  },
  {
    label: 'attendees',
    lookupData: ['project-team', 'client-contacts', 'all-users']
  },
  {
    label: 'due-date',
    lookupData: []
  }
];

const additionalLabels = [
  {
    label: 'English',
    language_code: 'en-US',
    required: true
  },
  {
    label: 'Spanish',
    language_code: 'es-US'
  },
  {
    label: 'Portugese',
    language_code: 'pt-BR'
  }
];

const AddTypePopup = props => {
  const { currentRowData, isOpen, setIsOpen, setCurrentRowData, refetch } = props;
  const { register, handleSubmit, getValues, setValue, watch, reset } = useForm({});
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);

  const { t, tReady } = useLanguageHooks([
    TRANS_KEYS.PROJECT_ID,
    TRANS_KEYS.DICTATION,
    TRANS_KEYS.DESCRIPTION,
    TRANS_KEYS.DATE_TIME,
    TRANS_KEYS.TIME,
    TRANS_KEYS.TIMEZONE,
    TRANS_KEYS.ATTENDEES,
    TRANS_KEYS.DUE_DATE,
    TRANS_KEYS.ALL_PROJECTS,
    TRANS_KEYS.ALL_TIMEZONES,
    TRANS_KEYS.PROJECT_TEAM,
    TRANS_KEYS.ALL_USERS,
    TRANS_KEYS.CLIENT_CONTACTS
  ]);

  const { refetch: refetchFields } = useApi({
    queryKey: 'getAllActivityTypes',
    queryFn: async () => {
      setIsLoading(true);
      if (currentRowData) {
        const data = await activityInfoApi(GET, '', `activity-fields?activityTypeCode=${currentRowData?.activity_type_code}`);
        setValue(
          'additional_fields',
          additionalFields.map(item => {
            const fieldData = data?.data.find(field => field.field === item.label);
            return {
              label: item.label,
              active: fieldData ? true : false,
              mandatory: fieldData ? fieldData.mandatory : false,
              lookupData: fieldData?.lookup_data[0] === '' ? [] : fieldData?.lookup_data
            };
          })
        );
        return data;
      }
      setIsLoading(false);
    }
  });

  useEffect(() => {
    register('show_in');
    register('activity_type_code');
    register('labels');
    register('additional_fields');

    setValue(
      'labels',
      additionalLabels.map(item => ({
        label: '',
        language_code: item.language_code
      }))
    );

    setValue(
      'additional_fields',
      additionalFields.map(item => {
        return {
          label: item.label,
          active: false,
          mandatory: 'No',
          lookupData: item.lookupData.length === 1 ? [item.lookupData[0]] : []
        };
      })
    );
    setValue('show_in', []);
  }, []);

  useEffect(() => {
    if (currentRowData) {
      setValue('id', currentRowData.id);
      setValue('activity_type_code', currentRowData.activity_type_code);
      setValue('show_in', currentRowData.show_in ?? []);
      refetchFields();
      setValue(
        'labels',
        additionalLabels.map(item => ({
          label: currentRowData.labels.find(label => label.language_code === item.language_code)?.label ?? '',
          language_code: item.language_code
        }))
      );
      setIsLoading(false);
    }
  }, [currentRowData]);

  const onSubmit = async data => {
    if (currentRowData) {
      const res = await activityInfoApi(PUT, '', 'activity-types', data);
      if (res.status === 200) {
        setIsOpen(false);
        refetch();
        enqueueSnackbar('Activity Type Added Successfully', { variant: 'success' });
      }
    } else {
      const res = await activityInfoApi(POST, '', 'activity-types', data);
      if (res.status === 200) {
        setIsOpen(false);
        refetch();
        enqueueSnackbar('Activity Type Added Successfully', { variant: 'success' });
      }
    }
  };

  return (
    <CustomPopup
      open={isOpen}
      title='Add Activity Type'
      width='80vw'
      onClose={() => {
        reset({
          show_in: [],
          activity_type_code: '',
          labels: additionalLabels.map(item => ({
            label: '',
            language_code: item.language_code
          })),
          additional_fields: additionalFields.map(item => {
            return {
              label: item.label,
              active: false,
              mandatory: 'No',
              lookupData: item.lookupData.length === 1 ? [item.lookupData[0]] : []
            };
          })
        });
        setIsOpen(false);
        setCurrentRowData(null);
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 3,
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Tooltip title='Unique identifier for Activities in the system.'>
          <CustomInputField
            label='Activity Type'
            required
            autoComplete='off'
            className='w-25'
            disabled={currentRowData !== null}
            value={watch('activity_type_code')}
            onChange={e => setValue('activity_type_code', e.target.value)}
          />
        </Tooltip>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 3
          }}
        >
          <Typography className='body-font fs-14'>Show In:</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mr: 1
            }}
          >
            <Checkbox
              className='fs-18'
              onChange={e => {
                if (e.target.checked) {
                  setValue('show_in', [...getValues('show_in'), 'Contacts']);
                } else {
                  setValue(
                    'show_in',
                    getValues('show_in')?.filter(item => item !== 'Contacts')
                  );
                }
              }}
              checked={watch('show_in')?.includes('Contacts')}
            />
            <Typography className='body-font fs-14'>Contacts</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mr: 1
            }}
          >
            <Checkbox
              className='fs-18'
              onChange={e => {
                if (e.target.checked) {
                  setValue('show_in', [...getValues('show_in'), 'Candidates']);
                } else {
                  setValue(
                    'show_in',
                    getValues('show_in')?.filter(item => item !== 'Candidates')
                  );
                }
              }}
              checked={watch('show_in')?.includes('Candidates')}
            />
            <Typography className='body-font fs-14'>Candidates</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mr: 1
            }}
          >
            <Checkbox
              className='fs-18'
              onChange={e => {
                if (e.target.checked) {
                  setValue('show_in', [...getValues('show_in'), 'Companies']);
                } else {
                  setValue(
                    'show_in',
                    getValues('show_in')?.filter(item => item !== 'Companies')
                  );
                }
              }}
              checked={watch('show_in')?.includes('Companies')}
            />
            <Typography className='body-font fs-14'>Companies</Typography>
          </Box>
        </Box>
      </Box>
      <Divider
        sx={{
          mt: 2
        }}
      />

      {additionalLabels.map((label, idx) => {
        return (
          <Grid container spacing={3} marginTop={0} alignItems='center' key={idx}>
            <Grid item xs>
              <Typography className='body-font fs-14'>{label.label}</Typography>
            </Grid>
            <Grid item xs>
              <CustomInputField
                label='Label Name'
                required={label?.required}
                value={watch('labels')?.find(item => item.language_code === label.language_code)?.label}
                onChange={e => {
                  const labels = getValues('labels');
                  console.log(labels);
                  setValue(
                    'labels',
                    labels.map(item => {
                      if (item.language_code === label.language_code) {
                        return {
                          ...item,
                          label: e.target.value
                        };
                      }
                      return item;
                    })
                  );
                }}
              />
            </Grid>
          </Grid>
        );
      })}

      <Divider
        sx={{
          mt: 2
        }}
      />

      {additionalFields.map((field, idx) => additionalFieldRows(field, t, setValue, getValues, idx, watch))}

      <Stack direction='row' className='mt-3' spacing={2} justifyContent='flex-end'>
        <CustomButton
          buttonText='Save'
          onClick={() => {
            handleSubmit(onSubmit)();
          }}
        />
      </Stack>
    </CustomPopup>
  );
};

const additionalFieldRows = (fieldData, t, setValue, getValues, idx, watch) => {
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const isDisabled = !watch('additional_fields')?.find(item => item.label === fieldData.label)?.active;
    setDisabled(isDisabled);
  }, [watch('additional_fields')]);
  return (
    <Grid container spacing={3} marginTop={0} alignItems='flex-start'>
      <Grid item xs>
        {idx === 0 && <Typography className='header-font font-weight-bold fs-14 mb-2'>Additional Fields</Typography>}
        <Typography className='body-font fs-14'>{t(`${fieldData.label}:key`)}</Typography>
      </Grid>

      <Grid item xs>
        {idx === 0 && <Typography className='header-font font-weight-bold fs-14 mb-2'>Active</Typography>}

        <Switch
          checked={watch('additional_fields')?.find(item => item.label === fieldData.label).active}
          onChange={e => {
            const additional_fields = getValues('additional_fields');
            setValue(
              'additional_fields',
              additional_fields?.map(item => {
                if (item.label === fieldData.label) {
                  return {
                    ...item,
                    active: e.target.checked
                  };
                }
                return item;
              })
            );
            setDisabled(!e.target.checked);
          }}
        />
      </Grid>

      <Grid item xs>
        {idx === 0 && <Typography className='header-font font-weight-bold fs-14 mb-2'>Mandatory</Typography>}

        <Switch
          checked={watch('additional_fields')?.find(item => item.label === fieldData.label).mandatory}
          onChange={e => {
            const additional_fields = getValues('additional_fields');
            setValue(
              'additional_fields',
              additional_fields.map(item => {
                if (item.label === fieldData.label) {
                  return {
                    ...item,
                    mandatory: e.target.checked
                  };
                }
                return item;
              })
            );
          }}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={4}>
        {idx === 0 && <Typography className='header-font font-weight-bold fs-14 mb-2'>&nbsp;</Typography>}
        <CustomMuiDropdown
          label='Lookup data'
          options={fieldData.lookupData}
          disabled={disabled || fieldData.lookupData.length === 0}
          value={watch('additional_fields')?.find(item => item.label === fieldData.label)?.lookupData ?? []}
          onChange={(e, v) => {
            const additional_fields = getValues('additional_fields');
            setValue(
              'additional_fields',
              additional_fields?.map(item => {
                if (item.label === fieldData.label) {
                  return {
                    ...item,
                    lookupData: v
                  };
                }
                return item;
              })
            );
          }}
          getOptionLabel={option => {
            return option.label ?? option;
          }}
          autoCompleteProps={{
            size: 'small',
            multiple: true
          }}
        />
      </Grid>

      <Grid item xs>
        {idx === 0 && <Typography className='header-font font-weight-bold fs-14 mb-2'>&nbsp;</Typography>}
        <CustomDropdown disabled label='Show in' />
      </Grid>
    </Grid>
  );
};

AddTypePopup.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  refetch: PropTypes.func,
  currentRowData: PropTypes.object,
  setCurrentRowData: PropTypes.func
};
export default AddTypePopup;
