import React, { useRef, useState } from 'react';
import CustomPopup from '../common/CustomPopup';
import TabLayoutWithSteppers from '../TabLayoutWithSteppers/TabLayoutWithSteppers';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import BasicDetails from './BasicDetails';
import TeamInfo from './TeamInfo';
import { useEffect } from 'react';
import BillingDetails from './BillingDetails';
import { BDBillingSchema, /* BDSchema, */ IgnBDSchema, cleanup, prepareSearchData } from '../AddBD/utils';
import { customFormValidator, requireValidMessage } from '../../utils/common';
import { enqueueSnackbar } from 'notistack';
import { BILLING_VALIDATIONS, ERROR, FEE_TYPES, GET, PATCH, POST, SUCCESS } from '../../services/constantService';
import { VALIDATION_MESSAGE, requireMessage, successMessage, unableMessage, validateMessage } from '../../services/MessageService';
import { checkForError, validateCalculationAmount, validatePercentageFields } from '../ViewSearch/utils';
import { fetchCurrencyDetails } from '../../actions';
import { BDDataApi } from '../../services/ApiService';
import CustomConfirmationPopup from '../common/CustomConfirmationPopup';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import checkForChanges from '../../utils/checkForChanges';

const defaultValues = {
  // currency: "",
  bd_currency_values: { code: 'USD' },
  bd_currency: '2f2f31e2-1c89-43c5-8eea-90ef41e9ab32',
  stage: 'Draft',
  stage_desc: 'Draft',
  bd_status: 'Target Identified',
  retainers: [{ field: 'Retainer 1' }, { field: 'Retainer 2' }, { field: 'Retainer 3' }, { field: 'Final Retainer' }, { field: 'Contingent Fee' }, { field: 'Monthly Fee' }],
  partners: [],
  basic_partners: [],
  notes: [{}],
  recruiters: [{}],
  researchers: [{}],
  project_admin: [{}],
  estimated_percentage_bases: [{}],
  project_indirect_fees: [{}],
  term_type: 'Milestone',
  // fee_type: 'STD',
  fee_type: 'Standard - 1/3',
  fee_type_desc: FEE_TYPES.ONE_THIRD,
  fee_percentage: 33.33,
  record_type: 'Business Development',
  job_title: '',
  client_team: [],
  country_id: null,
  probability: '',
  competition: '',
  markets: {},
  projected_start_date: ''
};
const CreateBD = () => {
  const { setValue, watch, register, getValues, control, reset } = useForm({
    ...defaultValues
  });
  const dispatch = useDispatch();
  const userRef = useRef();
  const companyRef = useRef();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [tabValueNumber, setTabValueNumber] = useState(null);
  const [currentValues, setCurrentValues] = useState({ ...defaultValues });
  const currencyCodeDetails = useSelector(state => (state.commonReducer[watch('bd_currency_values.code')] ? state.commonReducer[watch('bd_currency_values.code')] : {}));
  const [isHide /* , setIsHide */] = useState(false);
  const [options /* , setOptions */] = useState([]);
  const [skipCheckThresholdValue, setSkipCheckThresholdValue] = useState(false);
  const [handleSaveArgs, setHandleSaveArgs] = useState({});
  const [thresholdPopup, setThresholdPopup] = useState(false);
  const [thresholdValue, setThresholdValue] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [tabValueOfBusinessDev, setTabValueOfBusinessDev] = useState(1);
  const params = useParams();
  const companyId = params.id;

  useEffect(() => {
    const currencyHandler = async () => {
      try {
        await fetchCurrencyDetails(watch('bd_currency_values.code'), dispatch);
      } catch (e) {
        console.log('Error found in currencyHandler::', e);
      }
    };
    if (watch('bd_currency_values.code') && Object.keys(currencyCodeDetails)?.length === 0) {
      currencyHandler();
    }
  }, [watch('bd_currency_values.code')]);

  useEffect(() => {
    register('stage');
    register('stage_desc');
    register('ign_companies');
    register('company_id');
    register('client_team');
    register('job_title');
    register('country');
    register('projected_start_date');
    register('basic_partners');
    register('probability');
    register('bd_status');
    register('service_offering');
    register('markets');
    // register('market')
    register('competition');
    register('bd_currency_values.code');
    //billing form
    register('currency');
    register('bd_currency');
    register('billing_region');
    register('fee_cap_amount');
    register('term_type');
    register('intervals_other');
    register('intervals');
    register('fee_type');
    register('fee_percentage');
    register('billing_notes');
    register('estimated_percentage_bases');
    register('project_indirect_fees');
    register('estimated_percentage_base');
    register('estimated_indirect_total_fee');
    register('estimated_revenue');
    register('projected_fee');
  }, [register]);
  useEffect(() => {
    if (companyId && companyId?.length > 0) {
      const ign_companies = {
        name: params.companyName,
        id: companyId
      };
      setCurrentValues({ ...currentValues, ign_companies });
    }
  }, [params, companyId]);
  const handleTabValue = value => {
    setTabValueOfBusinessDev(value);
  };

  const validateKgpInfo = formData => {
    let isError = false;
    if (formData.partners && formData.partners?.length > 0) {
      const fields = {
        origination_credit: 30,
        selling_credit: 20,
        execution_credit: 50
      };
      isError = checkForError(formData.partners, fields);
    }
    if (!isError && formData.recruiters && formData.recruiters?.length > 0) {
      const fields = {
        execution_credit: 100
      };
      isError = checkForError(formData.recruiters, fields);
    }
    if (!isError && formData.researchers && formData.researchers?.length > 0) {
      const fields = {
        execution_credit: 100
      };
      isError = checkForError(formData.researchers, fields);
    }
    if (!isError && formData.project_admin && formData.project_admin?.length > 0) {
      const fields = {
        execution_credit: 100
      };
      isError = checkForError(formData.project_admin, fields);
    }
    if (isError) {
      const message = validateMessage('valid number', 'enter');
      enqueueSnackbar(message, { variant: ERROR });
      return false;
    }
    return true;
  };

  const validateBillingInfo = async (formData, tabValue) => {
    const isValid = await BDBillingSchema.isValid(formData);
    if (tabValue === 2) {
      if (!isValid) {
        let requiredField = [
          { fieldName: 'bd_currency', label: 'Currency Code', type: String },
          { fieldName: 'billing_region', label: 'Billing Region', type: String }
        ];
        let dirtyField = customFormValidator(formData, requiredField);
        if (dirtyField) {
          const message = requireValidMessage(dirtyField);
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        }
      }
      const isValidIndirectFee = validatePercentageFields(formData);
      if (!isValidIndirectFee) {
        enqueueSnackbar(BILLING_VALIDATIONS.FEE_PERCENTAGE_MAX, { variant: ERROR });
        return;
      }
      const isCalculationAmount = validateCalculationAmount(formData);
      if (!isCalculationAmount) {
        enqueueSnackbar(BILLING_VALIDATIONS.CALCULATION_AMOUNT_MAX, { variant: ERROR });
        return;
      }
    }

    return true;
  };

  useEffect(() => {
    if (skipCheckThresholdValue) {
      // handleSave(tabChanged)
      const { busDev, tabValue, save, newTabValue } = handleSaveArgs;
      addBD(busDev, tabValue, save, newTabValue);
    }
  }, [skipCheckThresholdValue]);

  const addBD = async (busDev, tabValue, save, newTabValue) => {
    const isValid = await IgnBDSchema.isValid(busDev);
    if (!isValid) {
      let requiredField = [
        { fieldName: 'ign_companies', label: 'Company', type: {} },
        { fieldName: 'job_title', label: 'Job Title', type: String },
        // { fieldName: 'basic_partners', label: 'Partners', type: [] },
        { fieldName: 'probability', label: 'Probability', type: String },
        { fieldName: 'service_offering', label: 'Service Offering', type: String },
        { fieldName: 'stage', label: 'Stage', type: String },
        { fieldName: 'markets', label: 'Geography', type: String },
        { fieldName: 'location_place_id', label: 'Location', type: String },
        { fieldName: 'projected_start_date', label: 'Projected Start Date', type: String }
      ];
      let dirtyField = customFormValidator(busDev, requiredField);
      if (dirtyField) {
        const message = requireValidMessage(dirtyField);
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }

    const isValidKgpInfo = validateKgpInfo(busDev);
    if (!isValidKgpInfo) return;

    const isValidBillingInfo = await validateBillingInfo(busDev, tabValue);
    if (!isValidBillingInfo) return;
    if (busDev.projected_start_date === 'Invalid date') {
      const message = validateMessage('valid date', 'enter');
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    if (busDev.estimated_revenue > 0 && !busDev.market) {
      enqueueSnackbar(BILLING_VALIDATIONS.MARKET_VALUE, { variant: ERROR });
      return;
    }
    if (tabValue === 1) {
      if (busDev.partners) {
        if (!busDev.partners?.length || (busDev.partners?.length && !busDev.partners.find(ele => ele?.user))) {
          const message = requireMessage('Partners', 'is');
          enqueueSnackbar(message, { variant: ERROR });
          return;
        }
      }
    }
    setHandleSaveArgs({ busDev, tabValue, save, newTabValue });
    if (
      tabValue === 2 &&
      busDev &&
      busDev.billing_status !== 'PND_RHEAD' &&
      busDev.service_offering === 'Executive' &&
      busDev.estimated_revenue < busDev.markets?.threshold &&
      busDev.estimated_revenue !== 0 &&
      !skipCheckThresholdValue
    ) {
      setThresholdPopup(true);
      return;
    }
    setIsLoading(true);
    const payload = cleanup(busDev, tabValue);
    if (skipCheckThresholdValue) {
      if (thresholdValue) {
        payload['isBelowThreshold'] = true;
      } else {
        payload['isBelowThreshold'] = false;
      }
    }
    let { status, data: res } = payload.id ? await BDDataApi(PATCH, payload.id, payload, 'update') : await BDDataApi(POST, '', payload);
    if (status && status === 201) {
      let { status, data: result } = await BDDataApi(GET, payload.id ? payload.id : res.result.id);
      if (status && status === 200) {
        const basicPartners = result.partners ? result.partners.map(partner => partner.user)?.filter(user => user) : [];
        result.basic_partners = basicPartners;
        result.recruiters = result.recruiters?.length ? result.recruiters : [{}];
        result.researchers = result.researchers?.length ? result.researchers : [{}];
        result.project_admin = result.project_admin?.length ? result.project_admin : [{}];
        result.estimated_percentage_bases = result.estimated_percentage_bases?.length ? result.estimated_percentage_bases : [{}];
        result.project_indirect_fees = result.project_indirect_fees?.length ? result.project_indirect_fees : [{}];
        (result.intervals_desc = result.intervals_desc ? result.intervals_desc : result.intervals), (result.bd_currency_values = { code: 'USD' });
        setCurrentValues({ ...currentValues, ...result });
      }

      if (tabValueOfBusinessDev === 2) {
        const message = successMessage('Business Development', 'created');
        enqueueSnackbar(message, { variant: SUCCESS });
      }
    } else if (status && status === 200) {
      let { status: getStatus, data: searchResult } = await BDDataApi(GET, payload.id ? payload.id : res.result.id);
      if (getStatus && getStatus === 200) {
        const searchData = prepareSearchData(searchResult);
        setCurrentValues({ ...currentValues, ...searchData });
      }
      const message = successMessage('Business Development', 'updated');
      if (tabValue === 2 && save === 'saveButton') enqueueSnackbar(message, { variant: SUCCESS });
    } else {
      setIsLoading(false);
      const message = unableMessage('Business Development', `${payload.id ? 'update' : 'create'}`);
      enqueueSnackbar(res.message || message, { variant: ERROR });
      return false;
    }
    if (tabValue === 2 && save === 'saveButton' && skipCheckThresholdValue) handleClose();
    setIsLoading(false);
    if (newTabValue && newTabValue > 2) newTabValue = 2;
    skipCheckThresholdValue && setTabValueNumber(newTabValue);
    return true;
  };

  const handleClose = () => {
    if (!showConfirmationPopup) {
      if (companyId) {
        navigate(`/companies/all-companies/${companyId}`);
      } else {
        navigate('/companies/all-companies');
      }
    }
    setShowConfirmationPopup(false);
  };

  const handleCancel = () => {
    const formData = getValues();
    const isDataEqual = checkForChanges(currentValues, formData);
    if (!isDataEqual) {
      setShowConfirmationPopup(true);
    } else {
      if (companyId) {
        navigate(`/companies/all-companies/${companyId}`);
      } else {
        navigate('/companies/all-companies');
      }
    }
  };

  const handleChange = async (oldValue, newValue, save) => {
    try {
      const formValues = { ...currentValues, ...getValues() };
      // if (!formValues?.id) {
      //   if (formValues.partners?.length) {
      //     formValues.partners = []
      //   }
      // }
      // reset({ ...formValues })
      // setCurrentValues(formValues);

      const result = await addBD(formValues, oldValue, save, newValue);
      return result;
    } catch (e) {
      console.log('Error found in handleChange::', e);
    }
  };

  useEffect(() => {
    reset({ ...currentValues });
  }, [reset, currentValues]);

  const handleConfirmPopup = async () => {
    setShowConfirmationPopup(false);
    if (companyId) {
      navigate(`/companies/all-companies/${companyId}`);
    } else {
      navigate('/companies/all-companies');
    }

    //handleClose();
    // setIsLoading(true)
    // const { status } = await searchDataApi(DELETE, currentValues.id);
    // if (status === 200) {
    //   const message = successMessage("deleted", "Business Development")
    //   enqueueSnackbar(message, { variant: SUCCESS })
    //   handleClose();
    // } else {
    //   const message = unableMessage("Business Development", "delete")
    //   enqueueSnackbar(message, { variant: ERROR })
    // }
    // setIsLoading(false)
  };
  const handleCancelPopup = async () => {
    setShowConfirmationPopup(false);
  };

  // const handleThresholdClose = () => {
  //   setThresholdPopup(false);
  // }

  const handleConfirmThresholdPopup = () => {
    setThresholdValue(true);
    setThresholdPopup(false);
    setSkipCheckThresholdValue(true);
  };

  const handleThresholdCancelPopup = () => {
    setThresholdValue(false);
    setThresholdPopup(false);
    setSkipCheckThresholdValue(false);
  };

  return (
    <>
      <CustomPopup title={'Create Business Development'} open={true} onClose={handleCancel} showAction={false} overFlowClass='overflow-auto' loading={isLoading}>
        {showConfirmationPopup && (
          <CustomConfirmationPopup
            customMessage={VALIDATION_MESSAGE.delete_bd_search}
            open={showConfirmationPopup}
            onConfirm={handleConfirmPopup}
            onCancel={handleCancelPopup}
            onClose={handleClose}
            cancelText='Cancel'
            confirmText='Ok'
            setOpen={setShowConfirmationPopup}
          />
        )}
        {thresholdPopup && (
          <div className='confirmation-popup-bd-search'>
            <CustomConfirmationPopup
              open={thresholdPopup}
              customMessage={VALIDATION_MESSAGE.threshold_confirmation}
              onConfirm={handleConfirmThresholdPopup}
              // onCancel={handleThresholdCancelPopup}
              onClose={handleThresholdCancelPopup}
              cancelText='No'
              confirmText='Save & Continue'
              setOpen={setThresholdPopup}
            />
          </div>
        )}
        <TabLayoutWithSteppers
          navItems={['Basic Information', 'Team Info', 'Billing Details']}
          tabValueNumber={tabValueNumber}
          setTabValueNumber={setTabValueNumber}
          tabChange={handleChange}
          handleTabValue={handleTabValue}
          onClose={handleClose}
        >
          <BasicDetails
            register={register}
            setValue={setValue}
            companyRef={companyRef}
            userRef={userRef}
            watch={watch}
            control={control}
            currentValues={currentValues}
            currencyDetails={currencyCodeDetails}
            isHide={isHide}
            companyId={companyId}
          />
          <TeamInfo register={register} setValue={setValue} userRef={userRef} watch={watch} control={control} currentValues={currentValues} option={options} defaultStyle={{}} bd='AddBD' types='BD' />
          <BillingDetails register={register} setValue={setValue} watch={watch} currentValues={currentValues} currencyDetails={currencyCodeDetails} isHide={isHide} />
        </TabLayoutWithSteppers>
      </CustomPopup>
    </>
  );
};
export default CreateBD;
