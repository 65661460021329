import React from 'react';

import { Box, FormControl, MenuItem, Select } from '@mui/material';
import { useSelector } from 'react-redux';
import { globalSearchSelector } from '../../../selectors';

export const SubTabs = () => {
  const { selectedTabId, tabs } = useSelector(globalSearchSelector);

  const selectedTab = tabs.find(tab => tab?.model === selectedTabId);
  // const subTabs = selectedTab?.subTabs;
  const subTabs = [];

  return (
    <Box marginY={2} display={'flex'}>
      {subTabs?.map(tab => {
        return (
          <FormControl key={tab?.model} sx={{ marginRight: 2, minWidth: 120 }}>
            <Select
              sx={{
                margin: 0,
                background: 'white',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#959fb7'
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#959fb7'
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#959fb7'
                },
                fontSize: '12px',
                fontWeight: 'bolder',
                color: '#959fb7'
              }}
              size='small'
              value={''}
              onChange={() => {}}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem sx={{ fontSize: '12px' }} value=''>
                {tab?.label}
              </MenuItem>
              {tab?.values?.map(val => (
                <MenuItem sx={{ fontSize: '12px' }} key={val?.id} value={val?.id}>
                  {val?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      })}
    </Box>
  );
};
