import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickLists } from '../../../../actions';
import CustomSelectionComponent from '../../CustomSelectionComponent';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import PropTypes from 'prop-types';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';

const GridActionSelection = props => {
  const { handleAssignTag, toggleActivity, handleAddToProject, id = '', data = null, handleTryAthena = () => {}, handleInviteAthena = () => {} } = props;
  const [options, setOptions] = useState([]);

  let actions = useSelector(state => state.commonReducer.actions);
  const dispatch = useDispatch();
  // const [selectedOption, setSelectedOption] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_MARK_NOTE_AS,
    TRANS_KEYS.CONTACTS_ADD_TO_PROJECT,
    TRANS_KEYS.CONTACTS_LOG_AN_ACTIVITY,
    TRANS_KEYS.CONTACTS_ASSIGN_TAG,
    TRANS_KEYS.CONTACTS_ACTION,
    TRANS_KEYS.CONTACTS_TRY_ATHENA
  ]);

  useEffect(() => {
    if (!actions) {
      dispatch(fetchPickLists('INDIVIDUAL_CONTACT_ACTION', 'actions'));
    } else {
      // Temporary remove log an activity
      actions = actions?.filter(item => item.field_value !== 'log_an_activity');
      setOptions(actions);
    }
  }, [actions, dispatch]);

  const actionLabel = {
    label1: t(`${TRANS_KEYS.CONTACTS_ADD_TO_PROJECT}:key`),
    label2: t(`${TRANS_KEYS.CONTACTS_LOG_AN_ACTIVITY}:key`),
    label3: t(`${TRANS_KEYS.CONTACTS_ASSIGN_TAG}:key`),
    label4: t(`${TRANS_KEYS.CONTACTS_TRY_ATHENA}:key`),
    label5: t(`${TRANS_KEYS.CONTACTS_INVITE_TO_ATHENA}:key`)
  };

  const handleMenuItemClick = option => {
    if (option?.short_desc === actionLabel.label1) handleAddToProject(id);
    if (option?.short_desc === actionLabel.label2) toggleActivity(id);
    if (option?.short_desc === actionLabel.label3) handleAssignTag(data);
    if (option?.short_desc === actionLabel.label4) handleTryAthena(data);
    if (option?.short_desc === actionLabel.label5) handleInviteAthena(data);
    setAnchorEl(null);
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const customButtonForAllContact = <MoreVertIcon className='moveVerticalIconStyle' onClick={handleClick} />;

  return <CustomSelectionComponent options={options} handleMenuItemClick={handleMenuItemClick} component={customButtonForAllContact} setAnchorEl={setAnchorEl} anchorEl={anchorEl} />;
};

GridActionSelection.propTypes = {
  handleAssignTag: PropTypes.func,
  handleTryAthena: PropTypes.func,
  toggleActivity: PropTypes.func,
  handleAddToProject: PropTypes.func,
  id: PropTypes.string,
  data: PropTypes.object,
  handleInviteAthena: PropTypes.func
};

export default GridActionSelection;
