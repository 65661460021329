import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import './index.scss';

const OverflowAutocomplete = ({ optionsData, selectedValues, onChange, label = 'Select Options', name = 'autocomplete', className = 'AutoCompleteStyle animate-icon' }) => {
  // Generate options based on the data
  const options = optionsData.filter(option => !selectedValues.some(selectedOption => selectedOption.value === option.value));

  const renderTags = (value, getTagProps) => {
    return (
      <Box className='overflow-autocomplete'>
        {value.map((option, index) => (
          <Chip {...getTagProps({ index })} key={index} label={option.label} />
        ))}
      </Box>
    );
  };

  // Render the option with custom styling
  const renderOption = (props, option, { selected }) => {
    if (!selected) {
      if (selectedValues.length) {
        selected = selectedValues.some(item => {
          const optionValue = option?.short_desc || option?.label || option?.name || option?.description || option?.email || option?.type;
          if (typeof item === 'string') {
            return item.includes(optionValue);
          } else if (typeof item === 'object' && item.name) {
            return item.name === optionValue;
          }
          return false;
        });
      }
    }

    return (
      <li {...props} className='custom-dropdown-list'>
        <div className='d-flex justify-content-between m-2 align-items-center'>
          <div style={{ width: '100%' }}>
            <Box component='span'>{option?.short_desc || option?.label || option?.name || option?.description || option?.market || option?.value || option?.email}</Box>
          </div>
        </div>
      </li>
    );
  };

  // Render the input field
  const renderInput = params => (
    <TextField
      {...params}
      InputProps={{
        ...params.InputProps,
        endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>
      }}
      className=''
      label={label}
      name={name}
      color='heading'
      size='small'
      InputLabelProps={{ focused: true }}
    />
  );

  return (
    <Autocomplete
      label={label}
      options={options}
      popupIcon={<ExpandMoreIcon />}
      selectedValue={selectedValues}
      value={selectedValues}
      name={name}
      limitTags={2}
      multiple
      className={className}
      disableCloseOnSelect
      renderOption={renderOption}
      onChange={onChange}
      renderInput={renderInput}
      renderTags={renderTags}
    />
  );
};

OverflowAutocomplete.propTypes = {
  optionsData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      short_desc: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      email: PropTypes.string,
      type: PropTypes.string,
      market: PropTypes.string
    })
  ).isRequired,
  selectedValues: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      name: PropTypes.string
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string
};

export default OverflowAutocomplete;
