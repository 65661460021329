//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { FormControl, FormControlLabel, Switch } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import CompanySelection from '../../../AddContact/Components/CompanySelection';
import MultiCompanySelection from '../../../CompanySelection';
import InputField from '../../../common/InputField';
import SearchStageSelection from './Components/SearchStageSelection';
import SearchTypeSelection from './Components/SearchTypeSelection';
import SearchResultSelection from './Components/SearchResultSelection';
import CountrySelection from '../../../CountrySelection';
import IndustrySelection from '../../../IndustrySelection';
import JobFunctionSelection from '../../../jobFunctionSelection';
import ClientContacts from './Components/ClientContacts';
import PracticeSelection from '../../../AddUser/components/PracticeSelection';
/* import RightSideNav from "../../../RightSideNav"; */
import StateSelection from '../../../StateSelection';
import CapitalStructure from '../../../ViewSearch/Components/SFPA/Components/Targets/CapitalStructure';
//import { rightNav } from "../../../../Containers/Commons/styleUtils";
import RegionSelection from '../../../AddUser/components/RegionSelection';
import BrandSelection from './Components/BrandSelection';
//import WorkgroupSelection from "../../../../components/AddUser/components/WorkgroupSelection";
import { formatDate } from '../../../../utils/date';
import UserSelection from '../../../UserSelection';
import ProbabilitySelection from './Components/ProbabilitySelection';
import { useSelector } from 'react-redux';
import BDStatusSelection from './Components/BDStatusSelection';

export default function SearchInformation(props) {
  const { register, setValue, watch, control, handelScroll, currentValues, isEditing = true, isFromViewSearch = false, setRenderActionFlag, renderActionFlag, isHide, types, currentTabIndex } = props;
  const partnersData = useSelector(state => state.searchReducer.partners);
  const country = watch('country');
  useEffect(() => {
    if (isEditing) {
      const field = [
        'company',
        'job_title',
        'state',
        'vertical_practice',
        'horizontal_practice',
        'region',
        'type',
        'stage',
        'industries',
        'job_functions',
        'result',
        'brand',
        'on_hold_date',
        'off_hold_date',
        'start_date',
        'close_date',
        'loe_date',
        'is_confidential',
        'is_nda',
        'country',
        'city',
        'next_status_call_date',
        'next_status_call_time',
        'capital_structure',
        'is_executive_search_arena',
        'encore_number',
        'vc_pe',
        'competition',
        'decision',
        'work_group',
        'projected_start_date'
      ];
      field.forEach(field => setValue(field, currentValues[field]));
    }
  }, []);

  useEffect(() => {
    register('company');
    register('country');
    register('state');
    register('vertical_practice');
    register('horizontal_practice');
    register('region');
    register('type');
    register('stage_desc');
    register('stage');
    register('industries');
    register('job_functions');
    register('result');
    register('capital_structure');
    register('vc_pe');
    register('brand');
    register('billing_status');
    register('job_title');
    register('basic_partners');
    register('probability');
    register('bd_status');
  });

  useEffect(() => {
    setTimeout(() => {
      handelScroll();
    }, 3000);
  }, []);

  const stateChange = state => {
    setValue('state', state);
  };

  const handleChange = (name, value) => {
    setValue(name, formatDate(value));
  };

  const handleChanges = (e, value, name) => {
    setValue(name, value ? value.field_value : '');
  };

  useEffect(() => {
    const partnerData = () => {
      if (types === 'AddSearch' && currentTabIndex === 0) {
        if (partnersData !== undefined) {
          if (partnersData?.length) {
            const result = partnersData?.filter(obj => !(obj && Object.keys(obj)?.length === 0));
            if (result && result?.length) {
              let exists = result?.filter(obj => Object.keys(obj).includes('user'));
              if (exists && exists?.length) {
                currentValues.basic_partners = exists.map(item => (item.user && item.user ? item.user : item));
                setValue('basic_partners', currentValues.basic_partners);
              }
            } else {
              currentValues.basic_partners = result;
              setValue('basic_partners', currentValues.basic_partners);
            }
          } else {
            currentValues.basic_partners = partnersData;
            setValue('basic_partners', currentValues.basic_partners);
          }
        }
      }
    };
    partnerData();
  }, [partnersData, currentTabIndex !== 1]);

  useEffect(() => {
    if (types === 'AddSearch') {
      let filterPartnerData = [];
      if (currentValues.partners && currentValues.partners?.length) {
        const result = currentValues.partners?.filter(obj => !(obj && Object.keys(obj)?.length === 0));
        if (result && result?.length) {
          const basicPartners = watch('basic_partners');
          if (basicPartners && basicPartners?.length) {
            basicPartners.map(elem => {
              const isPartnerMatch = result.find(item => item?.user?.id === elem?.id);
              if (isPartnerMatch) {
                filterPartnerData.push(isPartnerMatch);
              } else {
                filterPartnerData.push({ user: elem });
              }
            });
            currentValues.partners = filterPartnerData;
            setValue('partners', filterPartnerData);
          } else {
            //const isUser = currentValues.partners.some((o) => o?.hasOwnProperty("user"));
            const isUser = currentValues.partners.some(o => Object.prototype.hasOwnProperty.call(o, 'user'));
            if (isUser) {
              const isUserNull = currentValues.partners.some(item => item?.user === null);
              if (!isUserNull) {
                const partner = [{}, {}, {}];
                currentValues.partners = partner;
                setValue('partners', partner);
              }
            }
          }
        }
      }
    }
  }, [watch('basic_partners')]);

  return (
    <div className='input-form'>
      <div id='client-info'>
        {/* client info */}
        <Accordion defaultExpanded={true} className='panel-bottom-margin' style={{ background: '#fff', color: '#fff' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />} aria-controls='panel1a-content' style={{ background: '#2a364c' }}>
            <div>Client Info</div>
          </AccordionSummary>
          <AccordionDetails className='d-flex flex-column'>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <label className='page-label'>
                  Company Name<span className='MuiInputLabel-asterisk'>*</span>
                </label>
                <div className='page-label-value'>
                  <CompanySelection
                    className='input-form-field'
                    defaultValue={watch('company') || null}
                    includeVcpe={true}
                    required={true}
                    disabled={!isEditing}
                    label=''
                    placeholder={'Search Company'}
                    InputLabelProps={{ focused: true }}
                    onChange={(e, data) => {
                      setValue('company', data);
                      if (!isFromViewSearch && Array.isArray(data?.vc_pe)) {
                        const value = data.vc_pe.map(value => ({ id: value.vcpe_id, name: value.company?.name }));
                        setValue('vc_pe', value);
                      }
                    }}
                    variant='outlined'
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <label className='page-label'>
                  Search Job Title<span className='MuiInputLabel-asterisk'>*</span>
                </label>
                <div className='page-label-value'>
                  <InputField
                    {...register('job_title')}
                    required={true}
                    disabled={!isEditing}
                    value={watch('job_title') || ''}
                    name='job_title'
                    label=''
                    placeholder='Search Job Title'
                    className='input-form-field'
                    InputLabelProps={{ focused: true }}
                    onChange={e => {
                      setValue('job_title', e.target ? e.target.value : '');
                    }}
                    variant='outlined'
                  />
                </div>
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <label className='page-label'>Job Location-Region</label>
                <div className='page-label-value'>
                  <RegionSelection
                    {...register('region')}
                    className='input-form-field'
                    defaultValue={watch('region') || null}
                    value={watch('region') || null}
                    name='region'
                    disabled={!isEditing}
                    label=''
                    placeholder={'Search'}
                    InputLabelProps={{ focused: true }}
                    onChange={(e, data) => {
                      setValue('region', data ? data.name : '');
                    }}
                    variant='outlined'
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <label className='page-label'>
                  Location<span className='MuiInputLabel-asterisk'>*</span>
                </label>
                <div className='page-label-value'>
                  <CountrySelection
                    //value={watch('country') || null}
                    required={true}
                    defaultValue={currentValues && currentValues.country}
                    disabled={!isEditing}
                    className='input-form-field'
                    InputLabelProps={{ focused: true }}
                    label=''
                    onChange={(e, data) => {
                      stateChange('');
                      setValue('country', data?.name);
                    }}
                    variant='outlined'
                  />
                </div>
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className=' d-flex intersection-page-view'>
                <label className='page-label'>State</label>
                <div className='page-label-value'>
                  <StateSelection
                    value={watch('state') || null}
                    defaultValue={currentValues && currentValues.state}
                    disabled={!isEditing}
                    className='input-form-field'
                    country={country}
                    register={register}
                    onChange={(e, state) => {
                      stateChange(state);
                    }}
                    variant='outlined'
                    label=''
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view '>
                <label className='page-label'>City</label>
                <div className='page-label-value'>
                  <InputField
                    value={watch('city') || ''}
                    {...register('city')}
                    disabled={!isEditing}
                    className='input-form-field'
                    label=''
                    placeholder='City'
                    onChange={e => {
                      setValue('city', e.target ? e.target.value : '');
                    }}
                    InputLabelProps={{ focused: true }}
                    name='city'
                    variant='outlined'
                  />
                </div>
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <label className='page-label'>
                  industries<span className='MuiInputLabel-asterisk'>*</span>
                </label>
                <div className='page-label-value multiple-selection-list'>
                  <IndustrySelection
                    multiple
                    value={watch('industries') || []}
                    disabled={!isEditing}
                    className='input-form-field'
                    label=''
                    required={true}
                    size='small'
                    isSearchIndustry={true}
                    filterSelectedOptions={true}
                    InputLabelProps={{ focused: true }}
                    onChange={(e, data) => {
                      setValue('industries', Array.isArray(data) ? data : []);
                    }}
                    variant='outlined'
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <label className='page-label'>Horizontal Practice</label>
                <div className='page-label-value'>
                  <PracticeSelection
                    className='input-form-field'
                    defaultValue={watch('horizontal_practice') || null}
                    value={watch('horizontal_practice') || null}
                    disabled={!isEditing}
                    label={''}
                    placeholder='Horizontal Practice'
                    InputLabelProps={{ focused: true }}
                    onChange={(e, data) => {
                      setValue('horizontal_practice', data ? data.name : '');
                    }}
                    type='horizontal'
                    variant='outlined'
                  />
                </div>
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className=' d-flex intersection-page-view'>
                <label className='page-label'>Vertical Practice</label>
                <div className='page-label-value'>
                  <PracticeSelection
                    className='input-form-field'
                    defaultValue={watch('vertical_practice') || null}
                    value={watch('vertical_practice') || null}
                    disabled={!isEditing}
                    label={''}
                    placeholder=''
                    InputLabelProps={{ focused: true }}
                    onChange={(e, data) => {
                      setValue('vertical_practice', data ? data.name : '');
                    }}
                    type='vertical'
                    variant='outlined'
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <label className='page-label'>
                  Partners<span className='MuiInputLabel-asterisk'>*</span>
                </label>
                <div className='page-label-value multiple-selection-list'>
                  <UserSelection
                    name='basic_partners'
                    multiple
                    // inputRef={userRef}
                    value={watch('basic_partners') || []}
                    // value={getPartnerValue()}
                    className='input-form-field'
                    InputLabelProps={{ focused: true }}
                    filterSelectedOptions={true}
                    label={''}
                    placeholder={'Select'}
                    required={true}
                    variant='outlined'
                    // onChange={([, data]) => data}
                    onChange={(e, data) => {
                      setValue('basic_partners', data);
                    }}
                  />
                </div>
              </div>
            </div>
            <div id='clientDetails' className=''>
              <ClientContacts
                register={register}
                setValue={setValue}
                control={control}
                isEditing={isEditing}
                watch={watch}
                currentValues={currentValues}
                isFromViewSearch={isFromViewSearch}
                variant='outlined'
              />
            </div>
          </AccordionDetails>
        </Accordion>
        {/* Basic info */}
        <Accordion defaultExpanded={true} className='panel-bottom-margin' style={{ background: '#fff', color: '#fff' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />} aria-controls='panel1a-content' style={{ background: '#2a364c' }}>
            <div>Basic Info</div>
          </AccordionSummary>
          <AccordionDetails className='d-flex flex-column'>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <span className='page-label'>
                  Stage<span className='MuiInputLabel-asterisk'>*</span>
                </span>
                <div className='page-label-value'>
                  <SearchStageSelection
                    label=''
                    className='input-form-field'
                    value={watch('stage_desc') || watch('stage') || null}
                    disabled={!isEditing || watch('stage') === 'Pending Acknowledgement'}
                    required={true}
                    InputLabelProps={{ focused: true }}
                    onChange={(e, data) => setValue('stage', data ? data.field_value : null, setValue('stage_desc', data ? data.short_desc : null))}
                    variant='outlined'
                    fullSearch={true}
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <span className='page-label'>Billing status</span>
                {/* <BillingStatus
                      className='input-form-field'
                      value={watch('billing_status') || null}
                      label=''
                      onChange={(e, data) =>{
                         setValue('billing_status', data ? data : null)
                        }}
                      variant="outlined"
                    /> */}
                <div className='page-label-value text-red'>{watch('billing_desc') === 'None' ? '--' : watch('billing_desc') || '--'}</div>
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <span className='page-label'>
                  Search Type<span className='MuiInputLabel-asterisk'>*</span>
                </span>
                <div className='page-label-value'>
                  <SearchTypeSelection
                    className='input-form-field'
                    value={watch('type') || null}
                    disabled={!isEditing}
                    required={true}
                    InputLabelProps={{ focused: true }}
                    onChange={(e, data) => {
                      setValue('type', data ? data.name : null);
                    }}
                    variant='outlined'
                    label=''
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <span className='page-label'>
                  Projected Start Date<span className='MuiInputLabel-asterisk'>*</span>
                </span>
                <div className='page-label-value'>
                  <KeyboardDatePicker
                    {...register('projected_start_date')}
                    format='MM/dd/yyyy'
                    autoOk
                    disabled={!isEditing}
                    className='input-form-field'
                    InputLabelProps={{ focused: true }}
                    label=''
                    inputProps={{ readOnly: true }}
                    placeholder={'Projected Start Date'}
                    value={formatDate(watch('projected_start_date')) || null}
                    // minDate={compareDateWithCurrentDate(watch('projected_start_date'))}
                    onChange={date => handleChange('projected_start_date', date)}
                    inputVariant='outlined'
                  />
                </div>
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <span className='page-label'>
                  Service Offering<span className='MuiInputLabel-asterisk'>*</span>
                </span>
                <div className='page-label-value'>
                  <BrandSelection
                    className='input-form-field'
                    defaultValue={currentValues.brand_desc ? watch('brand_desc') : watch('brand') || null}
                    disabled={!isEditing || isHide}
                    InputLabelProps={{ focused: true }}
                    onChange={(e, data) => {
                      setValue('brand', data ? data.field_value : '');
                    }}
                    variant='outlined'
                    label=''
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <span className='page-label'>Off Hold Date</span>
                <div className='page-label-value'>
                  <KeyboardDatePicker
                    {...register('off_hold_date')}
                    format='MM/dd/yyyy'
                    disabled={!isEditing}
                    autoOk
                    className='input-form-field'
                    InputLabelProps={{ focused: true }}
                    label={''}
                    placeholder={'Off Hold Date'}
                    value={formatDate(watch('off_hold_date')) || null}
                    onChange={date => handleChange('off_hold_date', date)}
                    inputVariant='outlined'
                  />
                </div>
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <span className='page-label'>Result</span>
                <div className='page-label-value '>
                  <SearchResultSelection
                    className='input-form-field'
                    value={watch('result') || null}
                    disabled={!isEditing}
                    InputLabelProps={{ focused: true }}
                    onChange={(e, data) => setValue('result', data ? data.name : null)}
                    variant='outlined'
                    label=''
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <FormControl style={{ display: 'contents' }}>
                  {/* <FormLabel focused={true} className='contact-view-label' style={{ color: "#7d75d1" }}>SFPA Completed</FormLabel> */}
                  <span className='page-label'>SFPA Completed</span>
                  <div className='page-label-value '>
                    {/* <FormControlLabel
                        disabled={!isEditing}
                        style={{ marginLeft: 0 }}
                        control={<Checkbox size='small' onChange={(e) => { setValue('is_sfpa_completed', e.target.checked); }} checked={watch('is_sfpa_completed') || false} name="is_sfpa_completed" {...register('')} />}
                        label="Yes"
                        /> */}
                    <FormControlLabel
                      disabled={!isEditing}
                      control={
                        <Switch
                          checked={watch('is_sfpa_completed') || false}
                          onChange={e => {
                            setValue('is_sfpa_completed', e.target.checked);
                          }}
                          color='primary'
                          {...register('is_sfpa_completed')}
                          name={'is_sfpa_completed'}
                        />
                      }
                      label=''
                      className='input-form-field checkbox-width'
                    />
                  </div>
                </FormControl>
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <FormControl style={{ display: 'contents' }}>
                  {/* <FormLabel focused={true} className='contact-view-label' style={{ color: "#7d75d1" }}>Non-disclosure agreement</FormLabel> */}
                  <span className='page-label'>Non-disclosure agreement</span>
                  <div className='page-label-value'>
                    {/* <FormControlLabel
                        disabled={!isEditing}
                        style={{ marginLeft: 0 }}
                        control={<Checkbox size='small' onChange={(e) => { setValue('is_nda', e.target.checked); }} checked={watch('is_nda') || false} name="is_nda" {...register('')} />}
                        label="Yes"
                        /> */}
                    <FormControlLabel
                      disabled={!isEditing}
                      control={
                        <Switch
                          checked={watch('is_nda') || false}
                          onChange={e => {
                            setValue('is_nda', e.target.checked);
                          }}
                          color='primary'
                          {...register('is_nda')}
                          name={'is_nda'}
                        />
                      }
                      label=''
                      className='input-form-field checkbox-width'
                    />
                  </div>
                </FormControl>
              </div>
              <div className='d-flex intersection-page-view'>
                <span className='page-label'>Start Date</span>
                <div className='page-label-value'>
                  <KeyboardDatePicker
                    {...register('start_date')}
                    format='MM/dd/yyyy'
                    disabled={!isEditing}
                    autoOk
                    className='input-form-field'
                    InputLabelProps={{ focused: true }}
                    label=''
                    placeholder={'Start Date'}
                    value={formatDate(watch('start_date')) || null}
                    onChange={date => handleChange('start_date', date)}
                    inputVariant='outlined'
                  />
                </div>
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <FormControl style={{ display: 'contents' }}>
                  {/* <FormLabel focused={true} className='contact-view-label' style={{ color: "#7d75d1" }}>Confidential</FormLabel> */}
                  <span className='page-label'>Confidential</span>
                  <div className='page-label-value'>
                    {/* <FormControlLabel
                        disabled={!isEditing}
                        style={{ marginLeft: 0 }}
                        control={<Checkbox size='small' onChange={(e) => { setValue('is_confidential', e.target.checked); }} checked={watch('is_confidential') || false} name="is_confidential" {...register('')} />}
                        label="Yes"
                      /> */}
                    <FormControlLabel
                      disabled={!isEditing}
                      control={
                        <Switch
                          checked={watch('is_confidential') || false}
                          onChange={e => {
                            setValue('is_confidential', e.target.checked);
                          }}
                          color='primary'
                          {...register('is_confidential')}
                          name={'is_confidential'}
                        />
                      }
                      label=''
                      className='input-form-field checkbox-width mb-0'
                    />
                  </div>
                </FormControl>
              </div>
              <div className='d-flex intersection-page-view'>
                <span className='page-label'>On Hold Date</span>
                <div className='page-label-value'>
                  <KeyboardDatePicker
                    {...register('on_hold_date')}
                    format='MM/dd/yyyy'
                    disabled={!isEditing}
                    autoOk
                    className='input-form-field'
                    InputLabelProps={{ focused: true }}
                    label=''
                    placeholder={'On Hold Date'}
                    value={formatDate(watch('on_hold_date')) || null}
                    onChange={date => handleChange('on_hold_date', date)}
                    inputVariant='outlined'
                  />
                </div>
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <label className='page-label'>
                  Probability<span className='MuiInputLabel-asterisk'>*</span>
                </label>
                <div className='page-label-value overflow-hidden'>
                  <ProbabilitySelection
                    label=''
                    className='input-form-field'
                    defaultValue={watch('probability') || null}
                    variant='outlined'
                    onChange={(e, data) => handleChanges(e, data, 'probability')}
                    name='probability'
                    disabled={!isEditing}
                    InputLabelProps={{ focused: true }}
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <span className='page-label'>BD Status</span>
                <div className='page-label-value overflow-unset'>
                  <BDStatusSelection
                    label=''
                    register={register('bd_status')}
                    InputLabelProps={{ focused: true }}
                    placeholder='BD Status'
                    defaultValue={watch('bd_status') || 'Target Identified'}
                    className='input-form-field'
                    variant='outlined'
                    onChange={(e, data) => handleChanges(e, data, 'bd_status')}
                    name='bd_status'
                    disabled={!isEditing}
                  />
                </div>
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <span className='page-label'>Competition</span>
                <div className='page-label-value'>
                  <InputField
                    value={watch('competition') || ''}
                    {...register('competition')}
                    disabled={!isEditing}
                    className='input-form-field'
                    label=''
                    placeholder='Competition'
                    onChange={e => {
                      setValue('competition', e.target ? e.target.value : '');
                    }}
                    InputLabelProps={{ focused: true }}
                    name='competition'
                    variant='outlined'
                  />
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        {/* Addition info */}

        <Accordion defaultExpanded={true} className='panel-bottom-margin' style={{ background: '#fff', color: '#fff' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />} aria-controls='panel1a-content' style={{ background: '#2a364c' }}>
            <div>Additional Info</div>
          </AccordionSummary>
          <AccordionDetails className='d-flex flex-column'>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <span className='page-label'>
                  Job Function<span className='MuiInputLabel-asterisk'>*</span>
                </span>
                <div className='page-label-value multiple-selection-list'>
                  <JobFunctionSelection
                    multiple
                    value={watch('job_functions') || []}
                    disabled={!isEditing}
                    className='input-form-field'
                    label=''
                    required={true}
                    size='small'
                    filterSelectedOptions={true}
                    InputLabelProps={{ focused: true }}
                    onChange={(e, data) => {
                      setValue('job_functions', data ? data.map(item => (typeof item === 'string' ? item : item.name)) : []);
                    }}
                    variant='outlined'
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <span className='page-label'>LOE Date</span>
                <div className='page-label-value'>
                  <KeyboardDatePicker
                    className='input-form-field'
                    {...register('loe_date')}
                    format='MM/dd/yyyy'
                    disabled={!isEditing}
                    autoOk
                    InputLabelProps={{ focused: true }}
                    label=''
                    placeholder={'LOE Date'}
                    value={formatDate(watch('loe_date')) || null}
                    onChange={date => {
                      handleChange('loe_date', date);
                    }}
                    inputVariant='outlined'
                  />
                </div>
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <span className='page-label'>Next Status Call Date</span>
                <div className='page-label-value'>
                  <KeyboardDatePicker
                    {...register('next_status_call_date')}
                    format='MM/dd/yyyy'
                    autoOk
                    disabled={!isEditing}
                    className='input-form-field'
                    InputLabelProps={{ focused: true }}
                    label=''
                    placeholder={'Next Status Call Date'}
                    value={watch('next_status_call_date') || null}
                    onChange={date => setValue('next_status_call_date', date)}
                    inputVariant='outlined'
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <span className='page-label'>Next Status Call Time</span>
                <div className='page-label-value'>
                  <KeyboardTimePicker
                    {...register('next_status_call_time')}
                    autoOk
                    className='input-form-field'
                    disabled={!isEditing}
                    keyboardIcon={<ScheduleIcon fontSize={'small'} />}
                    InputLabelProps={{ focused: true }}
                    label=''
                    placeholder={'Next Status Call Time'}
                    value={watch('next_status_call_time') || null}
                    onChange={date => setValue('next_status_call_time', date)}
                    inputVariant='outlined'
                  />
                </div>
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <span className='page-label'>Capital Structure</span>
                <div className='page-label-value multiple-selection-list'>
                  <CapitalStructure
                    multiple
                    value={watch('capital_structure') || []}
                    disabled={!isEditing}
                    className='input-form-field'
                    InputLabelProps={{ focused: true }}
                    filterSelectedOptions
                    onChange={(e, data) => {
                      setValue('capital_structure', Array.isArray(data) ? data.map(item => item?.name || item) : []);
                    }}
                    variant='outlined'
                    label=''
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <FormControl style={{ display: 'contents' }}>
                  {/* <FormLabel focused={true} className='contact-view-label' style={{ color: "#7d75d1" }} >Executive Search Arena</FormLabel> */}
                  <span className='page-label'>Executive Search Arena</span>
                  <div className='page-label-value'>
                    {/* <FormControlLabel
                        disabled={!isEditing}
                        style={{ marginLeft: 0 }}
                        control={<Checkbox size='small' onChange={(e) => { setValue('is_executive_search_arena', e.target.checked); }} checked={watch('is_executive_search_arena') || false} name="is_executive_search_arena" {...register('')} />}
                        label="Yes"
                        /> */}
                    <FormControlLabel
                      disabled={!isEditing}
                      control={
                        <Switch
                          checked={watch('is_executive_search_arena') || false}
                          onChange={e => {
                            setValue('is_executive_search_arena', e.target.checked);
                          }}
                          color='primary'
                          {...register('is_executive_search_arena')}
                          name={'is_executive_search_arena'}
                        />
                      }
                      label=''
                      className='input-form-field checkbox-width'
                    />
                  </div>
                </FormControl>
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <span className='page-label'>Search VC/PE</span>
                <div className='page-label-value  multiple-selection-list'>
                  <MultiCompanySelection
                    value={watch('vc_pe') || []}
                    multiple={true}
                    disabled={!isEditing}
                    className='input-form-field'
                    label=''
                    size='small'
                    InputLabelProps={{ focused: true }}
                    onChange={(e, data) => {
                      setValue('vc_pe', Array.isArray(data) ? data : []);
                    }}
                    variant='outlined'
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <span className='page-label'>Encore Number</span>
                <div className='page-label-value'>
                  <InputField
                    value={watch('encore_number') || ''}
                    {...register('encore_number')}
                    disabled={!isEditing}
                    className='input-form-field'
                    label=''
                    placeholder='Encore Number'
                    onChange={e => {
                      setValue('encore_number', e.target ? e.target.value : '');
                    }}
                    InputLabelProps={{ focused: true }}
                    name='encore_number'
                    variant='outlined'
                  />
                </div>
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <span className='page-label'>Decision</span>
                <div className='page-label-value'>
                  <InputField
                    value={watch('decision') || ''}
                    {...register('decision')}
                    disabled={!isEditing}
                    className='input-form-field'
                    label=''
                    placeholder='Decision'
                    onChange={e => {
                      setValue('decision', e.target ? e.target.value : '');
                    }}
                    InputLabelProps={{ focused: true }}
                    name='decision'
                    variant='outlined'
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <span className='page-label'>End Date</span>
                <div className='page-label-value'>
                  <KeyboardDatePicker
                    {...register('close_date')}
                    format='MM/dd/yyyy'
                    disabled={!isEditing}
                    autoOk
                    className='input-form-field'
                    InputLabelProps={{ focused: true }}
                    label=''
                    placeholder={'End Date'}
                    minDate={watch('start_date')}
                    value={formatDate(watch('close_date')) || null}
                    onChange={date => handleChange('close_date', date)}
                    onError={error => {
                      if (error) {
                        setRenderActionFlag(true);
                      } else {
                        renderActionFlag && setRenderActionFlag(false);
                      }
                    }}
                    inputVariant='outlined'
                  />
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        {/* <div className=''>
            <FormControl className="input-form-field">
              <FormLabel focused={true} className='input-label' >New Client</FormLabel>
              <FormControlLabel
                disabled={!isEditing}
                style={{ marginLeft: 0 }}
                control={<Checkbox size='small' onChange={(e) => { setValue('new_client', e.target.checked); }} checked={watch('new_client') || false} name="new_client" {...register('')} />}
                label="Yes"
              />
            </FormControl>

          </div> */}

        {/* <div className=''>
            <WorkgroupSelection
              value={watch('work_group') || ''}
              className="input-form-field"
              InputLabelProps={{ focused: true }}
              {...register('work_group')}
              onChange={(e, data) =>
                setValue('work_group', data ? data.name : null)
              }
            />
          </div> */}
      </div>
    </div>
  );
}

SearchInformation.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  control: PropTypes.object,
  currentValues: PropTypes.object,
  isEditing: PropTypes.bool,
  isFromViewSearch: PropTypes.bool,
  handelScroll: PropTypes.func,
  setRenderActionFlag: PropTypes.func,
  renderActionFlag: PropTypes.bool,
  isHide: PropTypes.bool,
  types: PropTypes.string,
  currentTabIndex: PropTypes.number
};
