import * as yup from 'yup';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
const columnDefs = t => {
  let columns = [
    {
      field: 'name',
      headerName: t(`${TRANS_KEYS.TAGS_SETUP_TAG_NAME}:key`),
      cellRenderer: 'nameRenderer',
      minWidth: 300,
      width: 350,
      maxWidth: 370,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null]
    },
    {
      field: 'color',
      headerName: t(`${TRANS_KEYS.TAGS_SETUP_COLOUR_LABEL}:key`),
      minWidth: 300,
      width: 350,
      maxWidth: 370,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellRenderer: 'colorRenderer'
    },
    // Show default header name

    {
      field: 'module',
      headerName: t(`${TRANS_KEYS.TAGS_SETUP_MODULE}:key`),
      minWidth: 300,
      width: 350,
      maxWidth: 370,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellRenderer: 'getModules'
    },
    {
      field: 'status',
      headerName: t(`${TRANS_KEYS.TAGS_SETUP_STATUS}:key`),
      minWidth: 300,
      width: 350,
      maxWidth: 370,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellRenderer: 'statusRender'
    }
  ];
  columns.push({
    headerName: '',
    field: 'action',
    colId: 'action',
    sortable: false,
    maxWidth: 80,
    minWidth: 80,
    cellRenderer: 'GetActionRenderer',
    pinned: 'right',
    lockPinned: true
  });
  return columns;
};
export { columnDefs };
export const createSchema = yup.object().shape({
  name: yup.string().required(),
  color: yup.string().required(),
  tag_module: yup
    .array()
    .of(
      yup.object().shape({
        module_name: yup.string().required()
      })
    )
    .min(1)
});
export const createRequiredField = t => {
  return [
    { fieldName: 'name', label: t(`${TRANS_KEYS.TAGS_SETUP_TAG_NAME}:key`), type: String },
    { fieldName: 'color', label: t(`${TRANS_KEYS.TAGS_SETUP_COLOUR_LABEL}:key`), type: String },
    {
      fieldName: 'tag_module',
      label: t(`${TRANS_KEYS.TAGS_SETUP_MODULE}:key`),
      type: [],
      obj: {
        fieldName: 'module_name',
        label: t(`${TRANS_KEYS.TAGS_SETUP_MODULE}:key`),
        type: String
      }
    }
  ];
};

export const getUpdatedPayload = (obj, userData) => {
  if (typeof obj.status === 'string') {
    obj.status = obj.status === 'Active' ? true : false;
  }

  if (obj.tag_module) {
    const tagModuleArray = obj?.tag_module.map(item => {
      if (item?.field_value) {
        return {
          module_name: item?.field_value,
          created_by: userData?.id,
          updated_by: userData?.id
        };
      } else {
        return {
          module_name: item?.module_name,
          created_by: userData?.id,
          updated_by: userData?.id
        };
      }
    });
    obj['tag_module'] = tagModuleArray;
  }

  return obj;
};
export const updateObject = (obj1, obj2) => {
  for (const key in obj2) {
    if (obj2[key] !== undefined) {
      obj1[key] = obj2[key];
    }
  }
  return obj1;
};
export const getPayload = (formValues, userData) => {
  let tagModuleArray = [];
  if (formValues?.tag_module) {
    tagModuleArray = formValues?.tag_module.map(item => ({
      module_name: item?.field_value,
      created_by: userData?.id,
      updated_by: userData?.id
    }));
  }
  const result = {
    name: formValues?.name,
    color: formValues?.color,
    status: formValues?.status === 'Active' || formValues?.status === undefined,
    tag_module: tagModuleArray
  };
  return result;
};
export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
