import { Stack } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import proptype from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../services/constantService';
import ColumnFilter from '../../Commons/ColumnFilter';
import { defaultColumns } from '../../Companies/Utils';
import CustomHeader from './CustomHeader';
let columnApi;
const TableView = ({ projectGridColumn, projectGridData, sx, loading }) => {
  const [isGridReady, setIsGridReady] = useState(false);
  const navigate = useNavigate();

  const onClickGridProject = project => {
    const { id, candidate_count } = project;

    let pathWithTab = ROUTES.viewProjectWithDeepLink;
    pathWithTab = pathWithTab.replace(':id', id);
    if (candidate_count > 0) {
      pathWithTab = pathWithTab.replace(':parentTab', 'Candidate Pipeline');
      pathWithTab = pathWithTab.replace(':subTab', 'null');
    } else {
      pathWithTab = pathWithTab.replace(':parentTab', 'Overview');
      pathWithTab = pathWithTab.replace(':subTab', 'Details');
    }
    navigate(pathWithTab);
  };
  const columns = useMemo(() => {
    return projectGridColumn.map(col => {
      if (col.field === 'job_number') {
        return {
          ...col,
          cellRenderer: params => {
            const a = document.createElement('a');
            a.textContent = params.value;
            a.style.textDecoration = 'underline';
            a.onclick = () => {
              onClickGridProject(params?.data);
            };
            return a;
          }
        };
      }
      return col;
    });
  }, [projectGridColumn, navigate]);

  const data = useMemo(() => projectGridData, [projectGridData, loading]);
  const isRowSelectable = useMemo(() => {
    return params => (params.data ? params.data.stage === 'Open' : false);
  }, []);
  const onGridReady = params => {
    columnApi = params.columnApi;
    params.columnApi.autoSizeColumns();
    // loadColumnStateFromLocalStorage("contactColumns", { columnApi, gridApi });
    setIsGridReady(true);
  };
  return (
    <Stack id='myGrid' className='ag-theme-alpine'>
      <AgGridReact
        columnDefs={columns}
        rowData={data}
        suppressRowClickSelection={true}
        isRowSelectable={isRowSelectable}
        paginationPageSize={10}
        rowSelection='multiple'
        enableRangeSelection={true}
        defaultColDef={{
          resizable: true,
          filter: true,
          sortable: true
        }}
        headerComponentFramework={CustomHeader}
        {...sx}
        columnMenu={true}
        onGridReady={onGridReady}
      />
      {isGridReady && (
        <ColumnFilter
          columnApi={columnApi}
          defaultColumns={defaultColumns}
          //showFilterCount={showFilterCount}
          //filterModel={filterModel}
        />
      )}
    </Stack>
  );
};

TableView.propTypes = {
  projectGridColumn: proptype.array,
  projectGridData: proptype.array,
  sx: proptype.object,
  loading: proptype.bool
};

export default TableView;
