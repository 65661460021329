import { Stack, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import useTranscribe from '../Transcribe';
import './index.scss';
function RichText(props) {
  const {
    onChange = () => {},
    defaultValue,
    isView = false,
    displayToolbar = true,
    isCard = false,
    forwardedRef = null,
    onFocus = () => {},
    style = {},
    customClassName = null,
    showRecordOption = true,
    editActivity = null
  } = props;
  const quill = forwardedRef ?? useRef(ReactQuill); // Need to access the ref from outside\
  const { TranscribeButton, SummariseButton, transcribedText } = useTranscribe({
    defaultTranscribedText: editActivity?.original_text ?? ''
  });
  useEffect(() => {
    handleProcedureContentChange(transcribedText);
  }, [transcribedText]);

  const handler = {
    undo: React.useCallback(() => {
      quill.current.editor.history.undo();
    }, []),
    redo: React.useCallback(() => {
      quill.current.editor.history.redo();
    }, [])
  };
  let toolbar;
  if (displayToolbar) {
    toolbar = {
      container: [[{ header: [1, 2, 3, false] }], ['bold', 'italic', 'underline', 'link'], [{ list: 'ordered' }, { list: 'bullet' }]],
      handlers: handler
    };
  } else {
    toolbar = false;
  }
  const modules = {
    toolbar: toolbar
  };

  const CustomRecordButton = () => {
    return (
      <>
        <Stack direction={'row'} borderBottom={'1px solid'} borderColor={'#e0e4ec'} gap={1} width={'100%'} paddingTop={'1px'} paddingBottom={'5px'} paddingRight={'5px'} justifyContent={'flex-end'}>
          <Tooltip title='Start Transcribe'>
            <>{TranscribeButton()}</>
          </Tooltip>
          <Tooltip title='Summarize'>
            <>{SummariseButton()}</>
          </Tooltip>
        </Stack>
      </>
    );
  };
  /* const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font"
  ]; */
  const [, setCode] = useState('hellllo');
  const [value, setValue] = useState();
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  const handleProcedureContentChange = content => {
    setValue(content);
    onChange(content);
    setCode(content);
  };

  return (
    <>
      <div id='CustomRichText' className={`rich-text-popup w-100 ${customClassName ? customClassName : ''}`}>
        {!isView ? (
          <div
            className='rich-text-popup-container react-quill-container'
            style={{
              position: 'relative'
            }}
          >
            {showRecordOption && (
              <Stack
                sx={{
                  position: 'absolute',
                  top: '60px',
                  right: '0',
                  zIndex: '1',
                  alignItems: 'center'
                }}
                width={'100%'}
              >
                <CustomRecordButton />
              </Stack>
            )}
            <ReactQuill style={{ ...style }} bounds={'.react-quill-container'} onChange={handleProcedureContentChange} modules={modules} value={value} ref={quill} onFocus={() => onFocus()} />
          </div>
        ) : (
          <div className={`rich-text-popup-container react-quill-container grid ${isCard ? 'view-card pl-3' : ''}`}>
            <ReactQuill style={{ ...style }} bounds={'.react-quill-container'} readOnly={true} modules={modules} value={value} ref={quill} onFocus={() => onFocus()} />
          </div>
        )}
      </div>
    </>
  );
}

RichText.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  displayToolbar: PropTypes.bool,
  isView: PropTypes.bool,
  isCard: PropTypes.bool,
  forwardedRef: PropTypes.object,
  onFocus: PropTypes.func,
  style: PropTypes.object,
  customClassName: PropTypes.string,
  showRecordOption: PropTypes.bool,
  editActivity: PropTypes.object
};
export default RichText;
