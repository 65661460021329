import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import propTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomButton from '../../../components/common/CustomButton';
import Loader from '../../../components/common/Loader';
import PaginationNavigationBar from '../../../components/PaginationNavigationBar/PaginationNavigationBar';
import { ProjectCandidateApi, ProjectCandidateDataApi, requestExtendedSearchApi, requestQuickSearchApi } from '../../../services/ApiService';
import { GET, PATCH } from '../../../services/constantService';
import useApi from '../../../utils/Hooks/useApiHook';
import ColumnFilter from '../../Commons/ColumnFilter';
import { defaultColumns } from '../../Contacts/utils';
import {
  ColorBasedscore,
  CustomizedDialogs,
  FilterOption,
  LocationScore,
  RejectPopup,
  RenderCheckbox,
  // CustomizedDialogs,
  RenderCompanyName,
  RenderLiffer,
  RenderName,
  RenderOffLimits,
  RenderRejectReasons,
  RenderSummary,
  RenderTags,
  TagFilter
} from './Comman';
import SuccessPopup from './Comman/SuccessPopup';
import { filterIndividualInitialValue, filterType } from './Constant';
import style from './productOne.module.scss';

let columnApi;
function ProductOne() {
  const [isGridReady, setIsGridReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tagValue, setTagValue] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(100);
  const [rowData, setRowData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filterValue, setFilterValue] = useState(filterIndividualInitialValue);
  const [type, setType] = useState('Individual');
  const [openFilter, setOpenFilter] = React.useState(false);
  const [quickLoadingPopup, setQuickLoadingPopup] = React.useState(false);
  const [isQuickLoading, setIsQuickLoading] = React.useState(false);
  const [autoClose, setAutoClose] = React.useState(false);
  const [showGlobalLoader, setShowGlobalLoader] = useState(true);
  const gridRef = useRef();
  const { id } = useParams();
  const snackbar = useSnackbar();
  const [sort, setSort] = useState({
    colId: 'exact_company_match',
    sort: 'desc'
  });
  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      filter: true,
      minHeight: 300,
      resizable: true,
      sortable: true
    };
  }, []);
  const resetSorting = useCallback(() => {
    gridRef.current.api.setSortModel([]);
  }, []);

  const onGridReady = params => {
    columnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    params.columnApi.autoSizeColumns();

    setIsGridReady(true);
    // refresh the grid for row data
    // params.api.refreshCells();
  };

  const { data, refetch } = useApi({
    queryKey: 'ProjectCandidateDataApi',
    queryFn: async () => {
      showGlobalLoader && setIsLoading(true);
      let filter = createQueryParams(filterValue);
      const getSort = gridRef.current.api.getSortModel()
        ? gridRef.current.api.getSortModel()[0]
        : {
            colId: 'exact_company_match',
            sort: 'desc'
          };
      console.log(getSort);
      const res = await ProjectCandidateDataApi(GET, '', '', 'all', 'json', {
        page: page,
        limit: limit,
        projectId: id,
        ...filter,
        tags: tagValue,
        category: 'productone',
        sortOn: (getSort?.colId === 'contact.name' ? 'name' : !sort?.colId ? 'exact_company_match' : sort?.colId === 'job_function_1' ? 'job_function' : getSort?.colId) || 'exact_company_match',

        sortType: getSort?.sort === 'asc' ? 'ASC NULLS LAST' : 'DESC NULLS LAST'
      }).then(response => {
        setTotalCount(response?.data?.count);
        setRowData(response?.data?.rows);
        setShowGlobalLoader(false);
        return response;
      });
      setIsLoading(false);
      setShowGlobalLoader(false);
      return res;
    }
  });
  const RequestExtendedSearch = async () => {
    setQuickLoadingPopup(true);
    setIsQuickLoading(true);
    setAutoClose(true);
    await requestExtendedSearchApi({
      projectId: id
    })
      .then(res => {
        if (res.status !== 200) {
          snackbar.enqueueSnackbar(res?.data?.message, {
            variant: 'error'
          });
          setQuickLoadingPopup(false);
          return;
        }

        snackbar.enqueueSnackbar('Successfully requested for Extended Search', {
          variant: 'success'
        });

        setIsQuickLoading(false);
      })
      .catch(e => {
        snackbar.enqueueSnackbar('Failed to Request for Extended Search', {
          variant: 'error'
        });

        setIsQuickLoading(false);
        console.error(e);
      });
  };
  const RequestQuickSearch = async () => {
    try {
      setQuickLoadingPopup(true);
      setIsQuickLoading(true);
      await requestQuickSearchApi({
        projectId: id
      })
        .then(response => {
          if (response.status !== 200) {
            snackbar.enqueueSnackbar(response?.data?.message, {
              variant: 'error'
            });
            setQuickLoadingPopup(false);
          }
        })
        .catch(e => {
          snackbar.enqueueSnackbar(e?.data?.message, {
            variant: 'error'
          });
          setQuickLoadingPopup(false);
          console.error(e);
        });
      setIsQuickLoading(false);
    } catch (e) {
      console.error(e);
      setIsQuickLoading(false);
      setQuickLoadingPopup(false);
      snackbar.enqueueSnackbar(e, {
        variant: 'error'
      });
    }
  };
  useEffect(() => {
    if (data) {
      updateGridData();
    }
  }, [data]);
  const debouncedRefetch = debounce(refetch, 2000);
  const refetchData = async () => {
    setShowGlobalLoader(true);
    setIsLoading(true);
    await refetch();
  };
  const RenderAction = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const snackbar = useSnackbar();
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const handleClick = event => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleAddToPipeline = async () => {
      if (props?.data?.stage !== null) return;
      setLoading(true);
      ProjectCandidateApi(PATCH, '/', { id: props?.value, stage: 'Target' })
        .then(async () => {
          await refetch();
          await updateRowData();
          snackbar.enqueueSnackbar('Added to Pipeline', { variant: 'success', preventDuplicate: true });
          setLoading(false);
        })
        .catch(() => {
          snackbar.enqueueSnackbar('Failed to add to Pipeline', {
            variant: 'error'
          });
          setLoading(false);
        });
    };
    const buttonLabel = props?.data?.stage === null ? 'Add to Pipeline' : props?.data?.stage === 'rejected' ? 'Rejected' : 'Added to Pipeline';
    // const buttonStyle = props?.data?.stage === 'rejected' ? style.red_bg : props?.data?.stage === null ? style.green_bg2 : style.green_bg;
    const handleReject = () => {
      snackbar.enqueueSnackbar('Rejecting', { variant: 'info' });
      const reason = value?.map(item => item.title);
      setLoading(true);
      ProjectCandidateApi(PATCH, '/', {
        id: props?.value,
        stage: 'rejected',
        reason_codes: reason
      })
        .then(async () => {
          snackbar.enqueueSnackbar('Rejected', {
            variant: 'success',
            preventDuplicate: true
          });
          await refetch();
          await updateRowData();
          setLoading(false);
        })
        .catch(err => {
          console.error(err);
          setLoading(false);
        });
    };
    return (
      <Stack>
        <Stack className={`${style.btn_drop_body}`}>
          <Button
            type='button'
            variant='contained'
            color={props?.data?.stage === null ? 'primary' : props?.data?.stage === 'rejected' ? 'error' : 'success'}
            style={{
              padding: '0px',
              paddingLeft: '10px'
            }}
            className={` ${style.btn_drop}`}
            // disabled={projectData.record_type !== 'Project'}
            startIcon={loading ? <CircularProgress size={20} color='inherit' /> : null}
            endIcon={
              <Button
                aria-describedby={anchorEl ? 'simple-popper' : undefined}
                type='button'
                variant='contained'
                onClick={handleClick}
                color={props?.data?.stage === null ? 'primary' : props?.data?.stage === 'rejected' ? 'error' : 'secondary'}
                className={` ${style.btn_drop_icon}`}
                // disabled={props?.data?.stage !== null}
              >
                {anchorEl ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}
              </Button>
            }
          >
            <Typography className={'fs-10'} onClick={handleAddToPipeline}>
              {buttonLabel}
            </Typography>
          </Button>
        </Stack>
        <BasePopup id={anchorEl ? 'simple-popper' : undefined} open={Boolean(anchorEl)} anchor={anchorEl} className={`${style.base_popup} ${style.box}`}>
          <RejectPopup open={open} setOpen={setOpen} value={value} setValue={setValue} onSave={handleReject} />
        </BasePopup>
      </Stack>
    );
  };

  function RenderBookmark(props) {
    const [loading, setLoading] = React.useState(false);
    const [newState, setNewState] = React.useState(props?.data?.is_bookmarked);
    const handleAddToBookmark = async () => {
      setLoading(true);
      ProjectCandidateApi(PATCH, '/', { id: props?.data?.id, is_bookmarked: !props?.data?.is_bookmarked })
        .then(async () => {
          setLoading(false);
          debouncedRefetch();
          setNewState(!newState);
          snackbar.enqueueSnackbar(`${props?.data?.is_bookmarked ? 'Removed from Bookmark' : 'Added to Bookmark'}`, {
            variant: 'success',
            preventDuplicate: true
          });
        })
        .catch(() => {
          snackbar.enqueueSnackbar('Failed to add to Bookmark', {
            variant: 'error',
            preventDuplicate: true
          });
          setLoading(false);
        });
    };
    return (
      <Stack>
        <Button
          type='button'
          variant='text'
          style={{
            padding: '0px'
          }}
          className={` ${style.btn_drop}`}
          // disabled={projectData.record_type !== 'Project'}

          onClick={handleAddToBookmark}
        >
          {loading ? <CircularProgress size={20} color='inherit' /> : newState || props?.data?.is_bookmarked ? <StarOutlinedIcon /> : <StarOutlineOutlinedIcon />}
        </Button>
      </Stack>
    );
  }

  const agCol = () => {
    return [
      {
        headerName: '',
        field: 'check_box',
        colId: 'check_box',
        maxWidth: 60,
        minWidth: 60,
        // headerComponent: 'HeaderCheckbox',
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: true,
        pinned: 'left',
        lockPinned: true,
        lockPosition: true,
        sortable: false,
        filter: false,
        headerCheckboxSelection: true
      },
      {
        minWidth: 150,
        field: 'is_bookmarked',
        headerName: 'Bookmark',
        pinned: 'left',
        lockPinned: true,
        cellRendererFramework: RenderBookmark,
        editable: false,
        sortable: true,
        filter: false
      },
      {
        minWidth: 250,
        field: 'contact.name',
        headerName: 'Name',
        cellRendererFramework: RenderName,
        editable: false,
        sortable: true
      },
      {
        minWidth: 250,
        field: 'contact.contact_company.name',
        headerName: 'Company Name',
        cellRendererFramework: RenderCompanyName,
        editable: false,
        sortable: true
      },
      {
        minWidth: 200,
        field: 'contact.current_job_title',
        headerName: 'Job Title',
        cellRendererFramework: RenderCompanyName,
        editable: false,
        sortable: true
      },
      {
        minWidth: 200,
        field: 'off_limits',
        headerName: 'Offlimits',
        cellRendererFramework: RenderOffLimits,
        editable: false,
        sortable: true
      },
      {
        minWidth: 150,
        field: 'lifer',
        headerName: 'Lifer',
        cellRendererFramework: RenderLiffer,
        editable: false,
        sortable: true
      },
      {
        minWidth: 150,
        field: 'exact_company_match',
        headerName: 'Exact Company Match',
        cellRendererFramework: RenderCheckbox,
        editable: false,
        sortable: true
      },
      {
        minWidth: 150,
        field: 'title',
        headerName: 'Title',
        cellRendererFramework: ColorBasedscore,
        editable: false,
        sortable: true
      },
      {
        minWidth: 150,
        field: 'total_experience',
        headerName: 'Total Experience',

        editable: false,
        sortable: false
      },
      {
        minWidth: 275,
        field: 'job_function',
        headerName: 'Job Functions',
        cellRendererFramework: ColorBasedscore,
        editable: false,
        sortable: true
      },
      {
        minWidth: 170,
        field: 'company',
        headerName: 'Company',
        cellRendererFramework: ColorBasedscore,
        editable: false,
        sortable: true
      },
      { minWidth: 150, field: 'source', headerName: 'Source', editable: false },
      {
        minWidth: 150,
        field: 'company_industry',
        headerName: 'Company industry',
        cellRendererFramework: ColorBasedscore,
        hide: false,
        editable: false,
        sortable: true
      },
      {
        minWidth: 150,
        field: 'company_employee_range',
        headerName: 'Company employee range',
        cellRendererFramework: ColorBasedscore,
        hide: false,
        editable: false,
        sortable: true
      },
      {
        minWidth: 150,
        field: 'current_tenure',
        headerName: 'Current tenure',
        cellRendererFramework: ColorBasedscore,
        hide: false,
        editable: false,
        sortable: true
      },
      {
        minWidth: 150,
        field: 'industry',
        headerName: 'Industry',
        cellRendererFramework: ColorBasedscore,
        hide: false,
        editable: false,
        sortable: true
      },
      {
        minWidth: 150,
        field: 'company_revenue_range',
        headerName: 'Company revenue range',
        cellRendererFramework: ColorBasedscore,
        hide: false,
        editable: false,
        sortable: true
      },
      {
        minWidth: 150,
        field: 'career_experience',
        headerName: 'Career experience',
        cellRendererFramework: ColorBasedscore,
        hide: false,
        editable: false,
        sortable: true
      },
      {
        minWidth: 150,
        field: 'job_movement',
        headerName: 'Job movement',
        cellRendererFramework: ColorBasedscore,
        hide: false,
        editable: false,
        sortable: true
      },
      {
        minWidth: 150,
        field: 'proximity_location',
        headerName: 'Proximity location',
        cellRendererFramework: LocationScore,
        hide: false,
        editable: false,
        sortable: true
      },
      {
        minWidth: 150,
        field: 'relevancy',
        headerName: 'Relevancy',
        cellRendererFramework: ColorBasedscore,
        hide: false,
        editable: false,
        sortable: true
      },
      {
        minWidth: 250,
        field: 'reason_codes',
        headerName: 'Reject Reasons',
        cellRendererFramework: RenderRejectReasons,
        editable: false,
        sortable: true
      },
      {
        minWidth: 300,
        field: 'summary_html',
        headerName: 'Summary',
        cellRendererFramework: RenderSummary,
        editable: false,
        sortable: true
      },
      {
        minWidth: 250,
        field: 'tags',
        headerName: 'Tags',
        cellRendererFramework: RenderTags,
        editable: false,
        sortable: false
      },
      {
        minWidth: 210,
        pinned: 'right',
        headerName: 'Actions',
        lockPinned: true,
        cellRendererFramework: RenderAction,
        field: 'id',
        editable: false,
        sortable: false,
        filter: false
      }
    ];
  };

  const components = useMemo(() => {
    return {
      renderName: RenderName,
      renderCompanyName: RenderCompanyName,
      renderOffLimits: RenderOffLimits,
      renderLiffer: RenderLiffer,
      colorBasedscore: ColorBasedscore,
      renderAction: RenderAction,
      renderRejectReasons: RenderRejectReasons,
      renderSummary: RenderSummary,
      renderTags: RenderTags,
      locationScore: LocationScore,
      renderCheckbox: RenderCheckbox,
      renderBookmark: RenderBookmark
    };
  }, []);
  const updateGridData = () => {
    gridRef.current.api.setRowData(data?.data?.rows || []);
  };
  const createQueryParams = filters => {
    const params = {};
    filters.forEach(filter => {
      if (filter.value !== null || filter.value !== '' || filter.value !== 0 || filter.value !== undefined || isNaN(filter.value)) {
        if (filter.id !== 'tags') {
          if (filter.type === 'gte' || filter.type === 'lte') {
            params[`filters[${filter.id}][${filter.type}]`] = parseInt(filter.value) / 100;
          } else {
            params[`filters[${filter.id}][${filter.type}]`] = parseInt(filter.value) / 100;
          }
        }
      }
    });

    filters.forEach(filter => {
      if (filter.value === null || filter.value === '' || filter.value === 0) {
        params[`filters[${filter.id}][${filter.type}]`] = undefined;
      }
    });

    return params;
  };

  const setInitialValue = async () => {
    setTagValue([]);
    setFilterValue(prev => {
      return prev.map(item => {
        if (filterValue[item.id]) {
          return {
            ...item,
            value: filterValue[item.id],
            type: filterType[0].id
          };
        }
        if (item.id === 'tags') {
          return { ...item, value: [] };
        } else {
          return { ...item, value: null, type: filterType[0].id };
        }
      });
    });
  };
  const handleSave = async () => {
    await refetchData();
    setOpenFilter(false);
  };
  RenderAction.propTypes = {
    data: propTypes.object,
    value: propTypes.number
  };
  RenderBookmark.propTypes = {
    data: propTypes.object,
    value: propTypes.number
  };
  const updateRowData = async () => {
    if (gridRef.current) {
      gridRef.current.api.refreshCells({ force: true });
    }
  };

  useEffect(() => {
    refetchData();
  }, [page, sort]);
  const loadNextPage = async () => {
    if (page === Math.ceil(totalCount / limit)) return;
    setPage(page + 1);

    // gridRef.current.api.paginationGoToNextPage();
  };
  const loadPrevPage = async () => {
    if (page === 1) return;
    setPage(page - 1);

    // gridRef.current.api.paginationGoToPreviousPage();
  };
  return (
    <div className={`${style.white_bg}`}>
      <Stack className={style.product_one_body}>
        <Stack direction='row' gap={3}>
          <Button
            variant='outlined'
            sx={{
              textTransform: 'none'
            }}
            size='small'
            onClick={RequestQuickSearch}
          >
            Quick Search
          </Button>

          <CustomButton buttonText={'Extended Search'} onClick={RequestExtendedSearch} variant={'contained'} />
        </Stack>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <TagFilter tagValue={tagValue} setTagValue={setTagValue} refetch={refetchData} />
          <CustomizedDialogs />
          <Button variant='text' className={style.btn_prop} onClick={() => setOpenFilter(true)}>
            Advance Filters
          </Button>
          <FilterOption setFilterValue={setFilterValue} filterValue={filterValue} onSave={handleSave} open={openFilter} setOpen={setOpenFilter} type={type} setType={setType} reset={setInitialValue} />
          <Button
            variant='text'
            className={style.btn_prop}
            onClick={() => {
              setInitialValue();
              refetchData();
            }}
          >
            Reset Filters
          </Button>
          <Button variant='text' className={style.btn_prop} onClick={resetSorting}>
            Reset Sort
          </Button>
          <Typography variant='caption'>Total Count : {totalCount}</Typography>
        </Stack>
      </Stack>
      <div id='myGrid' className={`${style.table_body} ag-theme-alpine mt-2`}>
        {isLoading && <Loader color={'primary'} className='loader-class' show={true} />}
        <AgGridReact
          ref={gridRef}
          autoSizeStrategy={'fitColumns'}
          onSortChanged={sortModel => {
            if (page !== 1) {
              setPage(1);
            }

            setSort(sortModel.api.getSortModel()[0]);
            // refetchData();
          }}
          onGridReady={onGridReady}
          rowStyle={{ justifyContent: 'start' }}
          columnDefs={agCol()}
          rowData={rowData || []}
          rowSelection='multiple'
          defaultColDef={defaultColDef}
          components={components}
          rowHeight={70}
          suppressScrollOnNewData={true}
          disableStaticMarkup={true}
          // pagination={true}
        />
        {isGridReady && <ColumnFilter columnApi={columnApi} defaultColumns={defaultColumns} />}
      </div>
      <PaginationNavigationBar page={page} nextPageRequest={loadNextPage} prevPageRequest={loadPrevPage} totalCount={totalCount} pageCount={Math.ceil(totalCount / limit) + 1} limitPerPage={limit} />
      {quickLoadingPopup && (
        <SuccessPopup
          open={quickLoadingPopup}
          setOpen={setQuickLoadingPopup}
          autoClose={autoClose}
          setAutoClose={setAutoClose}
          isLoading={isQuickLoading}
          btn={true}
          onViewCandidate={() => {
            setQuickLoadingPopup(false);
            refetchData();
          }}
        />
      )}
    </div>
  );
}

ProductOne.propTypes = {
  projectData: propTypes.object.isRequired,
  sort: propTypes.object,
  setSort: propTypes.func
};

export default ProductOne;
