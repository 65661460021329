//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { forwardRef, useEffect, useRef, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import CloseIcon from '@mui/icons-material/Close';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import AddIcon from '@mui/icons-material/Add';
import { enqueueSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import CustomPhoneInput, { isValidPhoneNumber } from '../../../../components/common/CustomPhoneInput';
import '../../../../Containers/Commons/CheckboxStyle/index.scss';
import '../../../../Containers/Commons/InputTextFieldStyle/index.scss';
import '../../../../Containers/Commons/SwitchCss/index.scss';
import { getAccessToken } from '../../../../services/cognitoService';
import { DUPLICATION_FIELDS, ERROR, IGN_API } from '../../../../services/constantService';
import { validateMessage } from '../../../../services/MessageService';
import { checkDuplicateOnBlur, splitLastIfMultiple } from '../../../../utils/common';
import useContactFormHook from '../../../../utils/Hooks/useContactFormHook';
import { useCustomMessageHook } from '../../../../utils/Hooks/useCustomMessageHook';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
import CustomButton from '../../../common/CustomButton';
import CustomConfirmationPopup from '../../../common/CustomConfirmationPopup';
import DuplicateContactNotifier from '../../../common/CustomPopup/DuplicateContactNotifier';
import CustomTable from '../../../common/CustomTable';
import Footer from '../../../common/Footer';
import EmailTypeSelection from '../../../common/FunctionalComponents/EmailTypeSelection';
import PhoneTypeSelection from '../../../common/FunctionalComponents/PhoneTypeSelection';
import Loader from '../../../common/Loader';
import ProfileLabel from '../../../common/ProfileLabel';
import CustomInputField from '../../../common/StyledComponents/CustomInputField';
import { checkDuplicateEmails, validateEmailV1 as validateEmail } from '../../../MessageTemplatePopup/utils';
import { CreateSchema, getCommunicationPayload } from '../../utils';
import Address from '../ProfileDetails/AddressDetails';
import './index.scss';

const Communications = forwardRef((props, ref) => {
  const { handleSaveTabData, id, onCancel } = props;
  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_BASIC_INFO,
    TRANS_KEYS.CONTACTS_PHONE_TYPE,
    TRANS_KEYS.CONTACTS_PHONE,
    TRANS_KEYS.CONTACTS_IS_PRIMARY,
    TRANS_KEYS.CONTACTS_ADD_PHONE,
    TRANS_KEYS.CONTACTS_EMAIL_TYPE,
    TRANS_KEYS.CONTACTS_EMAIL,
    TRANS_KEYS.CONTACTS_ADD_EMAIL,
    TRANS_KEYS.CONTACTS_ADDRESS
  ]);
  const { translateMessage } = useCustomMessageHook();

  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  // const [, /* checkboxData */ setCheckboxData] = useState(false);
  const [toggleAddress, setSwitchAddress] = useState(false);
  const [srcUrl, setSrcUrl] = useState(null);
  // const [selectedOptionForMail, setSelectedOptionForMail] = useState(null);
  const { path } = useParams();
  const [phoneList, setPhoneList] = useState([]);
  const [addressDetails, setAddressDetails] = useState([]);
  const [eMailList, setEmailList] = useState([]);
  // const [relationList, setRelationList] = useState([]);
  // const [primaryPhone, setIsPrimaryPhone] = useState(null);
  const [addressCounts, addAddressCount] = useState([]);
  const [checkboxData, setCheckboxData] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [addressPayload, setAddressPayload] = useState([]);
  const [isConfirmationPopup, setIsConfirmationPopup] = useState(false);
  const [duplicateRecords, setDuplicateRecords] = useState(null);
  const [duplicationField, setDuplicationField] = useState('');
  const isValidated = async contact => {
    const isPhoneValid = contact.contact_phones.some(elem => !elem.phone_number);
    const isPhoneTypeValid = contact.contact_phones.some(elem => !elem.phone_type);

    // if ((isPhoneTypeValid && contact.contact_phones.length < 3) && (isPhoneValid && contact.contact_phones.length < 3)) {
    //   const message = translateMessage('Required', ...splitLastIfMultiple(['Phone type', 'Phone']));
    //   enqueueSnackbar(message, { variant: ERROR });
    //   return;
    // }
    if (isPhoneTypeValid) {
      const message = translateMessage('Required', false, 'Phone type');

      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    if (isPhoneValid) {
      const message = translateMessage('Required', false, 'Phone');
      enqueueSnackbar(message, { variant: ERROR });
      return false;
    }

    if (contact?.contact_emails.length > 0) {
      let isValid = true;
      const isEmailValid = contact.contact_emails.some(elem => !elem.email);
      const isEmailTypeValid = contact.contact_emails.some(elem => !elem.email_type);

      // const isEmailValid = contact.contact_emails.some(elem => !elem.email)
      if (isEmailTypeValid && contact.contact_emails.length < 3 && isEmailValid && contact.contact_emails.length < 3) {
        const message = translateMessage('Required', ...splitLastIfMultiple(['Email type', 'Email']));
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
      if (isEmailTypeValid && contact.contact_emails.length < 3) {
        const message = translateMessage('Required', false, 'Email type');

        enqueueSnackbar(message, { variant: ERROR });
        return;
      }

      if (isEmailValid && contact.contact_emails.length === 1) {
        const message = translateMessage('Required', false, 'Email');
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      } else {
        contact?.contact_emails.forEach(ele => {
          const validateEmailSyntax = validateEmail(ele.email);
          if (!validateEmailSyntax) {
            isValid = false;
          }
        });
        if (!isValid) {
          const message = translateMessage('ValidFiled', false, 'Email');

          enqueueSnackbar(message, { variant: ERROR });
          return;
        } else {
          if (contact.contact_emails.length > 1) {
            const isDuplicateEmail = checkDuplicateEmails(contact.contact_emails);
            if (isDuplicateEmail) {
              const message = validateMessage('different email ', 'enter');
              enqueueSnackbar(message, { variant: ERROR });
              return;
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
      }
    }
  };
  const { handleSave, register, setValue, currentValues, loading, setLoading, isDirty } = useContactFormHook({
    id,
    path,
    getPayload: getCommunicationPayload,
    handleSaveTabData,
    ref,
    formSchema: CreateSchema,
    isValidated,
    defaultFormValue: { contact_emails: [], contact_phones: [] },
    tabName: 'COMMUNICATION_TAB'
  });

  const [
    ,/* addressCounts */
    /* addAddressCount */
  ] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([{ phone_type: '', phone_number: '', is_primary: true, is_valid: true, is_type_selected: true }]);
  const [emails, setEmails] = useState([{ email_type: '', email: '', is_primary: true, is_valid: true, is_type_selected: true }]);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const selectedIndexRef = useRef();
  selectedIndexRef.current = selectedIndex;
  // const classes = useStyles()
  const removeAddress = index => {
    let details = [...addressDetails];
    details.splice(index, 1);
    setAddressDetails(details);
    addAddressCount(details);
    setCheckboxData(false);
  };

  useEffect(() => {
    register('contact_emails');
    register('contact_phones');
  }, [register]);

  useEffect(() => {
    const accessToken = async () => {
      let token = await getAccessToken();
      setSrcUrl(`${IGN_API.contact}/${currentValues?.id}/image?token=${token}&time=${Date.now()}`);
    };

    if (currentValues?.image_id) accessToken();
  }, [currentValues]);
  //Setting Payload for Address
  useEffect(() => {
    if (addressDetails.length === 1) {
      addressDetails[0]['is_primary'] = true;
    }
  }, [addressDetails]);
  useEffect(() => {
    const addressPayload = addressDetails.map(elem => {
      return {
        ...elem,
        country_id: elem?.contact_country?.id ? elem?.contact_country?.id : null,
        state_id: elem?.contact_state?.id ? elem?.contact_state?.id : null,
        address_type: elem?.address_type?.field_value ? elem?.address_type?.field_value : elem?.address_type ? elem.address_type : null
      };
    });
    addressPayload.forEach(elem => {
      delete elem.contact_state;
      delete elem.contact_country;
    });
    setAddressPayload(addressPayload);
  }, [addressDetails]);
  useEffect(() => {
    setValue('contact_address', addressPayload, { shouldDirty: true });
  }, [addressPayload]);

  useEffect(() => {
    if (currentValues?.contact_emails && currentValues?.contact_emails.length) {
      setEmails(currentValues?.contact_emails?.map(email => ({ ...email, is_valid: validateEmail(email?.email || ''), is_type_selected: email?.email_type !== '' })));
    }
    if (currentValues?.contact_phones && currentValues?.contact_phones.length) {
      setPhoneNumbers(currentValues?.contact_phones?.map(phone => ({ ...phone, is_valid: isValidPhoneNumber(phone?.phone_number || ''), is_type_selected: phone?.phone_type !== '' })));
    }
  }, [currentValues]);

  useEffect(() => {
    if (currentValues?.contact_address && currentValues?.contact_address.length) {
      setSwitchAddress(true);
      setAddressDetails(
        currentValues?.contact_address.map(ele => {
          let address = {
            ...ele,
            address_lines: ele.address_lines,
            address_type: ele.ign_translate_value,
            checked: ele.is_primary ? ele.is_primary : false,
            city: ele.city,
            contact_country: ele.contact_country,
            state: ele.contact_state,
            zip_code: ele.zip_code,
            metropolitan_area: ele.metropolitan_area,
            errors: {
              address_type: false,
              contact_country: false
            }
            //id: ele.id
          };
          return address;
        })
      );
      currentValues?.contact_address?.forEach((elem, index) => {
        if (elem?.is_primary) {
          setSelectedOption(index + 1);
        }
      });
    }
  }, [currentValues]);

  const handleChanges = event => {
    setSwitchAddress(event.target.checked);
    setCheckboxData(false);
    if (!event.target.checked) {
      addAddressCount([]);
    }
    if (event.target.checked && addressCounts.length < 2 && addressDetails.length !== 1 && event.target.checked) {
      if (!addressDetails.find(ele => ele.id)) {
        addAddressForm();
      }
    }
  };

  const validateAddress = (addressDetails, index, name) => {
    const updatedAddressDetails = [...addressDetails];
    const validations = {
      address_type: true,
      contact_country: true
    };

    if (validations[name]) {
      updatedAddressDetails[index] = {
        ...updatedAddressDetails[index],
        errors: {
          ...updatedAddressDetails[index].errors,
          [name]: !updatedAddressDetails[index][name]
        }
      };
    }

    return updatedAddressDetails;
  };

  const hadlechangeForCheckbox = (e, index) => {
    // setIsMakeAddressPrimary(e.target.checked)
    const hasPrimaryAddress = addressDetails.some(address => address.checked);

    const newAddressDetails = addressDetails.map((address, i) => {
      if (i === index) {
        if (!hasPrimaryAddress && i === 0) {
          return { ...address, checked: true };
        }
        return { ...address, checked: true };
      } else {
        return { ...address, checked: false };
      }
    });
    setAddressDetails(newAddressDetails);
    // handleChangeAddressData(index, "checked", e.target.checked)
  };

  const handleChangeAddressData = (index, name, data) => {
    if (name === 'is_primary') {
      hadlechangeForCheckbox('', index);
      return;
    }
    let newList = [...addressDetails];
    newList[index] = { ...newList[index], [name]: data };
    const updatedList = [...newList];
    const validatedAddress = validateAddress(updatedList, index, name);

    setAddressDetails(validatedAddress);
  };

  const addAddressForm = () => {
    if (addressCounts.length === 1) setCheckboxData(true);
    if (addressCounts.length < 2 && addressDetails.length < 2) {
      const newList = [
        ...addressDetails,
        {
          contact_country: null,
          contact_state: '',
          zip_code: '',
          city: '',
          metropolitan_area: '',
          address_lines: '',
          address_type: null,
          is_primary: addressDetails.length === 0,
          errors: {
            address_type: false,
            contact_country: false
          }
        }
      ];
      setAddressDetails(newList);
      addAddressCount(newList);
    }
  };

  const validatePhoneNumberAndType = (field, newValue = '', phoneNumber) => {
    let is_valid = phoneNumber.is_valid;
    let is_type_selected = phoneNumber.is_type_selected;

    if (field === 'phone_number') {
      is_valid = isValidPhoneNumber(newValue);
    }
    if (field === 'phone_type') {
      is_type_selected = newValue !== '';
    }

    return {
      is_valid,
      is_type_selected
    };
  };

  const handleChangePhoneNumber = (index, field, value) => {
    let updatedPhoneNumbers = phoneNumbers.map((phoneNumber, i) => {
      if (i === index) {
        const validateFields = validatePhoneNumberAndType(field, value, phoneNumber);
        if (field === 'is_primary') {
          return {
            ...phoneNumber,
            [field]: true,
            ...validateFields
          };
        }
        return {
          ...phoneNumber,
          [field]: value,
          ign_translate_value: value,
          ...validateFields
        };
      }
      if (i !== index && field === 'is_primary') {
        return {
          ...phoneNumber,
          is_primary: false
        };
      }
      return { ...phoneNumber };
    });

    setPhoneNumbers(updatedPhoneNumbers);
  };

  const validateEmailAndType = (field, newValue = '', email) => {
    let is_valid = email.is_valid;
    let is_type_selected = email.is_type_selected;

    if (field === 'email') {
      is_valid = validateEmail(newValue);
    }
    if (field === 'email_type') {
      is_type_selected = newValue !== '';
    }

    return {
      is_valid,
      is_type_selected
    };
  };

  const handleChangeEmail = (index, field, value) => {
    const updatedEmail = emails.map((email, i) => {
      if (i === index) {
        const validateFields = validateEmailAndType(field, value, email);
        if (field === 'is_primary') {
          return { ...email, is_primary: true, ...validateFields };
        }
        return { ...email, [field]: value, ign_translate_value: value, ...validateFields };
      }
      if (i !== index && field === 'is_primary') {
        return { ...email, is_primary: false };
      }

      return { ...email };
    });

    setEmails(updatedEmail);
  };

  const handleClose = () => {
    setIsConfirmationPopup(false);
  };

  const onBlurEmail = async e => {
    if (e.target.value && e.relatedTarget?.tagName !== 'BUTTON') {
      const emailData = { contact_emails: [{ email: e.target.value || '' }] };
      const { isDuplicate, duplicateRecords } = await checkDuplicateOnBlur(emailData, setLoading, enqueueSnackbar);
      setDuplicationField(DUPLICATION_FIELDS.EMAIL);
      setDuplicateRecords(duplicateRecords);
      setIsConfirmationPopup(isDuplicate);
    }
  };
  const onBlurPhoneNumber = async e => {
    if (e.target.value && e.relatedTarget?.tagName !== 'BUTTON') {
      const phoneNumberData = {
        contact_phones: [{ phone_number: e.target.value }]
      };
      const { isDuplicate, duplicateRecords } = await checkDuplicateOnBlur(phoneNumberData, setLoading, enqueueSnackbar);
      setDuplicationField(DUPLICATION_FIELDS.PHONE_NUMBER);
      setDuplicateRecords(duplicateRecords);
      setIsConfirmationPopup(isDuplicate);
    }
  };
  // const handleRelationChange = (index, field, value) => {
  //   const updatedRelation = relationData.map((relation, i) => {
  //     if (i === index) {
  //       return { ...relation, [field]: value };
  //     }
  //     return relation;
  //   });
  //   setRelationData(updatedRelation);
  // };
  const handleAddPhoneNumber = () => {
    setPhoneNumbers([...phoneNumbers, { phone_type: '', phone_number: '', is_primary: phoneNumbers.length === 0, is_valid: true, is_type_selected: true }]);
  };
  const handleRemovePhoneNumber = index => {
    const updatedPhoneNumbers = [...phoneNumbers];
    updatedPhoneNumbers.splice(index, 1);
    if (updatedPhoneNumbers?.length === 1) {
      const primaryPhone = updatedPhoneNumbers.map(number => {
        return { ...number, is_primary: true };
      });
      setPhoneNumbers(primaryPhone);
    } else {
      setPhoneNumbers(updatedPhoneNumbers);
    }
  };
  const handleAddEmail = () => {
    setEmails([...emails, { email_type: '', email: '', is_primary: emails.length === 0, is_valid: true, is_type_selected: true }]);
  };

  const handleRemoveEmail = index => {
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1);
    if (updatedEmails?.length === 1) {
      const primaryEmail = updatedEmails.map(email => {
        return { ...email, is_primary: true };
      });
      setEmails(primaryEmail);
    } else {
      setEmails(updatedEmails);
    }
  };
  const onConfirm = () => {
    let updatedEmails = [...emails];
    updatedEmails.splice(selectedIndexRef.current, 1);
    if (updatedEmails.length === 1 && !updatedEmails[0].is_primary) {
      updatedEmails = updatedEmails.map(elem => ({
        ...elem,
        is_primary: true
      }));
    }
    setEmails(updatedEmails);
  };
  const handleRemoveEmailConfirmation = index => {
    let updatedEmails = [...emails];
    if (updatedEmails[index].is_primary) {
      setSelectedIndex(index);
      setOpenConfirmationPopup(true);
    } else {
      handleRemoveEmail(index);
    }
  };
  const closeConfirmationPopup = () => {
    setOpenConfirmationPopup(false);
  };
  useEffect(() => {
    setValue(
      'contact_emails',
      emails.map(elem => {
        return {
          ...elem,
          email_type: elem?.email_type?.field_value ? elem?.email_type?.field_value : elem?.email_type
          // email: elem?.email,
          // is_primary: elem?.is_primary,
        };
      }),
      { shouldDirty: true }
    );
  }, [emails]);
  useEffect(() => {
    setValue(
      'contact_phones',
      phoneNumbers.map(elem => {
        return {
          ...elem,
          phone_type: elem?.phone_type?.field_value ? elem?.phone_type?.field_value : elem?.phone_type
          // phone_number: elem?.phone_number,
          // is_primary: elem?.is_primary
        };
      }),
      { shouldDirty: true }
    );
  }, [phoneNumbers]);

  const togglePrimary = (index, isChecked) => {
    setEmails(prevEmails => {
      const newEmails = [...prevEmails];

      newEmails[index].is_primary = isChecked;

      return newEmails;
    });
  };
  const handleTogglePrimary = (index, isChecked) => {
    if (!isChecked && emails.length === 1) {
      return;
    }
    if (isChecked) {
      // Uncheck all other checkboxes
      emails.forEach((field, i) => {
        if (i !== index) {
          field.is_primary = false;
        }
      });
    }

    togglePrimary(index, isChecked);
  };

  const cancelData = () => {
    onCancel(isDirty);
  };

  const validatePhoneNumbersFormData = phone => {
    return isValidPhoneNumber(phone.phone_number || '') && phone.phone_type !== '';
  };

  const validateEmailsFormData = email => {
    return validateEmail(email.email || '') && email.email_type !== '';
  };

  const validateAddressFormData = address => {
    return address?.contact_country && address?.address_type ? true : false;
  };

  const getPhoneNumberDisabledState = () => {
    let disabled = false;
    phoneNumbers?.forEach(phone => {
      const validate = validatePhoneNumbersFormData(phone);
      if (validate === false) {
        disabled = true;
      }
    });
    return disabled;
  };

  const isPhoneDisabled = getPhoneNumberDisabledState();

  const getEmailDisabledState = () => {
    let disabled = false;
    emails?.forEach(email => {
      const validate = validateEmailsFormData(email);

      if (validate === false) {
        disabled = true;
      }
    });
    return disabled;
  };

  const isEmailDisbled = getEmailDisabledState();

  const getAddressDisabledState = () => {
    let disabled = false;
    addressDetails?.forEach(address => {
      const validate = validateAddressFormData(address);

      if (validate === false) {
        disabled = true;
      }
    });
    return disabled;
  };

  const isAddressDisabled = getAddressDisabledState();

  const validateAllFields = () => {
    const updatedPhoneNumbers = phoneNumbers.map(phone => {
      return { ...phone, is_valid: isValidPhoneNumber(phone.phone_number || ''), is_type_selected: phone.phone_type !== '' };
    });
    setPhoneNumbers(updatedPhoneNumbers);

    if (isPhoneDisabled) {
      enqueueSnackbar('Please fill in the phone details correctly.', { variant: ERROR });
      return false;
    }
    const updatedemails = emails.map(email => {
      return { ...email, is_valid: validateEmail(email.email || ''), is_type_selected: email.email_type !== '' };
    });
    setEmails(updatedemails);

    if (isEmailDisbled) {
      enqueueSnackbar('Please fill in the email details correctly.', { variant: ERROR });
      return false;
    }
    if (toggleAddress) {
      const updatedAddressDetails = addressDetails.map(address => {
        return {
          ...address,
          errors: {
            address_type: !address.address_type,
            contact_country: !address.contact_country
          }
        };
      });
      setAddressDetails(updatedAddressDetails);
      if (isAddressDisabled) {
        enqueueSnackbar('Please fill in the address details correctly.', { variant: ERROR });
        return false;
      }
    }
    return true;
  };

  const handleOnSubmit = () => {
    if (validateAllFields()) {
      handleSave();
    }
  };
  return (
    <>
      <Loader show={loading} />
      {currentValues ? (
        <Box id='container-communication'>
          <Box className='form-section flex flex-column '>
            <Box>
              <Footer onSubmit={handleOnSubmit} onClose={cancelData}></Footer>
            </Box>
            <Box className='d-flex justify-content-between align-items-center'>
              <Box className='text-label'>{t(`${TRANS_KEYS.CONTACTS_BASIC_INFO}:key`)}</Box>
              <Box className='pt-1'>
                <ProfileLabel value={currentValues} srcUrl={srcUrl} />
              </Box>
            </Box>
            <Box className='communication-section p-11'>
              <Box className='head-container'>
                <Box className='d-flex sub-head-content'>
                  <Box className='field-label-input communication-content'>
                    <CustomTable
                      headerData={[t(`${TRANS_KEYS.CONTACTS_PHONE_TYPE}:key`), t(`${TRANS_KEYS.CONTACTS_PHONE}:key`), t(`${TRANS_KEYS.CONTACTS_IS_PRIMARY}:key`)]}
                      title={t(`${TRANS_KEYS.CONTACTS_ADD_PHONE}:key`)}
                      handleAnotherRow={handleAddPhoneNumber}
                      rows={phoneNumbers}
                      dataList={phoneList}
                    >
                      {phoneNumbers.map((phoneNumber, index) => {
                        return (
                          <TableRow key={phoneNumber?.id}>
                            <TableCell className='input-field-data p-2'>
                              <PhoneTypeSelection
                                // value={phoneNumber?.ign_translate_value?.short_desc}
                                value={phoneNumber?.ign_translate_value?.short_desc ? phoneNumber?.ign_translate_value?.short_desc : phoneNumber.phone_type || ''}
                                onChange={(e, value) => handleChangePhoneNumber(index, 'phone_type', value || '')}
                                selectedList={phoneNumbers}
                                setPhoneList={setPhoneList}
                                isApplyWidth={true}
                              />
                              {!phoneNumber.is_type_selected && <Typography className='invalid-message'>Please select type</Typography>}
                            </TableCell>
                            <TableCell className='input-field-data  p-2'>
                              <CustomPhoneInput
                                id='outlined-basic'
                                variant='outlined'
                                size='small'
                                label={t(`${TRANS_KEYS.CONTACTS_PHONE}:key`)}
                                value={phoneNumber.phone_number || ''}
                                onChange={e => handleChangePhoneNumber(index, 'phone_number', e)}
                                onBlur={e => onBlurPhoneNumber(e)}
                              />
                              <Typography className='invalid-message' style={{ marginLeft: '2rem' }}>
                                {!phoneNumber.is_valid && 'Invalid Phone Number'}
                              </Typography>
                            </TableCell>
                            <TableCell className='primary-checkbox p-2 justify-content-center'>
                              <Checkbox
                                className='checkboxStyle'
                                checked={phoneNumber.is_primary}
                                onChange={e => {
                                  handleChangePhoneNumber(index, 'is_primary', e.target.checked);
                                  // setIsPrimaryPhone(index);
                                }}
                                size='small'
                                name={`is_primary_${index}`}
                              />
                            </TableCell>
                            {phoneNumbers.length > 1 && (
                              <TableCell className='remove-another-button p-2 ' mt={2}>
                                <CloseIcon onClick={() => handleRemovePhoneNumber(index)} className='table-close-icon' />
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    </CustomTable>
                  </Box>
                </Box>
              </Box>
              <Box className='head-container'>
                <Box className='d-flex sub-head-content'>
                  <Box className='field-label-input communication-content'>
                    <CustomTable
                      headerData={[t(`${TRANS_KEYS.CONTACTS_EMAIL_TYPE}:key`), t(`${TRANS_KEYS.CONTACTS_EMAIL}:key`), t(`${TRANS_KEYS.CONTACTS_IS_PRIMARY}:key`)]}
                      title={t(`${TRANS_KEYS.CONTACTS_ADD_EMAIL}:key`)}
                      handleAnotherRow={handleAddEmail}
                      rows={emails}
                      dataList={eMailList}
                    >
                      {emails.map((email, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell className='input-field-data p-2'>
                              <EmailTypeSelection
                                value={email?.ign_translate_value?.short_desc ? email?.ign_translate_value?.short_desc : email.email_type || ''}
                                onChange={(e, value) => {
                                  handleChangeEmail(index, 'email_type', value || '');
                                }}
                                selectedList={emails}
                                setEmailList={setEmailList}
                                isApplyWidth={true}
                              />
                              {!email.is_type_selected && <Typography className='invalid-message'>Select type</Typography>}
                            </TableCell>
                            <TableCell className='input-field-data p-2'>
                              <CustomInputField value={email?.email || ''} onChange={e => handleChangeEmail(index, 'email', e.target.value)} onBlur={e => onBlurEmail(e)} />
                              {!email.is_valid && <Typography className='invalid-message'>Invalid Email</Typography>}
                            </TableCell>
                            <TableCell className='primary-checkbox p-2 justify-content-center'>
                              <Checkbox className='checkboxStyle' checked={email.is_primary} onChange={e => handleTogglePrimary(index, e.target.checked)} size='small' name={`is_primary_${index}`} />
                            </TableCell>
                            {emails.length > 1 && (
                              <TableCell className='remove-another-button p-2' mt={2}>
                                <CloseIcon onClick={() => handleRemoveEmailConfirmation(index)} className='table-close-icon' />
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    </CustomTable>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={`my-cards d-flex flex-wrap ${toggleAddress ? 'flip' : ''}`}>
              <Box className={`form-section d-flex flex-column ${toggleAddress ? 'flip2' : ''}`}>
                <Box className='section-content-data p-11 d-flex flex-column flex-wrap content-gap'>
                  <Box className='d-flex flex-row align-items-center'>
                    <Box className='header-font text-label fs-16'>{t(`${TRANS_KEYS.CONTACTS_ADDRESS}:key`)}</Box>
                    <Box className='pt-2 pl-2'>
                      <FormControlLabel control={<Switch className='SwitchStyle' checked={toggleAddress} {...label} onChange={handleChanges}></Switch>} />
                    </Box>
                  </Box>

                  {toggleAddress &&
                    addressDetails?.map((element, index) => {
                      return (
                        <Address
                          key={index}
                          addressDetails={addressDetails}
                          index={index}
                          data={addressCounts}
                          element={element}
                          checkboxData={checkboxData}
                          selectedOption={selectedOption}
                          removeAddress={removeAddress}
                          setValue={setValue}
                          handleChange={handleChangeAddressData}
                          isAddContact={true}
                        />
                      );
                    })}
                  {addressDetails.length < 2 && addressDetails.length > 0 && toggleAddress === true ? (
                    <Box className='pt-0'>
                      <CustomButton type={'tertiary'} onClick={addAddressForm} size={'medium'} buttonText={'Add Another'} iconLeft={<AddIcon sx={{ width: 14 }} />} variant='text' />
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}
      <CustomConfirmationPopup
        open={openConfirmationPopup}
        onClose={closeConfirmationPopup}
        type='delete'
        cancelText='No'
        confirmText='Yes'
        onConfirm={onConfirm}
        setOpen={setOpenConfirmationPopup}
        isEmailMessage={true}
      />
      {isConfirmationPopup && <DuplicateContactNotifier open={isConfirmationPopup} data={duplicateRecords} duplicationField={duplicationField} showAction={false} handleClose={handleClose} />}
    </>
  );
});

Communications.propTypes = {
  handleSaveTabData: PropTypes.func,
  id: PropTypes.string,
  srcUrl: PropTypes.string,
  onCancel: PropTypes.func
};
export default Communications;
