import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import React from 'react';
import { messageTemplatesApi } from '../../../../services/ApiService';

const columnDefs = [
  {
    field: 'title',
    headerName: 'Template Name',
    cellRenderer: 'templateNameRenderer',
    width: 350,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  // Using dot notation to access nested property
  {
    field: 'description',
    headerName: 'Description',
    width: 450,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  // Show default header name
  {
    field: 'language',
    width: 130,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  {
    field: 'public',
    headerName: 'Public / Private',
    width: 250,
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc', null]
  },
  {
    field: 'Delete',
    pinned: 'right',
    cellRenderer: 'deleteButtonRenderer',
    width: 100,
    resizable: false,
    sortable: false
  }
];

const DeleteButtonRenderer = props => {
  return (
    <DeleteIcon
      fontSize='small'
      className='cursor-pointer'
      onClick={async () => {
        props.setLoading(true);
        await messageTemplatesApi('delete', `sms/${props?.data?.id}`);

        props.setLoading(false);
        props.refetch();
      }}
    />
  );
};

const TemplateNameRenderer = props => {
  return (
    <div
      className='py-2'
      onClick={() => {
        props.setCurrentTemplate(props.data.id);
        props.setIsDrawerOpen(true);
      }}
    >
      {props.value}
    </div>
  );
};

const updateText = (textToInsert, ref, refString, updateMethod) => {
  const input = ref.current;

  const start = input.selectionStart;
  const end = input.selectionEnd;

  const newValue = `${input.value.substring(0, start)}${textToInsert}${input.value.substring(end, input.value.length)}`;

  updateMethod(refString, newValue);
  input.selectionStart = input.selectionEnd = start + textToInsert.length;
};

DeleteButtonRenderer.propTypes = {
  data: PropTypes.object,
  refetch: PropTypes.func,
  setLoading: PropTypes.func
};

TemplateNameRenderer.propTypes = {
  data: PropTypes.object,
  value: PropTypes.string,
  setIsDrawerOpen: PropTypes.func,
  setCurrentTemplate: PropTypes.func
};

export { DeleteButtonRenderer, TemplateNameRenderer, columnDefs, updateText };
