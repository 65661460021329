export function createEnglishStatements(apiResponse) {
  return apiResponse.map((item, index) => {
    try {
      let name = item.created_user ? item.created_user.name : 'Unknown user';
      let action, reference;

      if (item.activity_type === 'candidate_stage_change') {
        name = item.ign_contacts.name || 'Unknown user';
      }

      switch (item.activity_type) {
        case 'note':
          action = 'logged a note';
          break;
        case 'candidate_stage_change':
          action = 'moved';
          break;
        case 'project_stage_update':
          action = 'moved';
          break;
        default:
          action = `logged a ${item.activity_type}`;
      }
      if (item.activity_type === 'note') {
        action = action + ` as ${item.notes_type}`;
      }

      if (item.activity_type === 'candidate_stage_change') {
        action = action + ` to ${item.to_stage}`;
      }
      if (item.activity_type === 'project_stage_update') {
        action = action + ' to';
      }
      // Construct reference string
      if (item.project && item.project_id) {
        reference = `${item.project.job_number} - ${item.project.job_title}`;
      } else {
        reference = '0000 - ';
      }

      return {
        id: index,
        project_id: item?.project_id || '/',
        name: ` ${name}`,
        action: action,
        reference: reference,
        activity_type: item.activity_type,
        contact_id: item?.ign_contacts?.id || '/',
        stage_to: item?.to_stage || '/'
      };
    } catch (err) {
      console.log(err);
    }
  });
}
