//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, Checkbox, FormControlLabel, Popover, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import './index.scss';

export default function ColumnFilter(props) {
  const { columnApi, defaultColumns = [], filterModel, showFilterCount, classNameValue } = props;
  const [anchorEl, setAnchorEl] = useState();
  // eslint-disable-next-line no-unused-vars
  const [visible, setVisible] = useState(false);
  const [viewColumns, setViewColumns] = useState(false);

  function handleMessage() {
    if (showFilterCount === 1) {
      return `Filter is applied on ${Object.keys(filterModel)} column`;
    } else {
      return `Filters are applied on ${Object.keys(filterModel)?.length} columns`;
    }
  }
  return (
    <React.Fragment>
      <Box
        className={classNameValue || 'columns-icon m-2'}
        onClick={event => {
          setViewColumns(prevState => !prevState);
          setAnchorEl(event.currentTarget);
        }}
      >
        {showFilterCount > 0 ? (
          <Tooltip title={handleMessage()} placement='top-start'>
            <Box className={'menu-wrapper'}>
              <Box className={'badge-wrapper'}>
                <Box className={'badge'}></Box>
              </Box>
              <MoreHorizIcon className='menu-icon' />
            </Box>
          </Tooltip>
        ) : (
          <MoreHorizIcon className='menu-icon' />
        )}
      </Box>

      <Popover
        onClose={() => {
          setViewColumns(false);
        }}
        open={viewColumns}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {columnApi?.getAllColumns().map((column, index) => {
          if (!defaultColumns.includes(column.userProvidedColDef.field)) {
            return (
              <FormControlLabel
                id='checkbox-component'
                className='p-1'
                key={index}
                control={
                  <Checkbox
                    className='checkbox-container mr-2'
                    onChange={() => {
                      columnApi.setColumnVisible(column.colId, !column.visible);
                      columnApi.autoSizeColumn(column.colId, true);
                      setVisible(prevState => !prevState);
                    }}
                    checked={column.visible}
                  />
                }
                label={column.userProvidedColDef.headerName}
              />
            );
          }
          return null;
        })}
      </Popover>
    </React.Fragment>
  );
}

ColumnFilter.propTypes = {
  columnApi: PropTypes.object,
  defaultColumns: PropTypes.array,
  filterModel: PropTypes.object,
  showFilterCount: PropTypes.number,
  classNameValue: PropTypes.string
};
