import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ImportantDevicesOutlinedIcon from '@mui/icons-material/ImportantDevicesOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import { Stack, Typography, useTheme } from '@mui/material';
import propTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './Button';

import { ROUTES } from '../../../services/constantService';
import style from '../Home.module.scss';
function SectionModel({ headerTitle, middleContent, route, bottomContent, onClick, navigateState = {} }) {
  const navigate = useNavigate();
  const handleClick = () => {
    if (route) navigate(`${ROUTES.mySearches}/${navigateState.type}/${navigateState.filter}`, navigateState);
  };
  const theme = useTheme();
  return (
    <Stack className={style.section_stack} spacing={0.5} onClick={onClick}>
      <Typography className={`${style.header_title} ${!route && style.no_link}`} onClick={handleClick} color={theme.palette.heading.main}>
        {headerTitle}
      </Typography>

      {middleContent && (
        <Typography className={`${style.middle_content} ${!route && style.no_link} fs-8`} onClick={handleClick} color={theme.palette.primary.main}>
          {middleContent}
        </Typography>
      )}

      <Typography className={`${style.bottom_content} ${!route && style.no_link} fs-10`} onClick={handleClick}>
        {bottomContent}
      </Typography>
    </Stack>
  );
}
const addColumn = [
  {
    icon: <Button addIcon value={<DashboardOutlinedIcon fontSize='inherit' />} />,
    title: 'New Project',
    route: ROUTES.addProject
  },
  {
    icon: <Button addIcon value={<WorkOutlineOutlinedIcon fontSize='inherit' />} />,
    title: 'New BD',
    route: ROUTES.createBd
  },
  {
    icon: <Button addIcon value={<AssignmentIndOutlinedIcon fontSize='inherit' />} />,
    title: 'New Contact',
    route: ROUTES.addContact
  },
  {
    icon: <Button addIcon value={<ApartmentOutlinedIcon fontSize='inherit' />} />,
    title: 'New Company',
    route: ROUTES.companiesList,
    data: { isEdited: true }
  },
  {
    icon: <Button value={<DescriptionOutlinedIcon fontSize='inherit' />} />,
    title: 'Report Builder',
    route: ROUTES.reports
  },
  {
    icon: <Button value={<ImportantDevicesOutlinedIcon fontSize='inherit' />} />,
    title: 'Client Portal',
    route: ROUTES.clientSuite
  },
  {
    icon: <Button value={<ReceiptLongOutlinedIcon fontSize='inherit' />} />,
    title: 'Invoice Summary Page',
    route: ROUTES.invoiceSummary
  }
];

SectionModel.propTypes = {
  headerTitle: propTypes.string || propTypes.node,
  middleContent: propTypes.node,
  leftContent: propTypes.node,
  bottomContent: propTypes.node || propTypes.string,
  route: propTypes.string,
  onClick: propTypes.func,
  navigateState: propTypes.object
};
export { SectionModel, addColumn };
