import React, { useState } from 'react';
import { Card, CardContent, Grid, Typography, Link, Box, Container, IconButton, Tooltip, Stack } from '@mui/material';
import delete_icon from '../../assets/icons/delete_icon.svg';
import edit_icon from '../../assets/icons/edit_icon.svg';
import PropTypes from 'prop-types';
import ViewMorePopup from './ViewMorePopup';
import Divider from '@mui/material/Divider';
import CustomConfirmationPopup from './CustomConfirmationPopup';
import MakeConfidential from './MakeConfidential/MakeConfidential';
import '../../../src/utils/common.scss';
import RichText from './RichText';
import { useLanguageHooks } from '../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../utils/languageNamespaces';
import { ignContactDataApi } from '../../services/ApiService';
import { DELETE, SUCCESS } from '../../services/constantService';
import { successMessage } from '../../services/MessageService';
import { enqueueSnackbar } from 'notistack';
const CustomCard = props => {
  const {
    data,
    isTypes = false,
    addIcon,
    downloadIcon,
    indexValue,
    onSubmit = () => {},
    noteType1 = '',
    styleData = {},
    noteType2 = '',
    editItems = () => {},
    viewItems = () => {},
    approved1 = '',
    approved2 = '',
    isNotesType = false,
    notesData,
    notesLabel,
    notesTypeLabel,
    contact_id,
    getContact = () => {},
    getContactDetails = () => {},
    tab,
    setLoading = () => {}
  } = props;
  const [isViewMore, setIsViewMore] = useState(false);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const { titleSize = 14, textSize = 12, height = 110, width = 'auto', minWidth = 'auto' } = styleData;
  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_APPROVED_TO_PUBLISH]);
  let expanded = false;
  const OnViewMore = () => {
    viewItems(indexValue);
    setIsViewMore(true);
  };
  const editItem = () => {
    editItems(indexValue);
  };
  const handleSubmit = (data, isEditing) => {
    onSubmit(data, isEditing);
  };
  const deleteItem = () => {
    setOpenConfirmationPopup(true);
  };
  const closeConfirmationPopup = () => {
    setOpenConfirmationPopup(false);
  };
  const onConfirm = async () => {
    let sub_route;
    setLoading(true);
    if (isTypes) {
      sub_route = `contact-notes?notes_type=${noteType1}&notes_type=${noteType2}&contact_id=${contact_id}&project_id=${data.projects.project_id}`;
    } else if (isNotesType) {
      sub_route = `contact-notes?id=${data.note_id}`;
    }
    try {
      const { status } = await ignContactDataApi(DELETE, null, null, sub_route);
      if (status === 200) {
        const message = successMessage('Notes', 'Deleted');
        enqueueSnackbar(message, { variant: SUCCESS });
        getContact(contact_id, tab);
        getContactDetails(contact_id);
        setLoading(false);
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  // const typeValue = useMemo(() => {
  //   if (isTypes && data) {
  //     const updatedTypeValue = [];
  //     for (const key in data) {
  //       // eslint-disable-next-line no-prototype-builtins
  //       if (data.hasOwnProperty(key)) {
  //         const value = data[key];
  //         if (Array.isArray(value) && value.length > 0) {
  //           updatedTypeValue.push({
  //             type: key,
  //             text: [...value],

  //           });
  //         }
  //       }
  //     }
  //     return updatedTypeValue;
  //   }

  //   return [];
  // }, [data, isTypes, indexValue, isEditing, itemLength]);
  return (
    <>
      <Container id='custom-card' className='m-0 p-0'>
        <Card
          sx={{
            flex: '0 0 auto',
            maxHeight: height,
            minHeight: height,
            maxWidth: width,
            minWidth: minWidth
          }}
        >
          <CardContent>
            <Grid container>
              {' '}
              {/* 'container' creates a new row */}
              <Grid item xs={9} className='justify-content-between w-100 pr-2'>
                {' '}
                {/* 'item' creates a column */}
                <Box className='d-flex align-items-center ' gap={1}>
                  <Box component='div' className={`card-title font-weight-bold m-0 fs-${titleSize}`}>
                    {' '}
                    {isNotesType ? notesTypeLabel.label : isTypes ? data?.projects?.project_name : data.type}
                  </Box>
                  {isNotesType && data[notesData.approved] && (
                    <Tooltip title={t(`${TRANS_KEYS.CONTACTS_APPROVED_TO_PUBLISH}:key`)} placement='top' arrow>
                      <Box className='is-approved-checkbox'>
                        <MakeConfidential defaultChecked={data[notesData.approved]} />
                      </Box>
                    </Tooltip>
                  )}
                </Box>
                <Grid className='py-2' item>
                  {' '}
                  {/* Another column for the delete icon */}
                  {isTypes ? (
                    <Box>
                      <Typography variant='body2' className='card-containt'>
                        <Box className='d-flex align-items-center ' gap={1}>
                          <Box variant='body1' className={`card-title fs-${titleSize}  view-text m-0 mb-1`}>
                            {notesLabel?.label1}{' '}
                          </Box>
                          {data[approved1] && (
                            <Tooltip title={t(`${TRANS_KEYS.CONTACTS_APPROVED_TO_PUBLISH}:key`)} placement='top' arrow>
                              <Box className='is-approved-checkbox'>
                                <MakeConfidential defaultChecked={data[approved1]} />
                              </Box>
                            </Tooltip>
                          )}
                        </Box>
                        <Box className={`card-containt fs-${textSize}`}>
                          {expanded
                            ? data[noteType1] && data[noteType1].length > 0 && <RichText defaultValue={data[noteType1][0]?.notes || ''} isView={true} displayToolbar={false} isCard={true} />
                            : data[noteType1] &&
                              data[noteType1].length > 0 && <RichText defaultValue={data[noteType1][0]?.notes.substring(0, 70) || ''} isView={true} displayToolbar={false} isCard={true} />}
                          {!expanded && (data[noteType1].length > 1 || data[noteType1][0]?.notes.length > 50) && (
                            <Link component='button' onClick={OnViewMore} className={`show-more-custom-card fs-${textSize} ml-1`}>
                              Show more
                            </Link>
                          )}
                          <Stack direction='row' className='fs-10'>
                            {data[noteType1][0]?.updated_at && new Date(data[noteType1][0]?.updated_at).toLocaleString()}
                          </Stack>
                        </Box>
                      </Typography>
                      <Divider className='my-2' />
                      <Typography variant='body2' className='card-containt'>
                        <Box className='d-flex align-items-center' gap={1}>
                          <Box className={`card-title fs-${titleSize}  view-text m-0 mb-1`}>{notesLabel?.label2} </Box>
                          {data[approved2] && (
                            <Tooltip title={t(`${TRANS_KEYS.CONTACTS_APPROVED_TO_PUBLISH}:key`)} placement='top' arrow>
                              <Box className='is-approved-checkbox'>
                                <MakeConfidential defaultChecked={data[approved2]} />
                              </Box>
                            </Tooltip>
                          )}
                        </Box>
                        <Box className={`card-containt fs-${textSize}`}>
                          {expanded
                            ? data[noteType2] && data[noteType2].length > 0 && <RichText defaultValue={data[noteType2][0]?.notes || ''} isView={true} displayToolbar={false} isCard={true} />
                            : data[noteType2] &&
                              data[noteType2].length > 0 && <RichText defaultValue={data[noteType2][0]?.notes.substring(0, 70) || ''} isView={true} displayToolbar={false} isCard={true} />}
                          {!expanded && (data[noteType2].length > 1 || data[noteType2][0]?.notes.length > 50) && (
                            <Link component='button' onClick={OnViewMore} className={`show-more-custom-card fs-${textSize} ml-1`}>
                              Show more
                            </Link>
                          )}
                          <Stack direction='row' className='fs-10'>
                            {data[noteType2][0]?.updated_at && new Date(data[noteType2][0]?.updated_at).toLocaleString()}
                          </Stack>
                        </Box>
                      </Typography>
                    </Box>
                  ) : isNotesType ? (
                    <Box className={`card-containt fs-${textSize}`}>
                      {expanded ? (
                        <RichText defaultValue={data[notesData.noteType] || ''} isView={true} displayToolbar={false} isCard={true} />
                      ) : (
                        <RichText defaultValue={data[notesData.noteType].substring(0, 100) || ''} isView={true} displayToolbar={false} isCard={true} />
                      )}
                      {!expanded && data[notesData.noteType].length > 100 && (
                        <Link
                          // sx={{ fontSize: fontSize }}
                          component='button'
                          variant='body2'
                          onClick={OnViewMore}
                          className={`show-more-custom-card pl-1 fs-${textSize}`}
                        >
                          Show more
                        </Link>
                      )}
                      <Stack direction='row' className='fs-10'>
                        {data?.updated_at && new Date(data?.updated_at).toLocaleString()}
                      </Stack>
                    </Box>
                  ) : (
                    <Box className={`card-containt fs-${textSize}`}>
                      {expanded ? data.text : data.text.substring(0, 70)}
                      {!expanded && data.text.length > 70 && (
                        <Link
                          // sx={{ fontSize: fontSize }}
                          component='button'
                          onClick={OnViewMore}
                          className={`show-more-custom-card pl-1 fs-${textSize}`}
                        >
                          Show more
                        </Link>
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Box className='top-level'>
                  {downloadIcon && <IconButton className='download-icon p-0 m-0 pr-1'>{downloadIcon}</IconButton>}
                  <IconButton onClick={editItem} className='green-bg edit-icon mr-1 p-1'>
                    <img src={edit_icon} alt='' />
                  </IconButton>
                  <IconButton className='delete-icons pr-2 pt-1' onClick={deleteItem}>
                    <img src={delete_icon} alt='' />
                  </IconButton>
                </Box>
                <Box className='bottom-level pt-1'>
                  {addIcon && (
                    <Box className='security-icon pr-2'>
                      <img src={addIcon} alt='' />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </CardContent>
          {/* <CardActions className='personality-card-action'>
            {
              addAnother && data.projects[addAnother].length === 0 && <Button size="sm" disableRipple className='button-another-add' onClick={() => { handleAddAnotherClick(addAnother, data.projects.name) }}><span>Add {addAnother}</span></Button>
            }
          </CardActions> */}
        </Card>
      </Container>
      {isViewMore && !isTypes && !isNotesType && (
        <ViewMorePopup
          data={data}
          noteType1={noteType1}
          noteType2={noteType2}
          isViewMore={isViewMore}
          setIsViewMore={setIsViewMore}
          onSubmit={handleSubmit}
          isTypes={isTypes}
          addIcon={addIcon}
          editItems={editItems}
        ></ViewMorePopup>
      )}

      <CustomConfirmationPopup open={openConfirmationPopup} onClose={closeConfirmationPopup} type='delete' cancelText='No' confirmText='Yes' onConfirm={onConfirm} setOpen={setOpenConfirmationPopup} />
    </>
  );
};

CustomCard.propTypes = {
  data: PropTypes.array,
  isTypes: PropTypes.bool,
  // addAnother: PropTypes.string,
  // unSelectedTypeFunction: PropTypes.func,
  indexValue: PropTypes.string,
  types: PropTypes.string,
  setIndexValue: PropTypes.func,
  onSubmit: PropTypes.func,
  noteType1: PropTypes.string,
  noteType2: PropTypes.string,
  isEditing: PropTypes.bool,
  editItems: PropTypes.func,
  height: PropTypes.number,
  width: PropTypes.number,
  minWidth: PropTypes.number,
  addIcon: PropTypes.string,
  fontSize: PropTypes.number,
  viewItems: PropTypes.func,
  approved1: PropTypes.string,
  approved2: PropTypes.string,
  isNotesType: PropTypes.bool,
  notesData: PropTypes.object,
  styleData: PropTypes.object,
  downloadIcon: PropTypes.string,
  notesLabel: PropTypes.object,
  notesTypeLabel: PropTypes.object,
  contact_id: PropTypes.string,
  getContact: PropTypes.func,
  getContactDetails: PropTypes.func,
  tab: PropTypes.string,
  setLoading: PropTypes.func
};

export default CustomCard;
