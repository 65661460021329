import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Select, MenuItem, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { NumericFormat } from 'react-number-format';

//----------------------------------------------// Internal Imports // -------------------------------------------------

import InputField from '../../../../common/InputField';
import FeetypeSelection from '../../../../FeetypeSelection';
import { AddRecordButton, getFixedDecimal } from '../../../../../utils/common';

const IndirectFee = props => {
  const { register, setValue, currentValues, getTotalPercentageOfBase, watch, type = 'estimated', disabled = false, getValueWithCurrency } = props;
  const [indirectFee, setIndirectFee] = useState([]);
  const [availableType, setAvailableType] = useState([]);
  useEffect(() => {
    register('indirect_fees_searches');
    setValue('indirect_fees_searches', indirectFee);
  }, [register, indirectFee, setValue]);

  useEffect(() => {
    if (currentValues) {
      setIndirectFee(currentValues.indirect_fees_searches || []);
    }
  }, [currentValues]);

  const handleChangeIndirectFee = (data, field, index, shortDesc) => {
    let existingIndirectFee = [...indirectFee];
    existingIndirectFee[index] = { ...existingIndirectFee[index], [field]: data };
    if (field === 'type') {
      existingIndirectFee[index] = { ...existingIndirectFee[index], ['short_desc']: shortDesc };
    }
    if (field === 'calculation_type') {
      if (data === 'Flat Amount') {
        existingIndirectFee[index].percentage = 0;
        existingIndirectFee[index].calculation_amt = 0;
      }
    }

    if (field === 'percentage' || field === 'calculation_type') {
      existingIndirectFee[index].calculation_amt =
        existingIndirectFee[index].calculation_type === 'Flat Amount'
          ? existingIndirectFee[index].calculation_amt
          : getFixedDecimal((getTotalPercentageOfBase(watch(`${type}_percentage_bases`), `calculated_${type}_amt`).number * existingIndirectFee[index].percentage) / 100);
    }
    setIndirectFee(existingIndirectFee);
  };

  const handleRemoveIndirectFee = index => {
    let existingIndirectFee = [...indirectFee];
    existingIndirectFee.splice(index, 1);
    setIndirectFee(existingIndirectFee);
    calculateAmount();
  };
  const handleAddIndirectFee = () => {
    let newIndirectFee = [...indirectFee, {}];
    setIndirectFee(newIndirectFee);
  };

  const calculateAmount = () => {
    setIndirectFee(prevState => [
      ...prevState.map(ele => {
        return {
          ...ele,
          calculation_amt:
            ele.calculation_type === 'Flat Amount'
              ? ele.calculation_amt
              : getFixedDecimal((getTotalPercentageOfBase(watch(`${type}_percentage_bases`), `calculated_${type}_amt`).number * ele.percentage) / 100)
        };
      })
    ]);
  };

  useEffect(() => {
    if (indirectFee) {
      calculateAmount();
    }
    console.log(watch(`${type}_percentage_bases`));
  }, [watch(`${type}_percentage_bases`)]);

  useEffect(() => {
    setAvailableType(indirectFee.map(ele => ({ short_desc: ele.short_desc, type: ele.type })) || []);
  }, [indirectFee]);

  return (
    <div className={'d-flex flex-column team-information-table'}>
      <div className={'d-flex table-header '}>Indirect Fee</div>
      <div className=' d-flex flex-column'>
        <div className='d-flex align-items-center table-main-header'>
          <div className='col-3'>Fee Type</div>
          <div className='col-3'>Calculation Type</div>
          <div className='col-2'>Percentage</div>
          <div className='col-2'>Calculated Amount</div>
          <div>&nbsp;</div>
        </div>
      </div>
      <div className={'table-content '}>
        {indirectFee.map((item, index) => {
          return (
            <div className='d-flex team-info-row align-items-center' key={index}>
              {/* <InputField
                  variant="outlined"
                  type="number"
                  value={0}
                  className="team-info-field sum-value" /> */}
              {!disabled ? (
                <FeetypeSelection
                  selectedType={availableType}
                  variant='outlined'
                  className='col-3 team-info-field'
                  value={item.short_desc || ''}
                  InputLabelProps={{ focused: true }}
                  type='feetypes'
                  label=''
                  // onChange={(e, data) => {
                  //   setValue("fee_type", data ? data.name : null);

                  //   // calculateFees();
                  // }}
                  onChange={(e, data) => {
                    handleChangeIndirectFee(data ? data.field_value : null, 'type', index, data ? data.short_desc : null);
                  }}
                />
              ) : (
                <div className='col-3 team-info-field'>{item.short_desc || '--'}</div>
              )}
              {!disabled ? (
                <div className='col-3 team-info-field'>
                  <Select
                    className='w-100 team-info-field text-left'
                    variant='outlined'
                    value={item.calculation_type}
                    onChange={e => handleChangeIndirectFee(e.target.value, 'calculation_type', index)}
                  >
                    <MenuItem value='Percentages'>Percentage</MenuItem>
                    <MenuItem value='Flat Amount'>Flat Amount</MenuItem>
                  </Select>
                </div>
              ) : (
                <div className='col-3 team-info-field'>{item.calculation_type || '--'}</div>
              )}
              {!disabled ? (
                <InputField
                  value={item.percentage}
                  onChange={e => handleChangeIndirectFee(e.target.value ? Number(e.target.value) : null, 'percentage', index)}
                  variant='outlined'
                  type='number'
                  flag='true'
                  inputProps={{ min: 0, max: 100 }}
                  error={item.percentage > 100}
                  disabled={!item.calculation_type || item.calculation_type === 'Flat Amount'}
                  className='col-2 team-info-field'
                />
              ) : (
                <div className='col-2 team-info-field'>{item.percentage || '--'}</div>
              )}
              {!disabled ? (
                <NumericFormat
                  value={item.calculation_amt}
                  customInput={TextField}
                  // type="number"
                  label=''
                  variant='outlined'
                  placeholder=''
                  className='col-2 team-info-field'
                  InputLabelProps={{ focused: true }}
                  error={item.calculation_amt > 1000000000000000}
                  decimalScale={2}
                  isNumericString={true}
                  onValueChange={values => {
                    const { value } = values;
                    handleChangeIndirectFee(value ? Number(value) : null, 'calculation_amt', index);
                  }}
                  disabled={!item.calculation_type || item.calculation_type === 'Percentages'}
                />
              ) : (
                <div className='col-3 team-info-field'>{item.calculation_amt ? getValueWithCurrency(item.calculation_amt) : '--'}</div>
              )}
              <div className='close-action'>
                {!disabled ? (
                  <span className='close-icon col-1'>
                    <CloseIcon onClick={() => handleRemoveIndirectFee(index)} color='error' fontSize='inherit' />
                  </span>
                ) : null}
              </div>
            </div>
          );
        })}
        {/* {!disabled && <div className='text-start ml-2'><Button
          variant={"contained"}
          onClick={handleAddIndirectFee}
          style={{ backgroundColor: '#4BB543' }}
          className="button-text-capitalized"
        >
          Add Row
        </Button> </div>} */}
        {!disabled && (
          <div className='text-start'>
            <AddRecordButton onClick={handleAddIndirectFee} iconColor={indirectFee?.length > 1 ? 'inherit' : 'red'} disabled={indirectFee?.length > 1}>
              Add Row
            </AddRecordButton>
          </div>
        )}
      </div>
    </div>
  );
};

IndirectFee.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  getTotalPercentageOfBase: PropTypes.func,
  watch: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  getValueWithCurrency: PropTypes.func
};

export default IndirectFee;
