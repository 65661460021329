import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import CustomButton from '../../../components/common/CustomButton';
import Loader from '../../../components/common/Loader';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import '../index.scss';

import { useNavigate } from 'react-router-dom';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';

//TODO :  things pending on trevor
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useCandidateStages } from '../../../utils/Hooks/useCandidateStage';
import { useOfflimit } from '../../../utils/Hooks/useOfflimit';
import IconSwitch from './IconSwitch';

const CandidateProjectStatusSetupTable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useLanguageHooks([TRANS_KEYS.BACK, TRANS_KEYS.CONFIGURE_CANDIDATE_STATUS_TITLE, TRANS_KEYS.PROJECT_STATUS_DRAFT, TRANS_KEYS.PROJECT_STATUS_OPEN, TRANS_KEYS.PROJECT_STATUS_CLOSED]);

  const { condidateStages, getAllCandidateStatus } = useCandidateStages();
  const [checkedState, setCheckedState] = React.useState({});

  const { getOffLimitConfig, updateOffLimitConfig, offLimitConfig, isConfigFetched } = useOfflimit();

  useEffect(() => {
    if (!isConfigFetched || !condidateStages.length) return;

    let initialCheckedState = condidateStages.reduce((state, stage) => {
      return {
        ...state,
        [stage.stage_name]: {
          Draft: false,
          Open: false,
          Closed: false
        }
      };
    }, {});
    offLimitConfig.forEach(config => {
      const { project_status, candidate_status, is_off_limit } = config;
      if (initialCheckedState[candidate_status]) {
        initialCheckedState[candidate_status][project_status] = is_off_limit;
      }
    });
    setCheckedState(initialCheckedState);
  }, [isConfigFetched, condidateStages]);

  const backMenu = () => {
    navigate('/productSetup');
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([getAllCandidateStatus(), getOffLimitConfig()]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const rows = useCallback(
    condidateStages.map(stage => ({
      id: stage.stage_name,
      label: stage.stage_label
    })),
    [condidateStages]
  );

  const handleCheckboxChange = (rowId, field) => {
    const updatedState = {
      ...checkedState,
      [rowId]: {
        ...checkedState[rowId],
        [field]: !checkedState[rowId][field]
      }
    };
    setCheckedState(updatedState);
    const configData = {
      project_status: field,
      candidate_status: rowId,
      is_off_limit: updatedState[rowId][field]
    };
    updateOffLimitConfig(configData);
  };

  return (
    <div
      style={
        isLoading
          ? {
              height: '80vh'
            }
          : {}
      }
    >
      <Loader show={isLoading} />

      {!isLoading && (
        <Box height='75%' paddingX='20px' id='configuration-matrix-container'>
          <Box className='mb-3 pt-3'>
            <CustomButton
              buttonWrapperClassName='back-btn'
              onClick={() => backMenu()}
              buttonText={t(`${TRANS_KEYS.BACK}:key`)}
              type={'tertiary'}
              size={'small'}
              variant=''
              width={42}
              x
              customWidth={42}
              iconLeft={<KeyboardBackspaceIcon sx={{ width: 20, height: 20 }} />}
            />
          </Box>
          <Box sx={{ p: 4 }} id='configuration-matrix-box'>
            <Typography variant='h6' gutterBottom className='configuration-title'>
              {t(`${TRANS_KEYS.CONFIGURE_CANDIDATE_STATUS_TITLE}:key`)}
            </Typography>

            <Box id='configuration-matrix'>
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className='table-head'>
                      <TableCell />
                      <TableCell align='center'>{t(`${TRANS_KEYS.PROJECT_STATUS_DRAFT}:key`)}</TableCell>
                      <TableCell align='center'>{t(`${TRANS_KEYS.PROJECT_STATUS_OPEN}:key`)}</TableCell>
                      <TableCell align='center'>{t(`${TRANS_KEYS.PROJECT_STATUS_CLOSED}:key`)}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map(row => (
                      <TableRow key={row.id} className='table-body'>
                        <TableCell component='th' scope='row'>
                          {row.label}
                        </TableCell>
                        <TableCell align='center'>
                          <IconSwitch checked={!!checkedState?.[row.id]?.Draft} onChange={() => handleCheckboxChange(row.id, 'Draft')} />
                        </TableCell>
                        <TableCell align='center'>
                          <IconSwitch checked={!!checkedState?.[row.id]?.Open} onChange={() => handleCheckboxChange(row.id, 'Open')} />
                        </TableCell>
                        <TableCell align='center'>
                          <IconSwitch checked={!!checkedState?.[row.id]?.Closed} onChange={() => handleCheckboxChange(row.id, 'Closed')} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default CandidateProjectStatusSetupTable;
