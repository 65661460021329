import { Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DecisionMatrixChart from '../../../components/DecisionMatrixChart';
import CustomAccordionBuilder from '../../../components/common/CustomAccordionBuilder/index.js';
import CustomButton from '../../../components/common/CustomButton/index.js';
import Loader from '../../../components/common/Loader/index.js';
import '../../../index.scss';
import { DecisionGraphDataApi, requestQuickSearchApi } from '../../../services/ApiService.js';
import { GET, POST, ROUTES } from '../../../services/constantService.js';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook.js';
import { TRANS_KEYS } from '../../../utils/languageNamespaces.js';
import SuccessPopup from './Comman/SuccessPopup.js';
import { ProjectDataContext } from './Context/index.js';
const defOptions = [
  { id: 1, label: '1' },
  { id: 2, label: '2' },
  { id: 3, label: '3' },
  { id: 4, label: '4' },
  { id: 5, label: '5' }
];

const Decision = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoader] = useState(false);
  const [decisionState, setDecisionState] = useState([]);
  const { projectData } = useContext(ProjectDataContext);
  const [dataPoints, setDataPoints] = useState(undefined);
  const [quickLoadingPopup, setQuickLoadingPopup] = useState(false);
  const [isQuickLoading, setIsQuickLoading] = useState(false);

  const { t } = useLanguageHooks([TRANS_KEYS.DECISION_HEADER, TRANS_KEYS.SOMETHING_WENT_WRONG, TRANS_KEYS.CONTACTS_SAVE]);

  const handleDecisionChange = async (key, value) => {
    try {
      setLoader(true);
      console.log('handle-decision-change', {
        key,
        value,
        projectData
      });
      const project_id = projectData?.id;
      const payload = {
        project_id: project_id,
        sfpa_decision_question_id: key,
        answer_value: value
      };
      const res = await DecisionGraphDataApi(POST, '', payload, 'add');
      console.log('handle-decision-select', res, payload, 'all');
      await handleFetchAndDisplay();
      setLoader(false);
    } catch (err) {
      setLoader(false);
      enqueueSnackbar(err, 'err');
    }
  };

  const fetchDecisionGraphResponse = async () => {
    try {
      setLoader(true);
      const response = await DecisionGraphDataApi(GET, '', '', `get-all?projectId=${projectData?.id}`);

      if (response?.data) {
        setDecisionState(response?.data);
        return response?.data;
      }
      console.log('handle-decision-fetch-response', response);
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
    }
  };

  // const [initialCoords, setInitialCoords] = useState(null);

  const handleDataPointsBuilder = response => {
    let dataPoint = {};
    console.log('handle-decision', response);
    let idx = 1;
    response.forEach(data => {
      data?.sfpa_decision_questions.forEach(question => {
        const key = 'item_' + idx;
        dataPoint[key] = question?.project_sfpa_decisions?.answer_value ?? 0;
        idx = idx + 1;
      });
    });
    console.log('handle-decision-data-points', dataPoint);
    setDataPoints({ ...dataPoint });
  };

  const handleFetchAndDisplay = async () => {
    const response = await fetchDecisionGraphResponse();
    console.log('handle-decision-fetch-display', response);
    handleDataPointsBuilder(response);
  };

  useEffect(() => {
    handleFetchAndDisplay();
  }, []);

  const headerText = t(`${TRANS_KEYS.DECISION_HEADER}:key`);
  const RequestQuickSearch = async () => {
    try {
      setQuickLoadingPopup(true);
      setIsQuickLoading(true);
      await requestQuickSearchApi({
        projectId: projectData?.id
      })
        .then(response => {
          if (response.status !== 200) {
            enqueueSnackbar(response?.data?.message, {
              variant: 'error'
            });
            setQuickLoadingPopup(false);
          }
        })
        .catch(err => {
          enqueueSnackbar(err?.data?.message, {
            variant: 'error'
          });
          setQuickLoadingPopup(false);
        });
      setIsQuickLoading(false);
    } catch (err) {
      console.log('RequestQuickSearch', err);
    }
  };
  const handleSave = async () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <>
      <Grid container fullWidth>
        <Loader show={loader} />
        <Grid item sx={12} md={12} className='p-2'>
          <div
            className='fs-12'
            dangerouslySetInnerHTML={{
              __html: headerText
            }}
          />
        </Grid>
        <Grid item sx={12} md={12} className='p-2'>
          <Stack direction='row' justifyContent={'flex-end'}>
            <CustomButton type='primary m-1' variant='contained' buttonText={t(`${TRANS_KEYS.CONTACTS_SAVE}:key`)} onClick={handleSave} />
            <CustomButton
              type='primary m-1'
              variant='contained'
              buttonText={'Save and Find Candidate'}
              onClick={async () => {
                setQuickLoadingPopup(true);
                await RequestQuickSearch();
                // await handleSave();
              }}
            />
          </Stack>
        </Grid>
        <Grid item sx={12} md={6}>
          <CustomAccordionBuilder radioState={decisionState} questionary={decisionState ?? []} radioOptions={defOptions} handleChange={handleDecisionChange} />
        </Grid>
        <Grid item sx={12} md={6}>
          <DecisionMatrixChart dataPoints={dataPoints} projectId={projectData?.id} />
        </Grid>
      </Grid>
      {quickLoadingPopup && (
        <SuccessPopup
          open={quickLoadingPopup}
          setOpen={setQuickLoadingPopup}
          isLoading={isQuickLoading}
          btn={true}
          onViewCandidate={() => {
            setQuickLoadingPopup(false);
            navigate(`${ROUTES.projectDetails}/${id}/Research/Product One`);
          }}
        />
      )}
    </>
  );
};

Decision.propTypes = {
  getValueFromUniversalState: PropTypes.func.isRequired,
  handleUniversalState: PropTypes.func.isRequired
};

export default Decision;
