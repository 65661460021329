import { Box, Button, /* , IconButton */ Slide } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ActivityLogs from './ActivityLogsIgnyte';
import ContactDetails from './ContactDetails';
import ViewContactHeader from './ViewContactHeader';
// import delete_icon from "../../assets/icons/delete_icon.svg";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import { motion } from 'framer-motion';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SUCCESS_STATUS_CODE } from '../../Containers/Commons/Utils';
import { fetchPickLists } from '../../actions';
import profileUrl from '../../assets/images/profile-image.png';
import { getUserSelector } from '../../selectors';
import { FitScoreDataApi, ProjectCandidateApi, ignContactDataApi, logAnActivityApi } from '../../services/ApiService';
import { requireMessage, successMessage, validateMessage } from '../../services/MessageService';
import { CONTACT_DRAWER_HEADER_ADDITION_CONTENT_KEYS, DELETE, ERROR, GET, PATCH, POST, ROUTES, SUCCESS, TAG_SETUP_LOGS_MESSAGE } from '../../services/constantService';
import { useLanguageHooks } from '../../utils/Hooks/UseLanguageHook';
import { useCustomMessageHook } from '../../utils/Hooks/useCustomMessageHook';
import { customFormValidator, customFormValidatorInline, requireValidMessage, splitLastIfMultiple } from '../../utils/common';
import { TRANS_KEYS } from '../../utils/languageNamespaces';
import { createRequiredFieldEducationDetails, createRequiredFieldWorkExperience, createSchemaEducationDetails, createSchemaWorkExperience, getCleanedContact, validateDate } from '../AddContact/utils';
import { checkDuplicateEmails, validateEmail } from '../MessageTemplatePopup/utils';
import CustomConfirmationPopup from '../common/CustomConfirmationPopup';
import DuplicateContactNotifier from '../common/CustomPopup/DuplicateContactNotifier';
import DrawerComponent from '../common/Drawer';
import Loader from '../common/Loader';
import EditContactPopup from './EditContact';
import './index.scss';

const ViewContactDrawer = props => {
  const navigate = useNavigate();
  const { isDrawerOpen, setIsDrawerOpen, id, setIsEdited = () => {}, navigateToAllContacts = true, baseRoute, isCandidate = false, project_id = null, candidate_id = null } = props;
  const [selectedTab, setSelectedTab] = useState('DETAILS_TAB');
  const [isHeaderNav, setIsHeaderNav] = useState('open');
  const [drawerWidth, setDrawerWidth] = useState('normal');
  const [isWidthApply, setIsWidthApply] = useState(false);
  const [activityDetail, setActivityDetail] = useState(null);
  const [isHeaderBar, setIsHeaderBar] = useState('open');
  const [isIconOpen, setIsIconOpen] = useState('icon-closed');
  const [isHeaderOpen, setIsHeaderOpen] = useState(true);
  const selectedTabRef = useRef();
  selectedTabRef.current = selectedTab;
  const userData = useSelector(getUserSelector);
  const [isConfirmationPopup, setIsConfirmationPopup] = useState(false);
  const [recordData, setRecordData] = useState();
  const [allContact, setAllContact] = useState();
  const [forceCreatePayload, setForceCreatePayload] = useState();
  const {
    register,
    unregister,
    setValue,
    /* reset, */
    /* trigger, */
    control,
    getValues,
    watch
  } = useForm({});
  // const [currentValues, setCurrentValues] = useState({ ...defaultValues });
  const [contact, setContact] = useState(null);
  const [tabData, setTabData] = useState(null);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editingField, setEditingField] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [offLimitItems, setOffLimitItems] = useState([]);
  const [isLogAnActivityOpen, setIsLogAnActivityOpen] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const changeImage = useRef();
  const popupClose = useRef();
  const [projectAssociatedData, setProjectAssociatedData] = useState([]);

  const [fitScoreData, setFitScoreData] = useState(null);

  const childRef = useRef();
  popupClose.current = false;
  const { translateMessage } = useCustomMessageHook();
  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_RECENT_ENGAGEMENT,
    TRANS_KEYS.CONTACTS_SOURCE,
    TRANS_KEYS.CONTACTS_TAGS,
    TRANS_KEYS.CONTACTS_ADDED_BY,
    TRANS_KEYS.CONTACTS_PROJECTS,
    TRANS_KEYS.CONTACTS_ADD_TO,
    TRANS_KEYS.CONTACTS_ADD_TO_PROJECT,
    TRANS_KEYS.CONTACTS_VIEW_RESUME
  ]);

  // const getJobInfo = (jobInfoArray) => {
  //   const nejobInfoArray = jobInfoArray.filter((item) => item !== null && item !== undefined);
  //   const commaSeparatedJobInfo = nejobInfoArray.join(", ");
  //   return commaSeparatedJobInfo || "-";
  // };

  const handleTabChange = async tabName => {
    const cleanedTabName = tabName.replace(/-/g, '_');
    const formattedTabName = cleanedTabName.toUpperCase();
    const newTabName = `${formattedTabName}_TAB`;
    setSelectedTab(newTabName);
  };

  useEffect(() => {
    getContactDetails();
  }, [selectedTabRef.current]);

  const contactStatus = useSelector(state => state.commonReducer.contactStatus);

  const dispatch = useDispatch();

  const [updateEditData, setUpdateEditData] = useState(false);

  useEffect(() => {
    if (!contactStatus) {
      dispatch(fetchPickLists('CONTACT_STATUS', 'contactStatus'));
    } else {
      setOffLimitItems(contactStatus);
    }
  }, [contactStatus, dispatch]);

  const getLocation = address => {
    if (address?.filter(item => item?.is_primary === true).length > 0) {
      console.log(address?.filter(item => item?.is_primary === true)[0]?.metropolitan_area, 'address?.filter(item => item?.is_primary === true)[0]?.metropolitan_area');
      return address?.filter(item => item?.is_primary === true)[0]?.metropolitan_area;
    } else {
      console.log(address[0]?.metropolitan_area, 'address[0]?.metropolitan_area');
      return address[0]?.metropolitan_area || '-';
    }
  };
  const cleanupData = async data => {
    let contactObject = {};
    contactObject = {
      ...data,
      avatar: profileUrl,
      resume: true,
      viewResumeButton: t(`${TRANS_KEYS.CONTACTS_VIEW_RESUME}:key`),
      descText: true,
      sourceTitle: t(`${TRANS_KEYS.CONTACTS_SOURCE}:key`) + ':',
      tagsTitle: t(`${TRANS_KEYS.CONTACTS_TAGS}:key`) + ':',
      addedByTitle: t(`${TRANS_KEYS.CONTACTS_ADDED_BY}:key`) + ':',
      projectsTitle: t(`${TRANS_KEYS.CONTACTS_PROJECTS}:key`) + ':',
      linkedinIcon: <LinkedInIcon className='linkedin-icon-header fs-14' />,
      editIcon: (
        <EditContactPopup
          contactId={id}
          prevValues={data}
          updateData={updateEditData}
          updateHeader={async () => {
            await getContactDetails();
            await getHeaderDetails();
          }}
        />
      ),
      /* deleteIcon: (
        <IconButton className="delete-icons p-0" onClick={deleteItem}>
          <img src={delete_icon} alt="" />
        </IconButton>
      ), */
      deleteIcon: <DeleteIcon className='delete-icons p-0 fs-15' onClick={deleteItem}></DeleteIcon>,
      addButtonLabel: t(`${TRANS_KEYS.CONTACTS_ADD_TO_PROJECT}:key`),
      additionalContent: {
        jobTitle: {
          icon: <PersonIcon className='content-icon  fs-14 mr-2' />,
          text: data?.current_job_title,
          onClick: () => {}
        },
        [CONTACT_DRAWER_HEADER_ADDITION_CONTENT_KEYS.JOB_INFO]: {
          icon: <WorkIcon className='content-icon  fs-14 mr-2' />,
          text: data?.contact_company?.name,
          onClick: () => navigate(`${ROUTES.allCompanies}/${data?.current_company_id}`)
        },
        [CONTACT_DRAWER_HEADER_ADDITION_CONTENT_KEYS.ADDRESS]: {
          icon: <LocationOnIcon className='content-icon  fs-14 mr-2' />,
          text: getLocation(data.contact_address),
          onClick: () => {}
        },
        [CONTACT_DRAWER_HEADER_ADDITION_CONTENT_KEYS.JOB_INFO]: {
          icon: <WorkIcon className='content-icon  fs-14 mr-2' />,
          // text: getJobInfo([data?.current_job_title, data?.contact_company?.name]),
          text: data?.contact_company?.name,
          onClick: () => navigate(`${ROUTES.allCompanies}/${data?.current_company_id}`)
        }
      },
      lastActivityTime: {
        icon: <Box color={'gray'}>Phone number: </Box>,
        text: (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexWrap: 'nowrap',
              width: 'fit-content'
            }}
          >
            {data?.contact_phones?.filter(item => item?.is_primary === true)[0]?.phone_number || '-'}{' '}
            <Button
              variant='text'
              sx={{
                display: data?.contact_phones?.filter(item => item?.is_primary === true)?.length > 0 ? 'block' : 'none',
                padding: '0px',
                margin: '0px'
              }}
              onClick={() => {
                const phoneNumber = data?.contact_phones?.filter(item => item?.is_primary === true)[0]?.phone_number;
                navigator.clipboard.writeText(phoneNumber);
                enqueueSnackbar('Phone number copied to clipboard', { variant: 'success' });
              }}
            >
              {' '}
              <ContentCopyIcon fontSize='fs-12' />
            </Button>
          </Box>
        ),
        onClick: () => {}
      },
      recentEngagement: {
        title: 'Email: ',
        text: (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexWrap: 'nowrap',
              width: 'fit-content'
            }}
          >
            {data?.contact_emails?.filter(item => item?.is_primary === true)[0]?.email || '-'}{' '}
            <Button
              variant='text'
              sx={{
                display: data?.contact_emails?.filter(item => item?.is_primary === true)?.length > 0 ? 'block' : 'none',
                padding: '0px',
                margin: '0px'
              }}
              onClick={() => {
                const email = data?.contact_emails?.filter(item => item?.is_primary === true)[0]?.email;
                navigator.clipboard.writeText(email);
                enqueueSnackbar('Email copied to clipboard', { variant: 'success' });
              }}
            >
              {' '}
              <ContentCopyIcon fontSize='fs-12' />
            </Button>
          </Box>
        )
      },
      addedBy: {
        name: data?.contact_added_by?.length > 0 ? data?.contact_added_by[0]?.added_user?.name : '-',
        icon: <CheckCircleIcon className='circle-icon fs-14' />
      },
      projects: [],
      actionItems: ['Project', 'List'],
      disableEditing: false
    };
    return contactObject;
  };
  const getContactDetails = async () => {
    try {
      setLoading(true);
      const sub_route = `?tabName=${selectedTabRef.current}`;
      const { status, data } = await ignContactDataApi(GET, id, {}, sub_route);
      if (status === 200 && data) {
        setTabData(data);
        setLoading(false);
      }
      if (status === 500) {
        setLoading(false);
      }
    } catch (err) {
      console.log('error in getContactDetails::', err);
    }
  };

  const getHeaderDetails = async () => {
    try {
      setLoading(true);
      // const activity = await getActivityDetails();
      const sub_route = '';
      const { status, data } = await ignContactDataApi(GET, id, {}, sub_route);
      if (status === 200 && data) {
        const result = await cleanupData(data);
        setUpdateEditData(!updateEditData);
        setContact({
          data: result,
          avatarSizeXs: 2,
          avatarSizeMd: 1.3,
          avatarSizeSm: 2,
          mainContentWidthXs: 8,
          mainContentWidthMd: 9.5,
          mainContentWidthSm: 8,
          buttonSizeXs: 2,
          buttonSizeMd: 1.2,
          buttonSizeSm: 2,
          height: '24vh'
        });
        setLoading(false);
      }
    } catch (err) {
      console.log('error in getContactDetails::', err);
    }
  };
  const getAllContactDetails = async () => {
    try {
      const { status, data } = await ignContactDataApi(GET, id, {});
      if (status === 200 && data) {
        setAllContact(data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getActivityDetails = async () => {
    try {
      setLoading(true);
      const sub_route = 'activity';
      const { status, data } = await logAnActivityApi(GET, id, null, sub_route);
      if (status === 200) {
        setActivityDetail(data);
        setLoading(false);
      }
      return data;
    } catch (error) {
      console.log('error in getActivityDetails::', error);
    }
  };
  const getFitScoreDetails = async () => {
    try {
      setLoading(true);
      const sub_route = `?project_id=${project_id}&candidate_id=${candidate_id}`;
      const { status, data } = await FitScoreDataApi(GET, sub_route);
      if (status === 200) {
        // setActivityDetail(data);
        setFitScoreData(data);
        setLoading(false);
      }
      return data;
    } catch (error) {
      console.log('error in getActivityDetails::', error);
    }
  };

  useEffect(() => {
    if (id) {
      getAllContactDetails();
      getHeaderDetails();
      getContactDetails();
      getActivityDetails();
      if (isCandidate) getFitScoreDetails();
      fetchData();
    }
  }, [id]);

  // const getMostRecentActivity = activityInfo => {
  //   const activityData = activityInfo?.activity;
  //   if (activityData && activityData.length > 0) {
  //     activityData.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
  //     const mostRecentActivityData = activityData[0];
  //     mostRecentActivityNotesType.current = mostRecentActivityData?.ign_translate_value?.short_desc;
  //     mostRecentActivityNotes.current = mostRecentActivityData.notes;
  //     const mostRecentActivityTimeAgo = moment.utc(mostRecentActivityData?.updated_at).local().startOf('seconds').fromNow();
  //     return `Last Activity ${mostRecentActivityTimeAgo}`;
  //   }
  // };

  const deleteItem = () => {
    setOpenConfirmationPopup(true);
  };

  const onConfirm = async () => {
    setLoading(true);
    const { status } = await ignContactDataApi(DELETE, id);
    if (status === 200) {
      setLoading(false);
      setContact(null);
      setIsEdited(true);
      setIsDrawerOpen(false);
      setState({ ...state, ['right']: false });
      console.log('testees');
      navigate('/contacts/list/all-contacts');
      const message = successMessage('Contact', 'Deleted');
      enqueueSnackbar(message, { variant: SUCCESS });
    }
  };

  const closeConfirmationPopup = () => {
    setOpenConfirmationPopup(false);
  };

  const toggleDrawer = (anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    /* for recently viewed contact 
    const recentEngagement = async () => {
      let data = {};
      setLoading(true);
      const { status } = await ignContactDataApi(PATCH, id, data, "recent-engagement");
      if (status === 200) {
        setLoading(false);
      }
    }
    recentEngagement();
    */

    if (!open) setIsDrawerOpen(false);
    setState({ ...state, [anchor]: open });
    if (navigateToAllContacts) {
      console.log('testees');
      navigate('/contacts/list/all-contacts');
    }
  };

  useEffect(() => {
    if (isDrawerOpen) setState({ ...state, ['right']: open });
  }, [isDrawerOpen]);

  const updateContactDetails = async (field, required) => {
    const formData = { ...getValues() };
    if (formData.contact_phones && field === 'contact_phones') {
      formData.contact_phones = formData.contact_phones.map(e => ({ ...e, phone_type: e.phone_type?.field_value || e.phone_type }));
    }
    if (formData.contact_emails && field === 'contact_emails') {
      formData.contact_emails = formData.contact_emails.map(e => ({ ...e, email_type: e.email_type?.field_value || e.email_type }));
      const isEmailTypeValid = formData.contact_emails.some(elem => !elem.email_type);
      let isValid = true;
      const isEmailValid = formData.contact_emails.some(elem => !elem.email);
      if (isEmailTypeValid && formData.contact_emails.length < 3 && isEmailValid && formData.contact_emails.length < 3) {
        const message = translateMessage('Required', ...splitLastIfMultiple(['Email type', 'Email']));

        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
      if (isEmailTypeValid && formData.contact_emails.length < 3) {
        const message = translateMessage('Required', false, 'Email type');

        enqueueSnackbar(message, { variant: ERROR });
        return;
      }

      if (isEmailValid && formData.contact_emails.length < 3) {
        const message = translateMessage('Required', false, 'Email');
        enqueueSnackbar(message, { variant: ERROR });
        return;
      } else {
        for (const ele of formData.contact_emails) {
          let validateEmailSyntax = validateEmail(ele.email);
          if (!validateEmailSyntax) {
            isValid = false;
          }
          if (!isValid) {
            const message = translateMessage('ValidFiled', false, 'Email');
            enqueueSnackbar(message, { variant: ERROR });
            return;
          } else {
            if (formData.contact_emails.length > 1) {
              const isDuplicateEmail = checkDuplicateEmails(formData.contact_emails);
              if (isDuplicateEmail) {
                const message = validateMessage('different email ', 'enter');
                enqueueSnackbar(message, { variant: ERROR });
                return;
              }
            }
          }
        }
      }
    }
    if (formData.contact_notes_all && field === 'contact_notes_all') {
      const isBioNotesType = formData?.contact_notes_all?.some(item => item?.notes_type === 'published_bio');
      if (!isBioNotesType) {
        const dirtyField = [];
        const hasNullProjectId = formData?.contact_notes_all?.some(item => !item?.project_id);
        // const isNotes = formData?.contact_notes_all?.some(item => item?.seq === 0 && (!item?.notes || item?.notes === emptyString));
        if (hasNullProjectId) {
          dirtyField.push('Project');
          if (dirtyField.length) {
            const message = requireValidMessage(dirtyField);
            enqueueSnackbar(message, { variant: ERROR });
            return;
          }
        }
      }
    }

    if (formData.contact_name && field === 'contact_name') {
      if (!formData.contact_name.first_name || !formData.contact_name.last_name) {
        const message = translateMessage('Required', ...splitLastIfMultiple(['First Name', 'Last Name"']));

        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }
    if (formData?.birth_date && field === 'birth_date') {
      // const fromDateJsDate = new Date(formData.birth_date);
      const currentDate = new Date();
      const birthDate = new Date(formData?.birth_date);
      if (birthDate.getFullYear() < 1900 || birthDate.getFullYear() > 2100) {
        enqueueSnackbar('Invalid Date', { variant: ERROR });
        return;
      }
      if (birthDate.getTime() > currentDate.getTime()) {
        enqueueSnackbar('Birth Date cannot be future date', { variant: ERROR });
        return;
      }
    }

    if (formData.contact_relationship_type && field === 'contact_relationship_type') {
      const missingFields = [];
      formData['contact_relationship_type'] = formData?.contact_relationship_type.filter(element => {
        const isMissingContactRefId = !element.contact_ref_id ? true : false;
        const isMissingType = !element.type ? true : false;
        if (!(isMissingContactRefId && isMissingType) && (isMissingContactRefId || isMissingType)) {
          if (isMissingContactRefId) {
            missingFields.push('Contact Name');
          }
          if (isMissingType) {
            missingFields.push('Relation Type');
          }
          return;
        }
        return element.contact_ref_id && element.type;
      });
      // if(formData?.contact_relationship_type?.length < 1){
      //   return
      // }
      if (missingFields.length !== 0) {
        const uniqueMissingFields = [...new Set(missingFields)];
        const message = translateMessage('Required', ...splitLastIfMultiple(uniqueMissingFields));

        enqueueSnackbar(message, { variant: ERROR });
        return;
      }
    }

    if (formData[field] !== contact.data[field]) {
      setLoading(true);
      let payload = {
        [field]: getCleanedContact(formData, true, userData)[field]
      };
      if (payload['contact_name']) {
        let fullName = {
          first_name: payload.contact_name.first_name,
          last_name: payload.contact_name.last_name
        };
        payload = fullName;
      }
      const isEmpty = !(Array.isArray(payload[field]) ? payload[field]?.length : payload[field]);
      if (required && isEmpty) {
        let requiredField = [
          { fieldName: 'first_name', label: 'First Name', type: String },
          { fieldName: 'last_name', label: 'Last Name', type: String },
          { fieldName: 'country', label: 'Location', type: String }
        ];

        const dirtyField = customFormValidatorInline(field, requiredField);
        if (dirtyField) {
          const message = requireMessage(dirtyField, 'is');
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        }
      }
      setForceCreatePayload(payload);
      const { status, data } = await ignContactDataApi(PATCH, id, payload);
      if (status === 200) {
        setLoading(false);
        if (id && changeImage.current && field === 'profile_img') {
          await updateImg(formData?.profile_img, id);
        }
        await getContactDetails();
        await getHeaderDetails();
        popupClose.current = true;
        const message = translateMessage('Successfully', false, 'Contact', 'Updated');

        enqueueSnackbar(message, { variant: SUCCESS });
        return true;
      }
      if (status === 500) {
        setLoading(false);
        enqueueSnackbar(`${data.message}:${data.reason}`, { variant: ERROR });
      }
      if (status === 409) {
        setRecordData(data?.data);
        setIsConfirmationPopup(true);
        setLoading(false);
        // enqueueSnackbar(`${data.message}:${data.reason}`, { variant: ERROR });
      }
    }
  };

  const updateField = async (field, required, onCloseClick) => {
    try {
      if (field === 'profile_img') {
        changeImage.current = true;
        setEditingField('');
      }
      // setAddAnother(false);
      let result = await updateContactDetails(field, required, onCloseClick);

      /* Refresh contact grid only if contact edited */
      setIsEdited(true);

      if (result) onCloseClick(field);
      return result;
    } catch (e) {
      console.log('Error found in updateContact::', e);
    }
  };
  const updateImg = async (file, id) => {
    if (!file) return;
    const sub_route = 'image';
    const { status, data } = await ignContactDataApi(POST, id, file, sub_route);
    if (status === 200) {
      const message = translateMessage('Successfully', false, 'Profile image', 'uploaded');

      enqueueSnackbar(data?.message || message, { variant: SUCCESS });
    } else {
      const message = translateMessage('UnableMessage', false, 'Profile image', 'upload');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
  };

  const addCardData = async field => {
    let contactData;
    if (field.field === 'updated') {
      contactData = {
        ...getValues(),
        contact_id: contact?.data?.id,
        from: field.from,
        id: field.card_id
      };
    } else {
      contactData = {
        ...getValues(),
        contact_id: contact?.data?.id,
        from: field.from
      };
    }
    if (field.from === 'Work Experience') {
      let isValid = await createSchemaWorkExperience.isValid(contactData);
      let isValidDate = validateDate(contactData?.start_date, contactData?.end_date);
      if (!isValid) {
        let dirtyField = customFormValidator(contactData, createRequiredFieldWorkExperience);
        if (dirtyField) {
          const message = requireValidMessage(dirtyField);
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        }
      }
      if (!isValidDate.isValid) {
        enqueueSnackbar(isValidDate?.message, { variant: ERROR });
        return false;
      }
      if (contactData?.board_is_present === true) {
        let isBoardValidDate = validateDate(contactData?.board_start_date, contactData?.board_end_date);
        if (!isBoardValidDate.isValid) {
          enqueueSnackbar(isBoardValidDate?.message, { variant: ERROR });
          return false;
        }
      }
    }
    if (field.from === 'Education Details') {
      let isValidDate = validateDate(contactData?.start_date, contactData?.end_date);
      let isValid = await createSchemaEducationDetails.isValid(contactData);
      if (!isValid) {
        let dirtyField = customFormValidator(contactData, createRequiredFieldEducationDetails);
        if (dirtyField) {
          const message = requireValidMessage(dirtyField);
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        }
      }
      if (!isValidDate.isValid) {
        enqueueSnackbar(isValidDate?.message, { variant: ERROR });
        return false;
      }
    }
    const finalPayload = getCleanedContact(contactData, false, userData);
    const { status, data } = contactData.contact_id ? await ignContactDataApi(PATCH, contactData.contact_id, finalPayload) : await ignContactDataApi(POST, '', finalPayload);
    if (status === 201 || status === 200) {
      await getContactDetails();
      const message = successMessage(field.from, field.field);
      enqueueSnackbar(message, { variant: SUCCESS });
      setIsPopupOpen(false);
    }
    if (status === 500) {
      enqueueSnackbar(`${data.message}:${data.reason}`, { variant: ERROR });
    }
  };

  const createContactData = async field => {
    try {
      await addCardData(field);
    } catch (e) {
      console.log('Error found in addContact::', e);
    }
  };

  const headerDrawer = headerState => {
    setIsHeaderNav(headerState);
  };

  const handleClose = () => {
    setIsConfirmationPopup(false);
  };

  const onConfirmData = async () => {
    forceCreatePayload.force = true;
    setLoading(true);
    const { status } = await ignContactDataApi(PATCH, id, forceCreatePayload);
    if (status === 200) {
      setEditingField('');
      setLoading(false);
      setIsConfirmationPopup(false);
      popupClose.current = true;
      if (childRef.current) {
        childRef.current.handleCloseClick('first_name');
      }
      const message = translateMessage('Successfully', false, 'Contact', 'Updated');
      enqueueSnackbar(message, { variant: SUCCESS });
      await getContactDetails();
      await getHeaderDetails();
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);

      const { status, data } = await ProjectCandidateApi(
        GET,
        '/all',
        {},
        {
          contactId: id,
          page: 1,
          limit: 10000000
        }
      );
      if (status === SUCCESS_STATUS_CODE) {
        setProjectAssociatedData(data?.rows || []);
        return data?.rows || [];
      } else {
        // setLoading(false);
        throw new Error(TAG_SETUP_LOGS_MESSAGE.GET_ROWS_FOUND_ERROR);
      }
    } catch (error) {
      console.error(TAG_SETUP_LOGS_MESSAGE.GET_ROWS_FOUND_ERROR, error);
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  const handleProjectClick = () => {
    setActiveTab(4);
    setTabIndex(0);
  };

  return (
    <>
      <DrawerComponent
        setIsMenuOpen={setIsDrawerOpen}
        isMenuOpen={isDrawerOpen}
        id={'Contact_view_drawer'}
        anchor={'right'}
        open={state['right']}
        isViewDrawer={false}
        onClose={toggleDrawer('right', false)}
        closeIconPosition='close-rectangle-position'
        width='90vw'
        drawerWidth={drawerWidth}
        setDrawerWidth={setDrawerWidth}
        isWidthApply={isWidthApply}
        setIsWidthApply={setIsWidthApply}
        transitionType={Slide}
      >
        <>
          <Loader show={loading} />
          {contact && (
            <Box className='d-flex drawer-body'>
              <Box className='contact-details-head'>
                <Box className='sub-contact-details'>
                  <ViewContactHeader
                    {...contact}
                    offLimitItems={offLimitItems}
                    updateField={updateField}
                    register={register}
                    unregister={unregister}
                    watch={watch}
                    setValue={setValue}
                    editingField={editingField}
                    setEditingField={setEditingField}
                    headerDrawer={headerDrawer}
                    setIsHeaderBar={setIsHeaderBar}
                    isHeaderBar={isHeaderBar}
                    isIconOpen={isIconOpen}
                    setIsIconOpen={setIsIconOpen}
                    setIsHeaderOpen={setIsHeaderOpen}
                    isHeaderOpen={isHeaderOpen}
                    setIsHeaderNav={setIsHeaderNav}
                    isHeaderNav={isHeaderNav}
                    ref={childRef}
                    getHeaderDetails={getHeaderDetails}
                    setLoading={setLoading}
                    allContact={allContact}
                    fetchProjectAndActivitiesData={fetchData}
                    projectAndActivitiesData={projectAssociatedData}
                    handleProjectClick={handleProjectClick}
                  ></ViewContactHeader>
                  <motion.div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      height: '100%'
                    }}
                  >
                    <motion.div
                      animate={{
                        width: isLogAnActivityOpen ? '63%' : '95%'
                      }}
                    >
                      <ContactDetails
                        id={id}
                        data={tabData}
                        activityDetails={activityDetail}
                        register={register}
                        unregister={unregister}
                        control={control}
                        watch={watch}
                        getValues={getValues}
                        setValue={setValue}
                        isHeaderNav={isHeaderNav}
                        handleSave={createContactData}
                        updateField={updateField}
                        getContactDetails={getContactDetails}
                        isPopupOpen={isPopupOpen}
                        setIsPopupOpen={setIsPopupOpen}
                        onTabChange={handleTabChange}
                        popupClose={popupClose}
                        baseRoute={baseRoute}
                        fetchData={fetchData}
                        projectAssociatedData={projectAssociatedData}
                        isCandidate={isCandidate}
                        isLogAnActivityOpen={isLogAnActivityOpen}
                        fitScoreData={fitScoreData}
                        getFitScoreDetails={getFitScoreDetails}
                        project_id={project_id}
                        candidate_id={candidate_id}
                        tabIndex={tabIndex}
                        setTabIndex={setTabIndex}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                      />
                    </motion.div>
                    <motion.div
                      animate={{
                        width: isLogAnActivityOpen ? '32%' : '1%'
                      }}
                      style={{
                        position: 'relative'
                      }}
                    >
                      <motion.div
                        animate={{
                          rotate: isLogAnActivityOpen ? 180 : 0,
                          left: isLogAnActivityOpen ? '10px' : '-10px'
                        }}
                        className='toggle-log-an-activity'
                        onClick={() => {
                          setIsLogAnActivityOpen(!isLogAnActivityOpen);
                        }}
                      >
                        <ExpandMoreRoundedIcon cursor='pointer' className={`log-an-activity-arrow-icon fs-20 ${!isIconOpen}`} />
                      </motion.div>

                      <motion.div
                        animate={{
                          display: isLogAnActivityOpen ? 'block' : 'none'
                        }}
                        style={{
                          height: '100%'
                        }}
                      >
                        <ActivityLogs id={id} setDrawerWidth={setDrawerWidth} getHeaderDetails={getHeaderDetails} isHeaderNav={isHeaderNav} project_id={project_id} />
                      </motion.div>
                    </motion.div>
                  </motion.div>
                  <CustomConfirmationPopup
                    open={openConfirmationPopup}
                    onClose={closeConfirmationPopup}
                    type='delete'
                    deletingItem='contact'
                    deletingItemName={contact?.data?.name}
                    cancelText='No'
                    confirmText='Yes'
                    onConfirm={onConfirm}
                    setOpen={setOpenConfirmationPopup}
                  />
                  {isConfirmationPopup && <DuplicateContactNotifier open={isConfirmationPopup} data={recordData} handleClose={handleClose} onConfirmData={onConfirmData} label={'Update'} />}
                </Box>
              </Box>
            </Box>
          )}
        </>
      </DrawerComponent>
    </>
  );
};

ViewContactDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string,
  isDrawerOpen: PropTypes.bool,
  setIsDrawerOpen: PropTypes.func,
  setIsEdited: PropTypes.func,
  navigateToAllContacts: PropTypes.bool,
  baseRoute: PropTypes.string,
  isCandidate: PropTypes.bool,
  project_id: PropTypes.string,
  candidate_id: PropTypes.string
};

export default ViewContactDrawer;
