import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickListData } from '../../../../actions';
import CustomDropdown from '../../CustomDropdown';

const LevelOfEducationTypeSelection = props => {
  const { onChange = () => {}, label = '', defaultValue, required = false, ...res } = props;
  const [options, setOptions] = React.useState([]);
  const degrees = useSelector(state => state.commonReducer.degrees);
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (!degrees) {
      dispatch(fetchPickListData('degree-picklist?name=', 'degrees'));
    } else {
      let array = [];
      degrees.data?.map(data => array.push({ label: data.name, value: data.name }));
      setOptions(array);
    }
  }, [degrees, dispatch]);

  return <CustomDropdown {...res} options={options} onChange={onChange} label={label} value={value} required={required} />;
};

LevelOfEducationTypeSelection.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool
};

export default LevelOfEducationTypeSelection;
