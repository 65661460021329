import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import Footer from '../Footer';
import Loader from '../Loader';
import './index.scss';

export const POPUP_CLOSE_REASONS = {
  BACKDROP_CLICK: 'backdropClick'
};

const CustomPopup = props => {
  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_SAVE, TRANS_KEYS.CONTACTS_CANCEL]);
  const {
    open,
    children,
    onClose,
    cancelText = t(`${TRANS_KEYS.CONTACTS_CANCEL}:key`),
    confirmText = t(`${TRANS_KEYS.CONTACTS_SAVE}:key`),
    title = '',
    icons,
    handleSubmit,
    showAction = false,
    dropdown = false,
    overFlowClass = '',
    loading = false,
    disabled = false,
    popupHeight = '',
    width = 'auto',
    paperStyles,
    titleClassName = '',
    headerClassName,
    hideDivider = false,
    popupClassName = '',
    titleIcon
  } = props;

  return (
    <Dialog
      classes={{
        paper: paperStyles
      }}
      open={open}
      id='custom-popup'
      className={popupClassName}
      keepMounted={false}
      onClose={onClose}
    >
      <Loader show={loading} />
      <Box className={`d-flex justify-content-between align-items-center ${headerClassName}`} p={2}>
        <DialogTitle className={`custom-popup-title ${titleClassName}`}>
          {title}
          {titleIcon}
        </DialogTitle>
        <Box className='icon-section'>
          {icons &&
            icons.length &&
            icons.map((elem, index) => {
              return <React.Fragment key={index}>{elem}</React.Fragment>;
            })}
          <CloseIcon onClick={onClose} className='close-icon ml-2' />
        </Box>
      </Box>
      {!hideDivider && <Divider />}
      {
        <DialogContent sx={{ height: popupHeight, width: width }} className={`dialog-content ${dropdown ? 'p-0' : ' '} ${overFlowClass} ${!children && 'p-0'}`}>
          {children}
        </DialogContent>
      }
      {showAction && (
        <DialogActions className='dialog-actions pr-4'>
          <Box>
            <Footer onClose={onClose} onSubmit={handleSubmit} submitText={confirmText} closeText={cancelText} disabled={disabled}></Footer>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
};

CustomPopup.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  cancelText: PropTypes.string,
  confirmText: PropTypes.func,
  title: PropTypes.string,
  icons: PropTypes.array,
  handleSubmit: PropTypes.func,
  showAction: PropTypes.bool,
  dropdown: PropTypes.bool,
  overFlowClass: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  hideDivider: PropTypes.bool,
  popupHeight: PropTypes.string,
  width: PropTypes.string,
  paperStyles: PropTypes.string,
  titleClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  popupClassName: PropTypes.string,
  titleIcon: PropTypes.any
};

export default CustomPopup;
