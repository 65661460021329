import { Box, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import InputField from '../../../../../components/common/InputField';
import { useLanguageHooks } from '../../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../../utils/languageNamespaces';
import { ProjectDataContext } from '../../Context';

export default function WorkExperience() {
  const { projectData, handleUniversalState } = useContext(ProjectDataContext);
  //Translate
  const { t } = useLanguageHooks([TRANS_KEYS.MANDATORY, TRANS_KEYS.ADD_PROJECT_LABEL_MIN_YEARS_OF_EXPERIENCE, TRANS_KEYS.ADD_PROJECT_LABEL_MAX_YEARS_IN_CURRENT_COMPANY]);
  const [minExperience, setMinimumExperience] = useState({
    value: '',
    isMandate: false,
    loaded: false
  });
  const [maxExperience, setMaximumExperience] = useState({
    value: '',
    isMandate: false,
    loaded: false
  });

  const handleUniversalStateMinExperienceLocally = updatedVal => {
    const event = {
      //TODO
      target: {
        name: 'minExperience',
        value: updatedVal
      }
    };
    handleUniversalState(event);
  };
  const handleUniversalStateMaxExperienceLocally = updatedVal => {
    const event = {
      //TODO
      target: {
        name: 'maxExperience',
        value: updatedVal
      }
    };
    handleUniversalState(event);
  };

  const handleMinExperienceChange = (key, value) => {
    console.log({ key, value });
    minExperience[key] = value;
    const newExperience = { ...minExperience };
    setMinimumExperience(newExperience);
    handleUniversalStateMinExperienceLocally(newExperience);
  };
  const handleMaxExperienceChange = (key, value) => {
    maxExperience[key] = value;
    const newExperience = { ...maxExperience };
    setMaximumExperience(newExperience);
    handleUniversalStateMaxExperienceLocally(newExperience);
  };

  useEffect(() => {
    //TODO
    if (!minExperience.loaded || !maxExperience.loaded) {
      console.log(projectData?.work_experience_min_years_experience);
      setMinimumExperience({
        ...minExperience,
        value: projectData?.work_experience_min_years_experience,
        isMandate: projectData?.experience_mandatory,
        loaded: true
      });
      setMaximumExperience({
        ...maxExperience,
        value: projectData?.work_experience_max_years_current_company,
        isMandate: projectData?.max_experience_mandatory,
        loaded: true
      });
    }
    // if (minExperience.length == 0 && projectData?.minExperience) {
    // }
    // if (maxExperience.length == 0 && projectData?.maxExperience) {
    // }
  }, [projectData]);
  return (
    <Box>
      <Stack direction='row' className='w-50'>
        <InputField
          className={'w-100'}
          label={t(`${TRANS_KEYS.ADD_PROJECT_LABEL_MIN_YEARS_OF_EXPERIENCE}:key`)}
          onChange={event => handleMinExperienceChange('value', event?.target?.value)}
          value={minExperience.value}
        />
        <Stack direction='row' alignItems={'center'}>
          <input type='checkbox' className='m-1' checked={minExperience.isMandate} onChange={() => handleMinExperienceChange('isMandate', !minExperience.isMandate)} />
          <Typography variant='caption'>{t(`${TRANS_KEYS.MANDATORY}:key`)}</Typography>
        </Stack>
      </Stack>
      <Stack direction='row' className='mt-2 w-50'>
        <InputField
          className={'w-100'}
          label={t(`${TRANS_KEYS.ADD_PROJECT_LABEL_MAX_YEARS_IN_CURRENT_COMPANY}:key`)}
          onChange={event => handleMaxExperienceChange('value', event?.target?.value)}
          value={maxExperience.value}
        />
        <Stack direction='row' alignItems={'center'}>
          <input type='checkbox' className='m-1' checked={maxExperience.isMandate} onChange={() => handleMaxExperienceChange('isMandate', !maxExperience.isMandate)} />
          <Typography variant='caption'>{t(`${TRANS_KEYS.MANDATORY}:key`)}</Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
