//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useForm } from 'react-hook-form';
import { DeleteOutlined, EditOutlined } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { formatDate, formatTime } from '../../../../../../utils/date';
import Loader from '../../../../../common/Loader';
import LogAnActivity from '../LogAnActivity';
import RichTextPopup from '../../../../../RichTextPopup';
// import * as yup from "yup";
import { getUserSelector } from '../../../../../../selectors';
import ConfirmationPopup from '../../../../../ConfirmationPopup';
import { activityFormSchema } from '../../../../utils';
import { getActivityIcon } from './utils';
import { acquireLockApi, activitiesDataApi, releaseLockApi } from '../../../../../../services/ApiService';
import { DELETE, GET, PUT, SUCCESS, ERROR } from '../../../../../../services/constantService';
import { unableMessage, VALIDATION_MESSAGE, successMessage } from '../../../../../../services/MessageService';
import { showSnackbar } from '../../../../../../Containers/Commons/Utils';
import { customFormValidator, requireValidMessage } from '../../../../../../utils/common';
// const validationSchema = yup.object().shape({
//   activity: yup.string().required("Please fill data"),
//   type: yup.string().required(),
//   subject: yup.string().required("Required"),
//   direction: yup.string().required(),
// })

export default function ActivityHistoryDetails(props) {
  const { contact, setActivityCurrentValues, setActivityHistoriesForParent, readOnly } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [selectedHistory, setSelectedHistory] = useState(null);
  const [activityHistories, setActivityHistories] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [saveStatus, setSaveStatus] = useState(true);
  const { register, watch, setValue, reset, handleSubmit, getValues } = useForm();
  const user = useSelector(getUserSelector);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [getIndex, setIndex] = useState();

  useEffect(() => {
    const getData = async id => {
      setLoading(true);
      const sub_route = `contactId=${id}&limit=200`;
      const { status, data } = await activitiesDataApi(GET, sub_route);
      if (status && status === 200) {
        setActivityHistories(data.data);
      } else {
        const message = unableMessage('activities', 'fetch');
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      setLoading(false);
    };
    getData(contact.id).then(null);
  }, [contact.id, enqueueSnackbar]);

  useEffect(() => {
    typeof setActivityCurrentValues === 'function' &&
      setActivityCurrentValues(prevState => ({
        ...prevState,
        activities: activityHistories
      }));
    typeof setActivityHistoriesForParent === 'function' && setActivityHistoriesForParent(activityHistories);
  }, [activityHistories]);

  useEffect(() => {
    if (activityHistories?.length > 0 && editIndex === -1) {
      setSelectedHistory(activityHistories[0]);
    }
  }, [activityHistories, editIndex]);

  const getContactName = () => {
    return `${contact.first_name || ''} ${contact.last_name || ''}`;
  };

  const handleDeleteRecord = index => {
    if (editIndex !== -1) return;
    setPopupOpen(true);
    setIndex(index);
  };

  const handleClose = () => {
    setPopupOpen(false);
  };

  const getUser = details => {
    if (details.updated_user) {
      return details.updated_user.name;
    }
    return details.created_user.name;
  };

  const handleEdit = (e, index) => {
    if (editIndex !== -1) {
      e.stopPropagation();
      enqueueSnackbar(VALIDATION_MESSAGE.there_edit, { variant: ERROR });
      return;
    }
    reset({ ...activityHistories[index], activity_created_at: activityHistories[index].created_at });
    setEditIndex(index);
  };

  const handleDelete = async () => {
    const index = getIndex;
    if (editIndex === index) {
      enqueueSnackbar(VALIDATION_MESSAGE.edit, { variant: ERROR });
      return;
    }
    setLoading(true);
    const sub_route = `activity/${activityHistories[index].id}`;
    const { status, data } = await activitiesDataApi(DELETE, sub_route);
    if (status === 200) {
      const message = successMessage('Activity', 'deleted');
      enqueueSnackbar(message, { variant: SUCCESS });
    } else {
      const message = unableMessage('activity', 'delete');
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    setActivityHistories(prevState => {
      const activities = [...prevState];
      activities.splice(index, 1);
      return activities;
    });
    setSelectedHistory(data);
    setLoading(false);
    handleClose();
  };

  const handleCancel = () => {
    setPopupOpen(false);
    setEditIndex(-1);
    releaseLock();
  };

  const activityFormSchemas = yup.object().shape({
    activity: yup.string().required(),
    type: yup.string().required(),
    subject: yup.string().required(),
    direction: yup.string().required()
  });

  const handleUpdate = async (formData, ...rest) => {
    try {
      let formValues = getValues();
      const isValid = await activityFormSchemas.isValid(formValues);
      if (!isValid) {
        let requiredField = [
          { fieldName: 'activity', label: 'Activity', type: String },
          { fieldName: 'type', label: 'Type', type: String },
          { fieldName: 'subject', label: 'Subject', type: String },
          { fieldName: 'direction', label: 'Direction', type: String }
        ];
        let dirtyField = customFormValidator(formValues, requiredField);
        if (dirtyField) {
          const message = requireValidMessage(dirtyField);
          enqueueSnackbar(message, { variant: ERROR });
          return;
        }
      }
      if (await activityFormSchema(contact.id).isValid(formValues)) {
        if (formData.activity_created_at) {
          formData.created_at = formData.activity_created_at;
        }
        formData.updated_at = new Date();
        delete formData.activity_created_at;
        const sub_route = `activity/${activityHistories[editIndex].id}`;
        const { status, data } = await activitiesDataApi(PUT, sub_route, formData);
        if (status === 200) {
          const activities = [...activityHistories];
          activities[editIndex] = {
            ...activities[editIndex],
            ...formData
          };
          setActivityHistories(activities);
          setSelectedHistory(activities[editIndex]);
          setSaveStatus(true);
          if (!rest[0].autoSave) {
            setEditIndex(-1);
            const message = successMessage('Activity', 'updated');
            enqueueSnackbar(message, { variant: SUCCESS });
          }
        } else {
          setSaveStatus(false);
          const message = unableMessage('activity', 'update');
          enqueueSnackbar(data?.message || message, { variant: ERROR });
        }
      }
      setLoading(false);
      releaseLock();
    } catch (e) {
      console.log('Error found in handleUpdate::', e);
    }
  };
  const releaseLock = async () => {
    await releaseLockApi(contact.id);
  };
  const renderHistoryRow = (details, index) => {
    return (
      <div
        className={`activity-history-card d-flex cursor-pointer ${details.id === selectedHistory?.id ? 'selected-card' : ''}`}
        key={details.id}
        onClick={() => {
          if (editIndex !== -1) {
            enqueueSnackbar(VALIDATION_MESSAGE.edit_save, { variant: ERROR });
            return;
          }
          setSelectedHistory(details);
        }}
        style={{ backgroundColor: `${details.id === selectedHistory?.id ? 'rgba(232, 230, 251, 0.8)' : ''}` }}
      >
        <div className='d-flex flex-column activity-icons'>
          <div className='d-flex align-items-start'>
            <span className='activity-icon' style={{ marginRight: '10px' }}>
              {getActivityIcon(details.activity)}
            </span>
            <span className='activity-icon activity-direction' style={{ marginRight: '10px' }}>
              {details.direction === 'Incoming' ? <ArrowBackIcon fontSize='inherit' /> : <ArrowForwardIcon fontSize='inherit' />}
            </span>
          </div>
          <span className='history-details d-flex mt-2'>
            <span className='history-value' style={{ whiteSpace: 'pre-line' }}>
              {details.type || ''}
            </span>
          </span>
        </div>
        <div className='history-data d-flex flex-grow-1 flex-column'>
          <div className='history-details d-flex  flex-wrap'>
            <span className='history-value'>{getContactName()}</span>
            {details.search != null && (
              <>
                <span className='ml-2 mr-2'>|</span>
                <Typography color='primary' className='history-value'>
                  {details?.search?.company?.name}
                </Typography>
                <span className='ml-2 mr-2'>|</span>
                <span className='history-value'>{details && details.search && details?.search?.job_title}</span>
              </>
            )}
          </div>
          <span className='history-date' style={{ marginRight: '10px' }}>
            {formatDate(details.udpated_at ? details.udpated_at : details.created_at) || ''} | {formatTime(details.updated_at) || ''}
          </span>
          <div className='history-details d-flex flex-wrap'>
            <span className='history-lable' style={{ marginRight: '10px' }}>
              By:
            </span>
            <span className='history-value'>{getUser(details)}</span>
          </div>
          <div className='history-details d-flex history-subject flex-wrap'>
            <span className='history-lable' style={{ marginRight: '10px' }}>
              Subject:
            </span>
            <span className='history-value'>{details.subject || ''}</span>
          </div>
          <div className='d-flex flex-wrap'>
            <div className='history-details d-flex' style={{ overflow: 'hidden' }}>
              <div className='history-lable' style={{ marginRight: '10px' }}>
                Comments:
              </div>
              <div className='history-value text-truncate outlined-border transform p-0' style={{ width: '260px' }}>
                <RichTextPopup setOpen={false} spfaSkillFlag={true} value={details.comments || ''} />
              </div>
            </div>
          </div>
        </div>
        {!readOnly && details.created_user.id === user?.id ? (
          <div className='d-flex flex-grow-1 flex-column mr-2 mt-5'>
            <EditOutlined
              className='mb-1'
              color='primary'
              onClick={async e => {
                let { status, data } = await acquireLockApi(contact.id);
                if (status && status === 200 && data.message && data.user === false) {
                  //if (data.isAdmin) {
                  const object = {
                    enqueueSnackbar: enqueueSnackbar,
                    closeSnackbar: closeSnackbar,
                    message: data.message,
                    id: contact.id
                  };
                  showSnackbar(object);
                  /*  }
                 else {
                   enqueueSnackbar(data.message, { variant: 'warning' });
                 } */
                } else {
                  handleEdit(e, index);
                }
              }}
            />
            <DeleteOutlined color='primary' onClick={() => handleDeleteRecord(index)} />
          </div>
        ) : null}
      </div>
    );
  };

  const renderDetailsProperty = (label, value) => {
    return (
      <div className='d-flex align-items-center mb-1'>
        <Typography color='primary' style={{ flex: '1 0', paddingRight: '10px' }}>
          {label}
        </Typography>
        <span style={{ flex: '2.5 0' }}>{value}</span>
      </div>
    );
  };

  const renderActivityDetails = () => {
    if (!setSelectedHistory) return null;
    return editIndex !== -1 ? (
      <div className='d-flex flex-column' style={{ paddingLeft: '10px' }}>
        <h4 className='mb-4 mt-3'>Edit Activity</h4>
        <LogAnActivity
          register={register}
          watch={watch}
          setValue={setValue}
          fromHistoryPopup={true}
          isContactActivityLog={true}
          currentValues={selectedHistory}
          autoSaveSubmit={() => handleUpdate(getValues(), { autoSave: true })}
          saveStatus={saveStatus}
          contact={contact}
          selectedHistory={selectedHistory}
        />
        <div className='d-flex justify-content-end bottom_align'>
          <Button variant='text' className='mr-4' color='primary' onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant='contained' className='ml-2 mr-5' color='primary' onClick={handleSubmit(handleUpdate)}>
            Update
          </Button>
        </div>
      </div>
    ) : (
      <div className='d-flex flex-column' style={{ paddingLeft: '10px' }}>
        <div className='d-flex flex-column activity-info'>
          <h6 className='mb-2'>Activity Details</h6>
          <div className='d-flex'>
            <div className='flex-grow-1'>
              {renderDetailsProperty('Activity', selectedHistory.activity)}
              {renderDetailsProperty('Type', selectedHistory.type)}
              {renderDetailsProperty('By', selectedHistory.updated_user ? selectedHistory.updated_user.name : selectedHistory.created_user ? selectedHistory.created_user.name : '')}
              {selectedHistory.search &&
                renderDetailsProperty('Search ', `${selectedHistory.search.job_number || '-'} - ${selectedHistory.search.job_title || '-'} (${selectedHistory.search.company?.name || '-'})`)}
            </div>
            <div className='flex-grow-1'>{renderDetailsProperty('Direction', selectedHistory.direction)}</div>
          </div>
        </div>
        <div className='activity-comments'>
          <h6>Comments</h6>
          <span>
            <div className='outlined-border transform p-0'>
              <RichTextPopup setOpen={false} strengthsAndPotentialIssues={true} value={selectedHistory.comments || ''} />
            </div>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className='d-flex flex-grow-1 activity-histories-data position-relative'>
      <Loader show={isLoading} />
      {isPopupOpen ? <ConfirmationPopup message='Are you sure you want to permanently delete this record from the Galaxy database?' onClose={handleClose} onConfirm={handleDelete} /> : null}
      <div className='activity-list' style={{ width: '50%' }}>
        {activityHistories && activityHistories?.length > 0 ? (
          activityHistories.map((item, index) => {
            return renderHistoryRow(item, index);
          })
        ) : (
          <h5 style={{ padding: 20 }}>No History</h5>
        )}
      </div>
      <div className='activity-details'>{selectedHistory && renderActivityDetails()}</div>
    </div>
  );
}

ActivityHistoryDetails.propTypes = {
  contact: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  setActivityCurrentValues: PropTypes.func,
  setActivityHistoriesForParent: PropTypes.func,
  readOnly: PropTypes.bool
};
