import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomDropdown from '../../CustomDropdown';
import { picklistDropDownApis } from '../../../../services/ApiService';
import { GET } from '../../../../services/constantService';

const ProjectSelection = props => {
  const { defaultValue, name = '', onChange = () => {}, disabled = false, label, selectedList } = props;
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState('');

  const filterDataOptions = (data, list) => {
    return data?.filter(item => {
      return list.every(listItem => listItem?.projects?.project_id !== item?.id);
    });
  };
  useEffect(() => {
    const getProjectList = async () => {
      const sub_route = `project?name=${value}`;
      const { status, data } = await picklistDropDownApis(GET, sub_route);
      if ((status === 200) & (data.data.length > 0)) {
        let array = [];
        data.data.map(data => array.push({ label: data.job_title, name: data.job_title, id: data?.id ? data?.id : null }));
        if (selectedList && selectedList.length > 0) {
          const filterData = filterDataOptions(array, selectedList);
          setOptions([]);
          setOptions(filterData);
        } else {
          setOptions(array);
        }
      } else {
        setOptions([]);
      }
    };
    getProjectList();
  }, [value, selectedList]);
  return (
    <CustomDropdown
      defaultValue={defaultValue}
      options={options}
      name={name}
      onChange={onChange}
      disabled={disabled}
      onInputChange={(e, val, reason) => {
        if (reason === 'input') {
          setValue(val);
        }
      }}
      label={label}
    />
  );
};
ProjectSelection.propTypes = {
  defaultValue: PropTypes.object,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  selectedList: PropTypes.array,
  label: PropTypes.string
};
export default ProjectSelection;
