import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Chip, Divider, IconButton, Menu, MenuItem, Snackbar, Stack, TextField, Tooltip, Typography } from '@mui/material';
import 'ag-grid-community/dist/styles/ag-grid.css';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ViewContactDrawer from '../../../../components/ViewContactDrawer';
import CustomDropdown from '../../../../components/common/CustomDropdown';
import ResumeParser from '../../../../components/common/ResumeParser';
import {
  CandidateColorLabelDataApi,
  CandidateStageSetupAPi,
  LogCandidateActivityAPI,
  ProjectCandidateApi,
  ignAthenaDataApi,
  ignCompanyDataApi,
  picklistDropDownApis
} from '../../../../services/ApiService';
import { DELETE, DROP_DOWN_PICKLIST, ERROR, GET, PATCH, POST, SUCCESS } from '../../../../services/constantService';
import { SCROLL_TIMEOUT, customFormValidator, renderRichText, splitLastIfMultiple } from '../../../../utils/common';
import { CustomLoadingOverlayComponent, loadColumnStateFromLocalStorage } from '../../../Commons/Utils';
import ChooseFromContact from '../Comman/ChooseFromContact';
import ConfirmModal from '../Comman/ConfirmModal';
// import QuickAdd from '../Comman/QuickAdd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import AddCompany from '../../../../components/AddCompany';
import { ButtonDropDown } from '../../../../components/common/ButtonDropdown';
import CustomButton from '../../../../components/common/CustomButton';
import CustomMuiDropdown from '../../../../components/common/CustomMuiDropdown';
import Loader from '../../../../components/common/Loader';
import QuickAddContact from '../../../../components/common/QuickAddContact/v1/QuickAddContact';
import { QUICK_ADD_CONTACT_COMMON_FIELD } from '../../../../components/common/QuickAddContact/v1/utils/Constants';
import { AthenaEmailInviteModal } from '../../../../utils/AthenaInvite';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';
import useApi from '../../../../utils/Hooks/useApiHook';
import { useCustomMessageHook } from '../../../../utils/Hooks/useCustomMessageHook';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
import { createRequiredField, createSchema } from '../../../Companies/Utils';
import { ProjectDataContext } from '../Context';
import { ColorLegend } from './ColorLegend';
import CustomDropdownFilter, { ColorDropdown } from './CustomDropdownFilter';
import ViewReportDrawer from './ViewReportDrawer';

import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import CustomPopup from '../../../../components/common/CustomPopup';
import OverflowAutocomplete from '../../../../components/common/OverflowAutocomplete';
import RichText from '../../../../components/common/RichText';
import { formatDate } from '../../../../utils/date';
import ColumnFilter from '../../../Commons/ColumnFilter';
import { defaultColumns } from '../../../Contacts/utils';
import '../index.scss';

const PAGE_LIMIT = 200;

const useQuery = () => {
  const search = useParams();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const useStyles = makeStyles({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '180px',
    display: 'block'
  }
});

const TextRender = props => {
  const classes = useStyles();
  return (
    <Tooltip title={props.text} arrow>
      <p className={classes.text}>{props.text}</p>
    </Tooltip>
  );
};

const NameRender = props => {
  const classes = useStyles();
  const handleNameClick = e => {
    e.stopPropagation();
    props.setSelectedCandidate(props.candidate);
    props.setIsDrawerOpen(true);
  };

  let newEntry = props?.candidate?.is_new;

  return (
    <Tooltip title={props.name} arrow>
      <div className={`${classes.text} name-text`} onClick={handleNameClick}>
        {props.name} {newEntry && <Chip label='New' color='primary' size='small' style={{ marginLeft: '.5rem' }} />}
      </div>
    </Tooltip>
  );
};

const ActionRenderer = ({ projectData, item, stages, handleStageChange, handleDeleteCandidate, handleViewAthena, handleInviteToAthena }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openStageAnchorEl, setOpenStageAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openStage, setOpenStage] = useState(false);
  const [viewReport, setViewReport] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useLanguageHooks([TRANS_KEYS.VIEW_REPORTS]);

  const handleMenuOpen = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleCandidateShowClientPortalChange = id => value => {
    ProjectCandidateApi(PATCH, '/', { id, show_in_client_portal: value }).catch(() => {
      enqueueSnackbar('Error updating show in client portal', { variant: ERROR });
    });
  };

  const handleStageMenuOpen = event => {
    event.stopPropagation();
    setOpenStageAnchorEl(event.currentTarget);
    setOpenStage(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleStageClose = event => {
    event.stopPropagation();
    setOpenStageAnchorEl(null);
    setOpenStage(false);
  };

  const changeStage = handleStageChange(item?.id);

  return (
    <div className='flex-align-center'>
      <CheckboxRenderer
        defaultChecked={item?.show_in_client_portal || false}
        hover={'Show in client portal'}
        onChange={event => {
          event?.stopPropagation();
          const checked = event.target.checked;
          handleCandidateShowClientPortalChange(item?.id)(checked);
        }}
      />
      <ViewReportDrawer
        candidateName={(item?.contact?.first_name || '') + ' ' + (item?.contact?.last_name || '')}
        projectName={projectData?.job_title}
        isDrawerOpen={viewReport}
        setIsDrawerOpen={setViewReport}
        loading={loading}
        setLoading={setLoading}
        projectId={projectData?.id}
        contactId={item?.contact_id}
        candidateId={item?.id}
      />
      <IconButton
        onClick={e => {
          e.stopPropagation();
          handleDeleteCandidate(item?.id);
        }}
      >
        <CloseIcon className='red' />
      </IconButton>

      <Box position={'relative'}>
        <IconButton style={{ cursor: 'pointer' }} onClick={handleMenuOpen}>
          <MoreVertIcon />
        </IconButton>

        <Menu
          id='lock-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox'
          }}
        >
          <MenuItem className='fs-14' onClick={handleStageMenuOpen}>
            <Menu
              id='lock-menu'
              anchorEl={openStageAnchorEl}
              open={openStage}
              onClose={handleStageClose}
              MenuListProps={{
                'aria-labelledby': 'lock-button',
                role: 'listbox'
              }}
            >
              {stages &&
                stages?.map(stage => {
                  return (
                    <MenuItem
                      onClick={event => {
                        changeStage(stage.stage_name);
                        handleStageClose(event);
                        handleClose(event);
                      }}
                      className='fs-14'
                      key={stage.stage_name}
                    >
                      {stage.stage_label}
                    </MenuItem>
                  );
                })}
            </Menu>
            Change Stage
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleViewAthena(item);
              handleClose();
            }}
            className='fs-14'
          >
            View Athena
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleInviteToAthena(item);
              handleClose();
            }}
            className='fs-14'
          >
            Invite To Athena
          </MenuItem>
          <MenuItem
            onClick={() => {
              setViewReport(true);
              handleClose();
            }}
            className='fs-14'
          >
            {t(`${TRANS_KEYS.VIEW_REPORTS}:key`)}
          </MenuItem>
        </Menu>
      </Box>
    </div>
  );
};

const CheckboxRenderer = ({ defaultChecked, onChange, hover }) => {
  return (
    <Tooltip title={hover} placement='top'>
      <Box display='flex' justifyContent='center' width='100%'>
        <input
          style={{
            width: '18px',
            height: '18px',
            cursor: 'pointer'
          }}
          type='checkbox'
          defaultChecked={defaultChecked}
          onChange={onChange}
        />
      </Box>
    </Tooltip>
  );
};

let gridApi, columnApi;
const CandidatePipeline = props => {
  const { refreshDataCbRef } = props;
  const [activeStage, setActiveStage] = useState(null);
  const [stages, setStages] = useState([]);
  const stagesWithoutLogical = stages.filter(stage => !stage?.isLogicalStage);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [isGridReady, setIsGridReady] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const gridApiRef = useRef(null);
  const [alert, setAlert] = useState({});
  const [openQuickAddContact, setOpenQuickAddContact] = useState(false);
  const [openCreateFromResume, setOpenCreateFromResume] = useState(false);
  const [openChooseFromContact, setOpenChooseFromContact] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const activeStageRef = useRef(activeStage);
  const { selectedColor } = useSelector(state => state.viewProjectReducer);

  const selectedColorIdRef = useRef(selectedColor);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [filterStages, setFilterStages] = useState([]);
  const [filterStageOptionValues, setFilterStageOptionValues] = useState([]);
  const [filterButtonText, setFilterButtonText] = useState('Select all filters');
  const deleteId = useRef(null);
  let query = useQuery();
  const navigate = useNavigate();
  const [selectedStage, setSelectedStage] = useState(null);
  const [selectedStages, setSelectedStages] = useState([]);
  const activeSelectedStages = useRef(selectedStages);
  const [quickAddContactProcessing, setQuickAddContactProcessing] = useState(false);

  const [colDefs, setColDefs] = useState([
    {
      field: 'Color',
      headerName: 'Color',
      flex: 1,
      suppressSizeToFit: true,
      cellRendererFramework: ({ value }) => {
        return <ColorDropdown onChange={colorId => handleCandidateColorChange(value?.id)(colorId)} selectedColor={value?.color_id} fromCandidate />;
      },
      filterFramework: props => CustomDropdownFilter({ ...props, gridApiRef }),
      minWidth: 100,
      maxWidth: 100
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      suppressSizeToFit: true,
      cellRendererFramework: ({ value }) => value,
      minWidth: 200,
      maxWidth: 200,
      sortable: true
    },
    {
      field: 'Job Title',
      headerName: 'Job Title',
      flex: 1,
      cellRendererFramework: ({ value }) => value,
      minWidth: 200,
      maxWidth: 300
    },
    {
      field: 'Current Company',
      flex: 1,
      headerName: 'Current Company',
      cellRendererFramework: ({ value }) => value,
      minWidth: 200,
      maxWidth: 200
    },
    {
      field: 'Industry',
      headerName: 'Industry',
      flex: 1,
      cellRendererFramework: ({ value }) => value,
      minWidth: 200,
      maxWidth: 300
    },
    {
      field: 'contact.contact_address.city',
      headerName: 'Location',
      flex: 1,
      sortable: true,
      cellRendererFramework: ({ value }) => value,
      minWidth: 200,
      maxWidth: 200
    },
    {
      field: 'stage',
      headerName: 'Stage',
      flex: 1,
      sortable: true,
      suppressSizeToFit: true,
      cellRendererFramework: ({ value }) => value,
      minWidth: 200,
      maxWidth: 200
    },
    {
      field: 'Comments',
      headerName: 'Comments',
      flex: 1,
      cellRendererFramework: ({ value }) => value,
      minWidth: 200,
      maxWidth: 200
    },
    {
      minWidth: 140,
      maxWidth: 140,
      flex: 1,
      field: 'created_at',
      headerName: 'Created At',
      cellRendererFramework: ({ value }) => value,
      editable: false,
      sortable: true
    },
    {
      minWidth: 140,
      maxWidth: 140,
      flex: 1,
      field: 'created_by',
      headerName: 'Created By',
      cellRendererFramework: ({ value }) => value,
      editable: false,
      sortable: true
    },
    {
      minWidth: 140,
      maxWidth: 140,
      flex: 1,
      field: 'updated_at',
      headerName: 'Updated At',
      cellRendererFramework: ({ value }) => value,
      editable: false,
      sortable: true
    },
    {
      minWidth: 140,
      maxWidth: 140,
      flex: 1,
      field: 'updated_by',
      headerName: 'Updated By',
      cellRendererFramework: ({ value }) => value,
      editable: false,
      sortable: true
    },
    {
      headerName: 'Action',
      flex: 1,
      field: 'Action',
      cellRendererFramework: ({ value }) => value,
      minWidth: 200,
      maxWidth: 200,
      pinned: 'right'
    }
  ]);

  const toggleStageSelection = stage => {
    if (selectedStages.some(selected => selected.stage_name === stage.stage_name)) {
      setSelectedStages(selectedStages.filter(selected => selected.stage_name !== stage.stage_name));
    } else {
      setSelectedStages([...selectedStages, stage]);
    }
  };

  const clearQuickAddContactData = () => {
    const fieldsToReset = [
      QUICK_ADD_CONTACT_COMMON_FIELD.stage,
      QUICK_ADD_CONTACT_COMMON_FIELD.company,
      QUICK_ADD_CONTACT_COMMON_FIELD.first_name,
      QUICK_ADD_CONTACT_COMMON_FIELD.last_name,
      QUICK_ADD_CONTACT_COMMON_FIELD.email,
      QUICK_ADD_CONTACT_COMMON_FIELD.phonenumber,
      QUICK_ADD_CONTACT_COMMON_FIELD.linkedin,
      QUICK_ADD_CONTACT_COMMON_FIELD.current_job_title
    ];

    fieldsToReset.forEach(field => {
      if (field && typeof field === 'string') {
        setValue(field, '');
      }
    });

    setSelectedStage(null);
  };

  const { projectData } = useContext(ProjectDataContext);

  useEffect(() => {
    if (isDrawerOpen) {
      setColDefs(colDefs.slice(1));
    } else {
      setColDefs(colDefs => [
        {
          width: 40,
          checkboxSelection: true,
          headerCheckboxSelection: true,
          pinned: 'left'
        },
        ...colDefs
      ]);
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    activeStageRef.current = activeStage;
    activeSelectedStages.current = selectedStages;
  }, [activeStage, selectedStages]);

  useEffect(() => {
    selectedColorIdRef.current = selectedColor;
  }, [selectedColor]);

  const refreshData = async () => {
    await fetchCandidateData();
    await fetchCandidateStages();
  };

  useEffect(() => {
    if (refreshDataCbRef.current !== null) {
      refreshData();
    }
  }, [refreshDataCbRef.current]);

  const fetchCandidateStages = () => {
    CandidateStageSetupAPi(GET, `/all?projectId=${projectData?.id ? projectData?.id : query.get('id')}&showLogicalStages=true`).then(res => {
      setStages(res.data);
      if (activeStage) {
        const stage = res.data.find(stage => stage.stage_name === activeStage.stage_name);
        setActiveStage(stage);
        setSelectedStage(stage);
      } else {
        setActiveStage(res.data?.[0]);
        setSelectedStage(res.data?.[0]?.stage_name);
      }
    });
  };

  const bulkUpdateCandidateStage = stage => {
    let selectedNodes = gridApiRef.current.api.getSelectedNodes();
    let selectedData = selectedNodes.map(node => node.data);
    const ids = selectedData.map(data => data.id);
    if (typeof stage === 'string' && ids?.length > 0) {
      bulkUpdateStage(ids, stage);
    }
  };

  const bulkUpdateStage = (ids, stage) => {
    setAlert({ open: true, message: 'Updating candidate stages' });
    ProjectCandidateApi(PATCH, '/bulk', { ids, stage })
      .then(() => {
        setAlert({ open: true, message: 'Candidate stages updated successfully' });
        refreshData();
      })
      .catch(() => {
        setAlert({ open: true, message: 'Error updating candidate stages' });
      });
  };

  useEffect(() => {
    fetchCandidateStages();
  }, []);

  const handleStageChange = id => stage => {
    setAlert({ open: true, message: 'updating stage' });

    ProjectCandidateApi(PATCH, '/', { id, stage })
      .then(() => {
        setAlert({ open: true, message: 'Stage updated successfully' });
        refreshData();
      })
      .catch(() => {
        setAlert({ open: true, message: 'Error updating stage' });
      });
  };

  const handleCandidateColorChange = id => colorId => {
    setAlert({ open: true, message: 'updating tag' });
    ProjectCandidateApi(PATCH, '/', { id, color_id: colorId })
      .then(() => {
        setAlert({ open: true, message: 'Tag updated successfully' });
        refreshData();
      })
      .catch(() => {
        setAlert({ open: true, message: 'Error updating tag' });
      });
  };
  const handleCandidateColorLabelChange = (label, colorId) => {
    setAlert({ open: true, message: 'updating label' });
    CandidateColorLabelDataApi(POST, { projectId: query.get('id'), label, colorId })
      .then(() => {
        setAlert({ open: true, message: 'Label updated successfully' });
        refreshData();
      })
      .catch(() => {
        setAlert({ open: true, message: 'Error updating Label' });
      });
  };

  const handleViewAthena = async item => {
    try {
      const payload = {
        project_id: item.project_id,
        contact_id: item.contact_id
      };
      const res = await ignAthenaDataApi(POST, '', payload, 'create-assessment');

      const assessmentId = res?.data ?? '';
      if (assessmentId.length > 0) {
        navigate(`/athena/${assessmentId}/${item?.contact?.first_name + ' ' + item?.contact?.last_name}`);
      }
    } catch (err) {
      enqueueSnackbar(err);
    }
  };

  const [isInviteAthenaModalOpen, setInviteAthenaModalOpen] = useState(false);
  const [inviteAthenaContact, setInviteAthenaContact] = useState({});
  const handleInviteAthena = item => {
    setInviteAthenaContact({ ...item?.contact, id: item?.contact_id, name: item?.contact?.first_name + ' ' + item?.contact?.last_name });
    setInviteAthenaModalOpen(true);
  };

  const [{ open: isCommentsModalOpen, candidate, loading: updateCommentsLoading, initialCommentText, value: commentText }, setCommentsModalData] = useState({
    open: false,
    candidate: null,
    loading: false,
    initialCommentText: '',
    value: ''
  });

  const formatData = data => {
    return data.map(item => {
      return {
        id: item.id,
        Color: item,
        name: <NameRender name={item?.contact?.name} candidate={item} setSelectedCandidate={setSelectedCandidate} setIsDrawerOpen={setIsDrawerOpen} />,
        'Job Title': <TextRender text={item?.contact?.current_job_title} name={'Job Title'} />,
        'Current Company': <TextRender text={item?.contact?.contact_company?.name} name={'Current Company'} />,
        Industry: <TextRender text={item?.contact?.contact_industries?.[0]?.industries?.name} name={'Industry'} />,
        location: <TextRender text={item?.contact?.contact_address?.[0]?.city} name='Location' />,
        stage: <TextRender text={item?.stage} name='Stage' />,
        Comments: (
          <Box
            onDoubleClick={() =>
              setCommentsModalData({
                open: true,
                candidate: item,
                loading: false,
                initialCommentText: item?.contact?.contact_notes_all?.[0]?.notes,
                value: item?.contact?.contact_notes_all?.[0]?.notes
              })
            }
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '180px',
              cursor: 'pointer'
            }}
          >
            {renderRichText(item?.contact?.contact_notes_all?.[0]?.notes || '--')}
          </Box>
        ),
        created_at: <TextRender text={formatDate(item?.created_at)} name='Created At' />,
        created_by: <TextRender text={`${item?.created_by_user?.first_name || ''} ${item?.created_by_user?.last_name || ''}`.trim()} name='Created By' />,
        updated_at: <TextRender text={formatDate(item?.updated_at)} name='Updated At' />,
        updated_by: <TextRender text={`${item?.updated_by_user?.first_name || ''} ${item?.updated_by_user?.last_name || ''}`.trim()} name='Updated By' />,
        Action: (
          <ActionRenderer
            projectData={projectData}
            item={item}
            stages={stagesWithoutLogical}
            handleStageChange={handleStageChange}
            handleDeleteCandidate={handleDeleteCandidate}
            handleViewAthena={handleViewAthena}
            handleInviteToAthena={handleInviteAthena}
          />
        )
      };
    });
  };

  const onGridReady = params => {
    gridApi = params.api;
    columnApi = params.columnApi;
    // const columnApi = params.columnApi
    params.columnApi.autoSizeAllColumns();
    loadColumnStateFromLocalStorage('masterSearchContactColumns', {
      columnApi
    });
    // this.setState({ isGridReady: true });
    setIsGridReady(true);
  };

  const handleAddCandidate = option => {
    if (option === 'Quick add') {
      setOpenQuickAddContact(true);
    } else if (option === 'Add from resume') {
      setOpenCreateFromResume(true);
    } else if (option === 'Select existing contact') {
      setOpenChooseFromContact(true);
    }
  };

  const fetchCandidateData = useMemo(
    () =>
      debounce(async () => {
        gridApiRef.current.api.refreshInfiniteCache();

        setDataSource({
          getRows: async params => {
            gridApi.showLoadingOverlay();

            const { sortModel } = params;
            const sortKey = activeSelectedStages.current.map(stage => `${encodeURIComponent(stage.stage_name)}`).join('&stage=');
            let page = Math.floor(params.endRow / 100);
            let sub_route = `/all?limit=${PAGE_LIMIT}&page=${page}&projectId=${projectData.id}&stage=${sortKey ? sortKey : encodeURIComponent(activeStageRef.current.stage_name)}`;

            if (activeStageRef.current.isLogicalStage) {
              sub_route = `/all?limit=${PAGE_LIMIT}&page=${page}&projectId=${projectData.id}`;
              if (sortKey) {
                sub_route += `&stage=${sortKey}`;
              } else {
                activeStageRef.current.stageFilters.forEach(filter => {
                  sub_route += `&stage=${encodeURIComponent(filter)}`;
                });
              }
            }

            if (selectedColorIdRef.current) {
              sub_route += `&color_id=${selectedColorIdRef.current}`;
            }

            if (sortModel?.length > 0) {
              sub_route = sub_route.concat(`&sortOn=${sortModel[0].colId}&sortType=${sortModel[0].sort.toUpperCase()}`);
            }

            const res = await ProjectCandidateApi(GET, sub_route);
            if (res.status === 200) {
              const formattedData = formatData(res.data.rows);
              if (res.data.count === 0) {
                params.successCallback(formattedData, res.data.count + 1);
              } else {
                params.successCallback(formattedData, res?.data?.rows?.length || 0);
              }
            } else {
              params.failCallback();
            }
            gridApi.hideOverlay();
          }
        });
      }, 300),
    [projectData.id, formatData]
  );

  const handleDeleteCandidate = id => {
    deleteId.current = id;
    setShowConfirmDialog(true);
  };

  const handleConfirmDeleteCandidate = async () => {
    setAlert({ open: true, message: 'Deleting candidate' });
    ProjectCandidateApi(DELETE, `/?id=${deleteId.current}`)
      .then(res => {
        if (res.status === 500) {
          throw new Error(res.data.message);
        }
        setAlert({ open: true, message: 'Candidate deleted successfully' });
        refreshData();
      })
      .catch(err => {
        setAlert({ open: true, message: err?.message });
      })
      .finally(() => {
        setShowConfirmDialog(false);
      });
  };

  useEffect(() => {
    if (activeStage?.stage_name && projectData?.id) {
      fetchCandidateData();
    }
  }, [activeStage, projectData, selectedStages]);

  const handleFilterByStages = (event, values) => {
    setFilterStageOptionValues(values);
    let stagesValues = stages.filter(stage => values.filter(option => option.value === stage.stage_name).length > 0);
    setFilterStages(stagesValues);
    if (stagesValues.length > 0) {
      setActiveStage(stagesValues[0]);
      setSelectedStage(stagesValues[0]);
    }
  };

  const handleFilterButton = () => {
    if (filterButtonText === 'Select all filters') {
      setFilterButtonText('Reset all filters');
      setFilterStageOptionValues(stages.map(stage => ({ label: stage.stage_label, value: stage.stage_name })));
      setFilterStages(stages);
    } else {
      setFilterButtonText('Select all filters');
      setFilterStageOptionValues([]);
      setFilterStages([]);
    }
  };
  const openCandidateDrawer = id => {
    setIsDrawerOpen(true);
    setSelectedCandidate(id);
  };

  const useStyles = makeStyles(() => ({
    inputRoot: {
      height: '56.28px' // Adjust the height as needed
    }
  }));

  const { register, handleSubmit, setValue, control, getValues, watch } = useForm();
  const classes = useStyles();
  const { data: companiesResponse, refetch: fetchCompanies } = useApi({
    queryFn: () => {
      return picklistDropDownApis(GET, DROP_DOWN_PICKLIST.COMPANIES_PICKLIST);
    }
  });
  const { t } = useLanguageHooks([TRANS_KEYS.ADD_PROJECT_SELECT_COMPANY_NAME, TRANS_KEYS.ADD_COMPANY]);
  const [addCompanyPopup, setAddCompanyPopup] = useState(false);
  const { translateMessage } = useCustomMessageHook();

  const newFields = () => {
    const [loader, setLoader] = useState(false);
    const { getValues: companygetValues, reset: companyReset, setValue: setCompanyValue, register: registerCompany, unregister: unregisterCompany, watch: watchCompany } = useForm();
    const changeOptionOnLoad = useRef(null);

    useEffect(() => {
      if (changeOptionOnLoad.current !== null) {
        setValue('company', changeOptionOnLoad.current);
        changeOptionOnLoad.current = null;
      }
    }, [companiesResponse]);

    const isValidated = async requestBody => {
      let isValid = await createSchema.isValid(requestBody);
      if (!isValid) {
        let dirtyField = customFormValidator(requestBody, createRequiredField);
        if (dirtyField) {
          const message = translateMessage('Required', ...splitLastIfMultiple(dirtyField));
          enqueueSnackbar(message, { variant: ERROR });
          return false;
        }
      } else {
        return true;
      }
    };

    const getPayload = data => {
      const addCompanyPayload = {
        ...data,
        company_tags: data?.company_tags ? data?.company_tags.map(tag => ({ tag_id: tag.id })) : [],
        company_industries: data?.company_industries
          ? data?.company_industries.map(industry => ({
              industry_id: industry.id
            }))
          : [],
        capital_structure: data?.capital_structure?.field_value,
        currency_unit: data?.currency_unit?.field_value
      };
      return addCompanyPayload;
    };
    const saveCompanyData = async () => {
      let formValues = { ...companygetValues() };
      const requestBody = getPayload(formValues);
      const IsValidRequestBody = await isValidated(requestBody);
      if (IsValidRequestBody) {
        setLoader(true);
        const { status, data } = await ignCompanyDataApi(POST, requestBody);
        if (status === 201) {
          const message = translateMessage('Successfully', false, 'Company', 'created');
          enqueueSnackbar(message, { variant: SUCCESS });
          setAddCompanyPopup(false);
          companyReset();

          setLoader(false);
          const companyData = await ignCompanyDataApi(GET, {}, data?.id);
          fetchCompanies();
          changeOptionOnLoad.current = { id: companyData?.data?.id, name: companyData?.data?.name };
        } else if (status === 409) {
          const message = translateMessage('AlreadyExist', false, 'Company');
          enqueueSnackbar(message, {
            variant: ERROR
          });
        } else {
          const message = translateMessage('UnableMessage', false, 'Create', 'Company');
          enqueueSnackbar(message, { variant: ERROR });
        }
        setLoader(false);
      }
    };

    return (
      <>
        <CustomDropdown
          options={companiesResponse?.data ?? []}
          multiple={false}
          classes={{
            inputRoot: classes.inputRoot
          }}
          onChange={(event, newValue) => {
            setValue('company', newValue);
          }}
          label={t(`${TRANS_KEYS.ADD_PROJECT_SELECT_COMPANY_NAME}:key`)}
          name='company'
          className={'w-100'}
          noOptionsText={<CustomButton type={'w-100 primary'} variant={'outlined'} onClick={() => setAddCompanyPopup(true)} buttonText={'+ ' + t(`${TRANS_KEYS.ADD_COMPANY}:key`)} />}
          value={watch('company')}
        />
        <CustomMuiDropdown
          options={
            stages?.map(stage => {
              if (stage.stage_name !== 'All') return { label: stage.stage_name };
            }) || []
          }
          disabled={activeStage?.stage_name !== 'All'}
          label='Stage'
          value={selectedStage?.stage_name !== 'All' ? { label: selectedStage?.stage_name } : null}
          renderInput={params => <TextField {...params} variant='outlined' />}
          onChange={(event, newVal) => {
            setSelectedStage({ stage_name: newVal?.label ?? '' });
          }}
          getOptionLabel={option => {
            return option?.label || '';
          }}
        />
        <AddCompany
          isPopupOpen={addCompanyPopup}
          handleClose={() => setAddCompanyPopup(false)}
          handleSubmit={saveCompanyData}
          setIsLoading={setLoader}
          loading={loader}
          setValue={setCompanyValue}
          register={registerCompany}
          reset={companyReset}
          setIsPopupOpen={setAddCompanyPopup}
          unregister={unregisterCompany}
          watch={watchCompany}
        ></AddCompany>
      </>
    );
  };

  const schema = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().nullable(),
    linkedin: yup.string().url().nullable(),
    phonenumber: yup
      .string()
      .transform((value, originalValue) => {
        // Coerce empty string or null to null
        if (originalValue === '' || originalValue === null) return null;
        return value;
      })
      .matches(/^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/, 'Phone number is not valid')
      .nullable(),
    jobtitle: yup.string().nullable()
  });

  const [disabled, setDisabled] = useState(false);

  const clearInputs = () => {
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.firstName, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.first_name, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.lastName, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.last_name, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.email, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.contact_emails, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.linkedin, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.linkedin_url, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.phonenumber, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.contact_phones, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.jobtitle, null);
    setValue(QUICK_ADD_CONTACT_COMMON_FIELD.current_job_title, null);
    setValue('company', null);
    setSelectedStage(null);
  };

  const onAddSubmit = data => {
    schema
      .validate(data)
      .then(async () => {
        if (!data?.email && !data?.linkedin && !data?.phonenumber) {
          setAlert({ open: true, message: 'Email/LinkedIn/Phone number is required.', error: true });
        } else if (!data?.company || !data?.company?.id || !data?.company?.name) {
          setAlert({ open: true, message: 'Company is required', error: true });
        } else if (!selectedStage || !selectedStage?.stage_name) {
          setAlert({ open: true, message: 'Stage is required', error: true });
        } else {
          setDisabled(true);
          setQuickAddContactProcessing(true);
          const payload = {
            contact: data,
            projectId: projectData?.id,
            stage: selectedStage?.stage_name
          };
          if (!(data?.email && data?.email?.length > 0)) {
            delete payload['contact']['email'];
          }
          await ProjectCandidateApi(POST, '/create-from-contact', payload)
            .then(res => {
              if (res.status === 500) {
                setAlert({ open: true, message: res.data.message });
                setDisabled(false);
                setQuickAddContactProcessing(false);
              } else {
                setAlert({ open: true, message: 'Candidate added successfully' });
                refreshData().finally(() => {
                  setDisabled(false);
                  setOpenQuickAddContact(false);
                });
              }
            })
            .catch(err => {
              setAlert({ open: true, message: err?.message });
              setDisabled(false);
              setOpenQuickAddContact(false);
            })
            .finally(() => {
              setDisabled(false);
              clearInputs();
              setQuickAddContactProcessing(false);
            });
        }
      })
      .catch(err => {
        setAlert({ open: true, message: err.errors[0] });
        setDisabled(false);
        setQuickAddContactProcessing(false);
      });
  };

  const handleUpdateCandidateComments = async candidate => {
    if (updateCommentsLoading || !candidate) return;

    setCommentsModalData(prev => ({ ...prev, loading: true }));
    const type = candidate?.contact?.contact_notes_all?.[0]?.id ? PATCH : POST;

    try {
      await LogCandidateActivityAPI(type, candidate?.contact?.contact_notes_all?.[0]?.id, {
        notes: commentText,
        activity_type: 'NOTE',
        notes_type: 'comments',
        project_id: candidate?.project_id,
        contact_id: candidate?.contact_id
      });
      setCommentsModalData({ loading: false, open: false, initialCommentText: '', candidate: null, value: '' });
      fetchCandidateData();
    } catch (error) {
      setCommentsModalData(prev => ({ ...prev, loading: false }));
      enqueueSnackbar(`Error updating comments: ${error?.message}`, { variant: ERROR });
    }
  };

  function handleCommentsClose() {
    setCommentsModalData({ loading: false, open: false, candidate: null, initialCommentText: '', value: '' });
  }

  return (
    <div className='ms-1'>
      <CustomPopup width={'60vw'} title={'Comments'} open={isCommentsModalOpen} onClose={handleCommentsClose} disabled={false} handleSubmit={() => {}}>
        <RichText
          defaultValue={candidate?.contact?.contact_notes_all?.[0]?.notes || ''}
          onChange={content => {
            setCommentsModalData(prev => ({ ...prev, value: content }));
          }}
        />
        <CustomButton
          disabled={commentText === initialCommentText || updateCommentsLoading}
          buttonText={updateCommentsLoading ? 'Saving' : 'Save'}
          boxClassName={'flex justify-content-end mt-2'}
          onClick={() => handleUpdateCandidateComments(candidate)}
        />
      </CustomPopup>

      <Loader show={quickAddContactProcessing} />
      <div className='main-container'>
        <Stack direction={'row'} gap={4}>
          <CustomDropdown label='Search Value' options={[]} multiple={false} name='searchValue' className={''} />
          <OverflowAutocomplete
            label='Filter By Stages'
            optionsData={stages.map(stage => ({ label: stage.stage_label, value: stage.stage_name }))}
            selectedValues={filterStageOptionValues}
            onChange={handleFilterByStages}
          />
        </Stack>
        <Stack direction={'row'} gap={2}>
          <Button variant='text' onClick={handleFilterButton}>
            {filterButtonText}
          </Button>
          <ColorLegend handleCandidateColorLabelChange={handleCandidateColorLabelChange} />
          <ButtonDropDown
            disabled={selectedRowsCount === 0}
            options={stagesWithoutLogical ? stagesWithoutLogical.map(stage => stage.stage_label) : []}
            buttonText={'Change Stage'}
            onClick={bulkUpdateCandidateStage}
          />
          <ButtonDropDown options={['Quick add', 'Add from resume', 'Select existing contact']} buttonText={'Add Candidate'} onClick={handleAddCandidate} />
        </Stack>
      </div>

      <div className='contact-pipeline'>
        <div style={{ display: 'grid', gridTemplateColumns: !isDrawerOpen ? '140px 1fr' : '1fr' }}>
          {!isDrawerOpen && (
            <div style={{ margin: '1.2rem 0 1.2rem .5rem' }}>
              {(stages?.length ? (filterStages?.length > 0 ? filterStages : stages) : []).map((stage, index) => (
                <Tooltip key={index} placement='right' arrow title={stage.description}>
                  <div
                    key={index}
                    className='stage-container'
                    style={{
                      backgroundColor: stage.color || '#1e79ab',
                      boxShadow: selectedStages.some(selected => selected.stage_name === stage.stage_name)
                        ? 'rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset'
                        : null
                    }}
                    onClick={() => toggleStageSelection(stage)}
                  >
                    <Typography className='stage-text' variant='body1' textTransform='capitalize'>
                      {stage.stage_label}
                    </Typography>
                    <div className='flex-align-center'>
                      <Divider orientation='vertical' flexItem className='white-divider' />
                      <Typography className='stage-text' variant='body1'>
                        {stage.project_candidate_count || 0}
                      </Typography>
                    </div>
                  </div>
                </Tooltip>
              ))}
            </div>
          )}
          <div className='container-secondary'>
            {selectedStages.length === 1 && (
              <div
                className='stage-header'
                style={{
                  backgroundColor: selectedStages[0].color || '#1e79ab'
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant='subtitle1' fontWeight='bold' style={{ marginRight: '.7rem' }}>
                    {selectedStages[0].stage_name}
                  </Typography>
                  {selectedStages[0].recent_candidate_count && (selectedStages[0].largest_created_at || selectedStages[0].largest_updated_at) ? (
                    <Typography variant='body2'>
                      {selectedStages[0].recent_candidate_count} New added{' '}
                      {moment(selectedStages[0]?.largest_created_at || selectedStages[0]?.largest_updated_at)
                        .startOf('minute')
                        .fromNow()}
                    </Typography>
                  ) : null}
                </div>
                <Typography variant='subtitle1' fontWeight='bold'>
                  {selectedStages[0].project_candidate_count} Candidates
                </Typography>
              </div>
            )}

            {selectedStages.length > 1 && (
              <div
                className='stage-header'
                style={{
                  backgroundColor: selectedStages[0].color || '#1e79ab',
                  padding: '1rem',
                  borderRadius: '8px'
                }}
              >
                <Typography
                  variant='subtitle1'
                  fontWeight='bold'
                  style={{
                    marginBottom: '.7rem',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'block',
                    width: '33.33%',
                    boxSizing: 'border-box'
                  }}
                >
                  {activeSelectedStages.current.map(stage => stage.stage_name).join(', ')}
                </Typography>

                {selectedStages.some(stage => stage.recent_candidate_count && (stage.largest_created_at || stage.largest_updated_at)) && (
                  <Typography variant='body2' style={{ marginBottom: '.5rem', color: '#ffffff' }}>
                    {new Intl.NumberFormat().format(selectedStages.reduce((acc, stage) => acc + (parseInt(stage.recent_candidate_count, 10) || 0), 0))} New added{' '}
                    {moment(
                      Math.max(
                        ...selectedStages.map(stage => new Date(stage.largest_created_at || stage.largest_updated_at).getTime()).filter(time => !isNaN(time)) // Filter out invalid dates
                      )
                    )
                      .startOf('minute')
                      .fromNow()}
                  </Typography>
                )}

                <Typography variant='body2' style={{ color: '#ffffff' }}>
                  {new Intl.NumberFormat().format(selectedStages.reduce((acc, stage) => acc + (parseInt(stage.project_candidate_count, 10) || 0), 0))} Candidates
                </Typography>
              </div>
            )}
            <div id='myGrid' className='ag-theme-alpine mx-2 ag-grid-container' style={{ height: `${5 * 60 + 50}px`, overflow: 'hidden' }}>
              <AgGridReact
                enableBrowserTooltips={true}
                ref={gridApiRef}
                rowHeight={60}
                datasource={dataSource}
                columnDefs={colDefs}
                scrollbarWidth={12}
                rowSelection='multiple'
                onGridReady={onGridReady}
                defaultColDef={{
                  minWidth: 100,
                  resizable: true,
                  autoSizeStrategy: 'fitGridWidth'
                }}
                frameworkComponents={{
                  CustomLoadingOverlayComponent
                }}
                loadingOverlayComponent={'CustomLoadingOverlayComponent'}
                getRowNodeId={data => data.id}
                blockLoadDebounceMillis={SCROLL_TIMEOUT}
                suppressHorizontalScroll={false}
                rowModelType='infinite'
                onSelectionChanged={event => {
                  setSelectedRowsCount(event.api.getSelectedNodes().length);
                }}
                domLayout='normal'
              />
              {isGridReady && <ColumnFilter classNameValue={'columns-icon m-4 mt-4 ml-8'} columnApi={columnApi} defaultColumns={defaultColumns} />}
            </div>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={alert?.open === true}
              onClose={() => setAlert(alertOld => ({ ...alertOld, open: false, error: false }))}
              message={alert?.message}
              key={0}
              autoHideDuration={5000}
              ContentProps={{
                sx: {
                  bgcolor: alert.error ? 'red' : 'black',
                  color: 'white'
                }
              }}
            />
          </div>
        </div>
      </div>
      <QuickAddContact
        open={openQuickAddContact}
        setOpen={setOpenQuickAddContact}
        label={activeStage?.stage_name}
        projectId={projectData?.id}
        callback={async () => {
          refreshData();
        }}
        useFormController={{ register, handleSubmit, setValue, control, getValues }}
        newFields={newFields} // flush fuction to reset all fields
        clearQuickAddContactData={clearQuickAddContactData}
        isAddDisabled={disabled && selectedStage && selectedStage?.stage_name && selectedStage.stage_name != ''}
        onAddSubmit={onAddSubmit}
      />
      <ChooseFromContact
        open={openChooseFromContact}
        setOpen={setOpenChooseFromContact}
        callback={refreshData}
        stages={stages}
        openCandidateDrawer={openCandidateDrawer}
        initStage={activeStage?.stage_name}
        projectId={projectData?.id}
      />
      <ResumeParser
        isPopupOpen={openCreateFromResume}
        handleClose={() => setOpenCreateFromResume(false)}
        createProjectCandidate={true}
        stage={activeStage?.stage_name}
        stages={stages}
        projectId={projectData?.id}
        callback={async () => {
          refreshData();
        }}
      />
      <ConfirmModal message={'Are you sure want to delete candidate?'} open={showConfirmDialog} setOpen={setShowConfirmDialog} onConfirm={handleConfirmDeleteCandidate} />
      <Box id='view-contact-drawer' zIndex={100000000}>
        <>
          <ViewContactDrawer
            isCandidate={true}
            project_id={projectData.id}
            candidate_id={selectedCandidate?.id}
            navigateToAllContacts={false}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            id={selectedCandidate?.contact_id}
          />
        </>
      </Box>
      <AthenaEmailInviteModal contact={inviteAthenaContact} isOpen={isInviteAthenaModalOpen} onClose={() => setInviteAthenaModalOpen(false)} projectId={projectData?.id} />
    </div>
  );
};

TextRender.propTypes = {
  text: PropTypes.string
};

CheckboxRenderer.propTypes = {
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
  hover: PropTypes.string
};

NameRender.propTypes = {
  name: PropTypes.string.isRequired,
  setSelectedCandidate: PropTypes.func.isRequired,
  setIsDrawerOpen: PropTypes.func.isRequired,
  candidate: PropTypes.object.isRequired
};

ActionRenderer.propTypes = {
  item: PropTypes.object.isRequired,
  stages: PropTypes.array.isRequired,
  handleStageChange: PropTypes.func.isRequired,
  handleDeleteCandidate: PropTypes.func.isRequired,
  handleViewAthena: PropTypes.func.isRequired,
  projectData: PropTypes.object,
  handleInviteToAthena: PropTypes.func
};

CandidatePipeline.propTypes = {
  refreshDataCbRef: PropTypes.object
};

export default CandidatePipeline;
