import { createTheme } from '@mui/material/styles';
import variables from './variables.scss';

export const defaultTheme = themeConfig =>
  createTheme({
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true
        }
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: true
        }
      }
    },
    palette: {
      primary: {
        main: themeConfig?.primary || variables?.primary
      },
      secondary: {
        main: themeConfig?.secondary || variables?.secondary
      },
      text: {
        primary: themeConfig?.text || variables?.text
      },
      heading: {
        main: themeConfig?.heading || variables?.heading
      },
      error: {
        main: themeConfig?.error || variables?.error
      },
      background: {
        main: themeConfig?.background || variables?.background
      },
      titleBarBackground: {
        main: themeConfig?.titleBarBackground || variables?.titleBarBackground
      },
      success: {
        main: themeConfig?.success || variables?.success
      },
    },
    typography: {
      fontFamily: '"open-sans" !important'
    },
    overrides: {
      MuiCheckbox: {
        colorSecondary: {
          '&$checked': {
            color: themeConfig?.secondary || variables?.secondary
          }
        }
      }
    }
  });
