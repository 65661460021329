import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CustomDropdown from '../../CustomDropdown';
import { fetchPickLists } from '../../../../actions';

const CompanyRelationParentTypeSelection = props => {
  const {
    value,
    name = '',
    label = '',
    onChange = () => {},
    disabled = false,
    isDrawer = false,
    //selectedList,
    //setEmailList = () => { },
    required = false,
    ...rest
  } = props;
  const [options, setOptions] = useState([]);
  const companyRelationParentTypeOption = useSelector(state => state.commonReducer.companyRelationParentType);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!companyRelationParentTypeOption) {
      dispatch(fetchPickLists('COMPANY_RELATION_PARENT_TYPE', 'companyRelationParentType'));
    } else {
      setOptions(companyRelationParentTypeOption);
    }
  }, [companyRelationParentTypeOption, dispatch]);

  return (
    <div>
      <CustomDropdown {...rest} value={value} options={options} name={name} onChange={onChange} isDrawer={isDrawer} disabled={disabled} label={label} required={required} />
    </div>
  );
};
CompanyRelationParentTypeSelection.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  isDrawer: PropTypes.bool,
  onChange: PropTypes.func,
  selectedList: PropTypes.array,
  label: PropTypes.string,
  setEmailList: PropTypes.func,
  required: PropTypes.bool
};

export default CompanyRelationParentTypeSelection;
