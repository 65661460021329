import React from 'react';
import './index.scss';
import { Box, Divider } from '@mui/material';
import CardSlider from '../../common/CardsSlider';
import { useState } from 'react';
import AddEductionAndExperience from '../../AddContact/Components/EducationAndLanguages/AddEductionAndExperience';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CustomTable from '../../common/CustomTable';
import SchoolIcon from '@mui/icons-material/School';
import LanguageIcon from '@mui/icons-material/Language';
import CloseIcon from '@mui/icons-material/Close';
import '../../../../src/utils/common.scss';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import { AddAnotherButton } from './ViewProfessionalExperience';
import LevelOfExpertiseSelection from '../../common/FunctionalComponents/LevelOfExpertiseSelection';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import EducationCard from '../../common/FunctionalComponents/EducationCard';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LanguagesSelection from '../../common/FunctionalComponents/LanguagesSelection';
import CustomInputField from '../../common/StyledComponents/CustomInputField';
import CertificationTypeSelection from '../../common/FunctionalComponents/CertificationTypeSelection';
import DatePickerYear from '../../common/DatePickerYear/DatePickerYear';

const ViewEducationLanguages = props => {
  const {
    register,
    data,
    unregister,
    setValue = () => {},
    updateField,
    watch = () => {},
    handleSave = () => {},
    getContactDetails = () => {},
    setIsPopupOpen = () => {},
    isPopupOpen = () => {}
  } = props;
  const onSave = async () => {
    if (isEdit) {
      await handleSave({ field: 'updated', from: 'Education Details', card_id: data?.contact_education_details[indexValue].id });
    } else {
      await handleSave({ field: 'created', from: 'Education Details' });
    }
  };
  const [isEdit, setIsEdit] = useState(false);
  const [indexValue, setIndexValue] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [isEditingLanguages, setIsEditingLanguages] = useState(true);
  const [isEditingCertificate, setIsEditingCertificate] = useState(true);
  const { t } = useLanguageHooks([
    TRANS_KEYS.CONTACTS_EDUCATION,
    TRANS_KEYS.CONTACTS_LANGUAGES,
    TRANS_KEYS.CONTACTS_UPDATE_EDUCATION_DETAILS,
    TRANS_KEYS.CONTACTS_ADD_EDUCATION_DETAILS,
    TRANS_KEYS.CONTACTS_LEVEL_OF_EXPERTISE,
    TRANS_KEYS.CONTACTS_ADD_LANGUAGES,
    TRANS_KEYS.CONTACTS_ADD_ANOTHER,
    TRANS_KEYS.CONTACTS_ADD_LANGUAGES,
    TRANS_KEYS.CONTACTS_WORD_ADD,
    TRANS_KEYS.CONTACT_CERTIFICATION_AND_LICENSE,
    TRANS_KEYS.ADD_CERTIFICATION_AND_LICENSE,
    TRANS_KEYS.CERTIFICATION_EXPIRE_ON,
    TRANS_KEYS.CERTIFICATION_TYPE,
    TRANS_KEYS.CERTIFICATION_TITLE
  ]);
  const handleOpenPopup = () => {
    setIsEdit(false);
    setIsPopupOpen(true);
  };
  const openPopupEditing = index => {
    setIndexValue(index);
    setIsEdit(true);
    setIsPopupOpen(true);
  };
  // const cardData = [

  //   {
  //     education_level: 'BE',
  //     study_type: 'computer eng',
  //     school_name: 'abc',
  //     country: 'India',
  //     start_Date: '2020',
  //     end_Date: '2023',
  //   },
  //   {
  //     education_level: 'BE',
  //     study_type: 'computer eng',
  //     school_name: 'abc',
  //     country: 'India',
  //     start_Date: '2020',
  //     end_Date: '2023',
  //   },
  //   {
  //     education_level: 'BE',
  //     study_type: 'computer eng',
  //     school_name: 'abc',
  //     country: 'India',
  //     start_Date: '2020',
  //     end_Date: '2023',
  //   },
  //   {
  //     education_level: 'BE',
  //     study_type: 'computer eng',
  //     school_name: 'abc',
  //     country: 'India',
  //     start_Date: '2020',
  //     end_Date: '2023',
  //   },
  // ]

  const [language, setLanguage] = useState([{ language: '', expertise: '' }]);
  const [certificate, setCertificate] = useState([{ certification_type: '', certifications: '', expire_on: '' }]);
  useEffect(() => {
    setLanguage(data?.contact_languages);
    setCertificate(data?.contact_certificate);
  }, [data]);
  useEffect(() => {
    register('expire_on');
    register('contact_languages');
    register('contact_certificate');

    return () => {
      unregister('expire_on');
      unregister('contact_languages');
      unregister('contact_certificate');
    };
  }, [register, unregister]);

  const handleAddLanguage = () => {
    setLanguage([...language, { language: '', expertise: '' }]);
  };

  const handleAddCertificate = () => {
    setCertificate([...certificate, { certification_type: '', certifications: '', expire_on: '' }]);
  };

  const handleEditLanguage = value => {
    setIsEditingLanguages(value);
  };

  const handleEditCertificate = value => {
    setIsEditingCertificate(value);
  };

  const handleRemoveLanguage = index => {
    const updatedLanguage = [...language];
    updatedLanguage.splice(index, 1);
    setLanguage(updatedLanguage);
  };

  const handleRemoveCertificate = index => {
    const updatedCertificate = [...certificate];
    updatedCertificate.splice(index, 1);
    setCertificate(updatedCertificate);
  };

  const handleChangeCertification = (index, field, value) => {
    const updatedCertificate = certificate.map((certificate, i) => {
      if (i === index) {
        return { ...certificate, [field]: value };
      }
      return certificate;
    });
    setCertificate(updatedCertificate);
  };

  const handleLanguageChange = (index, field, value) => {
    const updatedLanguage = language?.map((ele, i) => {
      if (i === index) {
        // if(field=="language"){

        //   return{ ...ele, [field]: value };

        // }
        // else{
        return { ...ele, [field]: value };
        // }
      }
      return ele;
    });
    setLanguage(updatedLanguage);
  };
  useEffect(() => {
    setValue(
      'contact_languages',
      language?.map(item => {
        return { expertise: item?.expertise, language_id: item?.language?.id };
      })
    );
  }, [language]);

  useEffect(() => {
    setValue(
      'contact_certificate',
      certificate?.map(elem => {
        return {
          certification_type: elem?.certification_type ? elem.certification_type : '',
          certifications: elem?.certifications ? elem?.certifications : '',
          expire_on: elem?.expire_on ? elem.expire_on : ''
        };
      })
    );
  }, [certificate]);

  return (
    <Box id='view-contact' className={'custom-scrollbar pl-3 pt-2 pb-2'}>
      <Box className='details-container-subtab scroll-content'>
        <Box className='section-details m-2'>
          <Box className='d-flex flex-column row-gap w-100 viewcontact-icon-style'>
            <Box className='d-flex flex-row column-gap w-100'>
              <SchoolIcon color='disabled'></SchoolIcon>
              <Box className='d-flex flex-column row-gap w-100'>
                <Box className='title-color fs-12'>{t(`${TRANS_KEYS.CONTACTS_EDUCATION}:key`)}</Box>
              </Box>
            </Box>
            <Box className='p-0'>
              <CardSlider
                cardData={data?.contact_education_details}
                styleData={{
                  titleSize: 13,
                  textSize: 10,
                  height: 125,
                  width: 300,
                  minWidth: 300
                }}
                handleOpenPopup={openPopupEditing}
              >
                {data?.contact_education_details?.map((card, cardIndex) => (
                  <React.Fragment key={cardIndex}>
                    <EducationCard
                      data={card}
                      index={cardIndex}
                      editItems={openPopupEditing}
                      getContactDetails={getContactDetails}
                      styleData={{
                        titleSize: 13,
                        textSize: 11,
                        height: 100,
                        width: 400,
                        minWidth: 400
                      }}
                    />
                    {cardIndex < data?.contact_education_details?.length - 1 && (
                      <Box className='pr-2 pl-2 arrowIcon'>
                        <ArrowBackIcon />
                      </Box>
                    )}
                  </React.Fragment>
                ))}
              </CardSlider>
            </Box>
            <Box>
              <AddAnotherButton className='add-another-btn' onClick={handleOpenPopup} variant='text'>
                +{data?.contact_education_details?.length === 0 ? t(`${TRANS_KEYS.CONTACTS_WORD_ADD}:key`) : t(`${TRANS_KEYS.CONTACTS_ADD_ANOTHER}:key`)}
              </AddAnotherButton>
            </Box>
          </Box>
          {isPopupOpen && (
            <AddEductionAndExperience
              title={`${isEdit ? 'Update' : 'Add'} Education Experience`}
              setIsPopupOpen={setIsPopupOpen}
              isPopupOpen={isPopupOpen}
              data={isEdit ? data?.contact_education_details[indexValue] : null}
              setIsEdit={setIsEdit}
              isEditing={isEdit}
              register={register}
              unregister={unregister}
              setValue={setValue}
              watch={watch}
              handleSave={onSave}
            />
          )}
        </Box>
        <Divider variant='middle' />

        <Box className='section-details m-2'>
          <Box className='language-section'>
            <Box className='d-flex column-gap mb-1 viewcontact-icon-style'>
              <LanguageIcon color='disabled'></LanguageIcon>
              <Box className='title-color fs-12'>{t(`${TRANS_KEYS.CONTACTS_LANGUAGES}:key`)}</Box>
            </Box>
            <Box>
              <CustomTable
                headerData={[t(`${TRANS_KEYS.CONTACTS_LANGUAGES}:key`), t(`${TRANS_KEYS.CONTACTS_LEVEL_OF_EXPERTISE}:key`)]}
                title={t(`${TRANS_KEYS.CONTACTS_ADD_LANGUAGES}:key`)}
                handleAnotherRow={handleAddLanguage}
                isEditing={isEditingLanguages}
                rows={language}
                disable={3}
                onEditChange={handleEditLanguage}
                showAddAnotherButton={false}
                customTableId={'view-contact-content'}
                headerSize={'fs-12'}
                titleSize={'fs-13'}
                updateField={updateField}
                dataList={languageList}
                saveDataKey={'contact_languages'}
                // handleCloseClick = {handleCloseClick}
              >
                {isEditingLanguages ? (
                  language?.length === 0 ? (
                    <TableRow>
                      <TableCell align='center' className='p-2'>
                        <Box className='fs-11'>-</Box>
                      </TableCell>
                      <TableCell align='center' className='p-2'>
                        <Box className='fs-11'>-</Box>
                      </TableCell>
                    </TableRow>
                  ) : (
                    language?.map((selectedLanguages, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align='center' className='p-2'>
                            <Box className='fs-11'>{selectedLanguages?.language?.name || ''}</Box>
                          </TableCell>
                          <TableCell align='center' className='p-2'>
                            <Box className='fs-11'> {selectedLanguages?.ign_translate_value?.short_desc || ''} </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )
                ) : (
                  language?.map((selectedLanguages, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align='center' className='input-field-data justify-center align-center p-2' sx={{ width: '16vw' }}>
                          <Box className='language-dropdown viewcontact-icon-style'>
                            <LanguagesSelection
                              isDrawer={true}
                              defaultValue={selectedLanguages?.language?.name || ''}
                              onChange={(e, data) => handleLanguageChange(index, 'language', data)}
                              label={t(`${TRANS_KEYS.CONTACTS_LANGUAGES}:key`)}
                              selectedList={language}
                              setLanguageList={setLanguageList}
                              viewAddress={true}
                            />
                          </Box>
                        </TableCell>
                        <TableCell align='center' className='input-field-data justify-center align-center p-2' sx={{ width: '16vw' }}>
                          <Box className='language-dropdown viewcontact-icon-style'>
                            <LevelOfExpertiseSelection
                              isDrawer={true}
                              defaultValue={selectedLanguages?.expertise || ''}
                              onChange={(e, data) => {
                                handleLanguageChange(index, 'expertise', data && data?.field_value ? data.field_value : null);
                              }}
                              label={t(`${TRANS_KEYS.CONTACTS_LEVEL_OF_EXPERTISE}:key`)}
                              viewAddress={true}
                            />
                          </Box>
                        </TableCell>
                        {language?.length > 1 && (
                          <TableCell className='remove-another-button d-flex align-center pt-3 p-2'>
                            <CloseIcon onClick={() => handleRemoveLanguage(index)} className='table-close-icon' />
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })
                )}
              </CustomTable>
            </Box>
          </Box>
        </Box>

        <Divider variant='middle' />

        <Box className='section-details m-2'>
          <Box className='language-section'>
            <Box className='d-flex column-gap mb-1 viewcontact-icon-style'>
              <LanguageIcon color='disabled'></LanguageIcon>
              <Box className='title-color fs-12'>{t(`${TRANS_KEYS.CONTACT_CERTIFICATION_AND_LICENSE}:key`)}</Box>
            </Box>
            <Box>
              <CustomTable
                headerData={[t(`${TRANS_KEYS.CERTIFICATION_TYPE}:key`), t(`${TRANS_KEYS.CERTIFICATION_TITLE}:key`), t(`${TRANS_KEYS.CERTIFICATION_EXPIRE_ON}:key`)]}
                title={t(`${TRANS_KEYS.ADD_CERTIFICATION_AND_LICENSE}:key`)}
                handleAnotherRow={handleAddCertificate}
                isEditing={isEditingCertificate}
                rows={certificate}
                disable={3}
                onEditChange={handleEditCertificate}
                showAddAnotherButton={false}
                customTableId={'view-contact-content'}
                headerSize={'fs-12'}
                titleSize={'fs-13'}
                updateField={updateField}
                saveDataKey={'contact_certificate'}
                // handleCloseClick = {handleCloseClick}
              >
                {isEditingCertificate ? (
                  certificate?.length === 0 ? (
                    <TableRow>
                      <TableCell align='center' className='p-2'>
                        <Box className='fs-11'>-</Box>
                      </TableCell>
                      <TableCell align='center' className='p-2'>
                        <Box className='fs-11'>-</Box>
                      </TableCell>
                      <TableCell align='center' className='p-2'>
                        <Box className='fs-11'>-</Box>
                      </TableCell>
                    </TableRow>
                  ) : (
                    certificate &&
                    certificate?.map((selectedCertificate, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align='center' className='p-2'>
                            <Box className='fs-11'>{selectedCertificate?.certification_type || ''}</Box>
                          </TableCell>
                          <TableCell align='center' className='p-2'>
                            <Box className='fs-11'>{selectedCertificate?.certifications || ''}</Box>
                          </TableCell>
                          <TableCell align='center' className='p-2'>
                            <Box className='fs-11'> {selectedCertificate?.expire_on || ''} </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )
                ) : (
                  certificate?.map((selectedCertificate, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align='center' className='input-field-data-medium justify-center align-center col-2' sx={{ width: '13vw' }}>
                          <Box className='viewcontact-icon-style'>
                            <CertificationTypeSelection
                              isDrawer={true}
                              defaultValue={selectedCertificate?.certification_type || ''}
                              onChange={(e, data) => {
                                handleChangeCertification(index, 'certification_type', data && data?.field_value ? data.field_value : null);
                              }}
                              label={t(`${TRANS_KEYS.CERTIFICATION_TYPE}:key`)}
                              isApplyWidth={true}
                            />
                          </Box>
                        </TableCell>
                        <TableCell align='center' className='input-field-data-large  justify-center align-center p-2 col-6'>
                          <Box className='viewcontact-icon-style'>
                            <CustomInputField
                              value={selectedCertificate?.certifications || ''}
                              onChange={e => handleChangeCertification(index, 'certifications', e.target.value)}
                              label={t(`${TRANS_KEYS.CONTACT_CERTIFICATION_AND_LICENSE}:key`)}
                            />
                          </Box>
                        </TableCell>
                        <TableCell align='center' className='input-field-data justify-center align-center p-2 col mt-1'>
                          <Box className='viewcontact-icon-style'>
                            {/* <CustomInputField
                              type="number"
                              value={selectedCertificate?.expire_on || ""}
                              onChange={(e) =>
                                handleChangeCertification(
                                  index,
                                  "expire_on",
                                  e.target.value,
                                )
                              }
                              label={t(`${TRANS_KEYS.CERTIFICATION_YEARS}:key`)}
                            /> */}
                            <DatePickerYear
                              value={selectedCertificate?.expire_on || ''}
                              label={t(`${TRANS_KEYS.CERTIFICATION_EXPIRE_ON}:key`)}
                              className='input-field-data'
                              onChange={e => handleChangeCertification(index, 'expire_on', e)}
                              showOnlyYear={true}
                            />
                          </Box>
                        </TableCell>
                        {certificate?.length > 1 && (
                          <TableCell className='remove-another-button d-flex align-center pt-3 p-2 '>
                            <CloseIcon onClick={() => handleRemoveCertificate(index)} className='table-close-icon' />
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })
                )}
              </CustomTable>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ViewEducationLanguages.propTypes = {
  data: PropTypes.object,
  register: PropTypes.func,
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  values: PropTypes.object,
  watch: PropTypes.func,
  handleSave: PropTypes.func,
  defaultValues: PropTypes.object,
  updateField: PropTypes.func,
  getContactDetails: PropTypes.func,
  setIsPopupOpen: PropTypes.func,
  isPopupOpen: PropTypes.func
};

export default ViewEducationLanguages;
