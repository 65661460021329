import { Box, Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import CustomCardUi from '../../CustomCardUi';
import { formatDate } from '../../../../utils/date';
// import CardSlider from './CardsSlider';

const EducationCard = props => {
  const {
    index,
    addIcon,
    // deleteItem = () => { },
    styleData = {},
    deleteItem = () => {},
    data,
    editItems = () => {},
    getContact = () => {},
    getContactDetails = () => {},
    setIsLoading = () => {}
    // children
  } = props;
  const { titleSize = 14, textSize = 8 } = styleData;
  const handleEdit = () => {
    editItems(index);
  };

  let details = [data?.study_type || data?.major, data?.school_name, data?.country?.name];
  details = details.filter(item => item !== null && item !== undefined && item !== '').join(', ');
  let tenure = [data?.start_Date || formatDate(data?.start_date, 'YYYY'), data?.end_Date || formatDate(data?.end_date, 'YYYY')];
  tenure = tenure.filter(item => item !== null && item !== undefined && item !== '' && item !== 0).join('-');

  const handleDelete = () => {
    deleteItem(index);
  };

  return (
    <>
      {/* <CardSlider
        cardData={cardData}
        // isArrow={true}
        // addIcon={security_icon}
        styleData={{
          titleSize: 13,
          textSize: 11,
          height: 110,
          width: 400,
          minWidth: 400,
        }}
      // handleOpenPopup={openPopupEditing}
      > */}

      <CustomCardUi
        editItems={handleEdit}
        //   deleteItem = {deleteItem}
        addIcon={addIcon}
        deleteItem={handleDelete}
        item={data}
        type={'education-details'}
        deleteToast={'Education Details'}
        getContact={getContact}
        setLoading={setIsLoading}
        getContactDetails={getContactDetails}
        styleData={{
          titleSize: 13,
          textSize: 11,
          height: 110,
          width: 400,
          minWidth: 400
        }}
      >
        {/* </CardSlider> */}
        <Grid className='justify-content-between w-100 pr-2'>
          <Box className='d-flex align-items-center ' gap={1}>
            <Box component='div' className={`card-title font-weight-bold m-0 fs-${titleSize}`}>
              {data?.degree_name}
            </Box>
          </Box>
          <Box className={`fs-${textSize} header-containt`}>{details}</Box>
          <Box className={`fs-${textSize} header-containt`}>{tenure}</Box>
        </Grid>
      </CustomCardUi>
    </>
  );
};
EducationCard.propTypes = {
  addIcon: PropTypes.string,
  editItems: PropTypes.func,
  deleteItem: PropTypes.func,
  data: PropTypes.object,
  styleData: PropTypes.object,
  downloadIcon: PropTypes.string,
  children: PropTypes.element,
  index: PropTypes.number,
  getContact: PropTypes.func,
  getContactDetails: PropTypes.func,
  setIsLoading: PropTypes.func
};

export default EducationCard;
