import React, { useEffect, useState } from 'react';

import FmdGoodIcon from '@mui/icons-material/FmdGood';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import WorkIcon from '@mui/icons-material/Work';
import { Avatar, Box, ListItem, Skeleton, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CompanyImg from '../../../assets/images/company.svg';
import PersonSearch from '../../../assets/images/person_search.svg';
import { contactDocumentApi } from '../../../services/ApiService';
import { GET, ROUTES } from '../../../services/constantService';
import { convertArrayBufferToBlobUrl } from '../../../utils/common';
import ViewContactDrawer from '../../ViewContactDrawer';
import { Tags } from '../Components/Tags';

export function ContactCard({ item }) {
  const data = item?._source;
  const name = (data?.first_name || '') + ' ' + (data?.last_name || '');
  const jobTitle = data?.current_job_title || '--';
  let linkedInUrl = data?.linkedin_url;
  linkedInUrl = linkedInUrl?.startsWith('http') ? linkedInUrl : linkedInUrl ? `https://${linkedInUrl}` : linkedInUrl;
  const address = data?.contact_address?.[0];
  const location = `${address?.city || ''} ${address?.metropolitan_area || ''} ${(address?.city || address?.metropolitan_area) && address?.contact_country?.name ? ',' : ''} ${address?.contact_country?.name || ''}`;
  const companyName = data?.contact_company?.name || '--';
  const contactId = data?.id;
  const source = data?.source || '--';
  const tags = data?.contact_tags?.map(tag => tag?.ign_tags?.name);

  const [isContactDrawerOpen, setIsContactDrawerOpen] = useState(false);
  const [profileUrl, setProfileUrl] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);

  const fetchImages = async () => {
    setImgLoading(true);
    try {
      const response = await contactDocumentApi(GET, contactId, null, null, null, 'image');
      if (response.status === 200) {
        const url = convertArrayBufferToBlobUrl(response?.data?.Body?.data);
        setProfileUrl(url);
      }
      setImgLoading(false);
    } catch (error) {
      setImgLoading(false);
    }
  };

  useEffect(() => {
    if (contactId) {
      fetchImages();
    }
  }, [contactId]);

  return (
    <ListItem sx={{ padding: 0, marginBottom: 2 }}>
      {isContactDrawerOpen && (
        <ViewContactDrawer navigateToAllContacts={false} isDrawerOpen={isContactDrawerOpen} setIsDrawerOpen={setIsContactDrawerOpen} id={contactId} baseRoute={ROUTES.globalSearch} />
      )}

      <Box display={'flex'} width={'100%'} height={'10vh'} borderBottom={'1px solid rgba(0, 0, 0, 0.3)'}>
        <Box
          sx={{ display: 'flex', alignItems: 'center' }}
          onClick={() => {
            setIsContactDrawerOpen(true);
          }}
        >
          {imgLoading && <Skeleton variant='circular' width={'53px'} height={'53px'} />}
          {!imgLoading && <Avatar variant='circular' sx={{ width: '53px', height: '53px', borderRadius: '50%', cursor: 'pointer' }} src={profileUrl} />}
        </Box>

        <Box display={'flex'} marginLeft={3} width={'100%'}>
          <Box display={'flex'} flex={1} flexDirection={'column'} justifyContent={'center'}>
            <Box display={'flex'} alignItems={'center'} gap={0.5}>
              <Typography
                onClick={() => {
                  setIsContactDrawerOpen(true);
                }}
                color={'black'}
                fontSize={14}
                fontWeight={900}
                style={{
                  cursor: 'pointer'
                }}
              >
                {name}
              </Typography>

              <Link target={linkedInUrl ? '_blank' : '_self'} to={linkedInUrl || '#'} style={{ marginBottom: 4, color: linkedInUrl ? '' : 'inherit', pointerEvents: linkedInUrl ? 'auto' : 'none' }}>
                <LinkedInIcon sx={{ width: '18px', height: '18px', cursor: linkedInUrl ? 'pointer' : null }} />
              </Link>
            </Box>
            <Box display={'grid'} gridTemplateColumns={'1fr 1fr 1fr 0.8fr 0.6fr'} alignItems={'center'}>
              <Typography color={'#1b4965'} marginY={1} fontSize={10} fontWeight={500}>
                <WorkIcon sx={{ width: '14px', height: '14px', marginRight: 0.5, color: '#979FB5' }} />
                {jobTitle}
              </Typography>

              <Typography sx={{ overflow: 'hidden', whiteSpace: 'nowrap', cursor: 'pointer', textOverflow: 'ellipsis' }} color={'#1b4965'} marginY={1} fontSize={10} fontWeight={500}>
                <FmdGoodIcon sx={{ width: '14px', height: '14px', marginRight: 0.5, color: '#979FB5' }} />
                <Tooltip placement='top' title={location?.trim() || ''}>
                  {location?.trim() || '--'}
                </Tooltip>
              </Typography>

              <Typography color={'#1b4965'} marginY={1} fontSize={10} fontWeight={500}>
                <Box display={'flex'} alignItems={'center'}>
                  <img width={'12px'} height={'12px'} src={CompanyImg} alt={companyName} />
                  <Typography marginLeft={1} marginRight={0.5} color={'#959fb7'} fontSize={10} fontWeight={600}>
                    Company:
                  </Typography>
                  {companyName}
                </Box>
              </Typography>
              <Typography color={'#959fb7'} marginY={1} fontSize={10} fontWeight={500}>
                <Tags tags={tags} />
              </Typography>
              <Typography color={'#1b4965'} marginY={1} fontSize={10} fontWeight={500}>
                <Box display={'flex'} alignItems={'center'}>
                  <img width={'12px'} height={'12px'} src={PersonSearch} alt={source} />
                  <Typography marginLeft={1} marginRight={0.5} color={'#959fb7'} fontSize={10} fontWeight={600}>
                    Source:
                  </Typography>
                  {source}
                </Box>
              </Typography>
            </Box>
          </Box>

          <MoreHorizIcon style={{ cursor: 'pointer', color: '#5f6368', marginRight: 10 }} />
        </Box>
      </Box>
    </ListItem>
  );
}

ContactCard.propTypes = {
  item: PropTypes.object
};
