import React, { useContext, useEffect, useState } from 'react';
import { useLanguageHooks } from '../../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../../utils/languageNamespaces';
import { ProjectDataContext } from '../../Context';
import { Box, Stack, Grid } from '@mui/material';
import { AccordionHeader } from '../Scope';
import InputField from '../../../../../components/common/InputField';
import { DeleteForever } from '@mui/icons-material';
import CustomDropdown from '../../../../../components/common/CustomDropdown';
import { SFPADataApi, TargetIndustryDataApi, picklistDropDownApis } from '../../../../../services/ApiService';
import useApi from '../../../../../utils/Hooks/useApiHook';
import { DELETE, DROP_DOWN_PICKLIST, GET, POST } from '../../../../../services/constantService';
import Loader from '../../../../../components/common/Loader';
import { useSnackbar } from 'notistack';

const TargetIndustry = () => {
  const { projectData, handleUniversalState } = useContext(ProjectDataContext);
  const [loader, setLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  //API Integration
  const { data: targetIndustryOptionsResponse } = useApi({
    queryFn: () => {
      return picklistDropDownApis(GET, DROP_DOWN_PICKLIST.INDUSTRIES_PICKLIST);
    }
  });

  const { data: subIndustryOptionResponse } = useApi({
    queryFn: () => {
      return picklistDropDownApis(POST, DROP_DOWN_PICKLIST.SUB_INDUSTRY);
    }
  });

  const { t } = useLanguageHooks([
    TRANS_KEYS.ADD_PROJECT_LABEL_TARGET_INDUSTRIES,
    TRANS_KEYS.ADD_PROJECT_BUTTON_ADD_INDUSTRY,
    TRANS_KEYS.ADD_PROJECT_LABEL_WEIGHT,
    TRANS_KEYS.ADD_PROJECT_LABEL_SUB_INDUSTRY
  ]);

  const [recommendedIndustries, setRecommendedIndustries] = useState(projectData?.targetIndustries || []);

  const handleUniversalStateLocally = updatedVal => {
    const event = {
      target: {
        name: 'targetIndustries',
        value: updatedVal
      }
    };
    handleUniversalState(event);
  };

  const handleTargetIndustryChange = (key, value, index) => {
    console.log({ key, value, index });
    recommendedIndustries[index][key] = value;
    const newTargetIndustries = recommendedIndustries.slice();
    setRecommendedIndustries(newTargetIndustries);
    handleUniversalStateLocally(newTargetIndustries);
  };

  const deleteTargetIndustry = async cardData => {
    try {
      setLoader(true);
      console.log({ cardData });
      const projectId = projectData?.id;
      if (!cardData?.industry_id.includes('new') && !cardData?.industry_id.includes('bulk')) {
        const res = await TargetIndustryDataApi(
          DELETE,
          '',
          {
            projectId: projectId,
            industryId: cardData?.industry?.id,
            subIndustryIds: []
          },
          'bulk-delete'
        );
        console.log('targetIndustry-delete', res);
      }

      const updatedVal = recommendedIndustries.filter(selectedTargetIndustry => selectedTargetIndustry.industry_id != cardData?.industry_id);
      setRecommendedIndustries(updatedVal.slice());
      handleUniversalStateLocally(updatedVal.slice());
      setLoader(false);
    } catch (err) {
      enqueueSnackbar(err, 'error');
    }
  };

  const addTargetIndustry = event => {
    console.log({ targetIndustryData: event });
    console.log({ event: typeof event });

    let newIndustry = {};

    if (typeof event == 'object') {
      console.log(event, 'object');

      newIndustry = {
        industry: event,
        industry_id: 'new-' + event.id,
        weight: '0',
        sub_industries: [null]
      };
    } else {
      newIndustry = {
        industry: {
          name: event,
          id: 'new-type-' + recommendedIndustries.length + 1
        },
        weight: '0',
        industry_id: 'new-type-' + recommendedIndustries.length + 1,
        sub_industries: [null]
      };
    }

    const updatedIndustries = [newIndustry, ...recommendedIndustries];
    setRecommendedIndustries(updatedIndustries);
    handleUniversalStateLocally(updatedIndustries);
  };

  const onClickShowMore = async () => {
    try {
      setLoader(true);
      const companyName = projectData?.ign_companies?.name;
      const ign_industry = {
        name: projectData?.ign_industries?.name
      };
      const fetchedIndustry = recommendedIndustries?.map(industry => {
        return {
          isSelected: true,
          isNew: false,
          name: industry?.industry?.name
        };
      });
      const payload = {
        template_name: 'SFPA-Industry-Search',
        mode: 'more',
        variables: {
          searchCompany: companyName,
          searchIndustry: [ign_industry],
          fetchedIndustryList: fetchedIndustry,
          key: 'fetchedIndustryList'
        }
      };
      console.log('target-industry-more-payload', payload);

      const res = await SFPADataApi(POST, projectData?.id, payload);
      console.log('target-industry-res', res);
      if (res && res?.data && res?.data?.target_industries) {
        const allData = res?.data?.target_industries ?? [];
        const newData = allData?.filter(data => data?.isNew == true);

        const targetIndustries = newData.map((data, index) => {
          return {
            industry: {
              name: data?.name,
              industry_id: 'bulk-' + index
            },
            weight: '0',
            industry_id: 'bulk-' + index,
            sub_industries: []
          };
        });
        const newTargetIndustries = [...targetIndustries, ...recommendedIndustries];
        setRecommendedIndustries(newTargetIndustries);
        handleUniversalStateLocally(newTargetIndustries);

        console.log('target-industry-new-data', newData);
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      enqueueSnackbar(err, 'error');
    }
  };

  console.log('target-industry-dropdown', targetIndustryOptionsResponse, subIndustryOptionResponse?.data?.data);

  const handleSubIndustryChange = async (index, value) => {
    handleTargetIndustryChange('sub_industries', value, index);

    console.log({ eventChange: value });
  };
  useEffect(() => {
    if (!isDataLoaded) {
      setRecommendedIndustries(projectData?.targetIndustries ?? []);
      if (projectData?.targetIndustries) {
        console.log({ isDataLoadedIndustry: projectData?.targetIndustries });
        setIsDataLoaded(true);
      }
    }
    console.log({ TargetIndustryProjectData: projectData?.targetIndustries });
  }, [projectData]);
  return (
    <Box>
      <Loader show={loader} />
      <AccordionHeader
        onSearch={addTargetIndustry}
        searchOptions={targetIndustryOptionsResponse?.data ?? []}
        searchLabel={t(`${TRANS_KEYS.ADD_PROJECT_BUTTON_ADD_INDUSTRY}:key`)}
        isDropDown={false}
        onClickGetMore={() => onClickShowMore()}
      />
      <Stack
        direction='column'
        sx={{
          maxHeight: '40vh',
          overflowY: 'scroll',
          overflowX: 'hidden'
        }}
      >
        {recommendedIndustries.map((cardData, index) => (
          <Box
            key={index}
            sx={{
              width: '93%',
              backgroundColor: 'background.main',
              color: 'titleBarBackground.main',
              position: 'relative'
            }}
            className='pl-2 pt-3 pb-3 pr-2 rounded border m-1'
          >
            <DeleteForever
              sx={{
                color: 'error.main',
                top: '37%',
                left: '103%',
                position: 'absolute',
                cursor: 'pointer'
              }}
              fontSize='small'
              onClick={() => deleteTargetIndustry(cardData)}
            />
            <Grid container fullWidth spacing={3}>
              <Grid item xs={4}>
                <InputField
                  InputLabelProps={{
                    shrink: true
                  }}
                  label={t(`${TRANS_KEYS.ADD_PROJECT_LABEL_TARGET_INDUSTRIES}:key`)}
                  value={cardData?.industry?.name}
                  fullWidth
                  onChange={event => {
                    handleTargetIndustryChange('targetIndustry', event.target.value, index);
                  }}
                  size='small'
                />
              </Grid>
              <Grid item xs={2}>
                <InputField
                  label={t(`${TRANS_KEYS.ADD_PROJECT_LABEL_WEIGHT}:key`)}
                  fullWidth
                  value={cardData?.weight}
                  onChange={event => {
                    handleTargetIndustryChange('weight', event.target.value, index);
                  }}
                  size='small'
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDropdown
                  options={subIndustryOptionResponse?.data?.data ?? []}
                  multiple={true}
                  value={cardData?.sub_industries.filter(ind => ind != null) ?? []}
                  disableCloseOnSelect={true}
                  label={t(`${TRANS_KEYS.ADD_PROJECT_LABEL_SUB_INDUSTRY}:key`)}
                  className={'w-100'}
                  onChange={(event, value) => {
                    console.log({ eventDelete: event });
                    handleSubIndustryChange(index, value);
                  }}
                  size='small'
                />
              </Grid>
            </Grid>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default TargetIndustry;
