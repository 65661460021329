import PropTypes from 'prop-types';
import React from 'react';
import CustomPopup from '../common/CustomPopup';
import CustomInputField from '../common/StyledComponents/CustomInputField';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import CustomButton from '../common/CustomButton';
import { Box, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ignCompanyDataApi } from '../../services/ApiService';
import { GET, LINKEDIN_URL_REGEX_COMPANY, MAX_URL_LENGTH, ROUTES, URL_LENGTH_VALIDATION_MESSAGE, URL_VALIDATION_MESSAGE, WEBSITE_URL_REGEX_COMPANY } from '../../services/constantService';
import CurrencyUnitSelection from '../common/CurrencyUnitSelection';
import CompanyCapitalStructureSelection from '../common/FunctionalComponents/CompanyCapitalStructureSelection';
import CompanySelection from '../common/FunctionalComponents/CompanySelection';
import CurrencySelection from '../common/FunctionalComponents/CurrencySelection';
import IndustrySelection from '../common/FunctionalComponents/IndustrySelection';
import GeoLocationInput from '../common/GeoLocation';
import Loader from '../common/Loader';
import RichText from '../common/RichText';
import TagSelection from '../common/TagSelection';
import './index.scss';
const AddCompany = props => {
  const { isPopupOpen, setValue, register, unregister, setIsLoading, isUpdate = false, handleSubmit, setIsPopupOpen, reset, loading, watch, companyData } = props;
  const [inputValue, setInputValue] = useState('');
  const [, /*selectedOption*/ setSelectedOption] = useState('');
  const [fetchedCompanyData, setFetchedCompanyData] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [linkedInErrorMessage, setLinkedInErrorMessage] = useState();
  const [linkedInUrl, setLinkedInUrl] = useState();
  const [key, setKey] = useState();
  const [islengthError, setIslengthError] = useState(false);
  const [islengthLinkedInError, setIslengthLinkedInError] = useState(false);
  const navigate = useNavigate();
  const [url, setUrl] = useState();
  const handleClose = () => {
    setIsPopupOpen(false);
    setLinkedInUrl('');
    setUrl('');
    reset();
    if (!isUpdate) {
      navigate(ROUTES.allCompanies);
    }
  };
  const keys = [
    'name',
    'website_url',
    'linkedin_url',
    'employee_range_from',
    'employee_range_to',
    'revenue_range_from',
    'revenue_range_to',
    'company_industries',
    'currency_code',
    'currency_unit',
    'company_tags',
    'comments',
    'location_id',
    'location_description',
    'ticker_symbol',
    'capital_structure'
  ];

  useEffect(() => {
    register('name');
    register('website_url');
    register('linkedin_url');
    register('employee_range_from');
    register('employee_range_to');
    register('revenue_range_from');
    register('revenue_range_to');
    register('comments');
    register('company_tags');
    register('company_industries');
    register('currency_code');
    register('currency_unit');
    register('location_id');
    register('location_description');
    register('ticker_symbol');
    register('capital_structure');
    return () => {
      unregister('name');
      unregister('website_url');
      unregister('linkedin_url');
      unregister('employee_range_from');
      unregister('employee_range_to');
      unregister('revenue_range_from');
      unregister('revenue_range_to');
      unregister('comments');
      unregister('company_tags');
      unregister('company_industries');
      unregister('currency_code');
      unregister('currency_unit');
      unregister('location_id');
      unregister('location_description');
      unregister('ticker_symbol');
      unregister('capital_structure');
    };
  }, [register, unregister]);
  const validateURL = value => {
    if (!value) return true;
    if (value.length > MAX_URL_LENGTH) {
      setIslengthError(true);
      return false;
    }
    if (!WEBSITE_URL_REGEX_COMPANY.test(value)) {
      setIslengthError(false);
      return false;
    }
    return true;
  };
  const validateLinkedInURL = value => {
    if (!value) return true;
    if (value.length > MAX_URL_LENGTH) {
      setIslengthLinkedInError(true);
      return false;
    }
    if (!LINKEDIN_URL_REGEX_COMPANY.test(value)) {
      setIslengthLinkedInError(false);
      return false;
    }
    return true;
  };
  useEffect(() => {
    const isValid = validateURL(url);
    if (!isValid) {
      islengthError ? setErrorMessage(URL_LENGTH_VALIDATION_MESSAGE) : setErrorMessage(URL_VALIDATION_MESSAGE);
    } else {
      setErrorMessage('');
    }
  }, [url]);
  useEffect(() => {
    const isValid = validateLinkedInURL(linkedInUrl);
    if (!isValid) {
      islengthLinkedInError ? setLinkedInErrorMessage(URL_LENGTH_VALIDATION_MESSAGE) : setLinkedInErrorMessage(URL_VALIDATION_MESSAGE);
    } else {
      setLinkedInErrorMessage('');
    }
  }, [linkedInUrl]);
  const handleInputChange = (e, newValue) => {
    setInputValue(newValue);
    setValue('name', newValue, { shouldDirty: true });
    setSelectedOption('');
  };
  const transformTags = async (companyTags = []) => {
    const tags = companyTags.map(tag => ({
      id: tag.ign_tag?.id,
      name: tag.ign_tag?.name,
      color: tag.ign_tag?.color
    }));
    return tags;
  };
  const transformIndustries = async (companyIndustries = []) => {
    const industries = companyIndustries.map(tag => ({
      id: tag.industries?.id,
      name: tag.industries?.name
    }));
    return industries;
  };
  const handleOptionChange = async (e, selectedValue) => {
    setSelectedOption(selectedValue);

    try {
      if (selectedValue) {
        setValue('name', selectedValue.name, { shouldDirty: true });
        setIsLoading(true);
        const { status, data } = await ignCompanyDataApi(GET, '', selectedValue.id);
        if (status === 200 && data) {
          setFetchedCompanyData(data);
          setIsLoading(false);
        }
        if (status === 500) {
          setIsLoading(false);
        }
      }
    } catch (err) {
      console.log('error in getCompanyDetails::', err);
    }
  };
  useEffect(() => {
    if (fetchedCompanyData) {
      setKey(Date.now());
    }
  }, [fetchedCompanyData]);
  useEffect(() => {
    const fetchData = async () => {
      if (fetchedCompanyData || companyData) {
        for (const key of keys) {
          if (key === 'company_tags') {
            const tags = companyData ? await transformTags(companyData.data[key]) : await transformTags(fetchedCompanyData?.[key]);
            setValue(key, tags || '');
          } else if (key === 'company_industries') {
            const industries = companyData ? await transformIndustries(companyData.data[key]) : await transformIndustries(fetchedCompanyData?.[key]);
            setValue(key, industries || '');
          } else if (key === 'capital_structure') {
            setValue(key, companyData ? companyData.data['capital_struct_trans_value'] : fetchedCompanyData?.['capital_struct_trans_value'] || null);
          } else if (key === 'currency_unit') {
            setValue(key, companyData ? companyData.data['currency_unit_trans_value'] : fetchedCompanyData?.['currency_unit_trans_value'] || null);
          } else {
            setValue(key, companyData ? companyData.data[key] : fetchedCompanyData?.[key] || null);
          }
        }
      }
    };
    fetchData();
  }, [fetchedCompanyData, setValue, companyData]);

  return (
    <div>
      <CustomPopup
        dropdown={true}
        title={isUpdate ? ' Update Company' : 'Add Company'}
        open={isPopupOpen}
        onClose={handleClose}
        showAction={true}
        handleSubmit={handleSubmit}
        confirmText={isUpdate ? ' Update' : 'Add'}
      >
        {' '}
        <Loader show={loading} />
        <Box id='add-company' className='p-12' key={key}>
          <Box>
            <Box className='d-flex flex-row fetch-icon justify-content-center p-4 add-company-paper '>
              <Box className='d-flex flex-column rich-text-gap'>
                <Box className='d-flex flex-column field-gap'>
                  <Box className='d-flex flex-column error-field-gap'>
                    <Box className='d-flex flex-row fetch-icon  '>
                      <Box className='d-flex flex-row field-gap '>
                        <Box className='input-field-data'>
                          <CompanySelection
                            freeSolo
                            forcePopupIcon
                            defaultValue={watch('name') || ''}
                            required={true}
                            label={'Company Name'}
                            onInputChange={handleInputChange}
                            onChange={handleOptionChange}
                            inputValue={inputValue}
                            isForAddCompany={true}
                          />
                        </Box>
                        <Box className='input-field-data'>
                          <CustomInputField
                            defaultValue={watch('website_url')}
                            label={'Website URL'}
                            onChange={e => {
                              setUrl(e.target.value);
                              setValue('website_url', e.target.value, { shouldDirty: true });
                            }}
                          />{' '}
                          <Box className={`${errorMessage ? 'visible' : 'invisible'}fs-12 error-message-text `}>{errorMessage || ''}</Box>
                        </Box>
                      </Box>

                      {/* <Box className="d-flex flex-row fetch-icon align-items-center  ">
                      <Box> <CustomButton
                        type={"primary"}
                        variant="contained"
                        size={"medium"}
                        buttonText={"Fetch Details"}
                        customWidth={96}
                        onClick={() => { }}

                      /></Box>
                    </Box> */}
                    </Box>
                    <Box className='d-flex flex-row field-gap '>
                      <Box>
                        <IndustrySelection
                          multiple={true}
                          defaultValue={watch('company_industries')}
                          onChange={data => {
                            setValue('company_industries', data, { shouldDirty: true });
                          }}
                          disableCloseOnSelect
                          isCheckBox={true}
                          label={'Industry Selection'}
                        ></IndustrySelection>
                      </Box>
                      <Box>
                        <Box className='input-field-data'>
                          <CustomInputField
                            defaultValue={watch('linkedin_url')}
                            label={'LinkedIn URL'}
                            onChange={e => {
                              setLinkedInUrl(e.target.value);
                              setValue('linkedin_url', e.target.value, { shouldDirty: true });
                            }}
                          />
                          <Box className={`${linkedInErrorMessage ? 'visible' : 'invisible'}fs-12 error-message-text`}>{linkedInErrorMessage || ''}</Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box className='d-flex flex-row align-item-center '>
                      <Box className='input-field-data mr-1'>
                        <CustomInputField
                          type='number'
                          defaultValue={watch('employee_range_from')}
                          label={'Employeee Range From'}
                          onChange={e => {
                            setValue('employee_range_from', parseInt(e.target.value), { shouldDirty: true });
                          }}
                        />
                      </Box>
                      <Box className='divider mt-3'>
                        <Divider orientation='horizontal' flexItem />
                      </Box>
                      <Box className='input-field-data ml-1'>
                        <CustomInputField
                          type='number'
                          defaultValue={watch('employee_range_to')}
                          label={'Employee Range To'}
                          onChange={e => {
                            setValue('employee_range_to', parseInt(e.target.value), { shouldDirty: true });
                          }}
                        />
                      </Box>
                    </Box>
                    {/* <Box className="divider mt-3"><Divider orientation="horizontal" flexItem /></Box>
                    <Box className="input-field-data ml-1">
                      <CustomInputField
                        type="number"
                        defaultValue={watch("employee_range_to")}
                        label={"Employeee Range To"}
                        onChange={(e) => {
                          setValue("employee_range_to", parseInt(e.target.value));
                        }}
                      /></Box> */}
                  </Box>
                  <Box className='d-flex flex-row '>
                    <Box className='d-flex flex-row revenue-gap mr-1 '>
                      <Box className='compensation-autocomplete'>
                        <CurrencySelection
                          defaultValue={watch('currency_code') || ''}
                          onChange={(e, data) => {
                            setValue('currency_code', data?.label || '', { shouldDirty: true });
                          }}
                          label={'Currency'}
                        ></CurrencySelection>
                      </Box>
                      <Box className='revenue-field'>
                        {' '}
                        <CustomInputField
                          type='number'
                          defaultValue={watch('revenue_range_from')}
                          label={'Revenue Range From'}
                          onChange={e => {
                            setValue('revenue_range_from', parseInt(e.target.value) || null, { shouldDirty: true });
                          }}
                        />
                      </Box>
                    </Box>
                    <Box className='divider mt-3'>
                      <Divider orientation='horizontal' flexItem />
                    </Box>
                    <Box className='d-flex flex-row revenue-gap ml-1 '>
                      <Box className='revenue-field'>
                        {' '}
                        <CustomInputField
                          type='number'
                          defaultValue={watch('revenue_range_to')}
                          label={'Revenue Range To'}
                          onChange={e => {
                            setValue('revenue_range_to', parseInt(e.target.value) || null, { shouldDirty: true });
                          }}
                        />
                      </Box>
                      <Box className='compensation-autocomplete'>
                        <CurrencyUnitSelection
                          defaultValue={watch('currency_unit')}
                          onChange={(e, data) => {
                            setValue('currency_unit', data, { shouldDirty: true });
                          }}
                          label={'Unit'}
                        ></CurrencyUnitSelection>
                      </Box>
                    </Box>
                  </Box>
                  <Box className='d-flex flex-row field-gap '>
                    <Box className='input-field-data'>
                      <TagSelection
                        defaultValue={watch('company_tags')}
                        onChange={data => {
                          setValue('company_tags', data, { shouldDirty: true });
                        }}
                        label={'Tags'}
                        disableCloseOnSelect
                      />
                    </Box>
                    <Box className='input-field-data'>
                      <GeoLocationInput
                        label={'Location'}
                        val={watch('location_description')}
                        onChange={(event, location) => {
                          setValue('location_id', location?.place_id || '', { shouldDirty: true });
                          setValue('location_description', location?.description || '', { shouldDirty: true });
                        }}
                        className='w-100'
                        size='small'
                      />
                    </Box>
                  </Box>
                  <Box className='d-flex flex-row field-gap '>
                    <Box className='input-field-data'>
                      <CustomInputField
                        defaultValue={watch('ticker_symbol')}
                        label={'Ticker Symbol'}
                        onChange={e => {
                          setValue('ticker_symbol', e.target.value, { shouldDirty: true });
                        }}
                      />
                    </Box>
                    <Box className='input-field-data'>
                      <CompanyCapitalStructureSelection
                        label={'Capital Structure'}
                        value={watch('capital_structure')}
                        onChange={(e, value) => {
                          setValue('capital_structure', value ? value : '', { shouldDirty: true });
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className='fs-14 comment-text'>Comments</Box>
                <Box>
                  {' '}
                  <RichText
                    onChange={value => {
                      setValue('comments', value || '', { shouldDirty: true });
                    }}
                    defaultValue={watch('comments') || ''}
                  />
                </Box>
              </Box>
              {/* <Box className='v2 mt-2'>
                <InfoOutlinedIcon color="disabled"></InfoOutlinedIcon>
              </Box> */}
            </Box>
          </Box>
        </Box>
      </CustomPopup>
    </div>
  );
};
AddCompany.propTypes = {
  isPopupOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  setValue: PropTypes.func,
  register: PropTypes.func,
  unregister: PropTypes.func,
  watch: PropTypes.func,
  setIsLoading: PropTypes.func,
  reset: PropTypes.func,
  setIsPopupOpen: PropTypes.func,
  companyData: PropTypes.object,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool
};

export default AddCompany;
