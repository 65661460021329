import { Cancel, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Grid, Stack, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useContext, useMemo, useState } from 'react';
import CustomButton from '../../../../components/common/CustomButton';
import CustomDropdown from '../../../../components/common/CustomDropdown';
import InputField from '../../../../components/common/InputField';
import Loader from '../../../../components/common/Loader';
import {
  BulkSaveTargetDataApi,
  ProjectDataApi,
  ProjectEducationCertificationDataApi,
  TargetCapitalStructureDataApi,
  TargetCompanyDataApi,
  TargetCompanyToAvoidDataApi,
  TargetIndustryDataApi,
  TargetJobFunctionDataApi,
  TargetLocationDataApi,
  TargetTitleDataApi
} from '../../../../services/ApiService';
import { DELETE, POST, PUT } from '../../../../services/constantService';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
import { ProjectDataContext } from '../Context';
import EducationAndCertification from './Components/EducationAndCertification';
import TargetCapitalStructure from './Components/TargetCapitalStructure';
import TargetCompanies from './Components/TargetCompanies';
import TargetCompaniesToAvoid from './Components/TargetCompaniesToAvoid';
import TargetCompensation from './Components/TargetCompensation';
import TargetFunction from './Components/TargetFunction';
import TargetIndustry from './Components/TargetIndustry';
import TargetLocation from './Components/TargetLocation';
import TargetTitle from './Components/TargetTitle';
import WorkExperience from './Components/WorkExperience';

const AccordionBuilder = ({ title, propComponent, data, expanded, onClick }) => {
  return (
    <Accordion expanded={expanded} className='mb-3 m-2 rounded' id={title.split(' ').join('')}>
      <AccordionSummary expandIcon={<ExpandMore />} onClick={onClick} sx={{ fontWeight: 'bold' }}>
        {title} {data ? '(' + data + ')' : ''}
      </AccordionSummary>
      <AccordionDetails>{propComponent}</AccordionDetails>
    </Accordion>
  );
};

export const AccordionHeader = ({
  searchLabel = '',
  searchOptions = [],
  onSearch = () => {},
  isSearch = true,
  dropDownOptions = [],
  dropDownLabel = '',
  onDropDownChange = () => {},
  isDropDown = false,
  onClickGetMore = () => {},
  isGetMore = true,
  isRecommendOptions = true,
  onClickRecommendOption = () => {},
  recommendedOptions = [],
  isNegative = false,
  isExactSearch = false,
  onChangeExactSearch = () => {},
  noOptionsText = <></>,
  freeSolo = true
}) => {
  const { t } = useLanguageHooks([TRANS_KEYS.HELIA_BUILD_ROAD_MAP_TEXT]);
  const onDropDownSelect = (event, newEvent) => {
    if (newEvent) {
      onSearch(newEvent);
    }
  };
  return (
    <Box fullWidth>
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item xs={6}>
          {isSearch && (
            <Autocomplete
              freeSolo={freeSolo}
              options={searchOptions || []}
              onChange={onDropDownSelect}
              fullWidth
              size='small'
              renderInput={params => <TextField {...params} label={searchLabel} />}
              getOptionLabel={data => data?.name || data?.short_desc || data?.title_name || data?.id || ''}
              noOptionsText={noOptionsText}
            />
          )}
        </Grid>
        <Grid item xs={3}>
          {isDropDown && <CustomDropdown options={dropDownOptions ?? []} label={dropDownLabel} className={'w-100'} onChange={e => onDropDownChange(e)} value='' />}
          {isExactSearch && (
            <Stack direction='row' alignItems={'center'}>
              <input type='checkbox' onChange={e => onChangeExactSearch(e)} className='mr-1' />
              <Typography variant='caption'>Exact Search</Typography>
            </Stack>
          )}
        </Grid>
        <Grid container item xs={3} justifyContent={'flex-end'}>
          {isGetMore && <CustomButton onClick={onClickGetMore} buttonText='+ Get More Suggestions' variant='outlined' type='tertiary' />}
        </Grid>
      </Grid>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant='caption' className='p-1'>
          {t(`${TRANS_KEYS.HELIA_BUILD_ROAD_MAP_TEXT}:key`)}
        </Typography>
      </Stack>
      {isRecommendOptions && (
        <Stack direction='row' justifyContent={'flex-start'} flexWrap={'wrap'} className='mt-2'>
          {recommendedOptions.map((option, index) => (
            <Box
              key={index}
              sx={{
                backgroundColor: `${isNegative ? 'error.main' : 'secondary.main'}`,
                color: 'white',
                borderRadius: '20px',
                position: 'relative'
              }}
              className='p-2 m-1 border'
            >
              <Cancel
                sx={{
                  position: 'absolute',
                  top: '-27%',
                  right: '-4%',
                  color: `${isNegative ? 'error.main' : 'primary.main'}`,
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  cursor: 'pointer'
                }}
                fontSize='small'
                onClick={() => {
                  onClickRecommendOption(option);
                }}
              />
              <Typography variant='subtitle2'>
                {option?.name || option?.job_function?.name || option?.target_company?.name || option?.company?.name || option?.capital_structure?.name || option?.capital_structure?.short_desc}
              </Typography>
            </Box>
          ))}
        </Stack>
      )}
    </Box>
  );
};
AccordionHeader.propTypes = {
  searchLabel: PropTypes.string,
  dropDownLabel: PropTypes.string,
  searchOptions: PropTypes.array,
  onSearch: PropTypes.func,
  isSearch: PropTypes.bool,
  dropDownOptions: PropTypes.array,
  onDropDownChange: PropTypes.func,
  isDropDown: PropTypes.bool,
  onClickGetMore: PropTypes.func,
  isGetMore: PropTypes.bool,
  isRecommendOptions: PropTypes.bool,
  recommendedOptions: PropTypes.array,
  isNegative: PropTypes.bool,
  onClickRecommendOption: PropTypes.func,
  isExactSearch: PropTypes.bool,
  onChangeExactSearch: PropTypes.func,
  noOptionsText: PropTypes.func,
  freeSolo: PropTypes.bool
};

const Scope = () => {
  //Translation
  const { t } = useLanguageHooks([TRANS_KEYS.VIEW_PROJECTS_SCOPE_HEADER_TEXT, TRANS_KEYS.CONTACTS_SAVE, TRANS_KEYS.SAVE_AND_NEXT, TRANS_KEYS.EXPAND_ALL, TRANS_KEYS.COLLAPSE_ALL]);
  const { projectData, universalState, handleUniversalState, getValueFromUniversalState, setUniversalState, handleTabChanges = () => {}, fetchProjectData = () => {} } = useContext(ProjectDataContext);

  const [accordionCollapse, setAccordionCollapse] = useState([]);
  const [accordionVisited, setAccordionVisited] = useState([]);
  const [loader, setLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleAccordions = key => {
    if (accordionCollapse.includes(key)) {
      setAccordionCollapse(accordionCollapse.filter(data => data != key));
    } else {
      setAccordionCollapse([...accordionCollapse, key]);
      setAccordionVisited([...accordionVisited, key]);
    }
  };

  const onClickScrollTo = id => {
    setAccordionCollapse([...accordionCollapse, id]);
    setAccordionVisited([...accordionVisited, id]);
    document.getElementById(id.split(' ').join('')).scrollIntoView();
  };

  const stats = key => {
    const data = universalState[key] || projectData[key] || [];
    return data.length || 0;
  };

  const targetIndustryStat = useMemo(() => {
    return stats('targetIndustries');
  }, [universalState['targetIndustries'], projectData['targetIndustries']]);

  const targetCompanyStat = useMemo(() => {
    return stats('targetCompanies');
  }, [universalState['targetCompanies'], projectData['targetCompanies']]);

  const targetCompanyAvoidStat = useMemo(() => {
    const data = universalState['targetCompaniesToAvoid'] || projectData['companiesToAvoid'] || [];
    return data.length || 0;
  }, [universalState['targetCompaniesToAvoid'], projectData['companiesToAvoid']]);

  const targetTitleStat = useMemo(() => {
    return stats('targetTitles');
  }, [universalState['targetTitles'], projectData['targetTitles']]);

  const targetLocationStat = useMemo(() => {
    return stats('targetLocation');
  }, [universalState['targetLocation'], projectData['targetLocation']]);

  const targetFunctionStat = useMemo(() => {
    return stats('targetJobFunctions');
  }, [universalState['targetJobFunctions'], projectData['targetJobFunctions']]);

  const targetCapitalStat = useMemo(() => {
    const data =
      universalState['targetCapital'] && universalState['targetCapital'].length > 0
        ? universalState['targetCapital'][0]?.capital_structure
        : projectData['targetCapital'] && projectData['targetCapital'].length > 0
          ? projectData['targetCapital'][0]?.capital_structure
          : [];
    return data.length || 0;
  }, [universalState['targetCapital'], projectData['targetCapital']]);

  const AccordionData = [
    {
      title: 'Target Industries',
      propComponent: <TargetIndustry />,
      data: targetIndustryStat
    },
    {
      title: 'Target companies',
      propComponent: <TargetCompanies />,
      data: targetCompanyStat
    },
    {
      title: 'Companies To Avoid',
      propComponent: <TargetCompaniesToAvoid />,
      data: targetCompanyAvoidStat
    },
    {
      title: 'Target Title',
      propComponent: <TargetTitle />,
      data: targetTitleStat
    },
    {
      title: 'Target Location',
      propComponent: <TargetLocation />,
      data: targetLocationStat
    },
    {
      title: 'Target Function',
      propComponent: <TargetFunction />,
      data: targetFunctionStat
    },
    {
      title: 'Target Capital Structure',
      propComponent: <TargetCapitalStructure />,
      data: targetCapitalStat
    },
    {
      title: 'Target Compensation',
      propComponent: <TargetCompensation />
    },
    {
      title: 'Education And Certificate',
      propComponent: <EducationAndCertification />
    },
    {
      title: 'Work Experience',
      propComponent: <WorkExperience />
    }
  ];

  const handleSave = async () => {
    try {
      setLoader(true);
      console.log('handle-save', universalState);
      const project_id = projectData?.id;
      let collectivePayload = {};

      // ----TARGET-INDUSTRY------//
      if (universalState['targetIndustries']) {
        const targetIndustries = universalState['targetIndustries'];
        console.log('handle-save-industries', targetIndustries);

        const bulk = targetIndustries.filter(industry => industry?.industry_id.includes('bulk') || industry?.industry_id.includes('type'));
        console.log('handle-save-industries-bulk', bulk);

        let bulkPayload = [];
        bulk.map(async industry => {
          //for creating without sub_industry because in future if all sub industries are removed from this still it wont get deleted fully
          bulkPayload.push({
            industry_name: industry?.industry?.name,
            sub_industry_id: null,
            project_id: project_id,
            weight: +industry?.weight
          });

          //for rest with sub industry
          industry.sub_industries.map(sub_industry => {
            bulkPayload.push({
              industry_name: industry?.industry?.name,
              sub_industry_id: sub_industry ? sub_industry?.id : null,
              project_id: project_id,
              weight: +industry?.weight
            });
          });
        });

        const newIndustryAddedByList = targetIndustries.filter(industry => industry.industry_id.includes('new') && !industry.industry_id.includes('type'));
        console.log('handle-save-industries-save', newIndustryAddedByList);

        const oldIndustries = targetIndustries.filter(industry => !industry.industry_id.includes('new') && !industry.industry_id.includes('type') && !industry.industry_id.includes('bulk'));
        console.log('handle-save-industries-old', oldIndustries);

        // let bulkPayloadOld = [];
        // oldIndustries.forEach(async (industry) => {
        //   industry.sub_industries.map((sub_industry) => {
        //     bulkPayload.push({
        //       industry_name: industry?.industry?.name,
        //       sub_industry_id: sub_industry ? sub_industry?.id : null,
        //       project_id: project_id,
        //       weight: +industry?.weight,
        //     });
        //   });
        // });
        // const resold = await BulkSaveTargetDataApi(POST, "", bulkPayloadOld);
        oldIndustries.forEach(async industry => {
          let subIndustries = industry?.sub_industries.map(industry => {
            if (industry) return industry.id;
            else return null;
          });
          //adding null such that null won't get deleted
          subIndustries.push(null);
          const payload = {
            industryId: industry?.industry_id,
            projectId: project_id,
            newValues: {
              weight: +industry?.weight
            },
            subIndustries: subIndustries
          };

          const res = await TargetIndustryDataApi(PUT, '', payload, 'bulk-update');
          console.log('handle-save-industry-put', payload);
          console.log('handle-save-industry-put-res', res);
          // console.log("handle-save-industry-put-res-olf", resold);
          await TargetIndustryDataApi(
            DELETE,
            '',
            {
              projectId: project_id,
              industryId: industry?.industry?.id,
              subIndustryIds: subIndustries
            },
            'bulk-delete'
          );
          console.log('handle-save-industry-put', payload);
          console.log('handle-save-industry-put-res', res);
          // console.log("handle-save-industry-put-res-olf", resold);
        });

        const industryPayload = [...bulkPayload];
        collectivePayload['target_industries'] = industryPayload;
        console.log('handle-save-industry-payload', industryPayload);

        // Mapping added by list
        await newIndustryAddedByList.forEach(async industry => {
          const payload = {
            project_id: project_id,
            industry_id: industry?.industry?.id,
            weight: +industry?.weight,
            sub_industries: industry?.sub_industries
              ? industry?.sub_industries.map(industry => {
                  if (industry) return industry.id;
                })
              : []
          };

          const res = await TargetIndustryDataApi(POST, '', payload);
          console.log('handle-save-industry-old-api', res);
        });
      }

      // ----TARGET-COMPANY------//
      if (universalState['targetCompanies']) {
        const targetCompanies = universalState['targetCompanies'];
        console.log('handle-save-companies', targetCompanies);

        const bulk = targetCompanies.filter(industry => industry.id.includes('bulk') || industry.id.includes('type'));
        console.log('handle-save-industries-bulk', bulk);

        const bulkPayload = bulk.map(company => {
          return {
            target_company_name: company?.target_company?.name,
            project_id: project_id
          };
        });

        const newCompanyByList = targetCompanies.filter(industry => industry.id.includes('new') && !industry.id.includes('type'));
        console.log('handle-save-company-save', newCompanyByList);

        const companyPayload = [...bulkPayload];
        collectivePayload['target_companies'] = companyPayload;
        console.log('handle-save-company-payload', companyPayload);

        // Mapping pending for list
        await newCompanyByList.forEach(async company => {
          const payload = {
            project_id: project_id,
            target_company_id: company?.target_company?.id
          };

          const res = await TargetCompanyDataApi(POST, '', payload);
          console.log('handle-save-company-new-by-list-api', res);
        });
      }

      // ----TARGET-TITLE------//
      if (universalState['targetTitles']) {
        const targetTitles = universalState['targetTitles'];
        console.log('handle-save-titles', targetTitles);

        const bulk = targetTitles.filter(industry => industry.id.includes('bulk') || industry.id.includes('type'));
        console.log('handle-save-title-bulk', bulk);

        const bulkPayload = bulk.map(title => {
          return {
            title_name: title?.title_name,
            project_id: project_id,
            weight: +title?.weight,
            employee_range_from: +title?.employee_range_from,
            employee_range_to: +title?.employee_range_to,
            revenue_range_from: +title?.revenue_range_from,
            revenue_range_to: +title?.revenue_range_to
          };
        });

        const newTitleAddedByList = targetTitles.filter(industry => industry.id.includes('new') && !industry.id.includes('type'));
        console.log('handle-save-title-save', newTitleAddedByList);

        const oldTitle = targetTitles.filter(industry => !industry.id.includes('new') && !industry.id.includes('type') && !industry.id.includes('bulk'));
        console.log('handle-save-title-old', oldTitle);

        oldTitle.forEach(async title => {
          const payload = {
            id: title?.id,
            weight: +title?.weight,
            title_name: title?.title_name,
            project_id: project_id,
            employee_range_from: +title?.employee_range_from,
            employee_range_to: +title?.employee_range_to,
            revenue_range_from: +title?.revenue_range_from,
            revenue_range_to: +title?.revenue_range_to
          };
          console.log('handle-save-title-put', payload);

          const res = await TargetTitleDataApi(PUT, {}, payload);
          console.log('handle-save-title-update-api', res);
        });

        const titlePayload = [...bulkPayload];
        collectivePayload['target_titles'] = titlePayload;
        console.log('handle-save-title-payload', titlePayload);

        //Mapping added by list
        await newTitleAddedByList.forEach(async title => {
          const payload = {
            project_id: project_id,
            // target_name: title?.title_name,
            title_name: title?.title_name,
            weight: +title?.weight,
            employee_range_from: +title?.employee_range_from,
            employee_range_to: +title?.employee_range_to,
            revenue_range_from: +title?.revenue_range_from,
            revenue_range_to: +title?.revenue_range_to
          };

          const res = await TargetTitleDataApi(POST, '', payload);
          console.log('handle-save-title-old-api', res);
        });
      }

      // ----Target-job-function----------
      if (universalState['targetJobFunctions']) {
        const targetFunctions = universalState['targetJobFunctions'];
        console.log('handle-save-function', targetFunctions);

        const bulk = targetFunctions.filter(industry => industry.id.includes('bulk') || industry.id.includes('type'));
        console.log('handle-save-function-bulk', bulk);

        const bulkPayload = bulk.map(title => {
          return {
            project_id: project_id,
            job_function_name: title?.job_function?.name
          };
        });

        const newFunctionsAddedByList = targetFunctions.filter(industry => industry.id.includes('new') && !industry.id.includes('type'));
        console.log('handle-save-function-save', newFunctionsAddedByList);
        //Mapping added by list
        await newFunctionsAddedByList.forEach(async job_function => {
          const payload = {
            project_id: project_id,
            job_function_id: job_function?.job_function?.id
          };
          const res = await TargetJobFunctionDataApi(POST, '', payload);
          console.log('handle-save-job-function-old-api', res);
        });
        const titlePayload = [...bulkPayload];
        collectivePayload['target_functions'] = titlePayload;
        console.log('handle-save-function-payload', titlePayload);
      }

      let updateProjectPayload = { id: project_id };
      if (universalState['compensation']) {
        const compensation = universalState['compensation'];
        const bd_currency = compensation?.currency?.id;
        delete compensation.currency;
        updateProjectPayload = {
          ...updateProjectPayload,
          ...compensation,
          bd_currency: bd_currency
        };
      }
      if (universalState['targetLocation']) {
        const locations = universalState['targetLocation'];

        const newLocations = locations.filter(location => typeof location?.id == 'undefined');

        console.log('handle-save-new-location', newLocations);
        newLocations.forEach(async location => {
          console.log('handle-save-location', location);
          const payload = {
            project_id: project_id,
            place_id: location?.place_id,
            place_text: location?.description,
            weight: 100
          };
          console.log('handle-save-location-create-res', payload, TargetLocationDataApi);

          const res = await TargetLocationDataApi(POST, '', payload);
          console.log('handle-save-location-create-res', payload, res);
        });
      }

      if (universalState['targetCapital']) {
        const capitals = universalState['targetCapital'];
        const capitalPayload = capitals.length > 0 ? capitals[0]?.capital_structure.map(capital => capital?.name || capital?.short_desc) : [];
        const capitalStructureOldId = universalState['targetCapitalId'] ?? '';
        const payload = {
          project_id: project_id,
          capital_structure: capitalPayload
        };

        if (capitalStructureOldId.length > 0) {
          const payloadOld = {
            id: capitalStructureOldId,
            ...payload
          };
          const res = await TargetCapitalStructureDataApi(PUT, '', payloadOld);
          console.log('handle-save-capital-create-res', payload, res);
        } else {
          const res = await TargetCapitalStructureDataApi(POST, '', payload);
          console.log('handle-save-capital-create-res', payload, res);
        }
      }

      if (universalState['maxExperience']) {
        updateProjectPayload['work_experience_max_years_current_company'] = '' + universalState['maxExperience']?.value;
        updateProjectPayload['max_experience_mandatory'] = universalState['maxExperience']?.isMandate;
      }

      if (universalState['minExperience']) {
        updateProjectPayload['work_experience_min_years_experience'] = '' + universalState['minExperience']?.value;
        updateProjectPayload['experience_mandatory'] = universalState['minExperience']?.isMandate;
      }

      if (universalState['education']) {
        const educations = universalState['education'];
        educations.forEach(async (education, index) => {
          if (!education.new) {
            const payload = {
              ...education,
              seq_num: index
            };

            const res = await ProjectEducationCertificationDataApi(PUT, '', payload);
            console.log('handle-save-education-res', res);
          }
          if (education.new) {
            const payload = {
              project_id: project_id,
              seq_num: index,
              type: 'education',
              degree: education?.education,
              mandatory_flag: education?.mandatory_flag ?? false
            };

            const res = await ProjectEducationCertificationDataApi(POST, '', payload);
            console.log('handle-save-education-res', res);
          }
        });
      }

      if (universalState['certification']) {
        const certifications = universalState['certification'];

        certifications.forEach(async (certification, index) => {
          if (!certification.new) {
            const payload = {
              ...certification,
              seq_num: index
            };

            const res = await ProjectEducationCertificationDataApi(PUT, '', payload);
            console.log('handle-save-certification-res', res);
          }

          if (certification.new) {
            const payload = {
              project_id: project_id,
              seq_num: index,
              type: 'certificate',
              certificate: certification?.certification,
              mandatory_flag: certification?.mandatory_flag ?? false
            };

            const res = await ProjectEducationCertificationDataApi(POST, '', payload);
            console.log('handle-save-certification-res', res);
          }
        });
      }

      if (universalState['targetCompaniesToAvoid']) {
        const companies = universalState['targetCompaniesToAvoid'];

        const newCompanies = companies.filter(company => company?.id?.includes('new'));

        console.log('handle-save-avoid-new-companies', newCompanies);

        newCompanies.forEach(async company => {
          const payload = {
            project_id: project_id,
            company_id: company?.companyData?.id
          };
          const res = await TargetCompanyToAvoidDataApi(POST, '', payload);
          console.log('handle-save-avoid-new-res', res);
        });
      }

      console.log('handle-save-project-update-payload', updateProjectPayload);

      const updateProjectResponse = await ProjectDataApi(PUT, '', updateProjectPayload);
      console.log('handle-save-update-project-response', updateProjectResponse);

      console.log('handle-save-payload', collectivePayload);
      const res = await BulkSaveTargetDataApi(POST, '', collectivePayload);
      console.log('handle-save-collective-request-response', res);
      fetchProjectData();
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
      enqueueSnackbar(err, 'err');
    } finally {
      setUniversalState({});
    }
  };

  const handleNextTab = () => {
    handleTabChanges(1, 2);
  };

  console.log('string', getValueFromUniversalState('heliaTitle'));

  return (
    <Box fullWidth className='p-4' sx={{ backgroundColor: 'titleBarBackground.main' }}>
      <Stack direction={'row'} className='m-2' justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant='subtitle2'>{t(`${TRANS_KEYS.VIEW_PROJECTS_SCOPE_HEADER_TEXT}:key`)}</Typography>
        <Stack direction='row' spacing={1} justifyContent={'space-between'} alignItems={'center'}>
          <CustomButton variant={'outlined'} type='tertiary p-2' buttonText={t(`${TRANS_KEYS.CONTACTS_SAVE}:key`)} onClick={handleSave} />
          <CustomButton
            variant={'contained'}
            buttonText={t(`${TRANS_KEYS.SAVE_AND_NEXT}:key`)}
            type='primary p-2'
            onClick={async () => {
              await handleSave();
              handleNextTab();
            }}
          />
        </Stack>
      </Stack>
      <InputField
        className='m-2 w-50'
        size='small'
        label={'Helia Title'}
        variant='outlined'
        color='secondary'
        type='text'
        name='heliaTitle'
        InputLabelProps={{
          shrink: true
        }}
        value={getValueFromUniversalState('heliaTitle').length ? getValueFromUniversalState('heliaTitle') : projectData?.job_title}
        onChange={e => handleUniversalState(e)}
      />
      <Grid container fullWidth spacing={2} sx={{ position: 'relative' }}>
        <Grid item xs={10}>
          <Stack direction={'row'} justifyContent={'flex-end'} spacing={2}>
            <CustomButton
              variant={'text'}
              buttonText={t(`${TRANS_KEYS.EXPAND_ALL}:key`)}
              type={'btn'}
              onClick={() =>
                setAccordionCollapse([
                  'Target Industries',
                  'Target companies',
                  'Companies To Avoid',
                  'Target Title',
                  'Target Location',
                  'Target Function',
                  'Target Capital Structure',
                  'Target Compensation',
                  'Education And Certificate',
                  'Work Experience'
                ])
              }
            />
            <CustomButton variant={'text'} buttonText={t(`${TRANS_KEYS.COLLAPSE_ALL}:key`)} type={'btn'} onClick={() => setAccordionCollapse([])} />
          </Stack>
          {AccordionData.map(data => (
            <AccordionBuilder
              key={data.title}
              title={data.title}
              data={data?.data}
              expanded={accordionCollapse.includes(data.title)}
              propComponent={data.propComponent}
              onClick={() => handleAccordions(data.title)}
            />
          ))}
        </Grid>
        <Loader show={loader} />
        <Grid item xs={2}>
          <Box sx={{ position: 'sticky', top: 0 }} className='border rounded shadow mt-4 pt-2 pb-2 pl-4 pr-1'>
            <ul style={{ listStyleType: 'circle', listStylePosition: 'outside' }}>
              {AccordionData.map(data => (
                <li
                  key={data.title}
                  onClick={() => {
                    onClickScrollTo(data.title);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <Typography component={'span'} className='m-1' variant='caption' fontWeight={accordionVisited.includes(data.title) ? 'bold' : 200}>
                    <u>{data.title}</u>
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

AccordionBuilder.propTypes = {
  title: PropTypes.string,
  expanded: PropTypes.bool,
  propComponent: PropTypes.element,
  onClick: PropTypes.func,
  data: PropTypes.any
};
export default Scope;
