import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPickListData } from '../../../../actions';
import CustomDropdown from '../../CustomDropdown';

const JobFunctionSelection = props => {
  const {
    //value,
    name = '',
    defaultValue,
    onChange = () => {},
    disabled = false,
    isDrawer = false,
    multiple = false,
    placeholder,
    disableCloseOnSelect = false,
    className = '',
    isCheckBox = false,
    label = '',
    size = 'small',
    ...rest
    //selectedList
  } = props;

  const [options, setOptions] = useState([]);
  const jobFunction = useSelector(state => state.commonReducer.jobFunction);
  const dispatch = useDispatch();
  const [values, setValues] = useState({});
  const [option, setOption] = useState(false);

  useEffect(() => {
    if (!jobFunction) {
      dispatch(fetchPickListData('job-function-picklist', 'jobFunction'));
    } else {
      setOptions(jobFunction);
    }
  }, [jobFunction, dispatch]);
  const getValueOfSelected = () => {
    let array = [];
    for (let x of defaultValue) {
      const index = options && options.findIndex(obj => obj?.name === (x.job_function?.name || x.job_functions?.name));
      if (index !== -1) {
        // Check if index is found
        array.push(index);
      }
    }
    let value = [];
    for (let y of array) {
      value.push(options[y]);
    }

    return value;
  };
  const handleChange = (e, item) => {
    setOption(true);
    setValues(item);
    onChange(item);
  };

  return (
    <>
      <CustomDropdown
        {...rest}
        options={options}
        name={name}
        onChange={handleChange}
        disabled={disabled}
        isDrawer={isDrawer}
        multiple={multiple}
        placeholder={placeholder}
        disableCloseOnSelect={disableCloseOnSelect}
        className={className}
        isCheckBox={isCheckBox}
        label={label}
        selectedValue={defaultValue}
        value={option ? values : defaultValue ? getValueOfSelected() : []}
        size={size}
      ></CustomDropdown>
    </>
  );
};
JobFunctionSelection.propTypes = {
  defaultValue: PropTypes.array,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  isDrawer: PropTypes.bool,
  multiple: PropTypes.bool,
  isCheckBox: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  disableCloseOnSelect: PropTypes.bool,
  selectedList: PropTypes.array,
  size: PropTypes.string
};
export default JobFunctionSelection;
