import React, { useEffect, useState } from 'react';

import ApartmentIcon from '@mui/icons-material/Apartment';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import WorkIcon from '@mui/icons-material/Work';
import { Avatar, Box, ListItem, Tooltip, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ignCompanyDataApi } from '../../../services/ApiService';
import { GET, ROUTES } from '../../../services/constantService';
import { convertArrayBufferToBlobUrl } from '../../../utils/common';
import { Tags } from '../Components/Tags';

export function ProjectCard({ item }) {
  const data = item?._source;
  const stage = data?.stage || '--';
  const jobTitle = data?.job_title || '--';
  const industryName = data?.ign_industries?.name || '--';
  const location = data?.location || '--';
  const companyName = data?.ign_companies?.name || '--';
  const jobNumber = data?.job_number || '--';
  const tags = data?.project_tags?.map(tag => tag?.tag?.name);

  const [companyUrl, setCompanyUrl] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);

  const fetchImages = async () => {
    setImgLoading(true);
    try {
      const response = await ignCompanyDataApi(GET, null, data?.company_id, 'image');
      if (response.status === 200) {
        const url = convertArrayBufferToBlobUrl(response?.data?.Body?.data);
        setCompanyUrl(url);
      }
      setImgLoading(false);
    } catch (error) {
      setImgLoading(false);
    }
  };

  useEffect(() => {
    if (data?.company_id) {
      fetchImages();
    }
  }, [data?.company_id]);

  return (
    <ListItem sx={{ padding: 0, marginBottom: 2 }}>
      <Box display={'flex'} width={'100%'} height={'10vh'} borderBottom={'1px solid rgba(0, 0, 0, 0.3)'}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!companyUrl && !imgLoading && <ApartmentIcon sx={{ width: '53px', height: '53px', borderRadius: '50%', color: '#979FB5' }} />}
          {imgLoading && <Skeleton variant='circular' width={'53px'} height={'53px'} />}
          {companyUrl && !imgLoading && <Avatar variant='circular' sx={{ width: '53px', height: '53px', borderRadius: '50%' }} src={companyUrl} />}
        </Box>

        <Box display={'flex'} marginLeft={3} width={'100%'}>
          <Box display={'flex'} flex={1} flexDirection={'column'} justifyContent={'center'}>
            <Typography color={'black'} fontSize={14} fontWeight={900}>
              <Box display={'flex'} alignItems={'flex-end'}>
                {jobTitle}
                <Link to={`${ROUTES.projectDetails}/${data?.id}`} target={'_blank'} style={{ color: '#1b4965' }}>
                  <Typography sx={{ fontSize: 10, fontStyle: 'italic', marginLeft: 0.5, cursor: 'pointer' }}>Project Number {jobNumber}</Typography>
                </Link>
              </Box>
            </Typography>

            <Box display={'grid'} gridTemplateColumns={'1fr 1fr 1fr 0.8fr 0.6fr'} alignItems={'center'}>
              <Typography color={'#1b4965'} marginY={1} fontSize={10} fontWeight={500}>
                <WorkIcon sx={{ width: '14px', height: '14px', marginRight: 0.5, color: '#979FB5' }} />
                {companyName}
              </Typography>

              <Typography sx={{ overflow: 'hidden', whiteSpace: 'nowrap', cursor: 'pointer', textOverflow: 'ellipsis' }} color={'#1b4965'} marginY={1} fontSize={10} fontWeight={500}>
                <FmdGoodIcon sx={{ width: '14px', height: '14px', marginRight: 0.5, color: '#979FB5' }} />
                <Tooltip placement='top' title={location}>
                  {location}
                </Tooltip>
              </Typography>

              <Box display={'flex'} alignItems={'center'}>
                <Typography marginLeft={1} marginRight={0.5} color={'#959fb7'} fontSize={10} fontWeight={600}>
                  Industry:
                </Typography>
                <Typography sx={{ width: '150px', overflow: 'hidden', whiteSpace: 'nowrap', cursor: 'pointer', textOverflow: 'ellipsis' }} color={'#000000'} marginY={1} fontSize={10} fontWeight={500}>
                  <Tooltip placement='top' title={industryName}>
                    {industryName}
                  </Tooltip>
                </Typography>
              </Box>

              <Typography color={'#959fb7'} marginY={1} fontSize={10} fontWeight={500}>
                <Tags tags={tags} />
              </Typography>
              <Typography color={'#1b4965'} marginY={1} fontSize={10} fontWeight={600}>
                <Box display={'flex'} alignItems={'center'}>
                  <TimerOutlinedIcon sx={{ width: '16px', height: '16px', color: '#959fb7' }} />
                  <Typography marginX={0.5} fontSize={10} fontWeight={500}>
                    Project Stage:
                  </Typography>
                  {stage}
                </Box>
              </Typography>
            </Box>
          </Box>

          <MoreHorizIcon style={{ cursor: 'pointer', color: '#5f6368', marginRight: 10 }} />
        </Box>
      </Box>
    </ListItem>
  );
}

ProjectCard.propTypes = {
  item: PropTypes.object
};
