import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import React from 'react';

import CustomSelectionComponent from '../../../../components/common/CustomSelectionComponent';

const StageActionRenderer = ({ params, handleEditClick, toggleStage }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = event => setAnchorEl(event.currentTarget);

  return (
    <CustomSelectionComponent
      options={[
        {
          short_desc: 'Edit',
          onClick: () => handleEditClick(params.data)
        },
        {
          short_desc: params.data.deleted_at ? 'Activate' : 'Disable',
          onClick: () => toggleStage(params.data)
        }
      ]}
      handleMenuItemClick={() => null}
      component={<MoreVertIcon className='moveVerticalIconStyle' onClick={handleClick} />}
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
    />
  );
};

StageActionRenderer.propTypes = {
  params: PropTypes.object,
  handleEditClick: PropTypes.func,
  toggleStage: PropTypes.func
};

export default StageActionRenderer;
