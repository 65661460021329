import ApartmentIcon from '@mui/icons-material/Apartment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import WorkIcon from '@mui/icons-material/Work';
import { Box, Button, Divider, styled } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import '../../../../src/utils/common.scss';
import workExperience from '../../../assets/icons/workExperience.svg';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import AddWorkExperience from '../../AddContact/Components/ProfessionalExperience/AddWorkExperience';
import CardSlider from '../../common/CardsSlider';
import ExperienceCard from '../../common/FunctionalComponents/ExperienceCard';
import IndustrySelection from '../../common/FunctionalComponents/IndustrySelection';
import JobFunctionSelection from '../../common/FunctionalComponents/JobFunctionSelection';

// import workExperience from '../../../assets/icons/workExperience.svg';
// const cardData = [
//   {
//     title: "Visualiser",
//     job_history_contact_company: "Design Answers LLP",
//     job_history_city: "Gurugram",
//     job_history_state: "Haryana",
//     job_history_country: "India",
//     start_year: 2021,
//     end_year: 2022,
//     contact_board_from_year: 2020
//   },
//   {
//     title: "Director",
//     job_history_contact_company: "Design Answers LLP",
//     job_history_city: "Gurugram",
//     job_history_state: "Haryana",
//     job_history_country: "India",
//     start_year: 2021,
//     end_year: 2022,
//     contact_board_from_year: 0
//   },
//   {
//     title: "CEO",
//     job_history_contact_company: "Design Answers LLP",
//     job_history_city: "Gurugram",
//     job_history_state: "Haryana",
//     job_history_country: "India",
//     start_year: 2021,
//     end_year: 2022,
//     contact_board_from_year: 2020

//   },
//   {
//     title: "Project Manager",
//     job_history_contact_company: "Design Answers LLP",
//     job_history_city: "Gurugram",
//     job_history_state: "Haryana",
//     job_history_country: "India",
//     start_year: 2021,
//     end_year: 2022,
//     contact_board_from_year: 0
//   },
//   {
//     title: "Software Developer",
//     job_history_contact_company: "Design Answers LLP",
//     job_history_city: "Gurugram",
//     job_history_state: "Haryana",
//     job_history_country: "India",
//     start_year: 2021,
//     end_year: 2022,
//     contact_board_from_year: 2020
//   },
// ];
export const AddAnotherButton = styled(Button)(() => ({
  color: '#32B099',
  width: '122px',
  borderRadius: '0px',
  '&:hover': {
    color: '#32B099',
    backgroundColor: 'transparent'
  }
}));

const ProfessionalExperience = props => {
  const edit = {
    contact_industries: false,
    contact_job_functions: false
  };
  // const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [indexValue, setIndexValue] = useState();
  const [editing, setEditing] = useState(edit);
  const [isEdit, setIsEdit] = useState(false);
  const [industry, setIndustry] = useState([]);
  const [sortedWorkExperience, setSortedWorkExperience] = useState([]);

  const handleOpenPopup = () => {
    setIsEdit(false);
    setIsPopupOpen(true);
  };
  const openPopupEditing = index => {
    setIndexValue(index);
    setIsEdit(true);
    setIsPopupOpen(true);
  };

  const {
    renderActions,
    contactData,
    register,
    unregister,
    setValue = () => {},
    watch = () => {},
    handleSave = () => {},
    getContactDetails = () => {},
    setIsPopupOpen = () => {},
    isPopupOpen = () => {}
  } = props;

  const onSave = async () => {
    if (isEdit) {
      await handleSave({ field: 'updated', from: 'Work Experience', card_id: sortedWorkExperience[indexValue].id });
      // setIsPopupOpen(false)
    } else {
      await handleSave({ field: 'created', from: 'Work Experience' });
      // setIsPopupOpen(false)
    }
  };
  // const [industry setIndustry] = useState("Asset Management");
  // const [jobFunction setJobFunction] = useState("B2B");

  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_INDUSTRY, TRANS_KEYS.CONTACTS_JOB_FUNCTION, TRANS_KEYS.CONTACTS_WORK_EXPERIENCE, TRANS_KEYS.CONTACTS_ADD_ANOTHER, TRANS_KEYS.CONTACTS_WORD_ADD]);

  useEffect(() => {
    register('contact_industries');
    register('contact_job_functions');
    return () => {
      unregister('contact_industries');
      unregister('contact_job_functions');
    };
  }, [register, unregister]);

  useEffect(() => {
    setValue('contact_industries', contactData?.contact_industries);
    setValue('contact_job_functions', contactData?.contact_job_functions);
  }, [contactData]);

  useEffect(() => {
    if (contactData?.contact_work_experience) {
      let sortedValues = contactData?.contact_work_experience.sort((a, b) => {
        if (a?.is_present === b?.is_present) {
          return new Date(b?.end_date) - new Date(a?.end_date);
        }
        return b.is_present - a.is_present;
      });
      setSortedWorkExperience([...sortedValues]);
    }
  }, [contactData]);

  const handleEditClick = type => {
    setEditing(prevState => ({ ...prevState, [type]: true }));
  };

  const handleCloseClick = type => {
    setEditing(prevState => ({ ...prevState, [type]: false }));
  };

  const handleChangeForIndustry = value => {
    setIndustry(value);
    const industry = value.map(item => {
      return { industry_id: item.id };
    });
    setValue('contact_industries', industry);
  };

  const handleChangeForJobFunction = value => {
    const jobFunction = value.map(item => {
      return { job_function_id: item.id };
    });
    setValue('contact_job_functions', jobFunction);
  };

  return (
    <Box id='view-contact' className={'custom-scrollbar pl-3 pt-2 pb-2'}>
      <Box className='details-container-subtab scroll-content'>
        <Box className='section-details m-2'>
          <Box className='d-flex flex-column row-gap w-50'>
            <Box className='d-flex flex-row column-gap viewcontact-icon-style'>
              {editing.contact_industries === false && <ApartmentIcon color='disabled'></ApartmentIcon>}
              <Box className='d-flex row-gap contact-details'>
                <Box className='d-flex align-items-center'>
                  {editing.contact_industries === false && (
                    <Box className='title-color fs-12' onDoubleClick={() => handleEditClick('contact_industries')}>
                      {t(`${TRANS_KEYS.CONTACTS_INDUSTRY}:key`)}
                    </Box>
                  )}
                </Box>

                {editing.contact_industries ? (
                  <Box className='d-flex align-items-center content-space'>
                    <Box className='industry-jobfunction-compact-auto'>
                      <IndustrySelection
                        onChange={handleChangeForIndustry}
                        defaultValue={contactData?.contact_industries}
                        industry={industry}
                        isDrawer={true}
                        multiple={true}
                        isCheckBox={true}
                        className={'industry-jobfunction-custom-drop-down-size'}
                        label={t(`${TRANS_KEYS.CONTACTS_INDUSTRY}:key`)}
                      ></IndustrySelection>
                    </Box>
                    <Box className='d-flex'>{renderActions('contact_industries', false, handleCloseClick)}</Box>
                  </Box>
                ) : (
                  <Box className='d-flex align-center '>
                    <Box variant='body2' className='contact-details-value content-color fs-12' onDoubleClick={() => handleEditClick('contact_industries')}>
                      {contactData.contact_industries && contactData.contact_industries.length !== 0
                        ? contactData.contact_industries.map((item, index) => {
                            return item.industries?.name + (contactData.contact_industries.length - 1 !== index ? ',' : '');
                          })
                        : '-'}
                    </Box>
                  </Box>
                )}
              </Box>
              {/* <Box className='d-flex flex-column row-gap contact-details'>
                <Box variant='body1'>Industry</Box>
                <Box variant='body2' className="contact-details-value">Asset Management</Box>
              </Box> */}
            </Box>
          </Box>
          <Box className='d-flex flex-column column-gap row-gap w-50'>
            <Box className='d-flex flex-row column-gap viewcontact-icon-style'>
              {editing.contact_job_functions === false && <WorkIcon color='disabled'></WorkIcon>}
              <Box className='d-flex row-gap contact-details'>
                <Box className='d-flex align-items-center'>
                  {editing.contact_job_functions === false && (
                    <Box className='title-color fs-12' onDoubleClick={() => handleEditClick('contact_job_functions')}>
                      {t(`${TRANS_KEYS.CONTACTS_JOB_FUNCTION}:key`)}
                    </Box>
                  )}
                </Box>
                {editing.contact_job_functions ? (
                  <Box className='d-flex align-items-center content-space'>
                    <Box className='industry-jobfunction-compact-auto'>
                      <JobFunctionSelection
                        onChange={handleChangeForJobFunction}
                        isDrawer={true}
                        multiple={true}
                        isCheckBox={true}
                        defaultValue={contactData?.contact_job_functions}
                        className={'industry-jobfunction-custom-drop-down-size'}
                        label={t(`${TRANS_KEYS.CONTACTS_JOB_FUNCTION}:key`)}
                      ></JobFunctionSelection>
                      {/* <JobFunctionSelection jobFunction='B2B'></JobFunctionSelection> */}
                    </Box>
                    <Box className='d-flex'>{renderActions('contact_job_functions', false, handleCloseClick)}</Box>
                  </Box>
                ) : (
                  <Box className='d-flex align-center'>
                    <Box variant='body2' className='contact-details-value content-color fs-12' onDoubleClick={() => handleEditClick('contact_job_functions')}>
                      {contactData.contact_job_functions && contactData.contact_job_functions.length !== 0
                        ? contactData.contact_job_functions.map((item, index) => {
                            return item.job_functions?.name + (contactData.contact_job_functions.length - 1 !== index ? ',' : '');
                          })
                        : '-'}
                    </Box>
                  </Box>
                )}
              </Box>
              {/* <Box className='d-flex flex-column row-gap contact-details'>
                <Box variant='body1'>Job Function</Box>
                <Box variant='body2' className="contact-details-value">B2B</Box>
              </Box> */}
            </Box>
          </Box>
        </Box>
        <Divider variant='middle' />
        <Box className='section-details m-2'>
          <Box className='d-flex flex-column row-gap w-100 viewcontact-icon-style'>
            <Box className='d-flex flex-row column-gap w-100'>
              <BusinessCenterIcon color='disabled'></BusinessCenterIcon>
              <Box className='d-flex flex-column row-gap flex-1 w-100'>
                <Box className='title-color fs-12'>{t(`${TRANS_KEYS.CONTACTS_WORK_EXPERIENCE}:key`)}</Box>
              </Box>
            </Box>
            <Box className='card-carousels'>
              <Box className='work-experience-cards m-0 p-0'>
                <CardSlider
                  cardData={sortedWorkExperience}
                  //addIcon={security_icon}
                  styleData={{
                    titleSize: 13,
                    textSize: 10,
                    height: 125,
                    width: 290,
                    minWidth: 290
                  }}
                  handleOpenPopup={openPopupEditing}
                >
                  {sortedWorkExperience?.map((card, cardIndex) => (
                    <React.Fragment key={cardIndex}>
                      <ExperienceCard
                        data={card}
                        index={cardIndex}
                        addIcon={card.is_board_member ? workExperience : ''}
                        editItems={openPopupEditing}
                        getContactDetails={getContactDetails}
                        styleData={{
                          titleSize: 13,
                          textSize: 11,
                          height: 100,
                          width: 400,
                          minWidth: 400
                        }}
                      />
                      {cardIndex < sortedWorkExperience.length - 1 && (
                        <Box className='pr-2 pl-2 arrowIcon'>
                          <ArrowBackIcon />
                        </Box>
                      )}
                    </React.Fragment>
                  ))}
                </CardSlider>
              </Box>
            </Box>
            <Box onClick={handleOpenPopup}>
              <AddAnotherButton variant='text' className='text-capitalize'>
                +{sortedWorkExperience?.length === 0 ? t(`${TRANS_KEYS.CONTACTS_WORD_ADD}:key`) : t(`${TRANS_KEYS.CONTACTS_ADD_ANOTHER}:key`)}
              </AddAnotherButton>
            </Box>
            {isPopupOpen && (
              <AddWorkExperience
                title={`${isEdit ? 'Update' : 'Add'} Work Experience`}
                setIsPopupOpen={setIsPopupOpen}
                isPopupOpen={isPopupOpen}
                handleSave={onSave}
                register={register}
                unregister={unregister}
                setValue={setValue}
                watch={watch}
                data={isEdit ? sortedWorkExperience[indexValue] : null}
                isEditing={isEdit}
                setIsEdit={setIsEdit}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ProfessionalExperience.propTypes = {
  renderActions: PropTypes.func,
  contactData: PropTypes.object,
  register: PropTypes.func,
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  watch: PropTypes.func,
  handleSave: PropTypes.func,
  getContactDetails: PropTypes.func,
  setIsPopupOpen: PropTypes.func,
  isPopupOpen: PropTypes.func
};

export default ProfessionalExperience;
