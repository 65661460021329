import { LinkedIn, LocationOnSharp, Work } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../components/common/CustomButton';
import DrawerComponent from '../../../../components/common/Drawer';
import Loader from '../../../../components/common/Loader';
import {
  ClientPortalMenuSetupApi,
  ClientSuiteDataApi
  // DownloadDocumentDataApi
} from '../../../../services/ApiService';

import { documentDownload } from '../../../../services/AttachmentsService';
import { ERROR, GET, POST } from '../../../../services/constantService';
import { roundOffToDecimalFirst } from '../../../../utils/common';
import { useCustomMessageHook } from '../../../../utils/Hooks/useCustomMessageHook';
import ContactImageAvatar from '../../../Searches/ContactImageAvatar';
import './index.scss';
const ClientSuiteJobDetails = ({ drawerOpen, setDrawerOpen, data, onClickFeedback = () => {} }) => {
  const [documents, setDocuments] = useState(null);
  const [loader, setLoader] = useState(false);
  const candidateData = data;
  const [tabOptions, setTabOptions] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [contactData, setContactData] = useState(null);
  const { translateMessage } = useCustomMessageHook();
  const fetchMenuSetupData = async () => {
    try {
      setLoader(true);
      console.log({ fetchSetupMenu: data });
      if (data && data?.project?.id) {
        const res = await ClientPortalMenuSetupApi(GET, `/get-all?item_type=CANDIDATE_MENU_ITEMS&project_id=${data?.project?.id}`);
        console.log({ fetchSetupMenuOption: res });
        const tabs = res?.data ?? [];
        let showAbleTabs = tabs.filter(tab => tab?.long_desc.includes('Y'));
        showAbleTabs = showAbleTabs.sort((a, b) => a?.long_desc.split('-')[1] - b?.long_desc.split('-')[1]);
        setTabOptions(showAbleTabs);
        setActiveTab(showAbleTabs[0]?.field_value);
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
    }
  };

  const titleKeyValue = {
    published_bio: 'Bio',
    concerns: 'Concern',
    strength: 'Strength',
    recommendations: 'Recommendation'
  };

  const fetchTabData = async () => {
    try {
      setLoader(true);
      console.log({ inside: data });
      if (activeTab == 'summary' && summaryData == null) {
        const summary = await ClientSuiteDataApi(POST, '', {
          id: data.id,
          tabName: ['SUMMARY']
        });
        setSummaryData(summary?.data?.rows[0] ? summary?.data?.rows[0]?.contact?.contact_notes_all : []);
      }
      console.log({ activeTab, candidateData });
      if (activeTab == 'candidate_info' && contactData == null) {
        const contactDetails = await ClientSuiteDataApi(POST, '', {
          id: data?.id,
          tabName: ['CANDIDATE_INFO']
        });
        console.log({ contactDetails });
        setContactData(contactDetails?.data?.rows[0] ? contactDetails?.data?.rows[0]?.contact : []);
      }

      if (activeTab == 2) {
        console.log({ activeTab });
      }

      //TODO to change when decision added
      if (activeTab == 'attachment' && documents == null) {
        const documents = await ClientSuiteDataApi(GET, '', '', `${data?.id}/attachments?isConfidential=false&visibility=client_portal`);
        console.log({ documents });
        setDocuments(documents?.data);
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    } finally {
      setLoader(false);
    }
  };

  const handleDownloadButton = async data => {
    let attachmentId = data?.file_id;
    try {
      const url = await documentDownload(attachmentId);
      console.log({ url });
      // const url = await ignContactDataApi(GET, null, null, sub_route)
      if (url) {
        const origin = window.location.origin;
        const subdomain = origin.split('.')[0];
        const restOfDomain = origin.substring(origin.indexOf('.') + 1);
        window.open(`${subdomain}.app.${restOfDomain}/${url}`);
      } else {
        const message = translateMessage('UnableMessage', false, 'Document', 'download');

        enqueueSnackbar(message, { variant: ERROR });
      }
    } catch (e) {
      console.log('Error found in downloadAttachment::', e);
    }
  };

  useEffect(() => {
    if (data?.id) {
      fetchTabData();
    }
  }, [activeTab, data]);

  useEffect(() => {
    setDocuments(null);
  }, [data]);

  useEffect(() => {
    fetchMenuSetupData();
  }, [data]);

  useEffect(() => {
    setDocuments(null);
    setSummaryData(null);
    setContactData(null);
  }, [drawerOpen]);

  return (
    <DrawerComponent
      id={'client-suite-drawer'}
      anchor={'right'}
      open={drawerOpen}
      onClose={() => {
        setDrawerOpen(false);
      }}
      width='80vw'
    >
      <Stack justifyContent='space-between' direction={'row'} spacing={2} className='p-4' alignItems={'center'}>
        <Box className='d-flex align-items-center'>
          <ContactImageAvatar id={candidateData?.contact_id} className='ml-2 mr-2 candidate-img' />
          <Box>
            <Typography className='font-weight-bold fs-16'>
              {candidateData?.contact?.first_name + ' ' + candidateData?.contact?.last_name} <LinkedIn />
            </Typography>
            <Typography className='fs-13'>
              {candidateData.contact?.current_job_title},{candidateData.contact?.contact_company?.name}
            </Typography>
          </Box>
        </Box>

        <Box className='d-flex '>
          <LocationOnSharp className='m-1 fs-20' />
          <div>
            <Typography>{candidateData?.contact?.contact_address[0] ? candidateData?.contact?.contact_address[0]?.metropolitan_area : '-'}</Typography>
            <Typography className='fs-10 text-muted'>Metropolitan Area, India</Typography>
          </div>
        </Box>

        <Box className='d-flex'>
          <Work className='fs-20 m-1' />
          <div>
            <Typography>{roundOffToDecimalFirst(+candidateData?.total_experience ?? 0)} years</Typography>
            <Typography className='fs-10 text-muted'>of Total Experience</Typography>
          </div>
        </Box>

        <Box>
          <CustomButton type={'share-feedback-btn theme-color-bg theme-color-text-white'} variant={'contained'} buttonText={'Share Feedback'} onClick={onClickFeedback} />
        </Box>
      </Stack>
      <div className='tab-view d-flex'>
        <Divider />
        {tabOptions.map((tab, index) => {
          if (tab?.enabled == true && tab?.field_value != 'decision' && tab?.field_value != 'contact_details' && tab?.field_value != 'compensation_info') {
            return tab?.field_value == activeTab ? (
              <div key={tab?.field_value} className='tab-title theme-color-text active-tab pt-1 pl-4 fs-12 ml-3 mr-5 cursor-pointer' onClick={() => setActiveTab(tab?.field_value)}>
                {tab?.short_desc}
                <Divider color={`${index == activeTab ? 'error.main' : ''} `} className='mt-1' />
              </div>
            ) : (
              <div key={tab?.field_value} className='tab-title pt-1 pl-4 fs-12 mr-5 ml-3 cursor-pointer' onClick={() => setActiveTab(tab?.field_value)}>
                {tab?.short_desc}
              </div>
            );
          }
        })}
        <Divider />
      </div>
      <div className='p-3 w-100 tab-view-content'>
        <Loader show={loader} />
        {activeTab == 'summary' && (
          <div>
            {summaryData &&
              summaryData.map((comp, index) => (
                <Box key={index} className='d-flex border rounded p-2 m-1'>
                  <TextSnippetIcon className='theme-color-text fs-20 m-1' />
                  <div>
                    <div className='fs-20 theme-color-text'>{titleKeyValue[comp?.notes_type] ?? comp?.notes_type}</div>
                    <Typography component={'p'} className='fs-14 text-muted'>
                      <div
                        className='fs-16'
                        dangerouslySetInnerHTML={{
                          __html: comp?.notes
                        }}
                      />
                    </Typography>
                  </div>
                </Box>
              ))}
          </div>
        )}
        {activeTab == 'candidate_info' && (
          <div className='p-4'>
            <Grid container className='w-100'>
              <Grid item xs={3}>
                <div className='d-flex'>
                  <TaskAltIcon className='fs-10 m-1' />
                  <div>
                    <div className='fs-16'>
                      Phone Number{' '}
                      {contactData && contactData?.contact_phones[0]?.phone_number?.length > 0 && contactData?.contact_phones[0]?.is_primary ? '(Primary)' : <div className='ml-2 fs-12'> No Data</div>}
                    </div>
                    <div className='fs-14 text-muted'>{contactData && contactData?.contact_phones[0]?.phone_number}</div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className='d-flex'>
                  <TaskAltIcon className='fs-10 m-1' />
                  <div>
                    <div className='fs-16'>
                      Email {contactData && contactData?.contact_emails[0]?.email.length > 0 && contactData?.contact_emails[0]?.is_primary ? '(Primary)' : <div className='ml-2 fs-12'> No Data</div>}
                    </div>
                    <div className='fs-14 text-muted'>{contactData && contactData?.contact_emails[0]?.email}</div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <>
              <div className='d-flex mt-4 mb-2'>
                <TaskAltIcon className='fs-10 m-1' />
                <div className='fs-16'>Work Experience</div>
              </div>
              <Grid container className='w-100 ml-2 mt-2' spacing={1} rowGap={1} alignItems={'stretch'}>
                {contactData && contactData?.contact_work_experience && contactData?.contact_work_experience.length > 0 ? (
                  contactData?.contact_work_experience.map(data => (
                    <Grid key={data?.id} item xs={3}>
                      <div className='d-flex flex-column p-2 border rounded h-100'>
                        <div className='fs-14 bold'>{data?.title}</div>
                        <div className='fs-12 text-muted'>{data?.company?.name}</div>
                        <div className='fs-12 text-muted'>
                          {new Date(data?.start_date).getFullYear()} -{new Date(data?.end_date).getFullYear()}
                        </div>
                      </div>
                    </Grid>
                  ))
                ) : (
                  <div className='ml-2 fs-12'> No Data</div>
                )}
              </Grid>
            </>

            <>
              <div className='d-flex mt-4 mb-2'>
                <TaskAltIcon className='fs-10 m-1' />
                <div className='fs-16'>Education</div>
              </div>
              <Grid container className='w-100 ml-2 mt-2' spacing={1} rowGap={1} alignItems={'stretch'}>
                {contactData && contactData?.contact_education_details && contactData?.contact_education_details.length > 0 ? (
                  contactData?.contact_education_details.map(data => (
                    <Grid key={data?.id} item xs={3}>
                      <div className='d-flex flex-column p-2 border rounded h-100'>
                        <div className='fs-14 bold'>{data?.degree_name}</div>
                        <div className='fs-12 text-muted'>{data?.school_name}</div>
                        <div className='fs-12 text-muted'>
                          {new Date(data?.start_date).getFullYear()} -{new Date(data?.end_date).getFullYear()}
                        </div>
                      </div>
                    </Grid>
                  ))
                ) : (
                  <div className='ml-2 fs-12'> No Data</div>
                )}
              </Grid>
            </>

            <>
              <div className='d-flex mt-4 mb-2'>
                <TaskAltIcon className='fs-10 m-1' />
                <div className='fs-16'>Certification</div>
              </div>
              <Grid container className='w-100 ml-2 mt-2' spacing={1} rowGap={1}>
                {contactData && contactData?.contact_certificate && contactData?.contact_certificate.length > 0 ? (
                  contactData?.contact_certificate.map(data => (
                    <Grid key={data?.id} item xs={3}>
                      <div className='d-flex flex-column p-2 border rounded'>
                        <div className='fs-14 bold'>{data?.certifications}</div>
                        <div className='fs-12 text-muted'>{data?.provider}</div>
                        <div className='fs-12 text-muted'>{data?.expire_on != '' && `expire in ${data?.expire_on}`}</div>
                      </div>
                    </Grid>
                  ))
                ) : (
                  <div className='ml-2 fs-12'>No Data</div>
                )}
              </Grid>
            </>
          </div>
        )}

        {/* TODO in case of decision framework */}
        {activeTab == 'attachment' && (
          <div className='p-4'>
            {documents && documents.length ? (
              documents.map(data => (
                <div key={data.id} className='d-flex w-100 m-2 justify-content-space-between border rounded p-2 align-items-center'>
                  <div className='d-flex flex-column'>
                    <div className='fs-14 bold capitalize-text'>{data?.file_type}</div>
                    <div className='fs-10'>{data?.file_name}</div>
                  </div>
                  <DownloadIcon className='fs-20 theme-color-text cursor-pointer' onClick={() => handleDownloadButton(data)} />
                </div>
              ))
            ) : (
              <div className=' mt-5 w-100 d-flex justify-content-center align-items-center'>No Document Available</div>
            )}
          </div>
        )}
      </div>
    </DrawerComponent>
  );
};

ClientSuiteJobDetails.propTypes = {
  drawerOpen: PropTypes.bool.isRequired,
  setDrawerOpen: PropTypes.func.isRequired,
  data: PropTypes.object,
  onClickFeedback: PropTypes.func
};

export default ClientSuiteJobDetails;
