//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';

//-----------------------------------------------------------// Internal Imports // ------------------------------

// eslint-disable-next-line react/display-name
const CheckboxSelection = props => {
  const { updateInfoState, field } = props;

  const checkedHandler = event => {
    let checked = event.target.checked;
    let colId = props.column.colId;
    props.node.setDataValue(colId, checked);
    updateInfoState(checked, field);
  };

  /* const checkDisabled = () => {
    return (candidate?.dq_reason?.length) ? false : true
  } */

  return (
    <input
      type='checkbox'
      onClick={checkedHandler}
      checked={props.value}
      style={{ cursor: 'pointer' }}
      // disabled={checkDisabled()}
    />
  );
};

export default CheckboxSelection;

CheckboxSelection.propTypes = {
  value: PropTypes.string,
  data: PropTypes.object,
  api: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  stopEditing: PropTypes.func,
  node: PropTypes.object,
  column: PropTypes.object,
  updateInfoState: PropTypes.func,
  field: PropTypes.string
};
