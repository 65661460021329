import React, { useState } from 'react';
import DynamicTabLayout from '../../../components/DynamicTabs/TabLayout';
import EmailTemplatesList from './EmailTemplates/List';
import SmsTemplatesList from './SmsTemplates/List';
import './index.scss';

const MessageTemplates = () => {
  const TabData = [
    { label: 'Email Templates', content: 'Content for Profile', subTabs: [] },
    {
      label: 'SMS Templates',
      content: 'Content for Profile',
      subTabs: []
    }
  ];
  const [activeTab, setActiveTab] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <React.Fragment>
      <div className='position-relative mt-2 mr-3'>
        <DynamicTabLayout navItems={TabData} isDrawer={true} activeTab={activeTab} tabIndex={tabIndex} setTabIndex={setTabIndex} setActiveTab={setActiveTab}>
          <EmailTemplatesList index='Email Templates' />
          <SmsTemplatesList index='SMS Templates' />
        </DynamicTabLayout>
      </div>
    </React.Fragment>
  );
};

export default MessageTemplates;
