import PropTypes from 'prop-types';
import React from 'react';

import CustomPopup from '../../../../components/common/CustomPopup';
import '../../../ProductSetup/index.scss';
import StageForm from './StageForm';

const AddEditStagePopup = props => {
  const { isPopupOpen, handlePopupClose, onApiUpdate } = props;

  return (
    <CustomPopup dropdown={true} title={props.selectedStage ? 'Edit Stage' : 'Add Stage'} open={isPopupOpen} onClose={handlePopupClose}>
      <StageForm onApiUpdate={onApiUpdate} isEdit={props.selectedStage} defaultValues={props.selectedStage || {}} handlePopupClose={handlePopupClose} />
    </CustomPopup>
  );
};
AddEditStagePopup.propTypes = {
  onApiUpdate: PropTypes.func,
  selectedStage: PropTypes.object,
  isPopupOpen: PropTypes.bool,
  handlePopupClose: PropTypes.func
};

export default AddEditStagePopup;
