import React from 'react';
import PropTypes from 'prop-types';
import CustomCardUi from '../../CustomCardUi';
import { Box, Grid } from '@mui/material';
import { formatDate } from '../../../../utils/date';

const ExperienceCard = props => {
  const {
    index,
    addIcon,
    editItems = () => {},
    deleteItem = () => {},
    styleData = {},
    getContactDetails = () => {},
    setLoading = () => {},
    data
    // children
  } = props;

  let address = [
    data?.job_history_contact_company || data?.company?.name,
    data?.job_history_city || data?.city,
    data?.job_history_state || data?.state?.name,
    data?.job_history_country || data?.country?.name
  ];
  address = address.filter(item => item !== null && item !== undefined && item !== '').join(', ');
  let tenure = [data?.start_year || formatDate(data?.start_date, 'YYYY'), data?.end_year || formatDate(data?.end_date, 'YYYY')];
  tenure = tenure.filter(item => item !== null && item !== undefined && item !== '' && item !== 0).join('-');
  const { titleSize = 14, textSize = 8 } = styleData;
  const handleEdit = () => {
    editItems(index);
  };

  const handleDelete = () => {
    deleteItem(index);
  };

  return (
    <>
      {' '}
      <CustomCardUi
        index={index}
        addIcon={addIcon}
        editItems={handleEdit}
        deleteItem={handleDelete}
        getContactDetails={getContactDetails}
        setLoading={setLoading}
        type={'work-experience'}
        deleteToast={'Work Experience'}
        item={data}
        styleData={{
          titleSize: 13,
          textSize: 11,
          height: 110,
          width: 400,
          minWidth: 400
        }}
      >
        <Grid className='justify-content-between w-100 '>
          <Box className='d-flex align-items-center ' gap={1}>
            <Box component='div' className={`card-title font-weight-bold m-0 fs-${titleSize}`}>
              {data?.title || ''}
            </Box>
          </Box>
          <Box className={` m-0 fs-${textSize} header-containt`}>{address}</Box>
          <Box className={` m-0 fs-${textSize} header-containt`}>{tenure}</Box>
        </Grid>
      </CustomCardUi>
    </>
  );
};
ExperienceCard.propTypes = {
  addIcon: PropTypes.string,
  editItems: PropTypes.func,
  deleteItem: PropTypes.func,
  data: PropTypes.object,
  styleData: PropTypes.object,
  children: PropTypes.element,
  index: PropTypes.number,
  getContactDetails: PropTypes.func,
  setLoading: PropTypes.func
};

export default ExperienceCard;
