import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CustomDropdown from '../../CustomDropdown';
import { fetchPickLists } from '../../../../actions';

const CompanyRelationChildTypeSelection = props => {
  const {
    value,
    name = '',
    label = '',
    onChange = () => {},
    disabled = false,
    isDrawer = false,
    // selectedList,
    //setEmailList = () => { },
    required = false,
    ...rest
  } = props;
  const [options, setOptions] = useState([]);
  const companyRelationChildTypeOption = useSelector(state => state.commonReducer.companyRelationChildType);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!companyRelationChildTypeOption) {
      dispatch(fetchPickLists('COMPANY_RELATION_CHILD_TYPE', 'companyRelationChildType'));
    } else {
      setOptions(companyRelationChildTypeOption);
    }
  }, [companyRelationChildTypeOption, dispatch]);

  return (
    <div>
      <CustomDropdown {...rest} value={value} options={options} name={name} onChange={onChange} isDrawer={isDrawer} disabled={disabled} label={label} required={required} />
    </div>
  );
};
CompanyRelationChildTypeSelection.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  isDrawer: PropTypes.bool,
  onChange: PropTypes.func,
  selectedList: PropTypes.array,
  label: PropTypes.string,
  setEmailList: PropTypes.func,
  required: PropTypes.bool
};

export default CompanyRelationChildTypeSelection;
