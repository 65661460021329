import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Divider, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../../selectors';
import { authApi } from '../../../services/ApiService';
import { GET } from '../../../services/constantService';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { getDynamicUrl } from '../../../utils/common';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import SwitchContext from './SwitchContext';

export const userLogout = async () => {
  const url = window.location.origin,
    subdomain = url.split('.')[0];

  const restOfDomain = url.substring(url.indexOf('.') + 1); // Get the rest of the domain

  const dynamicUrl = getDynamicUrl(subdomain, restOfDomain).split('/api')[0];

  const data = await authApi(GET, `logout?callback=${dynamicUrl}/login`);
  window.location.href = data.data.logoutUrl;
};

const UserMenu = ({ anchorEl, open, setAnchorEl, showMyProfile = true }) => {
  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_PROJECT, TRANS_KEYS.CONTACTS_PENDING_TASK, TRANS_KEYS.CONTACTS_WORD_FOR]);

  {
    t(`${TRANS_KEYS.CONTACTS_PENDING_TASK}:key`);
  }

  const userData = useSelector(getUserSelector);
  const [contextPopupOpen, setContextPopupOpen] = useState(false);

  return (
    <div>
      <Menu
        keepMounted
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        anchorEl={anchorEl}
        className='mt-1 right-0'
        sx={{
          right: 0
        }}
      >
        <MenuItem className='d-flex flex-column justify-content-start align-items-start'>
          <p className='fs-12 body-font'>Account</p>
          <div className='d-flex align-items-center'>
            <div className='profile-default-icon mr-2'>
              <AccountCircleIcon className='fs-40' />
            </div>
            <div>
              <p className='fs-14 font-weight-bold header-font'>{userData?.name}</p>
              <p className='fs-10'>{userData?.email}</p>
            </div>
          </div>
        </MenuItem>
        <Divider />
        {showMyProfile && (
          <div>
            <MenuItem>
              <p className='fs-12'>My Profile</p>
            </MenuItem>
            <Divider />
          </div>
        )}

        <MenuItem
          onClick={() => {
            setContextPopupOpen(true);
            // setAnchorEl(null);
          }}
        >
          <p className='fs-12'>Switch Context</p>
        </MenuItem>
        <Divider />

        <MenuItem
          onClick={() => {
            userLogout();
          }}
        >
          <p className='fs-12'>Log Out</p>
        </MenuItem>
      </Menu>
      <SwitchContext
        open={contextPopupOpen}
        onClose={() => {
          setContextPopupOpen(false);
        }}
      />
    </div>
  );
};

export default UserMenu;

UserMenu.propTypes = {
  anchorEl: PropTypes.any,
  setAnchorEl: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  showMyProfile: PropTypes.bool
};
