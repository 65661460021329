import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import CustomButton from '../../../../components/common/CustomButton';
// import { DeleteOutlined } from '@mui/icons-material';
import { WorkBenchContext } from '../../Context';
import { useNavigate } from 'react-router-dom';
import { GET, ROUTES } from '../../../../services/constantService';
import { enqueueSnackbar } from 'notistack';
import { ignWorkbenchDataApi } from '../../../../services/ApiService';
import { Grid } from '@mui/material';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { TRANS_KEYS } from '../../../../utils/languageNamespaces';
import { useLanguageHooks } from '../../../../utils/Hooks/UseLanguageHook';

const ListHeader = ({ name, isShowing, onClick, showAddList = false, onClickAddList = () => {} }) => {
  return (
    <div className='p-2 mt-4 background-color-header d-flex justify-content-between align-items-center rounded' key={name}>
      <div className='fs-14 cursor-pointer' onClick={onClick}>
        {isShowing ? <ChevronRightOutlinedIcon className='fs-14' /> : <KeyboardArrowDownOutlinedIcon className='fs-14' />}
        <span>{name}</span>
      </div>
      {showAddList == true ? <CustomButton variant={'text'} type='tertiary custom-color-white' size={'small'} buttonText={'+ Add List'} onClick={onClickAddList} /> : ''}
    </div>
  );
};
ListHeader.propTypes = {
  name: PropTypes.string,
  isShowing: PropTypes.bool,
  onClick: PropTypes.func,
  showAddList: PropTypes.bool,
  onClickAddList: PropTypes.func
};

const DropDownListComponent = ({ data, onClick, types, selectedId }) => {
  return (
    <div className='mt-2'>
      {data && data.length == 0 && <div className='fs-12 ml-4'>no data </div>}
      {data &&
        data.map((elem, index) => (
          <Grid
            key={index}
            container
            onClick={() => onClick(elem)}
            component={'div'}
            className={`cursor-pointer rounded d-flex align-items-center p-2 mt-1 rounded ${elem.id == selectedId ? 'background-color-selected' : ''}`}
          >
            <Grid xs={2}>
              {elem?.type == types.PROJECT ? <DashboardOutlinedIcon className='fs-16'></DashboardOutlinedIcon> : <AssignmentIndOutlinedIcon className='fs-16'></AssignmentIndOutlinedIcon>}
            </Grid>
            <Grid xs={10}>
              <div className='fs-14'>{elem?.name}</div>
            </Grid>
          </Grid>
        ))}
    </div>
  );
};
DropDownListComponent.propTypes = { data: PropTypes.array, types: PropTypes.array, onClick: PropTypes.func, selectedId: PropTypes.string };

export default function ListComponent() {
  const { setAddWorkBenchModalOpen, setNoMyList, refetch, types, workbenchId } = useContext(WorkBenchContext);
  const navigate = useNavigate();
  const [showMyList, setShowMyList] = useState(true);
  const [showAllList, setShowAllList] = useState(false);

  const { t } = useLanguageHooks([TRANS_KEYS.MY_LIST, TRANS_KEYS.ALL_LIST]);
  const [listData, setListData] = useState();

  const onClickList = data => {
    const { id } = data;
    const path = ROUTES.workbench.replace(':id', id);
    navigate(path);
  };

  const handleAddWorkBenchModal = () => {
    setAddWorkBenchModalOpen(true);
  };

  const fetchListData = async () => {
    try {
      const res = await ignWorkbenchDataApi(GET, '', '', 'all');
      if (res && res.data) {
        const { data } = res;
        setListData(data);
        if (data?.myList?.length == 0) {
          setNoMyList(true);
        }
      }
    } catch (err) {
      enqueueSnackbar(err, 'error');
    }
  };

  useEffect(() => {
    fetchListData();
  }, [refetch]);
  return (
    <div className='w-100 border background-white custom-vh-80 pt-4 rounded'>
      <ListHeader
        key={'my-favorite-list'}
        name={t(`${TRANS_KEYS.MY_LIST}:key`)}
        onClick={() => setShowMyList(!showMyList)}
        isShowing={showMyList}
        showAddList={true}
        onClickAddList={handleAddWorkBenchModal}
      />
      {showMyList && (
        <>
          <DropDownListComponent data={listData?.myList} onClick={onClickList} types={types} selectedId={workbenchId} />
        </>
      )}

      <ListHeader key={'all-favorite-list'} name={t(`${TRANS_KEYS.ALL_LIST}:key`)} onClick={() => setShowAllList(!showAllList)} isShowing={showAllList} showAddList={false} />
      {showAllList && (
        <>
          <DropDownListComponent data={listData?.allList} onClick={onClickList} types={types} selectedId={workbenchId} />
        </>
      )}
    </div>
  );
}
