import { AgGridReact } from 'ag-grid-react';
import React, { useContext, useRef, useState } from 'react';
import { CandidateStageSetupAPi, ProjectStageGroupColor, candidateStageGroupApi, picklistsDataApis } from '../../../services/ApiService';
import { DELETE, GET, POST } from '../../../services/constantService';
import { SCROLL_TIMEOUT } from '../../../utils/common';
import { CustomLoadingOverlayComponent, loadColumnStateFromLocalStorage } from '../../Commons/Utils';
import { ProjectDataContext } from './Context';
import InputColorPicker from './InputColorPicker';
import StageGroupDropdown from './StageGroupDropdown';

const stageGroupingColumnDefs = [
  {
    field: 'menuItem',
    headerName: 'Stage',
    minWidth: 250
  },
  {
    field: 'stage',
    headerName: 'Stage Groups',
    cellRendererFramework: ({ value }) => value,
    minWidth: 300
  }
];

const stageGroupColorsColumnDefs = [
  {
    field: 'menuItem',
    headerName: 'Stage Group',
    minWidth: 250
  },
  {
    field: 'color',
    headerName: 'Color',
    cellRendererFramework: ({ value }) => value,
    minWidth: 262
  }
];

let gridApi, columnApi;
let colorsGridApi, colorsColumnApi;
function StageGrouping() {
  const gridApiRef = useRef(null);
  const ColorsGridApiRef = useRef(null);
  const [, setIsGridReady] = useState(false);
  const [, setIsColorGridReady] = useState(false);
  const { projectData } = useContext(ProjectDataContext);

  const onGridReady = params => {
    gridApi = params.api;
    columnApi = params.columnApi;
    // const columnApi = params.columnApi
    params.columnApi.autoSizeAllColumns();
    loadColumnStateFromLocalStorage('masterSearchContactColumns', {
      columnApi
    });
    // this.setState({ isGridReady: true });
    setIsGridReady(true);
  };

  const onGridReadyColors = params => {
    colorsGridApi = params.api;
    colorsColumnApi = params.columnApi;
    // const columnApi = params.columnApi
    params.columnApi.autoSizeAllColumns();
    loadColumnStateFromLocalStorage('masterSearchContactColumns', {
      columnApi: colorsColumnApi
    });
    // this.setState({ isGridReady: true });
    setIsColorGridReady(true);
  };

  const dataSource = {
    getRows: async params => {
      const optionsResponse = await picklistsDataApis(GET, 'CLIENT_PORTAL_STAGE_GROUP');
      gridApi.showLoadingOverlay();
      const stagesRes = await CandidateStageSetupAPi(GET, `/all?projectId=${projectData?.id}`);
      if (stagesRes.status === 200 && optionsResponse.status < 400) {
        const stageGroupings = await formatData(stagesRes.data, optionsResponse.data);
        params.successCallback(stageGroupings, stageGroupings.length);
      } else {
        params.failCallback();
      }
      gridApi.hideOverlay();
    }
  };

  const colorsDataSource = {
    getRows: async params => {
      colorsGridApi.showLoadingOverlay();
      const response = await ProjectStageGroupColor(GET, `get-all-colors-by-project-id?projectId=${projectData?.id}`);
      if (response.status === 200) {
        params.successCallback(formatColorsData(response.data), response.data.length);
      } else {
        params.failCallback();
      }

      colorsGridApi.hideOverlay();
    }
  };

  const createStageGroups = async (stageId, groupfieldValue) => {
    await candidateStageGroupApi(POST, 'create-if-not-exist', {
      project_id: projectData.id,
      stage_id: stageId,
      stage_group: groupfieldValue
    });
  };

  const deleteCandidateStageGroup = async (projectId, stageGroup, stageId) => {
    await candidateStageGroupApi(DELETE, '', { projectId, stageGroup, stageId });
  };

  const formatData = async (stageGroupings, optionsData) => {
    const options = optionsData.map(val => ({ label: val.short_desc, value: val.field_value }));
    const groupData = await candidateStageGroupApi(GET, `all?project_id=${projectData.id}`);
    const groupDataMapping = {};
    groupData.data.rows.forEach(element => {
      groupDataMapping[element.stage_id] = element.stage_group_value;
    });
    return stageGroupings.map(item => {
      return {
        id: item.id,
        menuItem: item.stage_name,
        stage: (
          <StageGroupDropdown
            createStageGroups={createStageGroups}
            deleteCandidateStageGroup={deleteCandidateStageGroup}
            item={item}
            options={options}
            groupDataMapping={groupDataMapping}
            projectData={projectData}
          />
        )
      };
    });
  };

  const formatColorsData = data => {
    const handleColorChange = async (color, groupName) => {
      await ProjectStageGroupColor(POST, 'create-if-not-exist', {
        projectId: projectData.id,
        stageGroup: groupName,
        color
      });
    };

    return data.map(item => {
      return {
        id: item.id,
        menuItem: item.short_desc,
        color: <InputColorPicker onColorChange={color => handleColorChange(color, item.field_value)} defaultColor={item.color} />
      };
    });
  };

  return (
    <div className='grid-2-col'>
      <div id='myGrid' className='ag-theme-alpine mx-2 ag-grid-container' style={{ height: '65vh', maxWidth: '32rem' }}>
        <AgGridReact
          enableBrowserTooltips={true}
          ref={gridApiRef}
          rowHeight={60}
          datasource={dataSource}
          columnDefs={stageGroupingColumnDefs}
          scrollbarWidth={12}
          rowSelection='multiple'
          onGridReady={onGridReady}
          defaultColDef={{
            minWidth: 100,
            resizable: true,
            autoSizeStrategy: 'fitGridWidth'
          }}
          frameworkComponents={{
            CustomLoadingOverlayComponent
          }}
          loadingOverlayComponent={'CustomLoadingOverlayComponent'}
          getRowNodeId={data => data.id}
          blockLoadDebounceMillis={SCROLL_TIMEOUT}
          suppressHorizontalScroll={false}
          suppressCellSelection={true}
          rowModelType='infinite'
        />
      </div>

      <div id='myGrid' className='ag-theme-alpine mx-2 ag-grid-container' style={{ height: '380px', width: '529px' }}>
        <AgGridReact
          enableBrowserTooltips={true}
          ref={ColorsGridApiRef}
          rowHeight={60}
          datasource={colorsDataSource}
          columnDefs={stageGroupColorsColumnDefs}
          scrollbarWidth={12}
          rowSelection='multiple'
          onGridReady={onGridReadyColors}
          defaultColDef={{
            minWidth: 100,
            resizable: true,
            autoSizeStrategy: 'fitGridWidth'
          }}
          frameworkComponents={{
            CustomLoadingOverlayComponent
          }}
          loadingOverlayComponent={'CustomLoadingOverlayComponent'}
          getRowNodeId={data => data.id}
          blockLoadDebounceMillis={SCROLL_TIMEOUT}
          suppressHorizontalScroll={false}
          suppressCellSelection={true}
          rowModelType='infinite'
        />
      </div>
    </div>
  );
}

export default StageGrouping;
