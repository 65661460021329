import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { globalSearchSelector } from '../../../selectors';
import { updateGlobalSearchPagination } from '../../../store/globalSearchSlice';

export const Pagination = () => {
  const { page: currentPage, pages, totalCount, limit, loading } = useSelector(globalSearchSelector);
  const dispatch = useDispatch();
  const totalPages = Math.ceil(totalCount / limit);
  const handlePagination = page => {
    if (loading || page === currentPage) return;
    dispatch(updateGlobalSearchPagination({ key: 'page', data: page }));
  };

  return totalPages > 0 && totalCount > limit ? (
    <Box display={'flex'} justifyContent={'center'}>
      <Box width={'fit-content'} borderRadius={1} marginY={4} paddingX={2} paddingY={1} bgcolor={'Background'}>
        {pages.map(page => {
          if (page === 'Prev' && pages.includes(1)) return;
          if (page === 'Next' && pages.includes(totalPages)) return;
          if (page > totalPages) return;
          return (
            <Button
              size='small'
              sx={{
                background: page === currentPage ? '#1fb591' : null,
                paddingX: 0.6,
                paddingY: 0,
                minWidth: 'fit-content',
                marginX: 1
              }}
              key={page}
              onClick={() => handlePagination(page)}
            >
              <Typography sx={{ textTransform: 'none', color: page === currentPage ? 'white' : 'rgba(0, 0, 0, 0.5)' }} fontSize={14} color={'rgba(0, 0, 0, 0.5)'} fontWeight={600}>
                {page}
              </Typography>
            </Button>
          );
        })}
      </Box>
    </Box>
  ) : (
    <></>
  );
};
