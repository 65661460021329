import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Menu, MenuItem, TextField, Typography } from '@mui/material';
import PaletteIcon from '@mui/icons-material/Palette';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateViewProjectData } from '../../../../store/viewProjectSlice';
import _ from 'lodash';

const ColorLegend = props => {
  const { handleCandidateColorLabelChange } = props;
  const { colors } = useSelector(state => state.viewProjectReducer);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { register, unregister, setValue, watch } = useForm({
    mode: 'onTouched'
  });

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = () => {
    handleClose();
  };

  useEffect(() => {
    colors.map(color => {
      register(color.id);
      setValue(color.id, color?.candidate_labels?.[0]?.label || '');
    });

    return () => {
      colors.map(color => {
        unregister(color.id);
      });
    };
  }, [register, unregister, colors]);

  const debouncedHandleCandidateColorLabelChange = useRef(_.debounce((val, colorId) => handleCandidateColorLabelChange(val, colorId), 500));

  const handleOnChange = (e, colorId) => {
    const label = e.target.value;
    setValue(colorId, label);

    // UPDATING LABEL IN STORE
    const copyColors = structuredClone(colors);
    const colorIndex = colors.findIndex(color => color.id === colorId);
    if (colorIndex !== -1) {
      copyColors[colorIndex].candidate_labels[0] = {
        label
      };
      dispatch(updateViewProjectData({ key: 'colors', data: copyColors }));
    }

    debouncedHandleCandidateColorLabelChange.current(label, colorId);
  };

  return (
    <Box position={'relative'}>
      <Button color='primary' variant={'outlined'} onClick={handleClick} size='small'>
        <Box className={'d-flex align-items-center justify-content-center '}>
          <Typography sx={{ textTransform: 'none' }} fontWeight={'medium'} fontSize={'14px'}>
            <PaletteIcon fontSize='small' /> Color Legend
          </Typography>
        </Box>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {open && (
          <Button size='small' style={{ position: 'absolute', top: 0, right: -12 }} onClick={handleMenuItemClick}>
            <CloseIcon />
          </Button>
        )}
        <Box marginTop={3}>
          {colors.map(color => (
            <MenuItem key={color.id}>
              <Box display={'flex'} gap={2} alignItems={'center'}>
                <Box border={'0.1px solid black'} marginTop={'2px'} bgcolor={color?.color_code || 'transparent'} width={12} height={12} />
                <TextField placeholder='Enter Text' variant='standard' onChange={e => handleOnChange(e, color?.id)} value={watch(color?.id)} size='small' />
              </Box>
            </MenuItem>
          ))}
        </Box>
      </Menu>
    </Box>
  );
};

ColorLegend.propTypes = {
  handleCandidateColorLabelChange: PropTypes.func
};

export { ColorLegend };
