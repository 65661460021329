import { Box } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import AddCompany from '../../../../../components/AddCompany';
import CustomButton from '../../../../../components/common/CustomButton';
import Loader from '../../../../../components/common/Loader';
import { TargetCompanyToAvoidDataApi, ignCompanyDataApi, picklistDropDownApis } from '../../../../../services/ApiService';
import { DELETE, DROP_DOWN_PICKLIST, ERROR, GET, POST, SUCCESS } from '../../../../../services/constantService';
import { customFormValidator, splitLastIfMultiple } from '../../../../../utils/common';
import useApi from '../../../../../utils/Hooks/useApiHook';
import { useCustomMessageHook } from '../../../../../utils/Hooks/useCustomMessageHook';
import { useLanguageHooks } from '../../../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../../../utils/languageNamespaces';
import { createRequiredField, createSchema } from '../../../../Companies/Utils';
import { ProjectDataContext } from '../../Context';
import { AccordionHeader } from '../Scope';
function TargetCompaniesToAvoid() {
  const { t } = useLanguageHooks([TRANS_KEYS.ADD_COMPANIES_TO_AVOID]);
  const { enqueueSnackbar } = useSnackbar();
  const { translateMessage } = useCustomMessageHook();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [loader, setLoader] = useState(false);
  const [addCompanyPopup, setAddCompanyPopup] = useState(false);
  const { projectData, handleUniversalState } = useContext(ProjectDataContext);
  const [recommendedCompaniesToAvoid, setRecommendedCompaniesToAvoid] = useState([]);
  const { getValues, setValue, register, unregister, watch, reset } = useForm({});

  //API Integration
  const { data: companyOptionResponse } = useApi({
    queryFn: () => {
      return picklistDropDownApis(GET, DROP_DOWN_PICKLIST.COMPANIES_PICKLIST);
    }
  });

  const handleUniversalStateLocally = updatedVal => {
    const event = {
      target: {
        name: 'targetCompaniesToAvoid',
        value: updatedVal
      }
    };
    handleUniversalState(event);
  };

  const deleteCompany = async option => {
    try {
      setLoader(true);
      if (!option?.id?.includes('new')) {
        const res = await TargetCompanyToAvoidDataApi(DELETE, '', {
          id: option?.id
        });
        console.log('handle-save-company-to-avoid-delete', res);
      }

      const updatedCompanies = recommendedCompaniesToAvoid.filter(curr => curr.id != option.id);
      setRecommendedCompaniesToAvoid(updatedCompanies);
      handleUniversalStateLocally(updatedCompanies);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      enqueueSnackbar(err, 'error');
    }
  };

  const addCompany = companyData => {
    const newCompany = {
      ...companyData,
      id: 'new-' + companyData.id,
      companyData
    };
    const updatedCompanies = [...recommendedCompaniesToAvoid, newCompany];
    setRecommendedCompaniesToAvoid(updatedCompanies);
    handleUniversalStateLocally(updatedCompanies);
  };

  const getPayload = data => {
    const addCompanyPayload = {
      ...data,
      company_tags: data?.company_tags ? data?.company_tags.map(tag => ({ tag_id: tag.id })) : [],
      company_industries: data?.company_industries
        ? data?.company_industries.map(industry => ({
            industry_id: industry.id
          }))
        : [],
      capital_structure: data?.capital_structure?.field_value,
      currency_unit: data?.currency_unit?.field_value
    };
    return addCompanyPayload;
  };

  const isValidated = async requestBody => {
    let isValid = await createSchema.isValid(requestBody);
    if (!isValid) {
      let dirtyField = customFormValidator(requestBody, createRequiredField);
      if (dirtyField) {
        const message = translateMessage('Required', ...splitLastIfMultiple(dirtyField));
        enqueueSnackbar(message, { variant: ERROR });
        return false;
      }
    } else {
      return true;
    }
  };

  const saveData = async () => {
    let formValues = { ...getValues() };
    console.log({ formValues });
    const requestBody = getPayload(formValues);
    const IsValidRequestBody = await isValidated(requestBody);
    if (IsValidRequestBody) {
      setLoader(true);
      const { status, data } = await ignCompanyDataApi(POST, requestBody);
      console.log(data);

      if (status === 201) {
        const message = translateMessage('Successfully', false, 'Company', 'created');
        enqueueSnackbar(message, { variant: SUCCESS });
        // setCompanyId(data.id);
        setAddCompanyPopup(false);
        reset();

        setLoader(false);
        addCompany({ ...formValues, ...data });
        // return data.id;
      } else if (status === 409) {
        const message = translateMessage('AlreadyExist', false, 'Company');
        enqueueSnackbar(message, {
          variant: ERROR
        });
      } else {
        const message = translateMessage('UnableMessage', false, 'Create', 'Company');
        enqueueSnackbar(message, { variant: ERROR });
      }
      setLoader(false);
    }
  };

  useEffect(() => {
    if (recommendedCompaniesToAvoid.length == 0 && !isDataLoaded) {
      setRecommendedCompaniesToAvoid(projectData?.companiesToAvoid || []);
      if (projectData?.companiesToAvoid) {
        setIsDataLoaded(true);
      }
    }
  }, [projectData]);
  return (
    <Box>
      <Loader show={loader} />
      <AccordionHeader
        onSearch={addCompany}
        searchOptions={companyOptionResponse?.data || []}
        searchLabel={t(`${TRANS_KEYS.ADD_COMPANIES_TO_AVOID}:key`)}
        // isExactSearch={true}
        onChangeExactSearch={() => {}}
        isGetMore={false}
        isNegative={true}
        freeSolo={false}
        noOptionsText={<CustomButton buttonText={'+ Add Company'} type='primary w-100' onClick={() => setAddCompanyPopup(true)} />}
        recommendedOptions={recommendedCompaniesToAvoid}
        onClickRecommendOption={deleteCompany}
      />
      <AddCompany
        isPopupOpen={addCompanyPopup}
        handleClose={() => setAddCompanyPopup(false)}
        handleSubmit={saveData}
        setIsLoading={loader}
        setValue={setValue}
        register={register}
        reset={reset}
        setIsPopupOpen={setAddCompanyPopup}
        unregister={unregister}
        watch={watch}
      ></AddCompany>
    </Box>
  );
}

export default TargetCompaniesToAvoid;
