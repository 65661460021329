import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { CONSTANTS_SHOW_OFF_LIMIT } from './constant';

const TextBlock = ({ title, value, className = '', underline = false, html, onClick = () => {} }) => {
  const newValue = html ? value.replace(/<\/?[^>]+(>|$)/g, '') : value;
  const valueClassName = CONSTANTS_SHOW_OFF_LIMIT.CLASSNAMES.VALUE + ' ' + (underline ? CONSTANTS_SHOW_OFF_LIMIT.CLASSNAMES.UNDERLINE : '');
  return (
    <Box className={`d-flex flex-column gap-5 ${className}`}>
      <Typography className={CONSTANTS_SHOW_OFF_LIMIT.CLASSNAMES.TITLE}>{title}</Typography>
      <Typography onClick={onClick} className={valueClassName}>
        {newValue}
      </Typography>
    </Box>
  );
};

TextBlock.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  underline: PropTypes.bool,
  html: PropTypes.bool,
  onClick: PropTypes.func
};

export default TextBlock;
