//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import RectangleIcon from '@mui/icons-material/Rectangle';
import WorkIcon from '@mui/icons-material/Work';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import profileUrl from '../../../assets/images/profile-image.png';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import { useSnackbar } from 'notistack';
import { Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ActivityBars from '../../../components/ViewContactDrawer/ActivityLogsIgnyte/ActivityBar';
import ActivityHistory from '../../../components/ViewContactDrawer/ActivityLogsIgnyte/ActivityHistorys';
import '../../../components/ViewContactDrawer/ActivityLogsIgnyte/index.scss';
import { logAnActivitySelector } from '../../../selectors';
import { AllDocumentsApi, ignContactDataApi, logAnActivityApi } from '../../../services/ApiService';
import { successMessage } from '../../../services/MessageService';
import { ERROR, GET, IGN_API, POST, SUCCESS } from '../../../services/constantService';
import { logAnActivityActions } from '../../../store/logAnActivitySlice';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import LogAnActivityTab from './LogAnActivityTab';
import styles from './index.module.scss';
import './index.scss';

const CustomTabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  index: PropTypes.string
};

export default function LogAnActivity(props) {
  const {
    id,
    location,
    setIsMenuOpen = () => {},
    isMenuOpen,
    params,
    // setDrawerWidth = () => {},
    // setIsWidthApply = () => {},
    getHeaderDetails = () => {},
    isViewCloseIcon,
    // setIsLogAnActivityClose = () => {},
    getContact = () => {},
    isHeaderNav,
    ifForCompany = false,
    project_id
  } = props;
  const { register, unregister, setValue, watch, getValues, handleSubmit, reset, control } = useForm({});
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });
  const [value1 /* setValue1 */] = React.useState(0);
  const [contact, setContact] = useState(null);
  const [activityComponent, setActivityComponent] = useState('recent_activity');
  const [activityDetail, setActivityDetail] = useState(null);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  // let id = params;
  const { enqueueSnackbar } = useSnackbar();

  const logData = useSelector(logAnActivitySelector);

  const getJobInfo = jobInfoArray => {
    const jobInfoArr = jobInfoArray && jobInfoArray.filter(item => item !== null && item !== undefined);
    const commaSeparatedJobInfo = jobInfoArr.join(',');
    return commaSeparatedJobInfo || '-';
  };

  const getLocation = address => {
    const newAddress = address.filter(item => item?.address_type === 'work');
    if (newAddress.length === 1) {
      const addressArray = [newAddress[0]?.metropolitan_area ? newAddress[0]?.metropolitan_area : newAddress[0]?.city, newAddress[0]?.contact_country?.name];
      const newAddressArray = addressArray.filter(item => item !== null && item !== undefined && item !== '');
      return newAddressArray.length !== 0 ? newAddressArray.join(',') : '-';
    } else {
      const addressDetails = address.filter(item => item?.address_type === 'residential');
      const addressArray = [addressDetails[0]?.address_lines, addressDetails[0]?.city, addressDetails[0]?.contact_country?.name];
      const newAddressArray = addressArray.filter(item => item !== null && item !== undefined && item !== '');
      return newAddressArray.length !== 0 ? newAddressArray.join(',') : '-';
    }
  };

  useEffect(() => {
    if (isMenuOpen) setState({ ...state, ['right']: open });
  }, [isMenuOpen]);

  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_LOG_AN_ACTIVITY, TRANS_KEYS.CONTACTS_VIEW_ACTIVITY_LOG, TRANS_KEYS.CONTACTS_SCREENED]);

  const cleanupData = data => {
    let contactObject = {};
    contactObject = {
      ...data,
      avatar: profileUrl,
      screened: true,
      linkedinIcon: <LinkedInIcon className='linkedin-icon-header' />,
      screen: {
        icon: <RectangleIcon className='screen-icon fs-14' />,
        text: t(`${TRANS_KEYS.CONTACTS_SCREENED}:key`),
        className: 'btn-Style'
      },
      additionalContent: {
        content1: {
          icon: <WorkIcon className='content-icon fs-14 mr-2' />,
          text: getJobInfo([data?.current_job_title, data?.contact_company?.name])
        },
        content2: {
          icon: <LocationOnIcon className='content-icon fs-14 mr-2' />,
          text: getLocation(data?.contact_address)
        }
      },
      additionalContentClass: { className: 'first-grid' },
      mainContent: {},
      actionItems: ['Project', 'List'],
      disableEditing: true
    };
    return contactObject;
  };

  const getContactDetails = async () => {
    try {
      setLoading(true);
      const sub_route = '?tabName=CONTACT_HEADER_TAB';
      const { status, data } = await ignContactDataApi(GET, id, {}, sub_route);
      if (status === 200 && data) {
        const result = cleanupData(data);
        setContact({
          data: result,
          avatarSizeXs: 1.8,
          avatarSizeMd: 1.8,
          avatarSizeSm: 1.8,
          mainContentWidthXs: 8.2,
          mainContentWidthMd: 8.2,
          mainContentWidthSm: 8.2,
          buttonSizeXs: 1.8,
          buttonSizeMd: 1.8,
          buttonSizeSm: 1.8,
          height: '19vh'
        });
        setLoading(false);
      }
    } catch (err) {
      console.log('error in getContactDetails::', err);
    }
  };

  const getActivityDetails = async () => {
    try {
      setLoading(true);
      const sub_route = ifForCompany ? 'company-activity' : 'activity';
      const { status, data } = await logAnActivityApi(GET, `${id}${project_id ? `?project_id=${project_id}` : ''}`, null, sub_route);
      if (status === 200) {
        setActivityDetail(data);
        setLoading(false);
      }
    } catch (error) {
      console.log('error in getActivityDetails::', error);
    }
  };
  useEffect(() => {
    if (id) {
      getContactDetails();
      getActivityDetails();
    }
  }, [params]);

  const onHandleSave = async operation => {
    setLoading(true);
    const logAnActivityData = { ...getValues() };
    console.log('logAnActivityData::', logAnActivityData);
    const finalPayload = {
      id: logData || null,
      project_id: logAnActivityData.project_id,
      notes_type: logAnActivityData.notes_type,
      notes: logAnActivityData.notes,
      activity_type: logAnActivityData.activity_type?.activity_type_code,
      activity_added_at: logAnActivityData.activity_added_at,
      due_date: logAnActivityData.due_date,
      original_text: logAnActivityData.original_text,
      created_by: logAnActivityData.created_by,
      updated_by: logAnActivityData.updated_by,
      internal_attendees: logAnActivityData.internal_attendees && logAnActivityData.internal_attendees.length > 0 ? logAnActivityData.internal_attendees : null,
      external_attendees: logAnActivityData.client_attendees && logAnActivityData.client_attendees.length > 0 ? logAnActivityData.client_attendees : null,
      interview_date: logAnActivityData.interview_date,
      user_attendees: logAnActivityData.user_attendees || null,
      contact_attendees: logAnActivityData.contact_attendees
    };
    if (ifForCompany) {
      finalPayload.company_id = id;
    } else {
      finalPayload.contact_id = id && id;
    }
    if (!finalPayload.activity_type) {
      setLoading(false);
      enqueueSnackbar('activity type is required', { variant: ERROR });
      return false;
    }
    const sub_route = ifForCompany ? 'company-create-activity' : 'create-activity';
    const { status, data } = await logAnActivityApi(POST, null, finalPayload, sub_route);

    if (operation === 'save') {
      setIsMenuOpen(false);
      setState({ ...state, ['right']: false });
    }

    if (status === 200) {
      const sub_route = `${IGN_API.add_document}/${id}/attachments`;
      await AllDocumentsApi(POST, sub_route, logAnActivityData.attachment);
      setLoading(false);
      getActivityDetails();
      setActivityComponent('recent_activity');
      getHeaderDetails();
      const message = successMessage('', 'Activity Created');
      enqueueSnackbar(message, { variant: SUCCESS });
      dispatch(logAnActivityActions.resetEditId());
      return true;
    }
    if (status === 500) {
      setLoading(false);
      enqueueSnackbar(`${data.message}`, { variant: ERROR });
      return false;
    }
  };

  return (
    <Box className={`${styles.log_an_activity} ${isHeaderNav === 'closed' ? styles.details_container_close : styles.details_container}`}>
      {loading && <Spinner animation='border' variant='success' className='mx-auto mt-5' />}
      {!loading && (
        <Box className={`${styles.log_an_activity_inside} mt-1 px-3`} id='ActivityLogs_container'>
          <Box className='side-nav-header mb-3 mt-2 font-weight-bold header-font text-capitalize'>
            {isViewCloseIcon ? `${t(`${TRANS_KEYS.CONTACTS_LOG_AN_ACTIVITY}:key`)} For : ${contact?.data?.name}` : t(`${TRANS_KEYS.CONTACTS_LOG_AN_ACTIVITY}:key`)}
          </Box>
          <Box className='mb-3'>
            <ActivityBars setActivityComponent={setActivityComponent} moduleId={id} ifForCompany={ifForCompany} />
          </Box>
          <CustomTabPanel value={value1} index={0}>
            {activityComponent === 'recent_activity' && (
              <ActivityHistory activityDetails={activityDetail} isHeaderNav={isHeaderNav} setActivityComponent={setActivityComponent} getActivityDetails={getActivityDetails} />
            )}
            {activityComponent === 'add_activity' && (
              <LogAnActivityTab
                location={location}
                register={register}
                unregister={unregister}
                setValue={setValue}
                getValues={getValues}
                reset={reset}
                handleSubmit={handleSubmit}
                onHandleSave={onHandleSave}
                watch={watch}
                enqueueSnackbar={enqueueSnackbar}
                isViewCloseIcon={isViewCloseIcon}
                getContact={getContact}
                ifForCompany={ifForCompany}
                project_id={project_id}
                gotoRecentActivity={() => setActivityComponent('recent_activity')}
                control={control}
              />
            )}
          </CustomTabPanel>
        </Box>
      )}
    </Box>
  );
}

LogAnActivity.propTypes = {
  id: PropTypes.string,
  location: PropTypes.string,
  params: PropTypes.object,
  setIsMenuOpen: PropTypes.func,
  isMenuOpen: PropTypes.bool,
  isViewCloseIcon: PropTypes.bool,
  getHeaderDetails: PropTypes.func,
  isHeaderNav: PropTypes.string,
  ifForCompany: PropTypes.bool,
  project_id: PropTypes.string,
  message: PropTypes.string,
  className: PropTypes.string,
  getContact: PropTypes.func
};
