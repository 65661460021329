//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useCallback, useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box, Typography, Grid } from '@mui/material';

//----------------------------------------------// Internal Imports // -------------------------------------------------

import { API } from '../../../services/constantService';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import { ExpandableSection } from '../../ActivityLog/Components/ExpandableSection';
import { getAccessToken } from '../../../services/cognitoService';

const getCredits = list => {
  let total_origination_credit = 0;
  let total_selling_credit = 0;
  let total_execution_credit = 0;
  for (let i = 0; i < list.length; i++) {
    total_origination_credit += parseFloat(list[i].origination_credit || 0);
    total_selling_credit += parseFloat(list[i].selling_credit || 0);
    total_execution_credit += parseFloat(list[i].execution_credit || 0);
  }
  return { total_origination_credit, total_selling_credit, total_execution_credit };
};

const TeamInfoView = props => {
  const { title = '', fields, values } = props;
  const { t } = useLanguageHooks([TRANS_KEYS.BD_TEAM_NAME, TRANS_KEYS.BD_TEAM_ORIGINATION, TRANS_KEYS.BD_TEAM_SELLING, TRANS_KEYS.BD_TEAM_EXECUTION, TRANS_KEYS.BD_TEAM_TOTAL_CREDIT]);

  const headerData = [
    '',
    t(`${TRANS_KEYS.BD_TEAM_NAME}:key`),
    t(`${TRANS_KEYS.BD_TEAM_ORIGINATION}:key`),
    t(`${TRANS_KEYS.BD_TEAM_SELLING}:key`),
    t(`${TRANS_KEYS.BD_TEAM_EXECUTION}:key`),
    t(`${TRANS_KEYS.BD_TEAM_TOTAL_CREDIT}:key`)
  ];

  const [list, setList] = useState([]);
  const [isError, setIsError] = useState(false);
  const [credits, setCredits] = useState({});
  // const [selectedOption, setSelectedOption] = useState([])

  // useEffect(() => {
  //     if (list.length > 0) {
  //         const options = option.filter((array_el) => {
  //             return list.every(anotherOne_el => anotherOne_el?.user?.id !== array_el?.id)
  //         });
  //         setSelectedOption(options)
  //     } else {
  //         setSelectedOption(option)
  //     }
  // }, [list])

  // useEffect(() => {
  //     register(field)
  // }, [register, field])
  // useEffect(() => {
  //     setValue(field, list)
  // }, [list, field, setValue])

  useEffect(() => {
    if (Array.isArray(values)) {
      setList(values);
    }
  }, [values, setList]);

  const updateProfileURL = useCallback(
    async (user, index, list) => {
      const token = await getAccessToken();
      const url = `${API.users}/${user.id}/profileimage?token=${token}`;

      const updatedList = [...list];
      updatedList[index] = {
        ...list[index],
        user: {
          ...list[index]['user'],
          image_url: url
        }
      };
      setList(updatedList);
    },
    [setList]
  );

  useEffect(() => {
    list.forEach((item, index) => {
      if (item.user && item.user.profile_image_id && !item.user.image_url) {
        updateProfileURL(item.user, index, list);
      }
    });
  }, [list, updateProfileURL]);

  const updateCredits = useCallback(list => {
    setCredits(getCredits(list));
  }, []);

  const checkForError = useCallback(
    list => {
      const { total_origination_credit, total_selling_credit, total_execution_credit } = getCredits(list);
      let isError = false;
      if (fields.origination_credit) {
        if (total_origination_credit > fields.origination_credit) {
          isError = true;
        }
      }
      if (fields.selling_credit) {
        if (total_selling_credit > fields.selling_credit) {
          isError = true;
        }
      }
      if (fields.execution_credit) {
        if (total_execution_credit > fields.execution_credit) {
          isError = true;
        }
      }
      return isError;
    },
    [fields]
  );

  useEffect(() => {
    updateCredits(list);
    let isError = checkForError(list);
    setIsError(isError);
  }, [updateCredits, checkForError, list]);

  const getTotalCredit = item => {
    return parseFloat(item.origination_credit || 0) + parseFloat(item.selling_credit || 0) + parseFloat(item.execution_credit || 0);
  };

  const getGrandTotalCredit = () => {
    return parseFloat(credits.total_origination_credit || 0) + parseFloat(credits.total_selling_credit || 0) + parseFloat(credits.total_execution_credit || 0);
  };

  return (
    <Box>
      <Box className='d-flex flex-column  my-2'>
        <ExpandableSection title={title} defaultOpen={true} isTeamInfo={true} accordionClassName='accordion' titleClassName='title-text'>
          <Grid container xs={12} alignItems='center' className='table-header fs-13 py-2 px-1'>
            {headerData.map((header, index) => (
              <Grid item key={index} xs={index === 0 ? 0.5 : index === 1 ? 3.5 : 2}>
                <Typography variant='subtitle1' align='center'>
                  {header}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Box className={`table-body ${isError ? 'validation-error' : ''}`}>
            {list.length > 0 ? (
              list.map((elem, index) => {
                return (
                  <Grid container alignItems='center' className='py-2 align-items-start mb-2' key={index}>
                    <Grid item xs={0.5} className='d-flex justify-content-center'>
                      <Typography>{index + 1}.</Typography>
                    </Grid>
                    <Grid item xs={3.5}>
                      <Box display='flex' alignItems='center' className='justify-content-center' gap={2}>
                        <Box className='mr-2 team-info-field d-flex align-items-center justify-content-start ml-5'>
                          {elem && elem.user ? elem.user.name : '--'}{' '}
                          {elem.user && (
                            <Box component={'span'} className='profile-image-container d-flex ml-2'>
                              <Box component={'span'} className='image-container'>
                                {elem.user && elem.user.image_url ? (
                                  <img src={elem.user.image_url} alt='Profile' />
                                ) : (
                                  <Box className='default-profile-icon'>
                                    <AccountCircleIcon fontSize='inherit' />
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={2} className='d-flex pl-2 justify-content-center text-wrap'>
                      {fields.origination_credit ? <Box>{elem.origination_credit || ''}</Box> : <Box></Box>}
                    </Grid>
                    <Grid item xs={2} className='d-flex pl-2 justify-content-center text-wrap'>
                      {fields.selling_credit ? <Box>{elem.selling_credit || ''}</Box> : <Box></Box>}
                    </Grid>
                    <Grid item xs={2} className='d-flex pl-2 justify-content-center text-wrap'>
                      {fields.execution_credit ? <Box>{elem.execution_credit || ''}</Box> : <Box></Box>}
                    </Grid>
                    <Grid item xs={2} className={`d-flex justify-content-center ${getGrandTotalCredit() === 100 && !isError && 'text-success'}`}>
                      {getTotalCredit(elem)}
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Box className='d-flex'>--</Box>
            )}
            <Grid container alignItems='center'>
              <Grid item xs={0.5}></Grid>
              <Grid item xs={3.5}></Grid>
              <Grid item xs={2} className='d-flex justify-content-center'>
                {fields.origination_credit ? <Typography className={`${!isError ? 'total-content' : 'text-red'}`}>{credits['total_origination_credit'] || 0}</Typography> : <Box></Box>}
              </Grid>
              <Grid item xs={2} className='d-flex justify-content-center'>
                {fields.selling_credit ? <Typography className={`${!isError ? 'total-content' : 'text-red'}`}>{credits['total_selling_credit'] || 0}</Typography> : <Box></Box>}
              </Grid>
              <Grid item xs={2} className='d-flex justify-content-center'>
                {fields.execution_credit ? <Typography className={`${!isError ? 'total-content' : 'text-red'}`}>{credits['total_execution_credit'] || 0}</Typography> : <Box></Box>}
              </Grid>
              <Grid item xs={2} className='d-flex justify-content-center'>
                <Typography className='total-content'> {getGrandTotalCredit()}</Typography>
              </Grid>
              <Grid item xs></Grid> {/* Empty grid item for alignment */}
            </Grid>
          </Box>
        </ExpandableSection>
      </Box>
    </Box>
  );
};

TeamInfoView.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.object.isRequired,
  values: PropTypes.array
};

export default TeamInfoView;
