import React, { useEffect, useState } from 'react';

import ApartmentIcon from '@mui/icons-material/Apartment';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import UpdateIcon from '@mui/icons-material/Update';
import { Avatar, Box, ListItem, Skeleton, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import NoteImg from '../../../assets/images/note.svg';
import PersonSearch from '../../../assets/images/person_search.svg';
import ViewCompanyDrawer from '../../../components/ViewCompany/ViewCompanyDrawer';
import { ignCompanyDataApi } from '../../../services/ApiService';
import { GET, ROUTES } from '../../../services/constantService';
import { convertArrayBufferToBlobUrl } from '../../../utils/common';
import { Tags } from '../Components/Tags';

export function CompanyCard({ item }) {
  const data = item?._source;
  const industryName = data?.company_industries?.[0]?.industries?.name || '--';
  const location = data?.location_description;
  const companyName = data?.name || '--';
  const companyId = data?.id;
  const capitalStructure = data?.capital_struct_trans_value?.short_desc || '--';
  const tags = data?.company_tags?.map(tag => tag?.ign_tag?.name);

  let websiteUrl = data?.website_url;
  websiteUrl = websiteUrl?.startsWith('http') ? websiteUrl : websiteUrl ? `https://${websiteUrl}` : websiteUrl;

  const [isCompanyDrawerOpen, setIsCompanyDrawerOpen] = useState(false);
  const [companyUrl, setCompanyUrl] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);

  const fetchImages = async () => {
    setImgLoading(true);
    try {
      const response = await ignCompanyDataApi(GET, null, companyId, 'image');
      if (response.status === 200) {
        const url = convertArrayBufferToBlobUrl(response?.data?.Body?.data);
        setCompanyUrl(url);
      }
      setImgLoading(false);
    } catch (error) {
      setImgLoading(false);
    }
  };

  useEffect(() => {
    if (companyId) {
      fetchImages();
    }
  }, [companyId]);

  return (
    <ListItem sx={{ padding: 0, marginBottom: 2 }}>
      {isCompanyDrawerOpen && (
        <ViewCompanyDrawer
          baseRoute={ROUTES.globalSearch}
          setIsEdited={() => {}}
          setIsUpdateCompany={() => {}}
          isUpdateCompany={false}
          options={[]}
          isDrawerOpen={isCompanyDrawerOpen}
          setIsDrawerOpen={setIsCompanyDrawerOpen}
          id={companyId}
        />
      )}
      <Box display={'flex'} width={'100%'} height={'10vh'} borderBottom={'1px solid rgba(0, 0, 0, 0.3)'}>
        <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={() => setIsCompanyDrawerOpen(true)}>
          {!companyUrl && !imgLoading && <ApartmentIcon sx={{ width: '53px', height: '53px', borderRadius: '50%', color: '#979FB5' }} />}
          {imgLoading && <Skeleton variant='circular' width={'53px'} height={'53px'} />}
          {companyUrl && !imgLoading && <Avatar variant='circular' sx={{ width: '53px', height: '53px', borderRadius: '50%' }} src={companyUrl} />}
        </Box>

        <Box display={'flex'} marginLeft={3} width={'100%'}>
          <Box display={'flex'} flex={1} flexDirection={'column'} justifyContent={'center'}>
            <Typography
              sx={{ cursor: 'pointer' }}
              color={'black'}
              onClick={() => {
                setIsCompanyDrawerOpen(true);
              }}
              fontSize={14}
              fontWeight={900}
            >
              {companyName}
            </Typography>
            <Box display={'grid'} gridTemplateColumns={'1fr 1fr 1fr 0.8fr 0.6fr'} alignItems={'center'}>
              <Box marginY={1} display={'flex'} alignItems={'center'}>
                <img width={'12px'} height={'12px'} src={NoteImg} alt={industryName} />
                <Typography marginLeft={0.5} color={'#1b4965'} fontSize={10} fontWeight={500}>
                  {industryName}
                </Typography>
              </Box>
              <Typography sx={{ overflow: 'hidden', whiteSpace: 'nowrap', cursor: 'pointer', textOverflow: 'ellipsis' }} color={'#1b4965'} marginY={1} fontSize={10} fontWeight={500}>
                <FmdGoodIcon sx={{ width: '14px', height: '14px', marginRight: 0.5, color: '#979FB5' }} />
                <Tooltip placement='top' title={location?.trim() || ''}>
                  {location?.trim() || '--'}
                </Tooltip>
              </Typography>
              <Typography color={'#1b4965'} marginY={1} fontSize={10} fontWeight={500}>
                <UpdateIcon sx={{ width: '14px', height: '14px', marginRight: 0.5, color: '#1b4965' }} />
                <Link target={websiteUrl ? '_blank' : '_self'} to={websiteUrl || '#'} style={{ color: '#1b4965', pointerEvents: websiteUrl ? 'auto' : 'none' }}>
                  {websiteUrl || '--'}
                </Link>
              </Typography>
              <Typography color={'#959fb7'} marginY={1} fontSize={10} fontWeight={500}>
                <Tags tags={tags} />
              </Typography>
              <Typography color={'#1b4965'} marginY={1} fontSize={9} fontWeight={600}>
                <Box display={'flex'} alignItems={'center'}>
                  <img width={'12px'} height={'12px'} src={PersonSearch} alt={capitalStructure} />
                  <Typography marginX={0.5} fontSize={10} fontWeight={500}>
                    Capital Structure:
                  </Typography>
                  {capitalStructure}
                </Box>
              </Typography>
            </Box>
          </Box>

          <MoreHorizIcon style={{ cursor: 'pointer', color: '#5f6368', marginRight: 10 }} />
        </Box>
      </Box>
    </ListItem>
  );
}

CompanyCard.propTypes = {
  item: PropTypes.object
};
