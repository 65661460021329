//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

// -------------------------------------------------// External Imports// -------------------------------------------------
import { TRANS_KEYS } from '../../../utils/languageNamespaces';
import { useLanguageHooks } from '../../../utils/Hooks/UseLanguageHook';

const TotalCount = props => {
  const { selectedRowsCount, rowCount, isRowSelected, loadedContactCount, showTotalCount = true } = props;
  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_TOTAL_COUNT, TRANS_KEYS.CONTACTS_SELECTED, TRANS_KEYS.CONTACTS_OUT_OF, TRANS_KEYS.SHOWING]);

  return (
    <Box component='div' className='d-flex align-items-center fs-14'>
      {isRowSelected ? (
        <>
          {t(`${TRANS_KEYS.CONTACTS_SELECTED}:key`)}
          <Box component='span' className='color-primary-main mx-1'>
            {selectedRowsCount}
          </Box>
          {t(`${TRANS_KEYS.CONTACTS_OUT_OF}:key`)} {rowCount}
        </>
      ) : (
        <>
          {' '}
          {showTotalCount
            ? `${t(`${TRANS_KEYS.CONTACTS_TOTAL_COUNT}:key`)} : ${rowCount}`
            : loadedContactCount
              ? `${t(`${TRANS_KEYS.SHOWING}:key`)} ${loadedContactCount} / ${rowCount}`
              : `${t(`${TRANS_KEYS.CONTACTS_TOTAL_COUNT}:key`)} : ${rowCount}`}
        </>
      )}
    </Box>
  );
};

TotalCount.propTypes = {
  rowCount: PropTypes.number,
  isRowSelected: PropTypes.bool,
  selectedRowsCount: PropTypes.number,
  loadedContactCount: PropTypes.number,
  showTotalCount: PropTypes.bool
};

export default TotalCount;
