import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import HomeIcon from '@mui/icons-material/Home';

import DynamicTabLayout from '../../components/DynamicTabs/TabLayout';
import List from './List';
import { TRANS_KEYS } from '../../utils/languageNamespaces';
import { useLanguageHooks } from '../../utils/Hooks/UseLanguageHook';

const breadcrumbsLinks = [
  {
    label: (
      <>
        <HomeIcon sx={{ mr: 0.5 }} fontSize='inherit' />
      </>
    ),
    link: '/contacts'
  }
];

const Container = props => {
  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_ALL_CONTACTS]);
  const [activeTab, setActiveTab] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <React.Fragment>
      <Helmet>
        <title>Add Contact - KGP Galaxy</title>
      </Helmet>

      <DynamicTabLayout
        navItems={[
          {
            label: t(`${TRANS_KEYS.CONTACTS_ALL_CONTACTS}:key`),
            content: '',
            subTabs: []
          }
        ]}
        baseRoute='/contacts/list'
        navLinks={breadcrumbsLinks}
        showBreadcrumbs={false}
        activeTab={activeTab}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        setActiveTab={setActiveTab}
      >
        <List index={t(`${TRANS_KEYS.CONTACTS_ALL_CONTACTS}:key`)} {...props} />
      </DynamicTabLayout>
    </React.Fragment>
  );
};

Container.propTypes = {};

export default Container;
