import { formatDate, getDifferenceInDays } from '../../utils/date';

export const columnDefs = () => {
  let columns = [
    {
      field: 'job_title',
      headerName: 'Project',
      cellRenderer: 'getProjects',
      width: 200,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellClass: 'text-style'
    },
    {
      // field: 'bd_project_id',
      headerName: 'Business Development',
      cellRenderer: 'getBdName',
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellClass: 'text-style'
      //cellRenderer: "colorRenderer",
    },
    // Show default header name

    {
      field: 'stage',
      headerName: 'Status',
      width: 200,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellRenderer: 'getStage'
    },
    {
      field: 'candidate_count',
      headerName: 'In Pipeline',
      width: 200,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellRenderer: 'statusRender',
      cellClass: 'text-style'
    }
  ];
  return columns;
};
export const columnBdDef = () => {
  let columns = [
    {
      field: 'job_title',
      headerName: 'Business Development',
      cellRenderer: 'getProjects',
      width: 400,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellClass: 'text-style'
    },
    {
      field: 'stage',
      headerName: 'Status',
      width: 325,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellClass: 'text-style'
      //cellRenderer: "colorRenderer",
    }
  ];
  return columns;
};
const dateFormatter = params => {
  const field_name = params.colDef.field;
  const inputDate = params.data[field_name];
  return formatDate(inputDate);
};
const getJobNumber = params => {
  return params?.data?.ProjectInvoiceInfo?.job_number;
};
const getJobTitle = params => {
  return params?.data?.ProjectInvoiceInfo?.job_title;
};
const getOutStandingDays = params => {
  const collectedDate = params.data?.collected_date;
  const billedDate = params.data?.billed_date;
  let daysOutstanding;
  if (!collectedDate && !billedDate) {
    daysOutstanding = '';
  } else if (collectedDate) {
    daysOutstanding = 0;
  } else if (billedDate) {
    daysOutstanding = getDifferenceInDays(new Date(), billedDate);
  }
  return daysOutstanding;
};
export const columnInvoicesDefs = () => {
  let columns = [
    {
      headerName: '',
      field: 'check_box',
      colId: 'check_box',
      sortable: false,
      maxWidth: 60,
      minWidth: 60,
      headerComponent: 'HeaderCheckbox',
      headerCheckboxSelectionFilteredOnly: false,
      checkboxSelection: true,
      pinned: 'left',
      lockPinned: true,
      lockPosition: true
    },
    {
      field: 'job_number',
      headerName: 'Search#',
      cellRenderer: getJobNumber,
      width: 150,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null]
    },
    {
      field: 'businessDevelopment',
      headerName: 'Title',
      width: 250,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellRenderer: getJobTitle
    },
    {
      field: 'invoice_id',
      headerName: 'Invoice ID',
      cellRenderer: 'getProjects',
      width: 250,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null]
    },
    {
      field: 'projected_bill_date',
      headerName: 'Projected Bill Date',
      width: 250,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      valueFormatter: dateFormatter
    },
    {
      field: 'invoice_amt',
      headerName: 'Invoice Amount',
      width: 250,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellClass: 'text-style'
      //cellRenderer: "colorRenderer",
    },

    {
      field: 'due_by_date',
      headerName: 'Due By',
      width: 250,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      valueFormatter: dateFormatter
    },

    {
      field: 'stage',
      headerName: 'Company',
      width: 250,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellRenderer: 'getStage',
      hide: true,
      suppressToolPanel: true
    },
    {
      field: 'invoice_label',
      headerName: 'Invoice Label',
      width: 250,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellRenderer: 'statusRender',
      hide: true,
      suppressToolPanel: true
    },
    {
      field: 'expenses',
      headerName: 'Expenses',
      width: 250,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellRenderer: 'getStage',
      hide: true,
      suppressToolPanel: true
    },
    {
      field: 'retainer',
      headerName: 'Retainers',
      width: 250,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellRenderer: 'statusRender',
      hide: true,
      suppressToolPanel: true
    },
    {
      field: 'indirect_fee',
      headerName: 'Indirect Fee',
      cellRenderer: 'getProjects',
      width: 250,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      hide: true,
      suppressToolPanel: true
    },
    {
      field: 'billed_date',
      headerName: 'Date Billed',
      width: 250,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellRenderer: 'getStage',
      hide: true,
      suppressToolPanel: true,
      valueFormatter: dateFormatter
    },

    {
      field: 'collected_date',
      headerName: 'Date Collected',
      cellRenderer: 'getProjects',
      width: 250,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      hide: true,
      suppressToolPanel: true,
      valueFormatter: dateFormatter
    },
    {
      field: 'businessDevelopment',
      headerName: 'Days Outstanding',
      width: 250,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      hide: true,
      suppressToolPanel: true,
      cellRenderer: getOutStandingDays
    },
    {
      field: 'collected_amt',
      headerName: 'Collected Amount',
      width: 250,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellRenderer: 'getStage',
      hide: true,
      suppressToolPanel: true
    },
    {
      field: 'outstanding_amt',
      headerName: 'Outstanding Amount',
      width: 250,
      resizable: true,
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      cellRenderer: 'statusRender',
      hide: true,
      suppressToolPanel: true
    }
  ];
  columns.push({
    headerName: '',
    field: 'action',
    colId: 'action',
    sortable: false,
    maxWidth: 50,
    minWidth: 50,
    cellRenderer: 'GetActionRenderer',
    pinned: 'right',
    lockPinned: true
  });
  return columns;
};
export const removeUnusedField = (formValues, dirtyFields) => {
  let newForm = {};
  Object.keys(dirtyFields).forEach(key => {
    if (key in formValues) {
      newForm[key] = formValues[key];
    }
  });
  return newForm;
};

export const getUpdatedPayload = (field, formData, companyID, contact) => {
  let payload = {};
  if (field === 'employeeRange') {
    payload = {
      employee_range_from: formData['employee_range_from'],
      employee_range_to: formData['employee_range_to']
    };
  } else if (field === 'revenueRange') {
    payload = {
      currency_code: formData['currency_code'] || '',
      currency_unit: formData?.currency_unit?.field_value || formData['currency_unit'] || '',
      revenue_range_to: formData['revenue_range_to'] || null,
      revenue_range_from: formData['revenue_range_from'] || null
    };
  } else if (field === 'company_associated_parent') {
    const companyArray = formData[field]?.map(ele => {
      let company = {
        parent_company: ele.parent_company?.id ? ele.parent_company?.id : ele.parent_company,
        relationship: ele?.relationship.field_value ? ele?.relationship.field_value : ele?.relationship
      };
      // if (ele.id) {
      //   company.id = ele.id;
      // }
      return company;
    });
    payload = { [field]: companyArray };
  } else if (field === 'company_associated_child') {
    const companyArray = formData[field]?.map(ele => {
      let company = {
        child_company: ele.child_company?.id ? ele.child_company?.id : ele.child_company,
        relationship: ele?.relationship.field_value ? ele?.relationship.field_value : ele?.relationship
      };
      // if (ele.id) {
      //   company.id = ele.id;
      // }
      return company;
    });
    payload = { [field]: companyArray };
  } else if (field === 'company_phones') {
    const phoneArray = formData[field]?.map(ele => {
      let phone = {
        phone_type: ele['phone_type']?.field_value || ele['phone_type'] || '',
        phone_number: ele['phone_number'],
        is_primary: ele['is_primary']
      };
      // if (ele.id) {
      //   phone.id = ele.id;
      // }
      return phone;
    });
    payload = { [field]: phoneArray };
  } else if (field === 'company_associated_contact') {
    const associatedContactArray = formData[field]?.map(ele => {
      return { contact_id: ele.id };
    });
    payload = { [field]: associatedContactArray };
  } else if (field === 'company_emails') {
    const emailArray = formData[field]?.map(ele => {
      let email = {
        email_type: ele['email_type']?.field_value || ele['email_type'] || '',
        email: ele['email'],
        is_primary: ele['is_primary']
      };
      // if (ele.id) {
      //   email.id = ele.id;
      // }
      return email;
    });
    payload = { [field]: emailArray };
  } else {
    if (formData[field] !== contact.data[field]) {
      payload = {
        [field]: formData[field]
      };
    }
  }
  return payload;
};
