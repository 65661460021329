import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { echoSenseApi } from '../../services/ApiService';
import { GET } from '../../services/constantService';
import CustomPopup from '../common/CustomPopup';
import './index.scss';

const Tasks = props => {
  const { isOpen, setIsPopupOpen } = props;
  const [pageUrl, setPageUrl] = React.useState(null);

  useEffect(() => {
    echoSenseApi(GET, 'page').then(res => {
      setPageUrl(res.data.url);
    });
  }, []);

  return (
    <CustomPopup
      open={isOpen}
      onClose={() => {
        setIsPopupOpen(false);
      }}
      title={'EchoSense'}
    >
      <iframe src={pageUrl} id='tasks-iframe'></iframe>
    </CustomPopup>
  );
};

Tasks.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsPopupOpen: PropTypes.func,
  userId: PropTypes.string
};

export default Tasks;
