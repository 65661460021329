//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import variables from '../../../variables.scss';

export default function DateTimePickers(props) {
  const { onChange, defaultValue, label } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker', 'MobileDateTimePicker', 'DesktopDateTimePicker', 'StaticDateTimePicker']}>
        <DateTimePicker
          onAccept={onChange}
          defaultValue={dayjs(defaultValue)}
          label={label || ''}
          sx={{
            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: variables.heading }
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}

DateTimePickers.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  label: PropTypes.string
};
