import { Button, Stack, Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useMemo, useRef, useState } from 'react';
import ColumnFilter from '../../Commons/ColumnFilter';
import { defaultColumns } from '../../Contacts/utils';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import {
  AppliedAt,
  ColorBasedscore,
  RejectPopup,
  // CustomizedDialogs,
  RenderCompanyName,
  RenderLiffer,
  RenderName,
  RenderOffLimits,
  Search
} from './Comman';
import { useSnackbar } from 'notistack';
import style from './productOne.module.scss';
import { useParams } from 'react-router-dom';
import useApi from '../../../utils/Hooks/useApiHook';

import { GET } from '../../../services/constantService';
import propTypes from 'prop-types';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import { ProjectCandidateApi, ProjectCandidateDataApi } from '../../../services/ApiService';
import { PATCH } from '../../../services/constantService';
let columnApi;
function Sourced() {
  const [search, setSearch] = useState('');
  const [isGridReady, setIsGridReady] = useState(false);
  const [page] = useState(1);
  const [limit] = useState(100000);
  const gridRef = useRef();
  const { id } = useParams();

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      filter: true,
      minHeight: 300,
      resizable: true,
      sortable: true
    };
  }, []);

  const agCol = () => {
    return [
      {
        minWidth: 70,
        headerName: '',
        checkboxSelection: true,
        headerCheckboxSelection: true,

        flexgrow: 1,
        pinned: 'left',
        lockPinned: true
      },
      {
        minWidth: 250,
        field: 'contact',
        headerName: 'Name',
        cellRendererFramework: RenderName,
        editable: false
      },
      {
        minWidth: 250,
        field: 'contact.contact_company.name',
        headerName: 'Company Name',
        cellRendererFramework: RenderCompanyName,
        editable: false
      },
      {
        minWidth: 200,
        field: 'contact.current_job_title',
        headerName: 'Job Title',
        editable: false
      },
      {
        minWidth: 200,
        field: 'off_limits',
        headerName: 'Offlimits',
        hide: true,
        cellRendererFramework: RenderOffLimits,
        editable: false
      },
      {
        minWidth: 150,
        field: 'lifer',
        headerName: 'Lifer',
        hide: true,
        cellRendererFramework: RenderLiffer,
        editable: false
      },
      {
        minWidth: 150,
        field: 'title',
        headerName: 'Title',
        hide: true,
        cellRendererFramework: ColorBasedscore,
        editable: false
      },
      {
        minWidth: 275,
        field: 'job_function',
        headerName: 'Job Functions',
        hide: true,
        cellRendererFramework: ColorBasedscore,
        editable: false
      },
      {
        minWidth: 170,
        field: 'company',
        headerName: 'Company',
        hide: true,
        cellRendererFramework: ColorBasedscore,
        editable: false
      },
      { minWidth: 150, field: 'source', headerName: 'Source', editable: false },
      {
        minWidth: 150,
        field: 'applied_at',
        headerName: 'Applied',
        editable: false,
        cellRendererFramework: AppliedAt
      },
      {
        minWidth: 150,
        field: 'company_industry',
        headerName: 'company industry',
        cellRendererFramework: ColorBasedscore,
        hide: true,
        editable: false
      },
      {
        minWidth: 150,
        field: 'company_employee_range',
        headerName: 'company employee range',
        cellRendererFramework: ColorBasedscore,
        hide: true,
        editable: false
      },
      {
        minWidth: 150,
        field: 'current_tenure',
        headerName: 'current tenure',
        cellRendererFramework: ColorBasedscore,
        hide: true,
        editable: false
      },
      {
        minWidth: 150,
        field: 'industry',
        headerName: 'industry',
        cellRendererFramework: ColorBasedscore,
        hide: true,
        editable: false
      },
      {
        minWidth: 150,
        field: 'job_movement',
        headerName: 'job movement',
        cellRendererFramework: ColorBasedscore,
        hide: true,
        editable: false
      },
      {
        minWidth: 150,
        field: 'proximity_location',
        headerName: 'proximity location',
        cellRendererFramework: ColorBasedscore,
        hide: true,
        editable: false
      },
      {
        minWidth: 150,
        field: 'relevancy',
        headerName: 'relevancy',
        cellRendererFramework: ColorBasedscore,
        hide: true,
        editable: false
      },

      {
        minWidth: 210,
        pinned: 'right',
        headerName: 'Actions',
        field: 'id',
        filter: 'none',
        lockPinned: true,
        cellRendererFramework: RenderAction,
        editable: false
      }
    ];
  };
  const onGridReady = params => {
    columnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    params.columnApi.autoSizeColumns();

    setIsGridReady(true);
    // refresh the grid for row data
    params.api.refreshCells();
  };
  // ProjectCandidateApi

  const { data, refetch } = useApi({
    queryKey: 'ProjectCandidateDataApi',
    queryFn: () =>
      ProjectCandidateDataApi(GET, '', '', 'all', 'json', {
        page: page,
        limit: limit,
        projectId: id,
        category: 'sourced'
      })
  });
  const refetchData = () => {
    refetch();
  };
  const RenderAction = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const snackbar = useSnackbar();
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState([]);
    const handleClick = event => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleAddToPipeline = async () => {
      if (props?.data?.stage !== null) return;
      snackbar.enqueueSnackbar('Adding to Pipeline', { variant: 'info' });

      ProjectCandidateApi(PATCH, '/', { id: props?.value, stage: 'sourced' })
        .then(() => {
          snackbar.enqueueSnackbar('Added to Pipeline', { variant: 'success' });
          refetchData();
        })
        .catch(() => {
          snackbar.enqueueSnackbar('Failed to add to Pipeline', { variant: 'error' });
        });
    };
    const buttonLabel = props?.data?.stage === null ? 'Add to Pipeline' : 'Under Review';
    const buttonStyle = props?.data?.stage === null ? style.green_bg : style.red_bg;
    const handleReject = () => {
      snackbar.enqueueSnackbar('Rejecting', { variant: 'info' });
      const reason = value?.map(item => item.title);
      ProjectCandidateApi(PATCH, '/', { id: props?.value, stage: 'rejected', reason_codes: reason })
        .then(() => {
          snackbar.enqueueSnackbar('Rejected', { variant: 'success' });
          refetchData();
        })
        .catch(err => {
          console.error(err);
        });
    };
    return (
      <Stack>
        <Stack className={`${style.btn_drop_body}`}>
          <Button type='button' variant='contained' className={`${buttonStyle} ${style.btn_drop}`}>
            <Typography className={' fs-10 h-4'} onClick={handleAddToPipeline}>
              {buttonLabel}
            </Typography>
          </Button>
          <Button aria-describedby={anchorEl ? 'simple-popper' : undefined} type='button' variant='contained' onClick={handleClick} className={` ${style.btn_drop_icon} ${buttonStyle} `}>
            {anchorEl ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}
          </Button>
        </Stack>
        <BasePopup id={anchorEl ? 'simple-popper' : undefined} open={Boolean(anchorEl)} anchor={anchorEl} className={`${style.base_popup} ${style.box}`}>
          <RejectPopup open={open} setOpen={setOpen} value={value} setValue={setValue} onSave={handleReject} />
        </BasePopup>
      </Stack>
    );
  };
  RenderAction.propTypes = {
    data: propTypes.object,
    value: propTypes.number
  };
  const components = useMemo(() => {
    return {
      renderName: RenderName,
      renderCompanyName: RenderCompanyName,
      renderAction: RenderAction
    };
  }, []);
  return (
    <div className={`${style.white_bg} px-4`} height={'100%'}>
      <Stack className={style.product_one_body}>{/* <RenderName /> */}</Stack>
      <Stack className={style.product_one_body}>
        <Search search={search} setSearch={setSearch} />
      </Stack>
      <div id='myGrid' className={`${style.table_body} ag-theme-alpine mt-2`}>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          rowStyle={{ justifyContent: 'start' }}
          columnDefs={agCol()}
          rowData={data?.data?.rows || []}
          rowSelection='multiple'
          defaultColDef={defaultColDef}
          components={components}
          rowHeight={70}
        />
        {isGridReady && <ColumnFilter columnApi={columnApi} defaultColumns={defaultColumns} />}
      </div>
    </div>
  );
}

export default Sourced;
