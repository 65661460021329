import { Close } from '@mui/icons-material';
import Task from '@mui/icons-material/Task';
import { Card, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../selectors';
import { ignUsersApi, logAnActivityApi } from '../../services/ApiService';
import { DELETE, GET } from '../../services/constantService';
import { useLanguageHooks } from '../../utils/Hooks/UseLanguageHook';
import useApi from '../../utils/Hooks/useApiHook';
import { TRANS_KEYS } from '../../utils/languageNamespaces';
import './index.scss';
import { isArray } from 'lodash';

const TasksNotification = () => {
  const { t } = useLanguageHooks([TRANS_KEYS.CONTACTS_PROJECT, TRANS_KEYS.CONTACTS_PENDING_TASK, TRANS_KEYS.CONTACTS_WORD_FOR]);

  const [anchorEl, setAnchorEl] = useState(null);
  const userData = useSelector(getUserSelector);

  const { data, refetch } = useApi({
    queryFn: () => ignUsersApi(GET, `tasks/${userData.id}`)
  });

  const formattedDate = dateString => {
    const date = new Date(dateString);

    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    try {
      return new Intl.DateTimeFormat('en-US', options).format(date);
    } catch (error) {
      return '';
    }
  };

  const deleteTask = async id => {
    await logAnActivityApi(DELETE, `activity/remove/${id}`);
    refetch();
  };

  const items = isArray(data?.data) ? data?.data : [];

  return (
    <div>
      <Tooltip title='Tasks'>
        <IconButton
          onClick={e => {
            setAnchorEl(e.currentTarget);
          }}
        >
          <Task />
        </IconButton>
      </Tooltip>
      <Menu
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        style={{
          maxHeight: '60vh'
        }}
        className='mt-1 right-0'
        anchorEl={anchorEl}
      >
        {items.map(task => (
          <MenuItem key={task.id} className='px-2'>
            <Card className='px-2 py-1'>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'start'
                }}
              >
                <div>
                  <p className='fs-10'>
                    {formattedDate(task?.activity_added_at)}
                    <span className='ml-2'>
                      <strong>{t(`${TRANS_KEYS.CONTACTS_PENDING_TASK}:key`)}</strong> {t(`${TRANS_KEYS.CONTACTS_WORD_FOR}:key`)} {task?.created_user?.name}
                    </span>
                  </p>
                  <p className='fs-11'>
                    <strong>{t(`${TRANS_KEYS.CONTACTS_PROJECT}:key`)}</strong>: <u>{task?.project?.name}</u>
                  </p>
                  <ReactQuill readOnly={true} value={task?.notes} modules={{ toolbar: false }} className='w-100 flex' theme='bubble' id='task-notes' />
                </div>
                <IconButton
                  onClick={() => {
                    deleteTask(task.id);
                  }}
                  size='small'
                  className='p-0 pl-2 pb-2'
                >
                  <Close className='fs-16' />
                </IconButton>
              </Box>
            </Card>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default TasksNotification;
