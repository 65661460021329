import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Switch } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLanguageHooks } from '../../utils/Hooks/UseLanguageHook';
import { TRANS_KEYS } from '../../utils/languageNamespaces';
import DatePickerCommon from '../common/DatePicker/DatePicker';
import CompanySelection from '../common/FunctionalComponents/CompanySelection';
import ProbabilitySelection from '../common/FunctionalComponents/ProbabilitySelection';
import SearchStageSelection from '../common/FunctionalComponents/SearchStageSelection';
import CustomInputField from '../common/StyledComponents/CustomInputField';
// import { formatDate } from '../../utils/date';
import BDStatusSelection from '../common/FunctionalComponents/BDStatusSelection';
import BrandSelection from '../common/FunctionalComponents/BrandSelection';
import RevenueMarketSelection from '../common/FunctionalComponents/RevenueMarketSelection';
// import BasicPartnerSelection from '../common/FunctionalComponents/BasicPartnerSelection';
import { FormControlLabel, InputLabel, Typography } from '@material-ui/core';
import { ignCompanyDataApi, picklistDropDownApis, picklistsDataApis } from '../../services/ApiService';
import { DROP_DOWN_PICKLIST, GET, PICKLISTS } from '../../services/constantService';
import useApi from '../../utils/Hooks/useApiHook';
import { getInputLabelPropsBasicDetails } from '../../utils/common';
import { formatDateUtcLocal } from '../../utils/date';
import CustomMuiDropdown from '../common/CustomMuiDropdown';
import BDProjectTypeSelection from '../common/FunctionalComponents/BDProjectTypeSelection';
import HorizontalPracticeSelection from '../common/FunctionalComponents/HorizontalPracticeSelection';
import JobFunctionSelection from '../common/FunctionalComponents/JobFunctionSelection';
import ResultSelection from '../common/FunctionalComponents/ResultSelection';
import VerticalPracticeSelection from '../common/FunctionalComponents/VerticalPracticeSelection';
import GeoLocationInput from '../common/GeoLocation';
import RichText from '../common/RichText';
// import ClientContacts from '../../Containers/Searches/ViewProject/ClientContacts';

const BasicDetails = props => {
  const { register, setValue = () => {}, currentValues, isEditing, watch, isProject = false, companyId /* projectData */ /* , control, currentValues, isHide */ } = props;
  const [jobFunction, setJobFunction] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [contactByCompany, setContactByCompany] = useState([]);
  const [companyByBD, setCompanyByBD] = useState({});
  const [companyChangeId, setCompanyChangeId] = useState();
  const [minExperience, setMinExperience] = useState(null);
  const [minExperienceError, setMinExperienceError] = useState(false);
  const [maxExperience, setMaxExperience] = useState(null);
  const [maxExperienceError, setMaxExperienceError] = useState(false);
  const [minCompensation, setMinCompensation] = useState(null);
  const [minCompensationError, setMinCompensationError] = useState(false);
  const [maxCompensation, setMaxCompensation] = useState(null);
  const [maxCompensationError, setMaxCompensationError] = useState(false);
  const { t } = useLanguageHooks([
    TRANS_KEYS.BD_BASIC_INFO,
    TRANS_KEYS.BD_OPPORTUNITY,
    TRANS_KEYS.BD_STAGE,
    TRANS_KEYS.BD_COMPANY,
    TRANS_KEYS.BD_JOB_TITLE,
    TRANS_KEYS.BD_PROJECTED_START_DATE,
    TRANS_KEYS.BD_LOCATION,
    TRANS_KEYS.BD_CONSULTANT,
    TRANS_KEYS.BD_PROBABILITY,
    TRANS_KEYS.BD_BD_STATUS,
    TRANS_KEYS.BD_SERVICE_OFFERING,
    TRANS_KEYS.BD_GEOGRAPHY,
    TRANS_KEYS.BD_COMPETITION,
    TRANS_KEYS.ADD_PROJECT_SELECT_JOB_TYPE,
    TRANS_KEYS.ADD_PROJECT_INPUT_EXPERIENCE_TO,
    TRANS_KEYS.ADD_PROJECT_INPUT_EXPERIENCE_FROM,
    TRANS_KEYS.ADD_PROJECT_LABEL_COMPENSATION_FREQUENCY,
    TRANS_KEYS.ADD_PROJECT_LABEL_CURRENCY_TYPE,
    TRANS_KEYS.ADD_PROJECT_INPUT_ADDITIONAL_INFORMATION,
    TRANS_KEYS.CONTACTS_JOB_FUNCTION,
    TRANS_KEYS.CONTACTS_INDUSTRIES,
    TRANS_KEYS.HORIZONTAL_PRACTICE,
    TRANS_KEYS.VERTICAL_PRACTICE,
    TRANS_KEYS.RESULT,
    TRANS_KEYS.PROJECT_TYPE,
    TRANS_KEYS.PROJECT_VC_PE,
    TRANS_KEYS.ENCORE_NUMBER,
    TRANS_KEYS.ADD_PROJECT_INPUT_MIN_COMPENSATION,
    TRANS_KEYS.ADD_PROJECT_INPUT_MAX_COMPENSATION,
    TRANS_KEYS.ADD_PROJECT_SELECT_HIRING_MANAGER,
    TRANS_KEYS.CONTACTS_ADD_CONTACT,
    TRANS_KEYS.ADD_PROJECT_SELECT_CLIENT_CONTACT_DETAILS,
    TRANS_KEYS.START_DATE,
    TRANS_KEYS.LOE_DATE,
    TRANS_KEYS.OFFHOLD_DATE,
    TRANS_KEYS.END_DATE,
    TRANS_KEYS.CONTACTS_INDUSTRY
  ]);
  useEffect(() => {
    console.log('====================================');
    console.log(currentValues, isProject);
    console.log('====================================');
    let id = companyId ? companyId : companyChangeId ? companyChangeId : currentValues?.company_id;
    fetchContacts(id);
    setMinExperience(currentValues?.min_experience);
    setMaxExperience(currentValues?.max_experience);
    setMinCompensation(currentValues?.min_compensation);
    setMaxCompensation(currentValues?.max_compensation);
  }, [currentValues]);

  useEffect(() => {
    console.log({ paramsEffectCurrentValues: currentValues });
    setCompanyByBD(currentValues?.ign_companies);
  }, [currentValues]);

  const fetchContacts = async companyId => {
    if (companyId) {
      const contacts = await ignCompanyDataApi(GET, {}, companyId, '?tabName=ASSOCIATED_CONTACTS_TAB');

      setContactByCompany(contacts?.data);
      console.log(contactByCompany);
    }
  };

  useEffect(() => {
    let id = companyId ? companyId : companyChangeId;
    fetchContacts(id);
  }, [currentValues, companyChangeId]);

  useEffect(() => {
    register('is_confidential');
  }, [register]);

  const {
    data: jobTypeResponse
    // success: isJobTypeResponse
  } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, PICKLISTS.JOB_TYPE);
    }
  });
  const { data: currencyTypeResponse } = useApi({
    queryFn: () => {
      return picklistDropDownApis(GET, DROP_DOWN_PICKLIST.CURRENCIES);
    }
  });

  const {
    data: compensationFrequencyResponse
    // success: isCompensationFrequencyResponse,
  } = useApi({
    queryFn: () => {
      return picklistsDataApis(GET, PICKLISTS.COMPENSATION_FREQUENCY);
    }
  });

  const { data: targetIndustryOptionsResponse } = useApi({
    queryFn: () => {
      return picklistDropDownApis(GET, DROP_DOWN_PICKLIST.INDUSTRIES_PICKLIST);
    }
  });

  useEffect(() => {
    setValue('industry_id', currentValues?.ign_industries?.id, { shouldDirty: true });
    setIndustries(currentValues?.ign_industries);
  }, [currentValues]);

  const handleJobFunctionChange = value => {
    const jobFunction = value?.map(item => {
      return { job_functions: item, job_function_id: item.id };
    });
    setValue('project_job_function', jobFunction || null, { shouldDirty: true });
    setJobFunction(jobFunction);
  };

  useEffect(() => {
    if (isEditing) {
      const field = [
        'ign_companies',
        'job_number',
        'stage_desc',
        'stage',
        'client_team',
        'job_title',
        'projected_start_date',
        'country',
        'basic_partners',
        'probability',
        'bd_status',
        'service_offering',
        'service_offering_desc',
        'markets',
        'competition',
        'market',
        'is_nda',
        'is_confidential',
        'project_client_team',
        'job_type',
        'result',
        'pay_frequency',
        'vertical_practice',
        'horizontal_practice',
        'currency',
        'bd_currency',
        'bd_currency_values',
        'project_type',
        'vc_pe',
        'loe_date'
      ];
      field.forEach(fieldName => {
        if (fieldName === 'country') {
          setValue('country_id', currentValues[fieldName]?.id);
        } else if (fieldName === 'market') {
          setValue('market', currentValues['markets']);
        } else if (fieldName === 'stage') {
          setValue('stage', currentValues['stage'] ? currentValues['stage'] : 'Draft');
        } else {
          setValue(fieldName, currentValues[fieldName]);
        }
      });
      setIndustries(currentValues['ign_industries']);
    }
  }, [currentValues]);

  /*const getHiringManager = data => {
    if (data && data.length > 0) {
      // Check if data is not null and not empty
      const hiringManager = data.find(item => item && item.hiring_mgr); // Check if item exists and has hiring_mgr as true
      if (hiringManager) {
        return hiringManager.contact ? hiringManager.contact.name : ''; // Check if contact exists before accessing its name
      }
    }
    return ''; // Default value if no hiring manager is found
  };*/

  const handleCompanySelectionChange = (e, data) => {
    const companyId = data ? data?.id : '';
    setCompanyChangeId(companyId);
  };
  const handleIndustryChange = (e, value) => {
    setValue('industry_id', value?.id, { shouldDirty: true });
    setIndustries(value);
  };

  /*const handleChangeHiringManager = data => {
    if (data) {
      const hiringManager = { ...data, hiring_mgr: true }; // Add hiring_mgr separately
      setValue('hiringManager', hiringManager);

      const projectClientTeam = watch('project_client_team');
      if (projectClientTeam && projectClientTeam.length > 0) {
        const existingItem = projectClientTeam.find(existing => existing.name === hiringManager.id);
        if (!existingItem) {
          // Update the state of project_client_team using setWatch (assuming it's a state variable)
          setValue('project_client_team', [...projectClientTeam, hiringManager]);
        }
      } else {
        // If project_client_team is empty, initialize it with hiringManager
        setValue('project_client_team', [hiringManager]);
      }
    } else {
      setValue('hiringManager', data);
    }
  };*/
  console.log({ companyByBD });
  return (
    <Box>
      <div className='p-2 pl-3 fs-18 my-2'>
        <b>Basic</b>
      </div>
      <Box id='education-experiences' className='education-container'>
        <Box className='my-cards flex flex-wrap'>
          <Box className='form-section flex flex-column pr-5'>
            {/* {!isProject && <Box className="text-label text-center fs-16 pl-1 pb-1 header-font my-4">
              {t(`${TRANS_KEYS.BD_BASIC_INFO}:key`)}
            </Box>} */}
            <Box className='flex flex-row py-2'>
              <Box className='col-md-4'>
                {companyByBD?.name ? (
                  <CustomInputField label={t(`${TRANS_KEYS.BD_COMPANY}:key`)} defaultValue={companyByBD?.name} disabled size='small' />
                ) : (
                  <CompanySelection
                    viewAddress={true}
                    value={watch('ign_companies')}
                    label={t(`${TRANS_KEYS.BD_COMPANY}:key`)}
                    onChange={(e, data) => {
                      setValue('ign_companies', data, { shouldDirty: true });
                      handleCompanySelectionChange(e, data);
                    }}
                    required={true}
                    disabled={false}
                    size='small'
                    InputLabelProps={{
                      style: getInputLabelPropsBasicDetails(watch('ign_companies') || companyByBD?.name)
                    }}
                  />
                )}
              </Box>
              <Box className='col-md-4'>
                <CustomInputField
                  defaultValue={currentValues?.job_title || ''}
                  value={watch('job_title')}
                  label={t(`${TRANS_KEYS.BD_JOB_TITLE}:key`)}
                  required={true}
                  onChange={e => {
                    setValue('job_title', e.target.value, { shouldDirty: true });
                  }}
                  size='small'
                  InputLabelProps={{
                    style: getInputLabelPropsBasicDetails(watch('job_title') || currentValues?.job_title),
                    shrink: watch('job_title') || currentValues?.job_title ? true : false
                  }}
                />
              </Box>
              <Box className='col-md-4'>
                <SearchStageSelection
                  value={watch('stage_desc') || (currentValues?.stage_desc ? currentValues?.stage_desc : 'Draft' || null)}
                  defaultValue={currentValues?.stage_desc ? currentValues?.stage_desc : 'Draft' || null}
                  onChange={() => {
                    setValue('stage', currentValues?.stage);
                    setValue('stage_desc', currentValues?.stage_desc || null, { shouldDirty: true });
                  }}
                  label={t(`${TRANS_KEYS.BD_STAGE}:key`)}
                  required={true}
                  disabled={true}
                  className={'w-100'}
                  size='small'
                />
              </Box>
            </Box>

            <Box className='flex flex-row py-2'>
              <Box className='col-md-4'>
                <DatePickerCommon
                  value={watch('projected_start_date') ? formatDateUtcLocal(watch('projected_start_date'), 'MM/DD/YYYY') : null}
                  format='MM/DD/YYYY'
                  placeholder='mm/dd/yyyy'
                  onChange={e => {
                    const newDate = e?.$d ? formatDateUtcLocal(e?.$d, 'MM-DD-YYYY') : null;
                    setValue('projected_start_date', newDate, { shouldDirty: true });
                  }}
                  label={t(`${TRANS_KEYS.BD_PROJECTED_START_DATE}:key`)}
                  required={true}
                  className={'w-100 DatePickerRequired'}
                  isDefaultDatePickerStyle={false}
                  slotProps={{
                    textField: {
                      size: 'small',
                      InputLabelProps: {
                        style: getInputLabelPropsBasicDetails(watch('projected_start_date'))
                      }
                    }
                  }}
                />
              </Box>
              <Box className='col-md-4'>
                <GeoLocationInput
                  label={t(`${TRANS_KEYS.BD_LOCATION}:key`)}
                  val={currentValues?.location ? currentValues?.location : currentValues?.country ? currentValues?.country?.name : '' || ''}
                  onChange={(event, location) => {
                    setValue('location', location?.description, { shouldDirty: true });
                    setValue('location_place_id', location?.place_id);
                  }}
                  className='w-100'
                  size='small'
                  required={true}
                />
              </Box>
              <Box className='col-md-4'>
                <BrandSelection
                  value={watch('service_offering_desc') ? watch('service_offering_desc') : watch('service_offering') || null}
                  onChange={(e, data) => {
                    setValue('service_offering', data?.short_desc, { shouldDirty: true });
                    setValue('service_offering_desc', data, { shouldDirty: true });
                  }}
                  label={t(`${TRANS_KEYS.BD_SERVICE_OFFERING}:key`)}
                  required={true}
                  className={'w-100'}
                  size='small'
                  InputLabelProps={{
                    style: getInputLabelPropsBasicDetails(watch('service_offering_desc') || watch('service_offering'))
                  }}
                />
              </Box>
            </Box>

            <Box className='flex flex-row py-2'>
              <Box className='col-md-4'>
                <BDStatusSelection
                  defaultValue={currentValues?.bd_status || null}
                  value={watch('bd_status') || null}
                  onChange={(e, data) => {
                    setValue('bd_status', data?.field_value, { shouldDirty: true });
                  }}
                  label={t(`${TRANS_KEYS.BD_BD_STATUS}:key`)}
                  className={'w-100'}
                  size='small'
                  InputLabelProps={{
                    style: getInputLabelPropsBasicDetails(watch('bd_status') || currentValues?.bd_status)
                  }}
                />
              </Box>
              <Box className='col-md-4'>
                <ProbabilitySelection
                  defaultValue={currentValues?.probability || null}
                  value={watch('probability') || null}
                  onChange={(e, data) => {
                    setValue('probability', data?.field_value, { shouldDirty: true });
                  }}
                  label={`${t(`${TRANS_KEYS.BD_PROBABILITY}:key`)} %`}
                  required={true}
                  className={'w-100'}
                  size='small'
                  InputLabelProps={{
                    style: getInputLabelPropsBasicDetails(watch('probability') || currentValues?.probability)
                  }}
                />
              </Box>
              <Box className={'col-md-4'}>
                <RevenueMarketSelection
                  defaultValue={currentValues?.markets?.market || null}
                  value={watch('markets')?.market || null}
                  onChange={(e, data) => {
                    setValue('markets', data, { shouldDirty: true });
                  }}
                  label={t(`${TRANS_KEYS.BD_GEOGRAPHY}:key`)}
                  required={true}
                  className={'w-100'}
                  size='small'
                  InputLabelProps={{
                    style: getInputLabelPropsBasicDetails(watch('markets')?.market || currentValues?.markets?.market)
                  }}
                />
              </Box>
            </Box>

            <Box className='flex flex-row py-2'>
              <Box className='col-md-12 multiInput'>
                <CustomMuiDropdown
                  className={'w-100'}
                  // multiple={true}
                  options={targetIndustryOptionsResponse?.data ?? []}
                  getOptionLabel={option => option.name}
                  value={industries}
                  defaultValue={currentValues?.ign_industries}
                  onChange={handleIndustryChange}
                  label={t(`${TRANS_KEYS.CONTACTS_INDUSTRY}:key`)}
                  required={true}
                  size='small'
                  InputLabelProps={{
                    style: getInputLabelPropsBasicDetails(industries || currentValues?.ign_industries)
                  }}
                />
              </Box>
            </Box>

            <Box className='flex flex-row py-2'>
              <Box className='col-md-12 multiInput'>
                <JobFunctionSelection
                  multiple={true}
                  defaultValue={currentValues?.project_job_function}
                  value={currentValues?.project_job_function}
                  onChange={value => {
                    handleJobFunctionChange(value);
                  }}
                  label={t(`${TRANS_KEYS.CONTACTS_JOB_FUNCTION}:key`)}
                  isCheckBox={true}
                  disableCloseOnSelect={true}
                  selectedList={jobFunction}
                  className={'w-100'}
                  size='small'
                ></JobFunctionSelection>
              </Box>
            </Box>

            <Accordion defaultExpanded={true} className='panel-bottom-margin w-100 p-0' style={{ background: '#fff', color: '#fff' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: '#222' }} />} aria-controls='panel1a-content' style={{ background: '#ddeedd' }}>
                <div style={{ color: '#222' }}>Additional Information</div>
              </AccordionSummary>
              <AccordionDetails className='px-0 '>
                <Box className='flex flex-row justify-content-between py-2'>
                  <Box className='col-md-4'>
                    <CustomMuiDropdown
                      options={jobTypeResponse?.data ?? []}
                      multiple={false}
                      defaultValue={currentValues?.job_type || null}
                      value={watch('job_type') || null}
                      onChange={(event, data) => {
                        setValue('job_type', data?.field_value, { shouldDirty: true });
                      }}
                      label={t(`${TRANS_KEYS.ADD_PROJECT_SELECT_JOB_TYPE}:key`)}
                      name='jobType'
                      className={'w-100'}
                      getOptionLabel={option => {
                        console.log('...))', option, jobTypeResponse?.data);
                        if (typeof option === 'string') {
                          return jobTypeResponse?.data?.find(item => item?.field_value === option)?.short_desc;
                        }
                        return option.short_desc;
                      }}
                      size='small'
                      InputLabelProps={{
                        style: getInputLabelPropsBasicDetails(watch('job_type') || currentValues?.job_type)
                      }}
                    />
                  </Box>
                  <Box className='col-md-4 flex flex-row justify-content-between'>
                    <Box width={'47%'}>
                      <CustomInputField
                        size='small'
                        label={t(`${TRANS_KEYS.ADD_PROJECT_INPUT_EXPERIENCE_FROM}:key`)}
                        type='number'
                        name='experienceFrom'
                        fullWidth={false}
                        value={minExperience}
                        inputPropsMin={0}
                        error={minExperienceError}
                        onChange={e => {
                          const newValue = e.target.value;
                          if (newValue === '' || (Number(newValue) >= 0 && !isNaN(newValue))) {
                            setMinExperienceError(false);
                            setValue('min_experience', newValue, { shouldDirty: true });
                            setMinExperience(newValue);
                          } else {
                            setValue('min_experience', 0, { shouldDirty: true });
                            setMinExperience(0);
                            setMinExperienceError(true);
                          }
                        }}
                        InputLabelProps={{
                          style: getInputLabelPropsBasicDetails(minExperience || watch('min_experience'))
                        }}
                      />
                    </Box>
                    <Box width={'47%'}>
                      <CustomInputField
                        size='small'
                        label={t(`${TRANS_KEYS.ADD_PROJECT_INPUT_EXPERIENCE_TO}:key`)}
                        variant='outlined'
                        color='secondary'
                        type='number'
                        fullWidth={false}
                        name='experienceTo'
                        value={maxExperience}
                        inputPropsMin={0}
                        error={maxExperienceError}
                        onChange={e => {
                          const newValue = e.target.value;
                          if (newValue === '' || (Number(newValue) >= 0 && !isNaN(newValue))) {
                            setMaxExperienceError(false);
                            setValue('max_experience', newValue, { shouldDirty: true });
                            setMaxExperience(newValue);
                          } else {
                            setValue('max_experience', 0, { shouldDirty: true });
                            setMaxExperience(0);
                            setMaxExperienceError(true);
                          }
                        }}
                        InputLabelProps={{
                          style: getInputLabelPropsBasicDetails(maxExperience || watch('max_experience'))
                        }}
                      />
                    </Box>
                  </Box>
                  <Box className='col-md-4'>
                    <ResultSelection
                      defaultValue={currentValues?.result || null}
                      value={watch('result') || null}
                      onChange={(e, data) => {
                        setValue('result', data?.field_value, { shouldDirty: true });
                      }}
                      label={`${t(`${TRANS_KEYS.RESULT}:key`)}`}
                      className={'w-100'}
                      size='small'
                      InputLabelProps={{
                        style: getInputLabelPropsBasicDetails(watch('result') || currentValues?.result)
                      }}
                    />
                  </Box>
                </Box>
                <Box className='flex flex-row justify-content-between py-2'>
                  <Box className='col-md-4'>
                    <CustomMuiDropdown
                      getOptionLabel={option => {
                        if (typeof option === 'string') {
                          return compensationFrequencyResponse?.data?.find(item => item?.field_value === option)?.short_desc;
                        }
                        return option.short_desc;
                      }}
                      options={compensationFrequencyResponse?.data ?? []}
                      multiple={false}
                      defaultValue={currentValues?.pay_frequency || null}
                      value={watch('pay_frequency') || null}
                      onChange={(event, data) => {
                        setValue('pay_frequency', data?.field_value, { shouldDirty: true });
                      }}
                      label={t(`${TRANS_KEYS.ADD_PROJECT_LABEL_COMPENSATION_FREQUENCY}:key`)}
                      name='compensationFrequency'
                      className={'w-100'}
                      size='small'
                      InputLabelProps={{
                        style: getInputLabelPropsBasicDetails(watch('pay_frequency') || currentValues?.pay_frequency)
                      }}
                    />
                  </Box>
                  <Box className='col-md-4'>
                    <VerticalPracticeSelection
                      defaultValue={currentValues?.vertical_practice || null}
                      value={watch('vertical_practice') || null}
                      onChange={(e, data) => {
                        setValue('vertical_practice', data?.field_value, { shouldDirty: true });
                      }}
                      label={`${t(`${TRANS_KEYS.VERTICAL_PRACTICE}:key`)}`}
                      className={'w-100'}
                      size='small'
                      InputLabelProps={{
                        style: getInputLabelPropsBasicDetails(watch('vertical_practice') || currentValues?.vertical_practice)
                      }}
                    />
                  </Box>
                  <Box className='col-md-4'>
                    <HorizontalPracticeSelection
                      defaultValue={currentValues?.horizontal_practice || null}
                      value={watch('horizontal_practice') || null}
                      onChange={(e, data) => {
                        setValue('horizontal_practice', data?.field_value, { shouldDirty: true });
                      }}
                      label={`${t(`${TRANS_KEYS.HORIZONTAL_PRACTICE}:key`)}`}
                      className={'w-100'}
                      size='small'
                      InputLabelProps={{
                        style: getInputLabelPropsBasicDetails(watch('horizontal_practice') || currentValues?.horizontal_practice)
                      }}
                    />
                  </Box>
                </Box>
                <Box className='flex flex-row justify-content-between py-2'>
                  <Box className='col-md-4'>
                    <CustomMuiDropdown
                      options={currencyTypeResponse?.data ?? []}
                      getOptionLabel={option => {
                        if (typeof option === 'string') {
                          return currencyTypeResponse?.data?.find(item => item?.field_value === option)?.code;
                        }
                        return option.code;
                      }}
                      multiple={false}
                      defaultValue={currentValues?.bd_currency_values ? currentValues?.bd_currency_values : null}
                      value={watch('bd_currency_values') ? watch('bd_currency_values') : null}
                      onChange={(event, data) => {
                        // console.log('=====', data);
                        setValue('currency', data?.field_value, { shouldDirty: true });
                        setValue('bd_currency', data?.id, { shouldDirty: true });
                        setValue('bd_currency_values', data, { shouldDirty: true });
                      }}
                      label={t(`${TRANS_KEYS.ADD_PROJECT_LABEL_CURRENCY_TYPE}:key`)}
                      name='currencyType'
                      className={'w-100'}
                      size='small'
                      InputLabelProps={{
                        style: getInputLabelPropsBasicDetails(watch('bd_currency_values') || currentValues?.bd_currency_values)
                      }}
                    />
                  </Box>
                  <Box className='col-md-4'>
                    <CustomInputField
                      size='small'
                      label={t(`${TRANS_KEYS.ADD_PROJECT_INPUT_MIN_COMPENSATION}:key`)}
                      variant='outlined'
                      color='secondary'
                      type='number'
                      inputPropsMin={0}
                      fullWidth
                      value={minCompensation}
                      error={minCompensationError}
                      onChange={e => {
                        const newValue = e.target.value;
                        if (newValue === '' || (Number(newValue) >= 0 && !isNaN(newValue))) {
                          setMinCompensationError(false);
                          setValue('min_compensation', newValue, { shouldDirty: true });
                          setMinCompensation(newValue);
                        } else {
                          setValue('min_compensation', 0, { shouldDirty: true });
                          setMinCompensation(0);
                          setMinCompensationError(true);
                        }
                      }}
                      name='minCompensation'
                      className={'w-100'}
                      InputLabelProps={{
                        style: getInputLabelPropsBasicDetails(watch('min_compensation') || minCompensation)
                      }}
                    />
                  </Box>
                  <Box className='col-md-4'>
                    <CustomInputField
                      size='small'
                      label={t(`${TRANS_KEYS.ADD_PROJECT_INPUT_MAX_COMPENSATION}:key`)}
                      variant='outlined'
                      color='secondary'
                      type='number'
                      inputPropsMin={0}
                      fullWidth
                      name='maxCompensation'
                      value={maxCompensation}
                      error={maxCompensationError}
                      onChange={e => {
                        const newValue = e.target.value;
                        if (newValue === '' || (Number(newValue) >= 0 && !isNaN(newValue))) {
                          setMaxCompensationError(false);
                          setValue('max_compensation', newValue, { shouldDirty: true });
                          setMaxCompensation(newValue);
                        } else {
                          setValue('max_compensation', 0, { shouldDirty: true });
                          setMaxCompensation(0);
                          setMaxCompensationError(true);
                        }
                      }}
                      InputLabelProps={{
                        style: getInputLabelPropsBasicDetails(watch('max_compensation') || maxCompensation)
                      }}
                    />
                  </Box>
                </Box>
                <Box className='flex flex-row py-2'>
                  <Box className='col-md-4'>
                    <CustomInputField
                      value={watch('competition') || currentValues?.competition || null}
                      label={t(`${TRANS_KEYS.BD_COMPETITION}:key`)}
                      onChange={e => {
                        setValue('competition', e.target.value, { shouldDirty: true });
                      }}
                      size='small'
                      InputLabelProps={{
                        style: getInputLabelPropsBasicDetails(watch('competition') || currentValues?.competition)
                      }}
                    />
                  </Box>
                  <Box className='col-md-4'>
                    <BDProjectTypeSelection
                      defaultValue={currentValues?.project_type || null}
                      value={watch('project_type') || null}
                      onChange={(e, data) => {
                        setValue('project_type', data?.field_value, { shouldDirty: true });
                      }}
                      label={`${t(`${TRANS_KEYS.PROJECT_TYPE}:key`)}`}
                      className={'w-100'}
                      size='small'
                      InputLabelProps={{
                        style: getInputLabelPropsBasicDetails(watch('project_type') || currentValues?.project_type)
                      }}
                    />
                  </Box>
                </Box>

                <Box className='flex flex-row justify-content-between py-2'>
                  <Box className='col-md-12 multiInput'>
                    <CompanySelection
                      label={t(`${TRANS_KEYS.PROJECT_VC_PE}:key`)}
                      defaultValue={currentValues?.vc_pe || []}
                      value={watch('vc_pe') || []}
                      onChange={(e, data) => {
                        const listVCPE = data.map(item => {
                          if (typeof item === 'string') {
                            return item;
                          }
                          return item.name;
                        });
                        setValue('vc_pe', listVCPE ? listVCPE : [], { shouldDirty: true });
                      }}
                      multiple={true}
                      className={'w-100'}
                      size='small'
                    />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded={true} className='panel-bottom-margin w-100 p-0' style={{ background: '#fff', color: '#fff' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: '#222' }} />} aria-controls='panel1a-content' style={{ background: '#ddeedd' }}>
                <div style={{ color: '#222' }}>Timeline</div>
              </AccordionSummary>
              <AccordionDetails className='px-0 '>
                <Box className='flex flex-row justify-content-between py-2'>
                  <Box className='col-md-4'>
                    <DatePickerCommon
                      value={currentValues?.projected_start_date ? formatDateUtcLocal(currentValues?.projected_start_date, 'MM/DD/YYYY') : null}
                      format='MM/DD/YYYY'
                      placeholder='Start Date'
                      onChange={e => {
                        const newDate = e?.$d ? formatDateUtcLocal(e?.$d, 'MM-DD-YYYY') : null;
                        setValue('timeline_start_date', newDate, { shouldDirty: true });
                      }}
                      label={t(`${TRANS_KEYS.START_DATE}:key`)}
                      required={true}
                      className={'w-100'}
                      isDefaultDatePickerStyle={false}
                      slotProps={{
                        textField: {
                          size: 'small',
                          InputLabelProps: {
                            style: getInputLabelPropsBasicDetails(watch('timeline_start_date') || currentValues?.projected_start_date)
                          }
                        }
                      }}
                    />
                  </Box>
                  <Box className='col-md-4'>
                    <DatePickerCommon
                      value={watch('loe_date') || currentValues?.loe_date ? formatDateUtcLocal(currentValues?.loe_date, 'MM/DD/YYYY') : null}
                      format='MM/DD/YYYY'
                      placeholder='LOE Date'
                      onChange={e => {
                        const newDate = e?.$d ? formatDateUtcLocal(e?.$d, 'MM-DD-YYYY') : null;
                        setValue('loe_date', newDate, { shouldDirty: true });
                      }}
                      label={t(`${TRANS_KEYS.LOE_DATE}:key`)}
                      required={true}
                      className={'w-100'}
                      isDefaultDatePickerStyle={false}
                      slotProps={{
                        textField: {
                          size: 'small',
                          InputLabelProps: {
                            style: getInputLabelPropsBasicDetails(watch('loe_date') || currentValues?.loe_date)
                          }
                        }
                      }}
                    />
                  </Box>
                  <Box className='col-md-4'>
                    <DatePickerCommon
                      value={currentValues?.projected_start_date ? formatDateUtcLocal(currentValues?.projected_start_date, 'MM/DD/YYYY') : null}
                      format='MM/DD/YYYY'
                      placeholder='Offhold Date'
                      onChange={e => {
                        const newDate = e?.$d ? formatDateUtcLocal(e?.$d, 'MM-DD-YYYY') : null;
                        setValue('projected_start_date', newDate, { shouldDirty: true });
                      }}
                      label={t(`${TRANS_KEYS.OFFHOLD_DATE}:key`)}
                      required={true}
                      className={'w-100'}
                      isDefaultDatePickerStyle={false}
                      slotProps={{
                        textField: {
                          size: 'small',
                          InputLabelProps: {
                            style: getInputLabelPropsBasicDetails(watch('projected_start_date') || currentValues?.projected_start_date)
                          }
                        }
                      }}
                    />
                  </Box>
                </Box>

                <Box className='flex flex-row justify-content-between py-2'>
                  <Box className='col-md-4'>
                    <DatePickerCommon
                      value={currentValues?.projected_start_date ? formatDateUtcLocal(currentValues?.projected_start_date, 'MM/DD/YYYY') : null}
                      format='MM/DD/YYYY'
                      placeholder='End Date'
                      onChange={e => {
                        const newDate = e?.$d ? formatDateUtcLocal(e?.$d, 'MM-DD-YYYY') : null;
                        setValue('projected_start_date', newDate, { shouldDirty: true });
                      }}
                      label={t(`${TRANS_KEYS.END_DATE}:key`)}
                      required={true}
                      className={'w-100'}
                      isDefaultDatePickerStyle={false}
                      slotProps={{
                        textField: {
                          size: 'small',
                          InputLabelProps: {
                            style: getInputLabelPropsBasicDetails(watch('projected_start_date') || currentValues?.projected_start_date)
                          }
                        }
                      }}
                    />
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Box className='flex flex-row flex-wrap d-flex'>
              <Box className='row d-flex '>
                <Box className='col-md-6'>
                  <Box className='education-input-base'>
                    <Box className='education-input d-flex' sx={{ width: '25vw' }}>
                      <Typography className='p-2 text-dark' variant='body2'>
                        {'Confidential'}
                      </Typography>
                      <FormControlLabel
                        className='mb-0'
                        control={
                          <Switch
                            {...register('is_confidential')}
                            className='SwitchStyle'
                            checked={watch('is_confidential') || false}
                            onChange={e => {
                              setValue('is_confidential', e.target.checked);
                            }}
                          ></Switch>
                        }
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className='col-md-6'>
                  <Box className='education-input-base'>
                    <Box className='education-input multiInput d-flex' sx={{ width: '25vw' }}>
                      <Typography className='p-2  text-dark' variant='body2'>
                        {'Non-Disclosure Agreement'}
                      </Typography>
                      <FormControlLabel
                        className='mb-0'
                        control={
                          <Switch
                            className='SwitchStyle'
                            checked={watch('is_nda') || false}
                            onChange={e => {
                              setValue('is_nda', e.target.checked);
                            }}
                          ></Switch>
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className='row d-flex '>
              <Box className='col-md-12'>
                <Box className='education-input-base'>
                  <Box className='education-input' sx={{ width: '100%' }}>
                    <InputLabel>{t(`${TRANS_KEYS.ADD_PROJECT_INPUT_ADDITIONAL_INFORMATION}:key`)}</InputLabel>
                    <RichText
                      defaultValue={currentValues?.additional_info || ''}
                      value={watch('additional_info') || ''}
                      onChange={data => {
                        setValue('additional_info', data, { shouldDirty: true });
                      }}
                      style={{ minHeight: '150px' }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* here */}

            <Box className='flex flex-row flex-wrap d-flex p-2 pl-3 '>
              <Box className='row d-flex'>
                <Box className='col-md-4'>
                  <Box className='education-input-base'>
                    <Box className='education-input' sx={{ width: '18vw' }}>
                      {/* <RevenueMarketSelection
                        defaultValue={currentValues?.markets?.market || null}
                        onChange={(e, data) => {
                          setValue('markets', data, { shouldDirty: true });
                        }}
                        label={t(`${TRANS_KEYS.BD_GEOGRAPHY}:key`)}
                        required={true}
                      /> */}
                    </Box>
                  </Box>
                </Box>
                <Box className='col-md-4'>
                  <Box className='education-input-base'>
                    <Box className='education-input' sx={{ width: '18vw' }}>
                      {/* {isProject && (
                        <SearchStageSelection
                          defaultValue={currentValues.billing_status_desc?.short_desc === 'None' ? '--' : currentValues.billing_status_desc?.short_desc || '--'}
                          // onChange={() => {
                          //   setValue('billing_desc', currentValues?.stage)
                          //   setValue('stage_desc', currentValues?.stage_desc || null, { shouldDirty: true })
                          // }
                          // }
                          label='Billing Status'
                          required={true}
                          disabled={true}
                        />
                      )} */}
                    </Box>
                  </Box>
                </Box>

                <Box className='col-md-4'>
                  <Box className='education-input-base'>
                    <Box className='education-input multiInput' sx={{ width: '18vw' }}></Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

BasicDetails.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object,
  watch: PropTypes.func,
  userRef: PropTypes.object,
  currentValues: PropTypes.object,
  isHide: PropTypes.bool,
  isEditing: PropTypes.bool,
  isProject: PropTypes.bool,
  companyId: PropTypes.string,
  projectData: PropTypes.object
};
export default BasicDetails;
