import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, FormControl, MenuItem, Select, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import { updateViewProjectData } from '../../../../store/viewProjectSlice';

const CustomDropdownFilter = props => {
  const { api, column } = props;

  const { selectedColor } = useSelector(state => state.viewProjectReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    const filterModel = api.getFilterModel();
    if (filterModel[column.colId]) {
      dispatch(updateViewProjectData({ key: 'selectedColor', data: filterModel[column.colId].filter }));
    }
  }, [api, column.colId]);

  const onChange = colorId => {
    dispatch(updateViewProjectData({ key: 'selectedColor', data: colorId }));
    api.onFilterChanged();
  };

  return <ColorDropdown selectedColor={selectedColor} onChange={onChange} />;
};

export const ColorDropdown = ({ onChange, selectedColor, fromCandidate }) => {
  const [selectedValue, setSelectedValue] = useState(selectedColor);
  const { colors } = useSelector(state => state.viewProjectReducer);

  const renderValue = value => {
    if (value === 'NA') return <Typography>--</Typography>;
    const selectedColor = colors.find(color => color.id === value);
    return <Box marginTop={'2px'} border={'0.1px solid black'} bgcolor={selectedColor?.color_code || 'transparent'} width={12} height={12} />;
  };

  return (
    <FormControl fullWidth>
      <Select
        style={{
          fontSize: '12px'
        }}
        labelId='color-select-label'
        fontSize={'12px'}
        value={selectedValue === null ? 'NA' : selectedValue}
        onChange={event => {
          const colorId = event.target.value || null;
          setSelectedValue(colorId);
          onChange(colorId);
        }}
        sx={{
          fontSize: '12px',
          '& .MuiSelect-icon': {
            color: 'black',
            fontSize: '1.2rem'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: fromCandidate ? 'none' : null
          }
        }}
        IconComponent={KeyboardArrowDownIcon}
        renderValue={renderValue}
      >
        <Button>
          <Typography sx={{ textTransform: 'none' }} fontWeight={600} fontSize={'12px'}>
            Clear Filter
          </Typography>
        </Button>
        {colors.map(color => (
          <MenuItem key={color?.id} value={color?.id}>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={1}>
              <Box border={'0.1px solid black'} bgcolor={color?.color_code} width={12} height={12} /> <Typography fontSize={'12px'}>{color?.candidate_labels?.[0]?.label || ''}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

ColorDropdown.propTypes = {
  onChange: PropTypes.func,
  selectedColor: PropTypes.string,
  fromCandidate: PropTypes.bool
};

CustomDropdownFilter.propTypes = {
  api: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired
};

export default CustomDropdownFilter;
